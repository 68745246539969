import { Pipe, PipeTransform } from '@angular/core';
import { Step } from "app/layouts/workflow";

@Pipe({
  name: 'isNotSkipped'
})
export class IsNotSkippedPipe implements PipeTransform {
  transform(step: Step, ...args: unknown[]): unknown {    
    if(step && step.skipOn) {
      return !step.skipOn(args[0])
    }
    return true;
  }

}
