import { Injectable } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import borrowerJourneyWorkflow, { BorrowerJourneyWorkFlow } from 'app/layouts/workflow/borrower-journey';
import flipkartBobJourneyWorkflow, { FlipkartBobJourneyWorkFlow } from 'app/layouts/workflow/flipkart-bob-journey';
import flipkartJourneyWorkflow, { FlipkartJourneyWorkFlow } from 'app/layouts/workflow/flipkart-journey';
import gstJourneyWorkflow from 'app/layouts/workflow/gst-borrower-journey';
import rm1Workflow from 'app/layouts/workflow/rm1';
import { TestWorkFlow } from 'app/layouts/workflow/test';
import { TokenService } from 'app/services/token.service';
import { BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Flow, Step } from "../../workflow";
import { GtmTriggerService } from '../gtm-trigger/gtm-trigger.service';
import { JourneyService } from '../journey/journey.service';
import { SweetAlertService } from '../sweet-alert/sweet-alert.service';
type Workflow = BorrowerJourneyWorkFlow | FlipkartJourneyWorkFlow | FlipkartBobJourneyWorkFlow | TestWorkFlow;

@Injectable({
  providedIn: 'root'
})
export class FlowManagerService {
  
  mobileNumber: string;
  whatsappConsent: boolean;

  private loading = new BehaviorSubject(false);
  private flow: BehaviorSubject<any> = new BehaviorSubject(null);
  private model: BehaviorSubject<any> = new BehaviorSubject({});
  private stepper: BehaviorSubject<MatStepper> = new BehaviorSubject<MatStepper>(null);
  private workflow: BehaviorSubject<Workflow> = new BehaviorSubject<Workflow>(null);
  private layout: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private history: { flow: Flow, step: number }[] = [];
  private allTouched: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private progressBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private invalidPan: BehaviorSubject<{ pan:string, message:string }> = new BehaviorSubject(null);

  model$ = this.model.asObservable();
  flow$ = this.flow.asObservable();
  loading$ = this.loading.asObservable();
  stepper$ = this.stepper.asObservable();
  workflow$ = this.workflow.asObservable();
  layout$ = this.layout.asObservable();
  allTouched$ = this.allTouched.asObservable();
  progressBar$ = this.progressBar.asObservable();
  invalidPan$ = this.invalidPan.asObservable();

  params:any ={};
  
  constructor(public journey: JourneyService,
    public gtmTriggerService: GtmTriggerService,
    private router: Router,
    public alertService: SweetAlertService,
    public route: ActivatedRoute,
    public tokenService: TokenService) {
      this.route.queryParams.subscribe(params => {
        this.params = params;
      });
  }

  get gstins() {
    if (!this.modelValue.gstins) return [];
    return this.modelValue.gstins.filter(b => b.isBusinessVintage).sort((x, y) => x.isActive ? -1 : 1);
  }

  get isGstinsEmpty() {
    return this.gstins.every((g: { isActive: any; }) => !g.isActive);
  }

  get isGstinsInvalid() {
    return this.isGstinsEmpty || this.gstins.every((g: { isBusinessVintage: any; }) => !g.isBusinessVintage);
  }

  is5LacAndHasInvalidGstins() {
    return this.modelValue.loanamount == "Rs 1-5 Lakhs" && this.isGstinsInvalid;
  }

  setInvalidPan(error: any) {
    this.invalidPan.next(error);
  }

  get currentInvalidPan () {
    return this.invalidPan.value;
  }

  setProgressBar(value: boolean) {
    this.progressBar.next(value);
  }

  setAllTouched(value: boolean) {
    this.allTouched.next(value);
  }

  logout() {
    this.tokenService.clearToken();
    this.gotoLogin();
  }

  navigate(workflowName: any) {
    const queryParam = new URLSearchParams({...this.params, loggedIn:true}).toString();
    console.log(queryParam);
    switch (workflowName) {
      case flipkartJourneyWorkflow.key:
        this.router.navigateByUrl('/flipkart?'+queryParam)
        break;
      case flipkartBobJourneyWorkflow.key:
        this.router.navigateByUrl('/flipkart-bob?'+queryParam)
        break;
      case borrowerJourneyWorkflow.key:
        this.router.navigateByUrl('/journey?'+queryParam)
        break;
      case gstJourneyWorkflow.key:
        this.router.navigateByUrl('/?'+queryParam)
        break;
      case rm1Workflow.key:
        this.router.navigateByUrl('/rm1?'+queryParam)
        break;
    }
  }

  start(workflow: Workflow) {
    this.history = [];
    this.workflow.next(workflow);
    this.flow.next(this.workflowValue[this.workflowValue.startFlowName]);
  }

  startWith(state: { workflow: Workflow, flowName: string, step: number }): void {
    this.history = [{ flow: state.workflow.basicDetails, step: 0 }];
    this.workflow.next(state.workflow);
    const flow: Flow = this.workflowValue[state.flowName];
    const steps = flow.steps.length;
    for (let i = 0; i < state.step && i < steps; i++) {
      const step = flow.steps[i];
      if(step && step.excludeHistory) continue;
      this.history.push({
        flow: flow,
        step: i
      });
    }
    this.flow.next(this.workflowValue[state.flowName]);
    setTimeout(() => {
      this.stepperValue.selectedIndex = state.step;
    });
  }

  init(stepper: MatStepper) {
    this.stepper.next(stepper);
  }

  gotoLogin() {
    if (this.flowValue.key != 'login') {
      this.goto('login');
    }
  }

  get layoutValue() {
    return this.layout.value;
  }

  set layoutValue(value: string) {
    this.layout.next(value);
  }

  get loadingValue() {
    return this.loading.value
  }

  get flowValue(): Flow {
    return this.flow.value
  }

  get modelValue() {
    return this.model.value
  }

  get workflowValue() {
    return this.workflow.value;
  }

  set modelValue(value) {
    this.model.next(value);
  }

  get location() {
    return {
      workflowName: this.workflowValue.key,
      flowName: this.flowValue.key,
      step: this.stepperValue.selectedIndex,
      key: this.currentStep.key
    };
  }

  get stepperValue() {
    return this.stepper.value
  }

  get currentStepFields() {
    const stepIndex = this.stepperValue.selectedIndex;
    const fields = this.flowValue.steps.filter(s => !s.skipOn || !s.skipOn(this.modelValue))[stepIndex].fields as any;
    return this.parseFields(fields);
  }

  get currentStep() {
    const stepIndex = this.stepperValue.selectedIndex;
    return this.flowValue?.steps.filter(s => !s.skipOn || !s.skipOn(this.modelValue))[stepIndex]
  }

  get progressValue() {
    if (!this.workflowValue || !this.flowValue) return 0;
    const steps: Step[] = this.workflowValue.linearFlows.reduce((previousSteps, currentFlowName) => {
      const flow: Flow = this.workflowValue[currentFlowName];
      flow.steps.forEach(step => previousSteps.push(step));
      return previousSteps;
    }, []);
    const fields = steps.filter(s => !s.skipOn || !s.skipOn(this.modelValue)).reduce((previous, currentStep) => {
      let currentFields = this.parseFields(currentStep.fields);
      currentFields = this.unwrapFields(currentFields);
      currentFields.forEach(v => previous.push(v));
      return previous;
    }, [])
    let currentFields = this.parseFields(this.currentStep.fields);
    currentFields = this.unwrapFields(currentFields);
    const lastCurrentField = currentFields.length > 0 ? currentFields[currentFields.length - 1] : { key: '' };
    let fieldIndex = fields.findIndex(field => !this.modelValue[field.key as string] || field.key == lastCurrentField.key);
    const result = Math.ceil(((fieldIndex) / fields.length) * 100);
    return result;
  }


  private unwrapFields(currentFields: FormlyFieldConfig[]) {
    if (currentFields.length == 1 && currentFields[0].fieldGroup)
      currentFields = currentFields[0].fieldGroup;
    return currentFields;
  }

  skipTo(flowName: string, step: number) {
    this.pushCurrentStepInHistory();
    this.flow.next(this.workflowValue[flowName]);
    setTimeout(() => {
      this.stepperValue.selectedIndex = step;
    });
  }

  private pushCurrentStepInHistory() {
    if (!this.currentStep.excludeHistory) {
      this.history.push({ flow: this.flowValue, step: this.stepperValue.selectedIndex });
    }
  }

  saveLocation() {
    if (this.modelValue._id) {
      return this.journey.updateLocation(this.modelValue._id, { location: this.location })
    }
    return of();
  }

  stepNext() {
    this.setAllTouched(false);
    this.pushCurrentStepInHistory();
    this.stepperValue.next();
  }

  stepPrevious() {
    if (this.currentStep.back) this.currentStep.back(this);
    else {
      const previousStep = this.history.pop();
      this.flow.next(previousStep.flow);
      setTimeout(() => this.stepperValue.selectedIndex = previousStep.step);
    }
  }

  finish() {
    this.setAllTouched(false);
    this.pushCurrentStepInHistory();
    this.flowValue.onFinish(this.modelValue, this);
  }

  throwError(err: any) {
    this.flowValue.onError(err, this);
  }

  goto(flowName: string, options:{excludeHistory:boolean} = { excludeHistory: false}) {
    this.setAllTouched(false);
    if(!options.excludeHistory) this.pushCurrentStepInHistory();
    this.flow.next(this.workflowValue[flowName]);
    this.stepperValue.reset();
  }

  startLoading() {
    this.loading.next(true);
  }

  stopLoading(): void {
    this.loading.next(false);
  }

  hasV2TrafficFulfilled (data: { v2DocumentUploadCount: number; recentApplicationCount: number; v2DocumentUploadThreshold: number; }) {
    let currentThreshold = data.v2DocumentUploadCount/data.recentApplicationCount;
    return currentThreshold >= data.v2DocumentUploadThreshold
  }

  private parseFields(fields) {

    let config: FormlyFieldConfig[];
    if (Array.isArray(fields)) {
      config = fields as FormlyFieldConfig[];
    }
    else if (fields) {
      config = fields(this);
    }

    return config || [];
  }

  isMobile() {
    const result = this.isMobileChecker().any();
    return result;
  }

  isMobileChecker() {
    return {
      Android: () => {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: () => {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: () => {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: () => {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: () => {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: () => {
        return (this.isMobileChecker().Android() || this.isMobileChecker().BlackBerry() || this.isMobileChecker().iOS() || this.isMobileChecker().Opera() || this.isMobileChecker().Windows());
      }
    }
  };
}

