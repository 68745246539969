import { getDateComponents } from "../utils/functions";
import { stateList } from "../utils/constants";
import FuzzySet from 'fuzzyset.js';

export
  class ExperianConsent {

  constructor(borrower?: any, format?: string) {
    if (borrower) {
      this.firstName = borrower["first_name"]?.toUpperCase();
      if (this.firstName) {
        this.firstName = this.firstName.substring(0, 26);
      }
      this.surName = borrower["last_name"]?.toUpperCase();
      if (this.surName) {
        this.surName = this.surName.substring(0, 26);
      }
      this.mobileNumber = borrower["userId"];
      this.email = borrower["email"].toLowerCase();
      this.gender = borrower["gender"] == "Male" ? 1 : 2;
      this.city = borrower["residence_city"]?.toUpperCase();
      if (this.city) {
        this.city = this.city.substring(0, 40);
      }
      this.stateName = borrower["residence_state"]?.toUpperCase();
      this.flatNo = borrower["residential_address"]?.toUpperCase();
      if (this.flatNo) {
        this.flatNo = this.flatNo.substring(0, 40); 
      }
      let stateKeys = Object.keys(stateList);
      if (stateKeys.indexOf(this.stateName?.toUpperCase()) >= 0) {
        this.state = parseInt(stateList[this.stateName?.toUpperCase()]);
      } else {
        let fs = FuzzySet(stateKeys);
        let results = fs.get(this.stateName?.toUpperCase());
        if (results.length > 0) {
          this.state = parseInt(stateList[results[0][1]]);
        }
      }
      if (format) {
        this.setDateOfBirth(borrower['dob'], format);
      }
      this.pinCode = borrower['residential_pincode'];
      this.pan = borrower['pan']?.toUpperCase();
      this.dealId = borrower['Deal_Id']?.toUpperCase();
      this.term = 'off';
    }
  }

  _token: string;
  firstName: string;
  surName: string;
  mobileNumber: string;
  email: string;
  gender: number;
  day: string;
  year: string;
  month: string;
  dateOfBirth: string;
  flatNo: string;
  city: string;
  stateName: string;
  state: number;
  pinCode: string;
  pan: string;
  term: string;
  dealId: string;

  setDateOfBirth(dob: string, format: string) {
    var date = getDateComponents(dob, format);
    this.day = this.twoDigit(date.day);
    this.month = this.twoDigit(date.month);
    this.year = this.twoDigit(date.year);
    this.dateOfBirth = `${this.year}-${this.month}-${this.day}`;
  }

  twoDigit(n) {
    return n > 9 ? "" + n : "0" + n;
  }
}
