import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { map, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'formly-field-input-mask',
  templateUrl: './formly-input-mask.component.html',
  styleUrls: ['./formly-input-mask.component.css']
})
export class FormlyInputMaskComponent extends FieldType 
implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
  }

  pattern: string; 
  placeholder: string;
  ngOnInit() {
    this.pattern = this.to.mask;
    if (this.pattern.includes("S")) {
      this.placeholder = this.pattern.replace(/S/g, "A").replace(/0/g, "1");
    } else {
      this.placeholder = this.pattern;
    }
  }

  valueChanged(args) {
    if (this.to.autoCapitalize) {
      args.value = args.value.toUpperCase()
    }
  }
}
