import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-svg',
  templateUrl: './file-svg.component.html',
  styleUrls: ['./file-svg.component.css']
})
export class FileSvgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
