const selectedSectors = [
    {
      "label": "Agriculture and Husbandry",
      "value": "Agriculture and Husbandry"
    },
    {
      "label": "Agriculture support Activities",
      "value": "Agriculture support Activities"
    },
    {
      "label": "Hospital activities",
      "value": "Hospital activities"
    },
    {
      "label": "Medical/ Dental/ Nursing and Pathology etc.",
      "value": "Medical/ Dental/ Nursing and Pathology etc."
    },
    {
      "label": "Educational Support Services",
      "value": "Educational Support Services"
    },
    {
      "label": "Fishing and Aqua culture",
      "value": "Fishing and Aqua culture"
    }
  ];

  export default selectedSectors;