import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { TokenService } from 'app/services/token.service';

@Component({
  selector: 'application-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatusComponent implements OnInit {

  status_response: any;
  wa_message = '';
  loggedIn: boolean = false;
  ce_status:any = {
    dealId: '',
    applied : true,
    documents_collected : false,
    ce_approved: false,
    rejected_at_ops: false,
    rejected_at_credit: false,
    rejected_at_lender: false,
    archived_at_ops: false,
    archived_at_credit: false,
    archived_at_lender: false,
    loan_approved:false,
    loan_disbursed: false,
    lenders_approved: [],
    lenders_disbursed: [],
    lenders_declined: [],
    lenders_inprogress: [],
    journey_reject: false
  };
  statuses = [];
  constructor(private tokenService: TokenService,
    private http:HttpClient) {
      this.tokenService.clearToken();
  }

  ngOnInit(): void {
    
    this.tokenService.token$.subscribe(token => {
      if (token)
      {
        this.loggedIn = true;
        this.http.get<any>(`api/Journey/getapplicationstatus`, { headers: { 'x-api-version': '2.0'} }).subscribe(response => {
          this.status_response = response;
          if (this.status_response.crm)
          {
            this.wa_message = "Hi! I would like to get more details about my application " + this.status_response.deal.dealId;
            this.ce_status.dealId = this.status_response.deal.dealId;
            this.ce_status['appliedOn'] = this.status_response.deal.appliedOn;

            if (this.status_response.deal.status == 'Rejected')
            {
              this.ce_status['rejectedOn'] = this.status_response.deal.rejectedOn;
              if (this.status_response.deal.documentsSharedOn) {
                this.ce_status['documentsSharedOn'] = this.status_response.deal.appliedOn;
                this.ce_status.documents_collected = true;
                if (this.status_response.dealX && this.status_response.dealX.length) {
                  this.ce_status.ce_approved = true;
                  this.ce_status['sharedWithLenderOn'] = this.status_response.deal.sharedWithLenderOn;
                  this.ce_status.rejected_at_lender = true;
                } else {
                  this.ce_status.rejected_at_credit = true;
                }
              } else {
                this.ce_status.rejected_at_ops = true;
              }
            } else if (this.status_response.deal.archivedOn) {
              this.ce_status['archivedOn'] = this.status_response.deal.archivedOn;
              if (this.status_response.deal.documentsSharedOn) {
                this.ce_status['documentsSharedOn'] = this.status_response.deal.appliedOn;
                this.ce_status.documents_collected = true;
                if (this.status_response.dealX && this.status_response.dealX.length) {
                  this.ce_status.ce_approved = true;
                  this.ce_status['sharedWithLenderOn'] = this.status_response.deal.sharedWithLenderOn;
                  this.ce_status.archived_at_lender = true;
                } else {
                  this.ce_status.archived_at_credit = true;
                }
              } else {
                this.ce_status.archived_at_ops = true;
              } 
            } else {
              if (this.status_response.deal.documentsSharedOn) {
                this.ce_status['documentsSharedOn'] = this.status_response.deal.appliedOn;
                this.ce_status.documents_collected = true;
                if (this.status_response.dealX && this.status_response.dealX.length) {
                  this.ce_status.ce_approved = true;
                  this.ce_status['sharedWithLenderOn'] = this.status_response.deal.sharedWithLenderOn; 
                }
              }
            }

            if (this.status_response.dealX.find(d => d.disbursedOn)) {
              this.ce_status.loan_disbursed = true;
              this.ce_status['loan_disbursed_on'] = this.status_response.dealX.filter(d => d.disbursedOn).sort()[0];
            } else if (this.status_response.dealX.find(d => d.approvedOn)) {
              this.ce_status.loan_approved = true;
              this.ce_status['loan_approved_on'] = this.status_response.dealX.filter(d => d.approvedOn).sort()[0];
            } 

            this.ce_status.lenders_approved = this.status_response.dealX.filter(d => d.approvedOn).map(d => d.lender);
            this.ce_status.lenders_disbursed = this.status_response.dealX.filter(d => d.disbursedOn).map(d => d.lender);
            this.ce_status.lenders_declined = this.status_response.dealX.filter(d => d.rejectedOn).map(d => d.lender);
            this.ce_status.lenders_inprogress = this.status_response.dealX.filter(d => !d.rejectedOn && !d.archivedOn && !d.approvedOn && !d.disbursedOn).map(d => d.lender);
          } 
          else 
          {
            if (this.status_response.borrower.disqualified){
              this.ce_status.applied = false;
              this.ce_status.journey_reject = true;
            }
          }

          if (this.ce_status.appliedOn)
          {
              this.statuses.push({
                date: this.ce_status.appliedOn,
                details: 'You applied with us!',
                positive : 1
              });

              if (this.ce_status.documents_collected) {
                this.statuses.push({
                  date: this.ce_status.documentsSharedOn,
                  details: 'You shared the documents required to complete the preliminary assessment of your loan application.',
                  positive: 1
                });

                if (this.ce_status.ce_approved) {
                  this.statuses.push({
                    date: this.ce_status.sharedWithLenderOn,
                    details: 'Our Credit Assessment team assessed your business loan application and matched it to one/more of our lender partners.',
                    positive: 1
                  });

                  if (this.ce_status.loan_approved) {
                    this.statuses.push({
                      date: this.ce_status.loan_approved_on,
                      details: 'Congratulations! You\'ve got your first loan approval.',
                      positive: 1
                    });

                    if (this.ce_status.loan_disbursed) {
                      this.statuses.push({
                        date: this.ce_status.loan_disbursed_on,
                        details: 'Congratulations! You\'ve got your first loan disbursed.',
                        positive: 1
                      });
                    }

                  } else if (!this.ce_status.rejected_at_lender) {
                    this.statuses.push({
                      date: this.ce_status.sharedWithLenderOn,
                      details: 'Our lender partners are still assessing your application and they will get in touch with you if any more details are required from your side. Thank you for your patience.',
                      positive: 0
                    });
                  } else if (this.ce_status.rejected_at_lender) {
                    this.statuses.push({
                      date: this.ce_status.rejectedOn,
                      details: 'Unfortunately, our lender partners have declined to proceed with your loan application at this point. Drop in a message to us on Whatsapp to re-engage with CreditEnable for your business financing needs!',
                      positive: -1
                    });
                  }
                } else if (!this.ce_status.rejected_at_credit) {
                  this.statuses.push({
                    date: this.ce_status.appliedOn,
                    details: 'Our credit assessment team is still assessing your profile and will get in touch with you if any more details are required from your side. Thank you for your patience.',
                    positive: 0
                  });
                } else if (this.ce_status.rejected_at_credit) {
                  this.statuses.push({
                    date: this.ce_status.rejectedOn,
                    details: 'Unfortunately, we could not proceed with the application at this point. Drop in a message to us on Whatsapp to re-engage with CreditEnable for your business financing needs!',
                    positive: -1
                  });
                }
              } else if (!this.ce_status.rejected_at_ops) {
                this.statuses.push({
                  date: this.ce_status.appliedOn,
                  details: 'We are still awaiting documents from your side to process your loan application',
                  positive: 0
                });
              } else if (this.ce_status.rejected_at_ops) {
                this.statuses.push({
                  date: this.ce_status.rejectedOn,
                  details: 'Unfortunately, we could not proceed with the application at this point. Drop in a message to us on Whatsapp to re-engage with CreditEnable for your business financing needs!',
                  positive: -1
                });
              }
          } else {
            this.statuses.push({
              date: this.status_response.borrower.created_at,
              details: 'You applied with us but unfortunately, we could not proceed with the application at this point given the information you had provided :(',
              positive: -1
            });
          }
        });  
      }
      else
      {
        this.loggedIn = false;
      }
    });
  }
}
