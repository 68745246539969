import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AzureMonitoringService } from './services/logging.service';
import { NgbDateIndiaParserFormatter } from './ngbdate-india-parserformatter';
import { AuthInterceptor } from './services/auth.interceptor';
import { TokenService } from './services/token.service';
import { UtilityService } from './services/utility.service';
import { MsalService } from '@azure/msal-angular'
import { NgxHotjarService } from 'ngx-hotjar';
import { SweetAlert2LoaderService, SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ConfirmationService } from 'primeng/api';
import { CelebrateService } from './services/celebrate.service';
import { BorrowerJourneyLayoutComponent } from './layouts/borrower-journey-layout/borrower-journey-layout.component';
import { FlowManagerService  } from './layouts/services/flow-manager/flow-manager.service';
import { JourneyService } from './layouts/services/journey/journey.service';
import { BorrowerJourneyLayoutModule } from './layouts/borrower-journey-layout/borrower-journey-layout.module';
import { FlipkartComponent } from './layouts/borrower-journey-layout/pages/flipkart/flipkart.component';
import { FlipkartBobComponent } from './layouts/borrower-journey-layout/pages/flipkart-bob/flipkart-bob.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { HomeComponent } from './layouts/borrower-journey-layout/pages/home/home.component';
import { CommonModule } from '@angular/common';
import { CountdownModule } from 'ngx-countdown';
import { GstFlowComponent } from './layouts/borrower-journey-layout/pages/gst-flow/gst-flow.component';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { GstPullOptionComponent } from './layouts/components/gst-pull-option/gst-pull-option.component';
import { GstPullStepperComponent } from './layouts/components/gst-pull-stepper/gst-pull-stepper.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Rm1FlowComponent } from './layouts/borrower-journey-layout/pages/rm1-flow/rm1-flow.component';
import { TestComponent } from './layouts/borrower-journey-layout/pages/test/test.component';
import { GstFlowNewComponent } from './layouts/borrower-journey-layout/pages/gst-flow-new/gst-flow-new.component';
import { StatusComponent } from './layouts/borrower-journey-layout/pages/status/status.component';

var paths: any = [
  {
    path: '',
    component: BorrowerJourneyLayoutComponent,
    children: [
      { path: '', component: GstFlowComponent,  pathMatch: 'full',  runGuardsAndResolvers: "always", },
      { path: 'gstnew', component: GstFlowNewComponent,  pathMatch: 'full',  runGuardsAndResolvers: "always", },
      { path: 'journey', component: HomeComponent,  pathMatch: 'full',  runGuardsAndResolvers: "always", },
      { path: 'flipkart', component: FlipkartComponent,  pathMatch: 'full',  runGuardsAndResolvers: "always", },
      // { path: 'flipkart-bob', component: FlipkartBobComponent,  pathMatch: 'full',  runGuardsAndResolvers: "always", },
      { path: 'v2', component: GstFlowComponent,  pathMatch: 'full',  runGuardsAndResolvers: "always", },
      { path: 'rm1', component: Rm1FlowComponent,  pathMatch: 'full',  runGuardsAndResolvers: "always", },
      { path: 'status', component: StatusComponent,  pathMatch: 'full',  runGuardsAndResolvers: "always", },
      { path: 'test', component: TestComponent,  pathMatch: 'full',  runGuardsAndResolvers: "always", },
      { path: 'v2/document-upload', component: TestComponent,  pathMatch: 'full',  runGuardsAndResolvers: "always", },
    ]
  },
  { path: '**', redirectTo:'' }
];



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,    
    RouterModule.forRoot(paths, {
      onSameUrlNavigation: "reload",
    }),
    NgbModule,    
    NgxMaskModule.forRoot(),
    SweetAlert2Module.forRoot(),
    CountdownModule,
    MatStepperModule,
    MatDialogModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    BorrowerJourneyLayoutModule
  ],
  providers: [
    //{ provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: NgbDateParserFormatter, useClass: NgbDateIndiaParserFormatter },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    AzureMonitoringService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ConfirmationService,
    TokenService,
    UtilityService,
    MsalService,
    NgxHotjarService,
    SweetAlert2LoaderService,
    CelebrateService,
    FlowManagerService,
    JourneyService,
  ],
  bootstrap: [AppComponent],
  entryComponents:[
    GstPullOptionComponent,
    GstPullStepperComponent
  ]
})
export class AppModule { }
