import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";


const isMabDisqualified = (model) => {
    const mabIsLessThan10000 = model.mab === "Less than INR 10,000";
    const loanIsGreaterThan5LacsAndMabIsLessThan20000 = (model.loanamount != "Rs 1-5 Lakhs" && model.mab == "INR 10,000 to INR 20,000");            
    return mabIsLessThan10000
};


const isTurnoverDisqualified = model => model.turnover == "Less than INR 12 Lakhs"

const skipItr =  (model) => {
    return model.loanamount=="Rs 1-5 Lakhs" || model.loanamount=="Rs 5-10 Lakhs"
};

const isItrDisqualified = model => {
        const hasNoItr = model.itr=="No";
        const isSecured = model.typeofloan=="Secured";
        const isAbove10 = model.loanamount!="Rs 1-5 Lakhs" && model.loanamount != "Rs 5-10 Lakhs";
        return hasNoItr &&
            (
                isSecured ||
                isAbove10
            )
}

const skipBankAccount = (model) => {
    return model.businesstype != "Sole Proprietorship" || (model.loanamount == "Rs 1-5 Lakhs" || model.loanamount == "Rs 5-10 Lakhs");
}

const isBankAccountDisqualified = model =>{
    const isSaving = model.bankaccount == "Savings account"
    const isSecured = model.typeofloan == "Secured loan";
    const isAbove10 = (model.loanamount != "Rs 1-5 Lakhs" && model.loanamount != "Rs 5-10 Lakhs");
    const notASoleProprietorship = model.businesstype != "Sole Proprietorship";
    return isSaving &&
    (isSecured ||
        isAbove10 ||
        notASoleProprietorship
    );
}




const fields: (flowManager: FlowManagerService) => FormlyFieldConfig[] = (flowManager: FlowManagerService) => (
    [
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    key: "turnover",
                    type: "question",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: "What is your annual gross sales revenue?",

                        options: [
                            { "label": "Less than ₹12 Lacs", "value": "Less than INR 12 Lakhs" },
                            { "label": "₹12 - ₹15 Lacs", "value": "INR 12 Lakhs - INR 15 Lakhs" },
                            { "label": "₹15 - ₹50 Lacs", "value": "INR 15 Lakhs - INR 50 Lakhs" },
                            { "label": "More than ₹50 Lacs", "value": "More than INR 50 Lakhs" }],
                        required: true,
                    },
                    className: "col-12 col-sm-12",
                },
                {
                    key: "mab",
                    type: "question",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: `What is your average monthly balance?`,
                        labelDescription: `(Balance maintained in your bank accounts on a consistent basis.)`,

                        options: [{ "label": "Less than ₹10,000", "value": "Less than INR 10,000" },
                        { "label": "₹10,000 - ₹20,000", "value": "INR 10,000 to INR 20,000" },
                        { "label": "₹20,001 - ₹30,000", "value": "INR 20,000 to INR 30,000" },
                        { "label": "₹30,001 - ₹50,000", "value": "INR 30,000 to INR 50,000" },
                        { "label": "More than ₹50,000", "value": "More than INR 50,000" }],
                        required: true,
                    },
                    className: "col-12 col-sm-12",
                    hideExpression: (model) => {
                        return !model.turnover || isTurnoverDisqualified(model)
                    }

                },
               
                {
                    key: "itr",
                    type: "question",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: "Have you filed your ITRs for last 2 financial years?",

                        options: [{ "label": "Yes", "value": "Yes" }, { "label": "No", "value": "No" }],
                        required: true,
                    },
                    className: "col-12 col-sm-12",
                    hideExpression: (model) => {
                        return !model.turnover || !model.mab || skipItr(model) || isTurnoverDisqualified(model) || isMabDisqualified(model)
                    }
                },
                {
                    key: "bankaccount",
                    type: "question",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: "My revenue goes into my:",

                        options: [
                            { "label": "Savings account", "value": "Savings account", icon: "icons-bank-ac-type-savings" },
                            { "label": "Current account", "value": "Current account", icon: "icons-bank-ac-type-current" },

                        ],
                        required: true,
                    },
                    className: "col-12 col-sm-12",
                    hideExpression: (model) => {
                        return !model.turnover || !model.mab || skipBankAccount(model) || (!skipItr(model) && !model.itr) || isTurnoverDisqualified(model) || isMabDisqualified(model)
                        || (!skipItr(model) && isItrDisqualified(model))
                    }
                }
            ]
        }
    ]
)


const loanDetailsDesktop: Step = {
    key: "loanDetailsDesktop",

    fields,


    conversation: (model) => {
        return {
            greeting: ` Thanks, ${model.first_name}!`,
            message: `           
            Now let's check whether your revenues and cash flow can support the loan you want. 
            This will only take a minute. 
            `
        }
    },


    next: (data: any, flowManager: FlowManagerService): Observable<any> => {

        const model = flowManager.modelValue;
        try {
            if (isMabDisqualified(model)) {
                data = {
                    ...data,
                    disqualified: true,
                    disqualificationKey: `mab`,
                    disqualificationMessage: `Your profile isn't a good match for our lender partners. 
                    Your monthly balane isn't sufficient to support the EMI payments linked to the loan 
                    you are looking for. 
                    ` }
            }

            if (isTurnoverDisqualified(model)) {
                data = {
                    ...data,
                    disqualified: true,
                    disqualificationKey: `turnover`,
                    disqualificationMessage: `Your profile isn't a good match for our lender partners. 
                    Your turnover isn't sufficient to support repayment of the loan you are looking for.  
                    ` }
            }

           
            if (isItrDisqualified(model)) {
                data = {
                    ...data,
                    disqualified: true,
                    disqualificationKey: `itr`,
                    disqualificationMessage: `Your profile isn't a good match for our lenders. 
                    Based on your input; it seems that your business is non-compliant on statutory obligations.
                    ` }
            }
            
            if (isBankAccountDisqualified(model)) {
                data = {
                    ...data,
                    disqualified: true,
                    disqualificationKey: `bankaccount`,
                    disqualificationMessage: `Your profile isn't a good match for our lenders. 
                    To get a business loan; 
                    you need to be able to show lenders that your business income goes into your business current account. 
                    This is so they can check you will have the ability to repay your EMIs.` }
            }

            if (!data.disqualified) {
                data = { ...data, qualified: true }

                flowManager.goto(workFlow.searchingLenders.key);
            }

            flowManager.startLoading()

        }
        catch (err) {
            console.log(err);
        }
        return flowManager.journey.updateJourney(flowManager.modelValue._id, { ...data }).pipe(

            tap((result) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap((result) => {
                if (result.experian_masked_flow) {
                    flowManager.goto(workFlow.experianMismatch.key);
                }
                else if (result.disqualified) {
                    if (result.disqualificationKey == 'experian') {
                        if (result.experian_score && result.is_experian_score_below_threshold) {
                            flowManager.gtmTriggerService.triggerButtonClick({ className: 'experian-disqualified' })
                        } else {
                            flowManager.gtmTriggerService.triggerButtonClick({ className: 'model-disqualified' })
                        }
                    }
                    flowManager.goto(workFlow.disQualifiedMessage.key);
                }
                else if (result.qualified) {
                    flowManager.finish();
                }
                else {
                    throw new Error("Failed to move forward");
                }
            }),
            catchError(err => {

                flowManager.throwError(err);
                return throwError(err);
            }),
            tap(_ => flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading())
        )
    },

};


export default loanDetailsDesktop;
