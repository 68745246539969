import { Component, OnInit } from '@angular/core';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';

@Component({
  selector: 'app-searching-lender-step',
  templateUrl: './searching-lender-step.component.html',
  styleUrls: ['./searching-lender-step.component.css']
})
export class SearchingLenderStepComponent implements OnInit {

  constructor(private flowManager:FlowManagerService) { }

  ngOnInit(): void {
    this.flowManager.gtmTriggerService.triggerButtonClick({ className: "stage-complete stage-2" });
  }

  conversation = (model) => ({
    greeting: `Thanks, ${model.first_name}!`,
    message: `We've got everything we need for now. Please just give me a second while I confirm your eligibility.`
  })
}
