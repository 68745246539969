import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from '../../../services/utility.service';
import { from, interval, Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AzureMonitoringService } from '../../../services/logging.service';
import { TokenService } from '../../../services/token.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NgxHotjarService } from 'ngx-hotjar';
import { relogin } from '../../../utils/functions';
import { JourneyStatus } from '../../../utils/constants';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  websiteUrl = 'https://creditenable.com/in/';
  mobileBrowser = false;  
  isUserLoggedIn = false;
  constructor(private http: HttpClient, 
    public tokenService: TokenService, 
    public router: Router, 
    private flowManager:FlowManagerService) {
    
  }

  logout() {
    this.tokenService.clearToken();
    this.flowManager.gotoLogin();
  }
  

  ngOnInit() {
    this.tokenService.token$.subscribe(token=> {
      this.isUserLoggedIn = !!token;
    });

    if (this.isMobile().any()) {
      this.mobileBrowser = true;
    }
  } 

  isMobile() {
    return {
      Android: () => {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: () => {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: () => {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: () => {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: () => {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: () => {
        return (this.isMobile().Android() || this.isMobile().BlackBerry() || this.isMobile().iOS() || this.isMobile().Opera() || this.isMobile().Windows());
      }
    }
  };
}

