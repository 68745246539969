import { Component, OnInit, AfterViewInit, AfterViewChecked, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { delay, tap } from 'rxjs/operators';

@Component({
  selector: 'formly-field-question',
  templateUrl: './formly-question.component.html',
  styleUrls: ['./formly-question.component.css']
})
export class FormlyQuestionComponent extends FieldType implements AfterViewInit, OnInit {
  loading: any;
  hasTwoFields = false;

  constructor(private flowManager:FlowManagerService, private cd: ChangeDetectorRef) {
    super();    
  }

  ngOnInit(): void {
    this.hasTwoFields = this.to.options && Array.isArray(this.to.options) && this.to.options.length == 2;
    this.flowManager.loading$
    .pipe(delay(0))
    .subscribe(loading=>{
      this.loading= loading;
      this.cd.detectChanges();
    });
  }



  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize(null);
    });

    
  }

  onResize($event) {
    let options = Array.from(document.querySelectorAll(`#${this.field.id} > div > label`));
    const heights = options.map(otp => otp.clientHeight).sort((a, b) => a - b);
    let minHeight = heights[0];
    let maxHeight = heights.reverse()[0];
    if (maxHeight > minHeight) {
      options.forEach(opt => {
        (opt as HTMLLabelElement).setAttribute('style', `height : ${maxHeight}px!important`);
      });
    }
    (this.to.options as []).filter(t => t['icon']).length > 0 ? 200 : 10
  }
}
