import { Component, Output, EventEmitter, OnInit, Input, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReloginModalComponent } from '../../../relogin/relogin-modal.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { trigger, transition, animate, style, state } from '@angular/animations'

import { interval } from 'rxjs';
import { take, map, finalize } from 'rxjs/operators';
import { UtilityService } from '../../../services/utility.service';
import { setClarity } from '../../../utils/functions';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';

@Component({
  selector: 'app-flipkart-login',
  templateUrl: './flipkart-login.component.html',
  styleUrls: ['./flipkart-login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(400, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class FlipkartLoginComponent implements OnInit, AfterViewInit {


  mobile: number;
  userOtp: string;
  validOtp: string = "Enter the OTP";
  otpRequested: boolean = false;
  hideResend = false;
  retryTime = 30;
  otpRetries = 1;
  partnerId: string = '';

  invalidOtp: boolean = false;
  token: string;
  borrower_consent: boolean = false;
  new_user: boolean = true;
  from_website: boolean = false;
  generate_otp: boolean = false;

  @Output()
  loggedIn: EventEmitter<any> = new EventEmitter<any>();

  customOptions: OwlOptions = {
    center: true,
    loop: true,
    margin: 30,
    autoplay: true,
    autoplayTimeout: 3000,
    rewind: false,
    items: 1,
    autoHeight: true,
    autoWidth: true,
    nav: false
  };

  loading = false;

  constructor(private http: HttpClient, 
    private tokenService: TokenService, 
    private modalService: NgbModal, 
    private activatedRoute: ActivatedRoute,
    private flowManager: FlowManagerService) {
    this.tokenService.clearToken();
  }

  ngAfterViewInit() {
    if (this.from_website) {
      document.getElementById('login-container').classList.add('show_all');
    }
   
  }

  ngOnInit() {
    this.partnerId =  this.activatedRoute.snapshot.queryParams.leadId;    
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params && params['leadId']) {
        this.partnerId = params['leadId'];
      }
      if (params && params.from_website) {
        this.from_website = true;
        document.body.classList.add('hide_all');
      } else if (params && params.generate_otp) {
        this.generate_otp = true;
      }
    });

    if (this.partnerId) {
      let lead = {
        journey: "flipkart",
        partnerId: this.partnerId
      };
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append("Content-Type", 'application/json');
      this.http.post('/account/partnerlead', JSON.stringify(lead), { headers: headers }).subscribe((response: any) => { });
    }

    setClarity("page", "login");
  }

  getOtp(resend?: boolean) {
    if (!this.mobile || this.mobile.toString().length != 10) {
      return;
    }
    this.invalidOtp = false;
    let headers: HttpHeaders = new HttpHeaders();
    let url = '/account/otp';
   
    url = `${url}?mobile=${this.mobile.toString()}&workflowName=${this.flowManager.workflowValue.key}`;
    if (this.generate_otp) {
      url += '&forceGenerate=true';
    }

    if (resend) {
      url += "&resend=true";
    }

    url += `&journey=flipkart`;

    if (this.partnerId) {
      url += `&partnerId=${this.partnerId}`;
    }

    this.hideResend = true;
    this.http.get(url, { headers }).subscribe((response: any) => {
      this.scroll(document.body);
      if (response.success) {
        if (this.otpRequested) {
          this.otpRetries += 1;
        } else {
          this.otpRequested = true;
        }
        setTimeout(() => this.hideResend = false, 30000);
        this.retryTime = 30;
        interval(1000).pipe(
          take(this.retryTime)
        ).map(() => --this.retryTime).subscribe(() => undefined);

        if (!environment.production) {
          this.validOtp = response.token;
        }
        this.new_user = response.userCreated;
        this.borrower_consent = !response.userCreated;
        
      } else {
        const modalRef = this.modalService.open(ReloginModalComponent, { windowClass: 'relogin-dialog' });
        modalRef.componentInstance.title = "OTP failed";
        if (response.error) {
          modalRef.componentInstance.content = "The OTP could not be sent. " + response.error;
        } else {
          modalRef.componentInstance.content = "The OTP could not be sent. Please retry";
        }
        modalRef.componentInstance.showDismiss = true;
        modalRef.componentInstance.dismissText = "Retry";
        this.hideResend = false;

        setTimeout(() => {
          this.modalService.dismissAll();
        }, 5000);
      }
    }, error => {
      this.hideResend = false;
    });
  }

  login() {
    if (!this.borrower_consent) {
      return;
    }
    this.loading = true;
    this.http.get(`/account/login?mobile=${this.mobile.toString()}&otp=${this.userOtp}&isNewUser=${this.new_user}&workflowName=${this.flowManager.workflowValue.key}`)
    .pipe(finalize(()=>  this.loading = false ))
    .subscribe((response: any) => {
      if (response.success) {
        if (this.partnerId) {
          UtilityService.setUserId(this.partnerId);
        } else {
          UtilityService.setUserId(this.mobile.toString());
        }
        this.token = response.token;
        this.tokenService.setToken(response.token);
        this.flowManager.finish();

      } else {        
        this.invalidOtp = true;
        this.userOtp = null;
        this.validOtp = null;
        this.otpRequested = false;
      }
    });
  }

  setOtpField() {
    this.userOtp = "";
    document.querySelectorAll('input.digit-box').forEach(el => {
      this.userOtp += (el as HTMLInputElement).value;
    });
  }


  getCodeBoxElement(index): HTMLElement {
    return document.getElementById('digit-' + index);
  }

  onKeyUpEvent(index, event) {
    const eventCode = event.which || event.keyCode;
    if (this.getCodeBoxElement(index) != null) {
      if (eventCode === 8 && index !== 1) {
        let prev = this.getCodeBoxElement(index - 1);
        prev.focus();
        if ((prev as HTMLInputElement).value) {
          (prev as HTMLInputElement).select();
        }
        return;
      } else if (eventCode != 9 && eventCode != 16) {
        if (index !== 6) {
          this.getCodeBoxElement(index + 1).focus();
        } else {
          this.setOtpField();
          if (document.getElementById('borrower_consent')) {
            document.getElementById('borrower_consent').focus();
          } else {
            document.getElementById('login-button').focus();
          }
        }
      }
    }
  }

  onFocusEvent(index) {
    for (let item = 1; item < index; item++) {
      const currentElement = this.getCodeBoxElement(item);
      if (currentElement != null) {
        currentElement.focus();
        break;
      }
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  scrollIfNotInViewPort(el: HTMLElement) {
    if (!this.isElementInViewport(el)) {
      this.scroll(el);
    }
  }

  isElementInViewport(el: HTMLElement) {

    var rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }
}

