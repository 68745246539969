import { Component, Input, AfterViewInit, ViewEncapsulation, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { ResponseContentType } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../../services/token.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ICategoryStepDocument, IStepSection } from 'app/layouts/constants/document-categories.modern.constant';
import { FileManagerService } from 'app/layouts/services/file-manager/file-manager';
import { delay, map, retry } from 'rxjs/operators';

@Component({
  selector: 'app-file-upload-modern',
  templateUrl: './file-upload-modern.component.html',
  styleUrls: ['./file-upload-modern.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FileUploadModernComponent implements OnInit, AfterViewInit {

  @Input() documentType: any;

  @Input()  borrower: any;

  @Input() fileOption: any;

  @Input() section: IStepSection;

  @Input() document: ICategoryStepDocument;

  allowMultiple = false;

  @ViewChild('docPond') docPond: any;

  private _inputFileCount = 0
  noOfInputs = [];
  showAddMoreBtn = false;

  set inputFileCount(value: number) {
    this._inputFileCount =value;
    //this.showAddMoreBtn = this._inputFileCount == 0;
    this.noOfInputs =   Array(this._inputFileCount).fill(0).map((x,i)=>i);
  };

  get inputFileCount() {
    return this._inputFileCount;  
  };

  file_options: any[];
  pondOptions;
  alerts:any[] = []
 

  constructor(private http: HttpClient, 
    private router: Router, 
    private tokenService: TokenService, 
    private fileManager:FileManagerService) {
   
  }

  ngOnInit(): void {
    this.initFilePond();
    this.handleFileChanges();
    this.allowMultiple = !!this.document.allowMultiple;
  }

  private handleFileChanges() {
    const isCurrentSubSectionEmpty = (files: any[]) => files
      .filter((f: { documentCategory: string; }) => f.documentCategory == this.section.doc_prepend)
      .filter((d: { documentName: string; }) => d.documentName == this.document.name).length == 0;

    const isFileInProcess = () => this.docPond && this.docPond.getFiles().length > 0;

    const updateinputFileCount = files => {
      if (this.allowMultiple) {
        this.inputFileCount = 1;
      }
      else {
        const someInputs = this.inputFileCount > 0 ? this.inputFileCount : 1;
        const noInput = 0;
        this.inputFileCount = isCurrentSubSectionEmpty(files) ? someInputs : noInput;
      }
    };

    const canUpdateInputFileCount = (files) => {
      if (isFileInProcess()) setTimeout(()=> canUpdateInputFileCount(files), 500);        
      else updateinputFileCount(files);
    }

    this.fileManager.files$
      .pipe(
        delay(100)
      )
      .subscribe(canUpdateInputFileCount);
  }

  private initFilePond() {
    this.pondOptions = {
      maxFileSize: '20MB',
      maxFiles: 20,
      instantUpload: true,
      allowMultiple: true,
      allowRevert: false,

      //allowFileTypeValidation: true,
      labelIdle: `     
      <div class='d-flex no-file-box'>
       <div class="file-info d-flex">
          <span class="no-shrink">${pdfSvg}</span>
          <span class="mx-2 text-left">${this.document.name}<span>
       </div>     
      
       <div class="actions">
          <div class="btn btn-outline-primary action-btn border-0">
            <i class="fa-solid fa-cloud-arrow-up"></i>
            Upload
          </div>
        </div>
          
      </div>
       `,

      labelMaxFileSizeExceeded: 'File is too large',
      labelMaxFileSize: 'Maximum file size is {filesize}',

      // set allowed file types with mime types
      acceptedFileTypes: this.document.acceptedFileTypes,

      // // allows mapping of file types to label values ( how type is shown in error label )
      // // "null" means don't show
      fileValidateTypeLabelExpectedTypesMap: {
        'image/jpg': '.JPG',
        'image/jpeg': '.JPEG',
        'image/png': '.PNG',
        'application/pdf': '.PDF',
        'application/x-zip-compressed': '.ZIP',
        'application/zip': '.ZIP',
        //'application/rar':'',
        'application/vnd.ms-excel': '.XLS',
        'application/msword': '.DOC',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.XLSX',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.DOCX'
      },

      // the label to show, {placeholders} are automatically replaced
      fileValidateTypeLabelExpectedTypes: 'Expects {allButLastType} or {lastType}',

      server: {
        //timeout: 30000,
        process: {
          url: "/journey/addfiles",
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.tokenService.getToken()}`
          },
          onload: (response) => {
            const uploadedFiles = JSON.parse(response).files as any[];
            this.fileManager.addFiles(uploadedFiles);
          },

          onerror: (message) => {
            if (typeof message == 'string' && message && message.trim() != '') {
              this.alerts.push({ type: 'danger', message });
            }
            else {
              this.alerts.push({ type: 'danger', message: 'Internal server error. Please try again later!' });
            }
          },

          ondata: (formData) => {
            formData.append('document_id', this.fileOption.upload_folder);
            formData.append('documentCategory', this.fileOption.value);
            formData.append('DocumentSection', this.section.name);
            formData.append('DocumentName', this.document.name);
            formData.append('DocumentUploadVersion', 'v2');
            if (this.borrower.journey && this.borrower.journey == 'dsa') {
              formData.append('mobile', this.borrower.userId);
            }
            this.alerts.length = 0;
            return formData;
          }
        },
      }
    };
  }

  addMore() {
    this.inputFileCount = this.inputFileCount + 1;
  }


  onProcessFile($event) {
    console.log($event);
    this.docPond.removeFile($event.file);
  }

  close(alert) {
    console.log("close");
    this.alerts = this.alerts.filter(a=>a!=alert);
  }


  ngAfterViewInit() {
    if (this.documentType.file_types && Object.keys(this.documentType.file_types).length > 0) {
      this.pondOptions.acceptedFileTypes = Object.keys(this.documentType.file_types);
      this.pondOptions.fileValidateTypeLabelExpectedTypesMap = this.documentType.file_types;
    }

    if (this.documentType.maxFileSize) {
      this.pondOptions.maxFileSize = this.documentType.maxFileSize;
    }

    this.file_options = this.documentType.documents.map((doc) => ({
      text: doc.name,
      value: doc.doc_prepend ? doc.doc_prepend : doc.name,
      password: !!doc.password
    }));

    if (this.documentType.repeat_for) {
      let options = [];
      this.file_options.forEach(opt => {
        let option = JSON.parse(JSON.stringify(opt));
        option.text = `${option.text} - For ${this.borrower.first_name} ${this.borrower.last_name}`;
        option.value = `${option.value} - For ${this.borrower.first_name} ${this.borrower.last_name}`;
        option.group = `${this.borrower.first_name} ${this.borrower.last_name}`;
        options.push(option);
      });

      this.borrower[this.documentType.repeat_for].forEach(group => {
        this.file_options.forEach(opt => {
          let option = JSON.parse(JSON.stringify(opt));
          option.text = `${option.text} - For ${group[this.documentType.repeat_section_label]}`;
          option.value = `${option.value} - For ${group[this.documentType.repeat_section_label]}`;
          option.group = group[this.documentType.repeat_section_label];
          options.push(option);
        });
      });
      this.file_options = options;
    }
  }





  showFileName(str: string) {
    let n = 25;
    if (str.indexOf(']_') > 0) {
      str = str.substr(str.indexOf(']_') + 2);
    }
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
  }

  isMobile() {
    return {
      Android: () => {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: () => {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: () => {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: () => {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: () => {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: () => {
        return (this.isMobile().Android() || this.isMobile().BlackBerry() || this.isMobile().iOS() || this.isMobile().Opera() || this.isMobile().Windows());
      }
    }
  };
}

const pdfSvg = `
<svg id="Group_74" data-name="Group 74" xmlns="http://www.w3.org/2000/svg" width="32.121" height="32.719" viewBox="0 0 32.121 32.719">
    <path id="Path_4" data-name="Path 4" d="M4110.1,1834.207v1.761l-1.766-1.766-3.915-3.915-1.766-1.766h1.761l0,.005,5.676,5.676Z" transform="translate(-4077.977 -1828.521)" fill="#888"/>
    <g id="Group_35" data-name="Group 35" transform="translate(0 0)">
    <g id="Group_33" data-name="Group 33" transform="translate(6.106)">
    <path id="Path_5" data-name="Path 5" d="M2231.442,1834.207h-5.054a.622.622,0,0,1-.622-.622v-5.054l-.005-.005H2207.3a1.866,1.866,0,0,0-1.866,1.866v14.092h1.245v-13.912a.8.8,0,0,1,.8-.8h17.044v4.268a1.413,1.413,0,0,0,1.413,1.413h4.268V1859.2a.8.8,0,0,1-.8.8h-21.924a.8.8,0,0,1-.8-.8v-3.694h-1.245v3.873a1.867,1.867,0,0,0,1.866,1.866h22.283a1.866,1.866,0,0,0,1.866-1.866v-25.167Z" transform="translate(-2205.432 -1828.526)" fill="#888"/>
    </g>
    <g id="Group_34" data-name="Group 34" transform="translate(0 15.958)">
    <path id="Path_6" data-name="Path 6" d="M2792.089,3784.684a1.349,1.349,0,0,0-.655-.331,6.386,6.386,0,0,0-1.162-.067h-.636v4.654h1.057a3.852,3.852,0,0,0,.856-.067,1.307,1.307,0,0,0,.572-.292,1.488,1.488,0,0,0,.37-.678,4.6,4.6,0,0,0,.143-1.287,4.212,4.212,0,0,0-.143-1.251A1.61,1.61,0,0,0,2792.089,3784.684Z" transform="translate(-2777.813 -3781.102)" fill="#888"/>
    <path id="Path_7" data-name="Path 7" d="M2121.556,3784.334a6.8,6.8,0,0,0-1.019-.048h-.684v2h.775a3.653,3.653,0,0,0,1.119-.11.929.929,0,0,0,.442-.345.946.946,0,0,0,.16-.547.91.91,0,0,0-.225-.633A.982.982,0,0,0,2121.556,3784.334Z" transform="translate(-2114.585 -3781.102)" fill="#888"/>
    <path id="Path_8" data-name="Path 8" d="M1606.748,3458.979h-24.106a1.035,1.035,0,0,0-1.035,1.035v8.952a1.035,1.035,0,0,0,1.035,1.035h24.106a1.035,1.035,0,0,0,1.035-1.035v-8.952A1.035,1.035,0,0,0,1606.748,3458.979Zm-16.144,5.244a1.925,1.925,0,0,1-.6.692,2.052,2.052,0,0,1-.736.333,8.073,8.073,0,0,1-1.472.1h-.925v2.658h-1.423v-7.034h2.279a8.27,8.27,0,0,1,1.689.105,1.843,1.843,0,0,1,1.012.688,2.179,2.179,0,0,1,.408,1.368A2.293,2.293,0,0,1,1590.6,3464.223Zm7.076,1.831a3.1,3.1,0,0,1-.752,1.243,2.491,2.491,0,0,1-1,.561,4.269,4.269,0,0,1-1.255.149h-2.669v-7.034h2.592a5.065,5.065,0,0,1,1.337.134,2.354,2.354,0,0,1,1.059.648,3.075,3.075,0,0,1,.671,1.14,5.2,5.2,0,0,1,.23,1.663A4.626,4.626,0,0,1,1597.681,3466.054Zm6.257-3.891h-3.41v1.665h2.944v1.19h-2.944v2.989h-1.423v-7.034h4.833Z" transform="translate(-1581.607 -3458.979)" fill="#888"/>
    </g>
    </g>
</svg>
`

