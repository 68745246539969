import { FormlyFieldConfig } from "@ngx-formly/core";
import { FlowManagerService } from "app/layouts/services/flow-manager/flow-manager.service";
import stateOptions from "./state-options";

export default (field:FormlyFieldConfig, flowManager:FlowManagerService) => {
    field.formControl.valueChanges.subscribe((value) => {
        flowManager.journey.isValidPincode(value).subscribe((data) => {
            const [state] = stateOptions.filter(s=>s.value == flowManager.modelValue.stateCode);
            if(state && data && data.state) {
                if (data.state.toLowerCase() != state.label.toLowerCase()){
                    field.templateOptions.alert=`You selected ${state.label} state before this. Pin code belongs to ${data.state} state.`;
                }
                else {
                    field.templateOptions.alert=""
                }
            }
            else {
                field.templateOptions.alert=""
            }
        });
    })
}