import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { mapLocation } from 'app/layouts/constants/mapLocation';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import flipkartBobJourneyWorkflow from 'app/layouts/workflow/flipkart-bob-journey';
import { TokenService } from 'app/services/token.service';
import { of } from 'rxjs';
import { catchError, delay, first, switchMap, tap } from 'rxjs/operators';
import navigate from '../../utils/handle-navigation';

@Component({
  selector: 'app-flipkart-bob',
  templateUrl: './flipkart-bob.component.html',
  styleUrls: ['./flipkart-bob.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlipkartBobComponent implements OnInit {
  params: any;
  outerLayout: string = null;
  workflow = flipkartBobJourneyWorkflow;

  constructor(private flowManagerService:
    FlowManagerService,
    private tokenService: TokenService,
    private route: ActivatedRoute) {
    this.flowManagerService.layoutValue = "flipkartBob";
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }



  ngOnInit(): void {
    this.flowManagerService.start(this.workflow);


    this.tokenService.token$.pipe(
      switchMap(token => {
        if (token) {
          this.flowManagerService.modelValue = { journey: 'flipkart-bob' };
          return this.flowManagerService.journey.getJourney(this.params).pipe(catchError(err => {
            return of({
              ...this.flowManagerService.modelValue,
              location: null,
              status: err.status
            });
          }))
        }
        else {
          return of({ status: 401 })
        }
      })
      ,
      delay(0),
      tap((data) => navigate(this.flowManagerService, data, this.workflow))
    ).subscribe();

    this.flowManagerService.stepper$.pipe(delay(0)).subscribe(stepper => {
      if (stepper) {
        this.outerLayout = this.flowManagerService.currentStep.outerLayout;
        stepper.selectionChange.asObservable().pipe(delay(0)).subscribe(() => {
          this.outerLayout = this.flowManagerService.currentStep.outerLayout;
        })
      }
    })

    this.flowManagerService.flow$.pipe(delay(0)).subscribe(() => {
      if (this.flowManagerService.stepperValue) {
        this.outerLayout = this.flowManagerService.currentStep.outerLayout;
      }
    })
  }

}

