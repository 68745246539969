import { Injectable, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { TokenService } from 'app/services/token.service';
import { BehaviorSubject, } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {
  
  private alert:BehaviorSubject<any> = new BehaviorSubject(null);
  public alert$ = this.alert.asObservable();

  constructor(private tokenService:TokenService) { 
   
  }

  show($event:{title:string, text:string, icon:string}) {
    this.alert.next($event);   
   
  }
}
