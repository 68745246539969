import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { getFormatFromComponents } from '../../functions/getFormatFromComponents.function';


@Component({
  selector: 'formly-field-date-picker',
  templateUrl: './formly-date-picker.component.html',
  styleUrls: ['./formly-date-picker.component.css']
})
export class FormlyDatePickerComponent extends FieldType implements OnInit {

  pattern: string; 
  placeholder: string;
  showDate: boolean = true;
  showMonth: boolean = true;
  showYear: boolean = true;

  defaultDate: number = 1;
  defaultMonth: number = 1;
  defaultYear: number = 1980;

  dateOptions: any[] = [];
  monthOptions: any[] = [];
  yearOptions: any[] = [];

  selectedDate: any;
  selectedMonth: any;
  selectedYear: any;

  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  ngOnInit() {
    this.setSelectOptions();

    this.placeholder = this.to.placeholder;

    if (!this.placeholder.includes("D")) {
      this.showDate = false;
      this.selectedDate = this.defaultDate;
    }

    if (!this.placeholder.includes("M")) {
      this.showMonth = false;
      this.selectedMonth = this.defaultMonth;
    }

    if (!this.placeholder.includes("Y")) {
      this.showYear = false;
      this.selectedYear = this.defaultYear;
    }

    this.pattern = this.placeholder.replace(/D/g, '0').replace(/M/g, '0').replace(/Y/g, '0');
  }

  setSelectOptions() {
    for (var i = 1; i <= 31; i++) {
      this.dateOptions.push({ label: i, value: i });
    }

    for (var i = 1; i <= 12; i++) {
      this.monthOptions.push({ label: this.months[i - 1], value: i });
    }

    let currentYear = new Date().getFullYear();
    for (var i = currentYear; i >= 1990; i--) {
      this.yearOptions.push({ label: i, value: i });
    }
  }

  dateChanged() {
    if (this.selectedDate && this.selectedMonth && this.selectedYear && !isNaN(this.selectedDate) && !isNaN(this.selectedYear)) {
      let dateValue = getFormatFromComponents(parseInt(this.selectedYear), this.selectedMonth, parseInt(this.selectedDate), this.placeholder);
      let el = document.getElementById(this.field.id) as HTMLInputElement;
      this.field.formControl.patchValue(dateValue);
      el.focus();
      el.blur();
    }
  }
}
