import { FormControl } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout, startWith, map, takeUntil } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";

const fields = (flowManager: FlowManagerService): Array<FormlyFieldConfig> =>
    [
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    key: "loanamount",
                    type: "currency",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: "Loan Amount",


                        required: true,
                    },

                    className: "col-md-6 col-sm-12",


                }
                ,
                {
                    key: "typeofloan",
                    type: "radio",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: "Loan Type",

                        options: [{ "label": "Secured loan", "value": "Secured" }, { "label": "Unsecured loan", "value": "Unsecured" }],
                        required: true,
                    },

                    className: "col-md-6 col-sm-12",


                }
                ,
                {
                    key: "first_name",
                    type: "input",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: "text",
                        label: "First Name",
                        placeholder: "As on PAN Card",

                        required: true,
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase().trim()),
                            tap(v => v == formControl.value? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: "col-md-6 col-sm-12",


                }
                ,
                {
                    key: "last_name",
                    type: "input",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: "text",
                        label: "Last Name",
                        placeholder: "As on PAN Card",

                        required: true,
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase().trim()),
                            tap(v => v == formControl.value? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: "col-md-6 col-sm-12",


                }
                ,
                {
                    key: "personal_pan",
                    type: "mask",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: "text",
                        label: "PAN of Applicant",
                        placeholder: "AAAAA1111A",
                        mask: 'SSSSS0000S',
                        required: true,
                        autoCapitalize: true
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase().trim()),
                            tap(v => v == formControl.value? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: "col-md-6 col-sm-12",


                }
                ,
                {
                    key: "dob",
                    type: "date",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: "Date of Birth",
                        placeholder: "DD/MM/YYYY",
                        required: true,
                    },
                    validators: {
                        validation: [
                            { name: 'date', options: { format: "DD/MM/YYYY" } },
                            { name: 'max-date', options: { max: 662256000, format: "DD/MM/YYYY", label:"Applicant's age" } },
                        ]
                    },
                    className: "col-md-6 col-sm-12",


                }
                ,
                {
                    key: "gender",
                    type: "radio",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: "Gender",

                        options: [{ "label": "Male", "value": "Male" }, { "label": "Female", "value": "Female" }],
                        required: true,
                    },

                    className: "col-md-6 col-sm-12",


                }
                ,
                {
                    key: "residential_address",
                    type: "input",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: "text",
                        label: "Residential Address",


                        required: true,
                    },

                    className: "col-md-6 col-sm-12",


                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.gender
                    },
                }
                ,
                {
                    key: "residential_pincode",
                    type: "pincode",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: "Pincode",


                        required: true,
                    },
                    className: "col-md-6 col-sm-12",
                    asyncValidators: {
                        isJammuKashmir: {
                            expression: (control: FormControl) => flowManager.journey.isValidPincode(control.value)
                                .pipe(
                                    map(data => !data.isJammuKashmir )),
                            message: (_error: any, field: FormlyFieldConfig) => `We are not present in this PIN code`
                        },
                        isValidPincode: {
                            expression: (control: FormControl) => flowManager.journey.isValidPincode(control.value)
                                .pipe(
                                    map(data => data.valid || data.isJammuKashmir)),
                            message: (_error: any, field: FormlyFieldConfig) => `The Pincode entered is not valid.`
                        }
                    },
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.gender
                    },
                }
                ,
                {
                    key: "email",
                    type: "input",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: "email",
                        label: "Email",
                        required: true,
                    },
                    className: "col-md-6 col-sm-12",
                    validators: {
                        validation: ['email'],
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toLowerCase().trim()),
                            tap(v => v == formControl.value? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },

                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.gender
                    },
                }
                ,
                {
                    key: "alternate_number",
                    type: "input",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: "text",
                        label: "Alternate Phone",


                        required: true,
                    },

                    className: "col-md-6 col-sm-12",


                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.gender
                    },
                }
                ,
                {
                    key: "businesstype",
                    type: "select",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: "Business Type",

                        options: [{ "label": "Proprietorship", "value": "Sole Proprietorship" }, { "label": "Partnership", "value": "Partnership" }, { "label": "Company", "value": "Private Limited" }],
                        required: true,
                    },
                    hooks: {
                        onInit: (field) => {
                          return field.formControl.valueChanges.pipe(
                            tap(value => {
                              if(value!="Proprietorship") {
                                  const model = {...flowManager.modelValue};
                                  model.partners.length = 1;
                                  flowManager.modelValue = model;
                              }
                            }),
                          );
                        },
                    },
                    className: "col-md-6 col-sm-12",
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.gender
                    },
                }
                ,
                {
                    key: "businessname",
                    type: "input",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: "text",
                        label: "Business Name",


                        required: true,
                    },
                    className: "col-md-6 col-sm-12",
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase().trim()),
                            tap(v => v == formControl.value? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.gender
                    },
                }
                ,
                {
                    key: "gstin",
                    type: "mask",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: "text",
                        label: "GSTIN registered with Flipkart",
                        placeholder: "AAAAAAAAAAAAAAA",
                        mask: "AAAAAAAAAAAAAAA",
                        required: true,
                        autoCapitalize: true
                    },
                    validators: {
                        regex: {
                            expression: (c) => !c.value || /(^([a-zA-Z0-9]{15})$)/.test(c.value),
                            message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a valid GST number. 
                            Please enter your 15 character GST number.`,
                        },
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase().trim()),
                            tap(v => v == formControl.value? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: "col-md-6 col-sm-12",


                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.gender
                    },
                }
                ,
                {
                    key: "businessdate",
                    type: "date",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: "Date of Incorporation",
                        placeholder: "MM/YYYY",

                        required: true,
                    },

                    className: "col-md-6 col-sm-12",

                    validators: {
                        businessdate: {
                            expression: (c) => {
                                if(!c.value) return false;
                                if(c.value.length < 6) return false;
                                try {
                                    const now = new Date();
                                    const values = [c.value.substring(0,2), c.value.substring(2,6)];
                                    const year = parseInt(values[1]);
                                    const month = parseInt(values[0]) -1;
                                    if(year < 1990) return false;
                                    if(month < 0 || month > 11) return false;
                                    const enterDate = new Date(year, month, 1);
                                    return enterDate < now && enterDate > new Date(1899,11,31);
                                } catch (error) {
                                    return false;
                                }
                            },
                            message: (error, field: FormlyFieldConfig) => {
                                if(field.formControl.value.length<6) return "Please enter a valid date."
                                else return `"${field.formControl.value.substring(0,2)}/${field.formControl.value.substring(2,6)}" does not seem to be a valid date.`
                            },
                        },
                    },
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.gender
                    },
                }
                ,
                {
                    key: "pan",
                    type: 'mask',
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: "text",
                        label: "Business PAN",
                        placeholder: 'AAAAA1111A',
                        mask: 'SSSSS0000S',
                        required: true,
                        autoCapitalize: true
                    },
                    className: "col-md-6 col-sm-12",
                    validators: {
                        regex: {
                            expression: (c) => !c.value || /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/.test(c.value),
                            message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a valid PAN. 
                            Please enter your 10 character PAN.`,
                        },
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase().trim()),
                            tap(v => v == formControl.value? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.businesstype || model.businesstype == "Sole Proprietorship"
                    },
                }
                ,
                {
                    key: "business_address",
                    type: "input",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: "text",
                        label: "Business Address",


                        required: true,
                    },

                    className: "col-md-6 col-sm-12",


                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.gender
                    },
                }
                ,
                {
                    key: "pincode",
                    type: "pincode",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        label: "Business Pincode",
                        placeholder: 'eg. 110011',
                        required: true,
                    },
                    className: "col-md-6 col-sm-12",
                    asyncValidators: {
                        isJammuKashmir: {
                            expression: (control: FormControl) => flowManager.journey.isValidPincode(control.value)
                                .pipe(
                                    map(data => !data.isJammuKashmir )),
                            message: (_error: any, field: FormlyFieldConfig) => `We are not present in this PIN code`
                        },
                        isValidPincode: {
                            expression: (control: FormControl) => flowManager.journey.isValidPincode(control.value)
                                .pipe(
                                    map(data => data.valid || data.isJammuKashmir)),
                            message: (_error: any, field: FormlyFieldConfig) => `The Pincode entered is not valid.`
                        }
                    },
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.gender
                    },
                }
                ,


                {
                    key: 'partners',
                    type: 'repeat',
                    templateOptions: {
                        label:'Partner/Director Details',
                        addText: 'Add Partner/Director',
                        required: true,
                    },
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !flowManager.modelValue.businesstype || flowManager.modelValue.businesstype == "Sole Proprietorship"
                    },
                    className: "col-md-12",
                    fieldArray: {
                        fieldGroupClassName: "row",
                        fieldGroup: [

                            {
                                key: "name",
                                type: "input",
                                modelOptions: {
                                    updateOn: 'change'
                                },
                                templateOptions: {
                                    type: "text",
                                    label: "Name of Partner/Director",
                                    placeholder: "Add Partner/Director",

                                    required: true,
                                },
                                className: "col-md-6 col-sm-12",
                                hooks: {
                                    onInit: ({ formControl }) => formControl.valueChanges.pipe(
                                        startWith(formControl.value),
                                        map(v => v || ""),
                                        map(v => v.toUpperCase().trim()),
                                        tap(v => v == formControl.value? {} : formControl.patchValue(v)),
                                    ).subscribe()
                                },
                                
                            },
                            {
                                key: "pan",
                                type: 'mask',
                                modelOptions: {
                                    updateOn: 'change'
                                },
                                templateOptions: {
                                    type: "pan",
                                    label: "PAN of Partner/Director",
                                    placeholder: 'AAAAA1111A',
                                    mask: 'SSSSS0000S',
                                    required: true,
                                    autoCapitalize: true
                                },
                                className: "col-md-6 col-sm-12",
                                validators: {
                                    regex: {
                                        expression: (c) => !c.value || /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/.test(c.value),
                                        message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a valid PAN. 
                                        Please enter your 10 character PAN.`,
                                    },
                                },
                                hooks: {
                                    onInit: ({ formControl }) => formControl.valueChanges.pipe(
                                        startWith(formControl.value),
                                        map(v => v || ""),
                                        map(v => v.toUpperCase().trim()),
                                        tap(v => v == formControl.value? {} : formControl.patchValue(v)),
                                    ).subscribe()
                                },
                               
                            },

                        ],
                    },
                },
            ]
        }
    ]


const stepOne: Step = {
    key: 'stepOne',
    
    fields,

    hideBack: true,

    label: 'Provide your details',

    outerLayout: 'flipkartOuterLayout',

    innerLayout: 'flipkartStep',

    conversation: (model) => ({
        greeting: `Hi! I'm Lakshmi.`,
        message: `I will help you find the loan that's right for you.
        <i class='d-block sub-header'>(Don't worry, this should only take a few minutes)</i>
        `
    }),

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        flowManager.startLoading()
        let payload = { ...data, location: flowManager.location, ...flowManager.modelValue, qualified: true };
        const partnerId  = flowManager.route.snapshot.queryParams.leadId;
        if(partnerId) {
            payload = { ...payload, partnerId }
        }
        return flowManager.journey.createJourney(payload).pipe(
            catchError(err => {
                if (err.error.includes("Borrower already exist")) {
                    return flowManager.journey.updateJourney(flowManager.modelValue._id, payload);
                }
                else {
                    flowManager.throwError(err);
                    return throwError(err);
                }
            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap(() => flowManager.finish()),

            tap(_=>flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },
}

export default stepOne;
