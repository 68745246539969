import { Component, Input, OnInit, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../../services/token.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { relogin, setClarity } from '../../../utils/functions';
import { UserIdleService } from 'angular-user-idle';
import { Subscription } from 'rxjs';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ExperianResponse } from '../../../definitions/experian-report';
import { ExperianConsent } from '../../../definitions/experian-consent';
import { ExperianReportModalComponent } from '../../../experian/experian-report-modal.component';
import { UtilityService } from '../../../services/utility.service';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { documentList } from 'app/layouts/constants/document-list.constant';

@Component({
  selector: 'app-flipkart-document-upload-step',
  templateUrl: './flipkart-document-upload-step.component.html',
  styleUrls: ['./flipkart-document-upload-step.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlipkartDocumentUploadStepComponent implements OnInit {


  get borrower() {
    return this.flowManager.modelValue;
  }

  set borrower(value) {
    this.flowManager.modelValue = value;
  }

  layout = '';

 
  @Input()
  files: any[];

  document_types;
  doc_type;
  filesInDocType;

  dealId: string;
  dealDate: string;
  modalRef: NgbModalRef;
  experianPullRequired: boolean = false;
  sortComplete = false;
  documentUploadComplete: boolean = false;

  timerSubscription: Subscription;
  timeoutSubscription: Subscription;
  pingSubscription: Subscription;
  submitting: boolean = false;

  experianResponse: ExperianResponse;
  experianModalRef: NgbModalRef;
  experianLoading: boolean = false;
  showPullConsent: boolean = false;
  isSubmitted: boolean = false;
  experian_consent: boolean = false;

  @ViewChild('successSwal') private successSwal: SwalComponent;
  @ViewChild('experianFailureSwal') private experianFailureSwal: SwalComponent;

  owlOptions: OwlOptions = {
    loop: false,
    margin: 30,
    autoplay: true,
    autoplayTimeout: 3000,
    rewind: true,
    responsive: { 0: { items: 2, nav: false, autoHeight: false, autoWidth: false }, 600: { items: 3, nav: false, autoHeight: false, autoWidth: false }, 800: { items: 6, nav: false, autoHeight: false, autoWidth: false }, 1000: { items: 6, nav: false, autoHeight: false, autoWidth: false } }
  };


  constructor(private modalService: NgbModal,
    private http: HttpClient, private router:
      Router, private tokenService: TokenService,
    private userIdle: UserIdleService,
    private flowManager: FlowManagerService) {

  }


  ngOnInit() {
    //this.flowManager.gtmTriggerService.triggerButtonClick({ className: 'stage-complete stage-qualified'});
        
    this.flowManager.layout$.subscribe(layout=> this.layout = layout);

    this.http.get('/journey/experianpullrequired').subscribe((response: any) => {
      if (response.experianPullRequired) {
        this.experianPullRequired = true;
      }
    });

    this.loadData();

    this.flowManager.model$.subscribe(() => {
      this.loadData();      
    });

    this.http.get('/journey/get').subscribe((response: any) => {
      this.files = response.files;
      this.userIdle.startWatching();
      this.watchIdle();
      this.checkRequiredStatus();  
      this.checkFileCategoryStatus();    
    });    
  }

  loadData() {
    const [{categories}] = documentList.filter(d=>d.predicate(this.borrower));
      this.document_types = categories || [];


      if (this.borrower.Deal_Id)
        this.borrower.CE_Lead_ID = this.borrower.Deal_Id;
      this.borrower.borrower_created_at = new Date(this.borrower.created_at);

      if (this.borrower.last_updated_at) {
        this.borrower.updated_at = new Date(this.borrower.last_updated_at);
        this.dealDate = this.borrower.updated_at;
      }
      else
        this.dealDate = this.borrower.borrower_created_at;

      this.dealId = this.borrower.CE_Lead_ID;
  }

  filesPresent(docType) {
    return this.files && this.files.filter(c => c.documentType == docType.document_id).length > 0;
  }


  open(docType, content) {

    setClarity("document", docType.document_id);

    this.filesInDocType = this.files ? this.files.filter(c => c.documentType == docType.document_id) : [];
    this.doc_type = docType;
    this.modalService.open(content,
      {
        windowClass: ''
      }).result.then((result) => {
      }, (reason) => {
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  filesAdded(eventArgs: any[]) {
    this.files = this.files.concat(eventArgs);
    this.checkFileCategoryStatus();
  }

  fileIdRemoved(eventArgs: any) {
    this.files.splice(this.files.findIndex(c => c.id == eventArgs), 1);
    this.checkFileCategoryStatus();
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    if (this.timeoutSubscription) {
      this.timeoutSubscription.unsubscribe();
    }

    if (this.pingSubscription) {
      this.pingSubscription.unsubscribe();
    }
  }

  watchIdle() {
    this.pingSubscription = this.userIdle.ping$.subscribe(next => {
      if (this.tokenService.getToken()) {
        this.tokenService.refreshToken();
      }
    });
  }

  checkFileCategoryStatus() {
    if (this.document_types) {
      this.document_types.forEach(docType => {
        let file_options = docType.documents.map((doc) => ({
          text: doc.name,
          value: doc.doc_prepend ? doc.doc_prepend : doc.name,
          required: !!doc.required
        }));

        if (docType.repeat_for && this.borrower[docType.repeat_for]) {
          let options = [];

          file_options.forEach(opt => {
            let option = JSON.parse(JSON.stringify(opt));
            option.text = `${option.text} - For ${this.borrower.first_name} ${this.borrower.last_name}`;
            option.value = `${option.value} - For ${this.borrower.first_name} ${this.borrower.last_name}`;
            option.group = `${this.borrower.first_name} ${this.borrower.last_name}`;
            options.push(option);
          });

          this.borrower[docType.repeat_for].forEach(group => {
            file_options.forEach(opt => {
              let option = JSON.parse(JSON.stringify(opt));
              option.text = `${option.text} - For ${group[docType.repeat_section_label]}`;
              option.value = `${option.value} - For ${group[docType.repeat_section_label]}`;
              option.group = group[docType.repeat_section_label];
              options.push(option);
            });
          });
          file_options = options;
        }

        docType.pendingFilesCount = file_options.filter(option => this.files.findIndex(f => f.documentType == docType.document_id && (f.documentCategory == option.value || f.documentCategory == option.text)) < 0 && option.required).length;
        docType.options = file_options;
        docType.requiredFilesCount = file_options.filter(option => option.required).length;
      });
      this.documentUploadComplete = this.document_types.filter(docType => !docType.eval_is_optional && docType.pendingFilesCount > 0).length == 0;
      this.sortComplete = true;
    }
  }

  documentUploadCompleted() {
    this.submitting = true;

    let url = '/journey/documentuploadcomplete';
    if (this.borrower.journey && this.borrower.journey == 'dsa') {
      url += `?mobile=${this.borrower.userId}`;
    }

    this.http.post(url, null).subscribe((res: any) => {
      if (res.success) {
        this.borrower = res.borrower;
        this.successSwal.fire().then(response => { });
        this.submitting = false;
      }
    }, error => {
      this.submitting = false;
    });
  }

  checkRequiredStatus() {
    if (this.document_types) {
      this.document_types.forEach(docType => {
        if (docType.is_optional) {
          docType.eval_is_optional = eval(docType.is_optional);
        } else {
          docType.eval_is_optional = false;
        }

        docType.documents.forEach(doc => {
          doc.hide = false;
          if (doc.hide_criteria) {
            doc.hide = eval(doc.hide_criteria);
          }
        });
        docType.hideCategory = docType.documents.filter(c => !c.hide).length == 0;
        docType.documents = docType.documents.filter(c => !c.hide);
      });

      this.document_types = this.document_types.filter(c => !c.hideCategory).sort((x, y) => {
        // true values first
        return (x.eval_is_optional === y.eval_is_optional) ? 0 : x.eval_is_optional ? 1 : -1;
      });
    }
  }

  experianPull() {
    let experianModel = new ExperianConsent(this.borrower, 'DD/MM/YYYY');
    experianModel.term = 'on';
    this.experianLoading = true;
    this.http.post('/journey/experianpull', JSON.stringify(experianModel)).subscribe((response: any) => {
      this.experianLoading = false;
      this.experianResponse = response.data as ExperianResponse;
      this.showPullConsent = false;
      this.experianPullRequired = false;
      if (this.experianResponse && this.experianResponse.isSuccess) {
        this.experianModalRef = this.modalService.open(ExperianReportModalComponent, { windowClass: 'experian-dialog' });
        this.experianModalRef.componentInstance.report = this.experianResponse.report;
        this.experianModalRef.result.then((result) => {
        }, (reason) => {
          
        });
      } else {
        this.experianFailureSwal.fire().then(response => { });
      }
    }, err => {
      
      this.experianFailureSwal.fire().then(response => { });
      this.experianLoading = false;
    });
  }
}

