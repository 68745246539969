import { Component, Input, OnInit, ViewEncapsulation, OnDestroy, ViewChild, EventEmitter, Output, HostListener } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../../services/token.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { setClarity } from '../../../utils/functions';
import { UserIdleService } from 'angular-user-idle';
import { Subscription } from 'rxjs';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ExperianResponse } from '../../../definitions/experian-report';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { documentList } from 'app/layouts/constants/document-list.constant';
import { documentCategories } from 'app/layouts/constants/depreciated/document-categories.constant';
import { GtmTriggerService } from 'app/layouts/services/gtm-trigger/gtm-trigger.service';

@Component({
  selector: 'app-document-upload-step',
  templateUrl: './document-upload-step.component.html',
  styleUrls: ['./document-upload-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentUploadStepComponent implements OnInit, OnDestroy {

  get borrower() {
    return this.flowManager.modelValue;
  }

  set borrower(v) {
    this.flowManager.modelValue = v;
  }

  get IsAllFileUpload() {
    if(!this.document_types) return false;
    return this.document_types.filter(c => !c.isComplete).length == 0;
  }

  get IsSomeFileUploaded() {
    return this.files.length > 0
  }

  fileCountChanged = new EventEmitter<void>();

  @Input()
  files: any[]=[];

  get lastUploadedFile() {
    if(this.files && this.files.length > 0)
      return this.files[this.files.length-1];
    else {
      return null;
    }
  }

  get lastUploadedFileType() {
    if(this.lastUploadedFile) {
      return this.lastUploadedFile.documentType;
    }
    else {
      return null;
    }
  }

  document_types =[];
  doc_type;
  filesInDocType: any = {};

  dealId: string;
  dealDate: string;
  modalRef: NgbModalRef;
  experianPullRequired: boolean = false;
  sortComplete = false;
  documentUploadComplete: boolean = false;

  timerSubscription: Subscription;
  timeoutSubscription: Subscription;
  pingSubscription: Subscription;
  submitting: boolean = false;

  experianResponse: ExperianResponse;
  experianModalRef: NgbModalRef;
  experianLoading: boolean = false;
  showPullConsent: boolean = false;
  isSubmitted: boolean = false;
  experian_consent: boolean = false;
  document_index = 0;

  accordion_selected = -1;

  @ViewChild('successSwal') private successSwal: SwalComponent;
  @ViewChild('experianFailureSwal') private experianFailureSwal: SwalComponent;
  @ViewChild('experianSuccessSwal') private experianSuccessSwal: SwalComponent;

  syncCRMTimeout: any;

  owlOptions: OwlOptions = {
    loop: false,
    margin: 30,
    autoplay: true,
    autoplayTimeout: 3000,
    rewind: true,
    responsive: { 0: { items: 2, nav: false, autoHeight: false, autoWidth: false }, 600: { items: 3, nav: false, autoHeight: false, autoWidth: false }, 800: { items: 6, nav: false, autoHeight: false, autoWidth: false }, 1000: { items: 6, nav: false, autoHeight: false, autoWidth: false } }
  };
  file_options: any;
  documentType: any;
  category: any;
  file_folder: any;


  @ViewChild('docPond') docPond: any;

  fileRemoved = new EventEmitter<any>();
  selectedDocument: any;
  password: string;
  conversation = (model) => ({      
    greeting:  ``,
    message:`Please upload the following documents within 24 hours to get a loan within 2-3 days` 
  })

  constructor(private modalService: NgbModal,
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    private userIdle: UserIdleService,
    public flowManager: FlowManagerService,
    private gtmService:GtmTriggerService) {
      
  }

  ngOnInit() {      
    this.loadDocumentType();
    this.checkFileCategoryStatus();
    this.http.get('/journey/get').subscribe((response: any) => {
      this.files = response.files;      
      this.updateDealDetails();
      this.checkRequiredStatus();     
      this.checkFileCategoryStatus(); 
      this.setExistingFilesByDocumentType();  
      this.userIdle.startWatching();
      this.watchIdle();  
      console.log(this.document_types);
    });
   
    setClarity("page", "document-upload");

    this.fileCountChanged.asObservable().subscribe(()=>{     
    
        if(this.IsAllFileUpload) {
          this.gtmService.triggerButtonClick({className:"uploaded-all-categories"});
          this.documentUploadCompleted();
          if(this.lastUploadedFileType) {
            this.gtmService.triggerButtonClick({className:`upload-${this.lastUploadedFileType}`, ignoreHistory: true});
          }
          this.conversation = (model) => ({      
            greeting:  ``,
            message:`Thanks. We've got your documents. We will be in touch!`
          })
        }
        else if(this.IsSomeFileUploaded) { 
          if(this.files.length == 1) {
            this.gtmService.triggerButtonClick({className:"uploaded-first-document"});
          }
          if(this.lastUploadedFileType) {
            this.gtmService.triggerButtonClick({className:`upload-${this.lastUploadedFileType}`, ignoreHistory: true});
          }
          this.conversation = (model) => ({      
            greeting:  ``,
            message:`Great thanks for getting started!`
          })
        }    
        else {         
          this.conversation = (model) => {
            return {      
              greeting:  ``,
              message:`Please upload the following documents within 24 hours to get a loan within 2-3 days` 
            };
          }
        }
        
      
    })
  }

  

  updateDealDetails() {
    if (this.borrower.Deal_Id) {
      this.borrower.CE_Lead_ID = this.borrower.Deal_Id;
    }
    this.borrower.borrower_created_at = new Date(this.borrower.created_at);

    if (this.borrower.last_updated_at) {
      this.borrower.updated_at = new Date(this.borrower.last_updated_at);
      this.dealDate = this.borrower.updated_at;
    }
    else {
      this.dealDate = this.borrower.borrower_created_at;
    }


    this.dealId = this.borrower.CE_Lead_ID;
  }

  loadDocumentType() {
    const [{categories}] = documentList.filter(d=>d.predicate(this.borrower));
    this.document_types = categories || [];
  }


  setExistingFilesByDocumentType() {
    this.filesInDocType = {};
    if (this.files && this.files.length > 0) {
      for(let f of this.files) {
        let newDocType = this.document_types.find(d => 
          d.documents.findIndex(d1 => (d1.name == f.documentCategory || d1.doc_prepend == f.documentCategory) && 
          d1.upload_folder == f.documentType) >= 0 || (d.document_id == f.documentType && 
            d.documents.findIndex(d1 => d1.name == f.documentCategory || d1.doc_prepend == f.documentCategory) >= 0));

        if (!newDocType) {
          
          let oldDocType = documentCategories.find(d => d.document_id == f.documentType && d.documents.findIndex(d1 => d1.name == f.documentCategory || d1.doc_prepend == f.documentCategory) >= 0);
          if(oldDocType) {
            let oldDoc = oldDocType.documents.find(d1 => d1.name == f.documentCategory || d1.doc_prepend == f.documentCategory);
            newDocType = this.document_types.find(d => d.document_id == oldDoc.new_document_id && d.documents.findIndex(d1 => d1.name == oldDoc.new_document_category || d1.doc_prepend == oldDoc.new_document_category) >= 0);
            let newDoc = newDocType.documents.find(d1 => d1.name == oldDoc.new_document_category || d1.doc_prepend == oldDoc.new_document_category);
            f.documentType = newDocType.document_id;
            f.documentCategory = newDoc.doc_prepend ? newDoc.doc_prepend : newDoc.name;
          }
        
        } else {
          let newDoc = newDocType.documents.find(d1 => (d1.name == f.documentCategory || d1.doc_prepend == f.documentCategory) && d1.upload_folder == f.documentType);
          if (!newDoc) {
            newDoc = newDocType.documents.find(d1 => (d1.name == f.documentCategory || d1.doc_prepend == f.documentCategory));
            f.documentType = newDoc.upload_folder;
            f.documentCategory = newDoc.doc_prepend ? newDoc.doc_prepend : newDoc.name;
          }
        }

        if (!this.filesInDocType[f.documentType]) {
          this.filesInDocType[f.documentType] = {};
        }

        if (!this.filesInDocType[f.documentType][f.documentCategory]) {
          this.filesInDocType[f.documentType][f.documentCategory] = [];
        }

        this.filesInDocType[f.documentType][f.documentCategory].push(f);
      }
    }
  }

  


  fileVerifiedNull() {
    var falseCount = this.files.filter(x => x.verified == false).length;
    var NullCount = this.files.filter(x => x.verified == null).length;
    var count = this.checkFileCategoryStatusOnEvent();
    if (falseCount > 0 || NullCount > 0) {
      return 1
    } else if (this.files.length > 0) {
      return 2;
    }
  }
 
  docuementVerifiedStatus(value: string) {
    if (this.files && this.files.length > 0) {
      var file = this.files.filter(x => x.documentCategory == value);
      var nullDocument = file.filter(x => x.verified == null).length;
      var trueDocuement = file.filter(x => x.verified == true).length;
      var falseDocuement = file.filter(x => x.verified == false).length;
      if (nullDocument > 0) {
        return "InProcess";
      }
      else if (trueDocuement > 0) {
        return "Verified";
      }
      else if (falseDocuement > 0) {
        return "Invalid";
      }
    }
  }

  filesVerifiedStatusAccordion(document_id: string) {
    if (this.files && this.files.length > 0) {

      var documents = this.document_types.filter(x => x.document_id == document_id)
      documents.forEach(docType => {
        this.file_folder = docType.documents.map((doc) => ({
          upload_folder: doc.upload_folder ? doc.upload_folder : docType.document_id
        }));
      });

      var file = this.files.filter(x => this.file_folder.some(({ upload_folder }) => x.documentType === upload_folder));
      var falseCount = file.filter(x => x.verified == false).length;
      var NullCount = file.filter(x => x.verified == null).length;
      if (falseCount > 0) {
        return "Invalid";
      } else if (NullCount > 0) {
        return "InProcess";
      }
      else {
        return "Verified";
      }

    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  filesAdded(eventArgs: any[]) {
    this.files = this.files.concat(eventArgs);
    this.setExistingFilesByDocumentType();
    this.checkFileCategoryStatusOnEvent();
    this.fileCountChanged.emit();
    if (this.syncCRMTimeout) {
      window.clearTimeout(this.syncCRMTimeout);
    }
    this.syncCRMTimeout = setTimeout(() => this.syncCRM(), 30000);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    if (this.syncCRMTimeout) {
      this.syncCRMTimeout = null;
      this.syncCRM();
    }
  }

  syncCRM() {
    this.syncCRMTimeout = null;
    this.http.post('/journey/CRMUpdateDocumentUploaded', null).subscribe();
  }

  fileIdRemoved(eventArgs: any) {
    this.files.splice(this.files.findIndex(c => c.id == eventArgs), 1);
    this.setExistingFilesByDocumentType();
    this.checkFileCategoryStatusOnEvent();
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    if (this.timeoutSubscription) {
      this.timeoutSubscription.unsubscribe();
    }

    if (this.pingSubscription) {
      this.pingSubscription.unsubscribe();
    }
  }

  watchIdle() {
    this.pingSubscription = this.userIdle.ping$.subscribe(next => {
      if (this.tokenService.getToken()) {
        this.tokenService.refreshToken();
      }
    });
  }

  checkFileCategoryStatusOnEvent() {    
    this.document_types.forEach(docType => {
      docType.pendingFilesCount = docType.options.filter(option => this.files.findIndex(f => ((option.value == f.documentCategory || option.text == f.documentCategory) && option.upload_folder == f.documentType) || (f.documentType == docType.document_id && (f.documentCategory == option.value || f.documentCategory == option.text))) < 0 && option.required).length;
      docType.requiredFilesCount = docType.options.filter(option => option.required).length;
      docType.isComplete = docType.pendingFilesCount == 0;
    });
  }

  checkFileCategoryStatus() {
    if (this.document_types) {
      this.document_types.forEach(docType => {
        this.file_options = docType.documents.map((doc) => ({
          text: doc.name,
          value: doc.doc_prepend ? doc.doc_prepend : doc.name,
          required: !!doc.required || (doc.is_optional ? !eval(doc.is_optional) : false),
          notes: doc.notes,
          upload_folder: doc.upload_folder ? doc.upload_folder : docType.document_id
        }));

        if (docType.repeat_for) {
          let options = [];

          this.file_options.forEach(opt => {
            let option = JSON.parse(JSON.stringify(opt));
            option.text = `${option.text} - For ${this.borrower.first_name} ${this.borrower.last_name}`;
            option.value = `${option.value} - For ${this.borrower.first_name} ${this.borrower.last_name}`;
            option.group = `${this.borrower.first_name} ${this.borrower.last_name}`;
            options.push(option);
          });

          this.borrower[docType.repeat_for].forEach(group => {
            this.file_options.forEach(opt => {
              let option = JSON.parse(JSON.stringify(opt));
              option.text = `${option.text} - For ${group[docType.repeat_section_label]}`;
              option.value = `${option.value} - For ${group[docType.repeat_section_label]}`;
              option.group = group[docType.repeat_section_label];
              options.push(option);
            });
          });
          this.file_options = options;
        }

        this.file_options.sort((x, y) => (x.required === y.required) ? 0 : x.required ? -1 : 1);
        docType.pendingFilesCount = this.file_options.filter(option => this.files.findIndex(f => ((option.value == f.documentCategory || option.text == f.documentCategory) && option.upload_folder == f.documentType) || (f.documentType == docType.document_id && (f.documentCategory == option.value || f.documentCategory == option.text))) < 0 && option.required).length;
        docType.options = this.file_options;
        docType.requiredFilesCount = this.file_options.filter(option => option.required).length;
        docType.isComplete = docType.pendingFilesCount == 0;
      });
      this.documentUploadComplete = this.document_types.filter(docType => !docType.eval_is_optional && docType.pendingFilesCount > 0).length == 0;
      this.sortComplete = true;

      if (this.accordion_selected == -1) {
        this.accordion_selected = this.document_types.findIndex(doc_type => doc_type.pendingFilesCount > 0);
      }
    }
  }

  documentUploadCompleted() {
    this.submitting = true;

    let url = '/journey/documentuploadcomplete';
    if (this.borrower.journey && this.borrower.journey == 'dsa') {
      url += `?mobile=${this.borrower.userId}`;
    }

    this.http.post(url, null).subscribe((res: any) => {
      if (res.success) {
        this.borrower = res.borrower;       
        this.submitting = false;
      }
    }, error => {
      this.submitting = false;
    });
  }

  checkRequiredStatus() {
    if (this.document_types) {
      this.document_types.forEach(docType => {
        if (docType.is_optional) {
          docType.eval_is_optional = eval(docType.is_optional);
        } else {
          docType.eval_is_optional = false;
        }

        docType.documents.forEach(doc => {
          doc.notes = doc.conditional_notes ? eval(doc.conditional_notes) : (doc.notes ? doc.notes : null);
          doc.hide = false;
          if (doc.hide_criteria) {
            doc.hide = eval(doc.hide_criteria);
          }
        });
        docType.hideCategory = docType.documents.filter(c => !c.hide).length == 0;
        docType.documents = docType.documents.filter(c => !c.hide);
      });

      this.document_types = this.document_types.filter(c => !c.hideCategory).sort((x, y) => {
        // true values first
        return (x.eval_is_optional === y.eval_is_optional) ? 0 : x.eval_is_optional ? 1 : -1;
        // false values first
        // return (x === y)? 0 : x? 1 : -1;
      });
    }
  }
}

