import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";

const fields: (flowManager: FlowManagerService) => FormlyFieldConfig[] = (flowManager: FlowManagerService) => (
    [{
        key: "bankaccount",
        type: "question",
        modelOptions: {
            updateOn: 'change'
        },
        templateOptions: {

            label: "My revenue goes into my:",

            options: [
                { "label": "Savings account", "value": "Savings account", icon: "icons-bank-ac-type-savings" },
                { "label": "Current account", "value": "Current account", icon: "icons-bank-ac-type-current" }],
            required: true,
        },
        hooks: {
            onInit: ({ formControl, key }) => {
                let lastValue = flowManager.modelValue[key as string];
                formControl.valueChanges.subscribe(value => {
                    if(lastValue== value || flowManager.loadingValue) return;
                    lastValue = value;
                    console.log(value)
                    const data = {};
                    const name = key as string;
                    data[name] = value;
                    bankaccount.next(data, flowManager).subscribe();
                })
            }
        },
        className: "col-12 col-sm-12",
    }]
)

const bankaccount: Step = {
    key:"bankaccount",
    
    fields,


    conversation: (model) => {
        return {
            greeting: ``,
            message: `Please tell us which type of account you use for your business transactions`
        }
    },


    skipOn: (model) => {
        return model.businesstype != "Sole Proprietorship" || (model.loanamount == "Rs 1-5 Lakhs" || model.loanamount == "Rs 5-10 Lakhs");
    },


    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        
        
        const model = flowManager.modelValue;
        const isSaving = model.bankaccount == "Savings account"
        const isSecured = model.typeofloan == "Secured loan";
        const isAbove10 = (model.loanamount != "Rs 1-5 Lakhs" && model.loanamount != "Rs 5-10 Lakhs");
        const notASoleProprietorship = model.businesstype != "Sole Proprietorship";
        if (isSaving &&
            (isSecured ||
                isAbove10 ||
                notASoleProprietorship
            )
        ) {
            data = {
                ...data,
                disqualified: true,
                disqualificationKey: `bankaccount`,
                disqualificationMessage: `Your profile isn't a good match for our lenders. 
                To get a business loan; 
                you need to be able to show lenders that your business income goes into your business current account. 
                This is so they can check you will have the ability to repay your EMIs.` }
        }
        else {
            data = { ...data, qualified: true }
        }
        flowManager.startLoading();
        flowManager.goto(workFlow.searchingLenders.key);
        
        return flowManager.journey.updateJourney(flowManager.modelValue._id, { ...data, location: flowManager.location }).pipe(

            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap((result) => {
                if (result.experian_masked_flow) {
                    flowManager.goto(workFlow.experianMismatch.key);
                }
                else if (result.disqualified) {
                    if (result.disqualificationKey == 'experian') {
                        if (result.experian_score && result.is_experian_score_below_threshold) {
                            flowManager.gtmTriggerService.triggerButtonClick({ className: 'experian-disqualified' })
                        } else {
                            flowManager.gtmTriggerService.triggerButtonClick({ className: 'model-disqualified' })
                        }                        
                    } 
                    flowManager.goto(workFlow.disQualifiedMessage.key);
                }
                else if (result.qualified) {
                    flowManager.finish();
                }
                else {
                    throw new Error("Failed to move forward");
                }                
            }),
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);
            }),            
            finalize(() => flowManager.stopLoading()),
        )
    },

};


export default bankaccount;
