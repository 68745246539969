import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
  @Input() isLoading = false;
  @Input() isInvalid = false;
  @Input() hideBack = false;
  @Input() isDisabled = false;
  constructor() { }

  ngOnInit(): void {
  }
}
