import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-fieldset',
  template: `
    <div class="form-group" [class.has-error]="showError">
      <ng-template #fieldComponent></ng-template>
      <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
      <small *ngIf="to.description" class="form-text text-muted">{{ to.description }}</small>
      <div class="alert alert-info mt-2 mb-4 d-flex" role="alert" *ngIf="to.alert">
      <i class="fa fa-info-circle mr-2 mt-1"></i>
      {{ to.alert }}
      </div>
    </div>
  `,
})
export class FieldsetWrapper extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent: ViewContainerRef;

  ngAfterViewInit() {
    this.field.formControl.valueChanges.subscribe(()=> console.log(this.to.attributes));
  }
}
