import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { mapLocation } from 'app/layouts/constants/mapLocation';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import flipkartJourneyWorkflow from 'app/layouts/workflow/flipkart-journey';
import { TokenService } from 'app/services/token.service';
import { of } from 'rxjs';
import { catchError, delay, first, switchMap, tap } from 'rxjs/operators';
import navigate from '../../utils/handle-navigation';

@Component({
  selector: 'app-flipkart',
  templateUrl: './flipkart.component.html',
  styleUrls: ['./flipkart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlipkartComponent implements OnInit {
  outerLayout: string = null;
  workflow = flipkartJourneyWorkflow;
  params: any;
  constructor(private flowManagerService: FlowManagerService,
    private tokenService: TokenService,
    private route: ActivatedRoute,
    private router: Router) {

    this.flowManagerService.layoutValue = "flipkart";
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit(): void {
    this.flowManagerService.modelValue = { journey: 'flipkart' };

    if (this.route.snapshot.queryParams.loggedIn) {
      this.flowManagerService.startWith({
        workflow: this.workflow,
        flowName: this.workflow.uploadDocument.key,
        step: 0
      });
    }
    else {
      this.flowManagerService.start(this.workflow);
    }

    if(this.route.snapshot.queryParams.token) {
      this.tokenService.setToken(this.route.snapshot.queryParams.token);
      const params = {...this.route.snapshot.queryParams};
      delete params.token;
      this.flowManagerService.journey.getJourney({...this.route.snapshot.queryParams}).subscribe(model => {
        this.initalize();
        this.flowManagerService.modelValue = model;
      });
      //this.router.navigateByUrl('/flipkart?loggedIn=true');
    }else{
      this.initalize();
    }
  }

  initalize(){
    this.tokenService.token$.pipe(
      switchMap(token => {
        if (token) {
          this.flowManagerService.modelValue = { journey: 'flipkart' };
          return this.flowManagerService.journey.getJourney(this.params).pipe(catchError(err => {
            return of({
              ...this.flowManagerService.modelValue,
              location: null,
              status: err.status
            });
          }))
        }
        else {
          return of({ status: 401 })
        }
      })
      ,
      delay(0),
      tap((data) => navigate(this.flowManagerService, data, this.workflow))
    ).subscribe();

    this.flowManagerService.stepper$.pipe(delay(0)).subscribe(stepper => {
      if (stepper) {
        this.outerLayout = this.flowManagerService.currentStep.outerLayout;
        stepper.selectionChange.asObservable().pipe(delay(0)).subscribe(() => {
          this.outerLayout = this.flowManagerService.currentStep.outerLayout;
        })
      }
    })

    this.flowManagerService.flow$.pipe(delay(0)).subscribe(() => {
      if (this.flowManagerService.stepperValue) {
        this.outerLayout = this.flowManagerService.currentStep.outerLayout;
      }
    })
  }

}
