import { Component, Input, AfterViewInit, ViewEncapsulation, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../../services/token.service';
import { saveAs } from 'file-saver';
import { FilePondOptions } from 'filepond';

@Component({
  selector: 'app-flipkart-file-upload',
  templateUrl: './flipkart-file-upload.component.html',
  styleUrls: ['./flipkart-file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlipkartFileUploadComponent implements OnInit {

  @Input()
  documentType: any;

  @Input()
  borrower: any;

  @Input()
  files: any[];

  @ViewChild('docPond') docPond: any;

  @Output()
  filesAdded = new EventEmitter<any[]>();

  @Output()
  fileRemoved = new EventEmitter<any>();

  file_options: any[];
  selectedDocument: any;

  showPassword = false;
  password: string;

  constructor(private http: HttpClient, 
    private router: Router, 
    private tokenService: TokenService) {
  }

  pondOptions = {
    maxFileSize: '5MB',
    maxFiles: 10,
    instantUpload: true,
    allowMultiple: true,
    allowRevert: false,
    //allowFileTypeValidation: true,

    labelIdle: 'Click to upload',

    labelMaxFileSizeExceeded: 'File is too large',
    labelMaxFileSize: 'Maximum file size is {filesize}',

    // set allowed file types with mime types
    acceptedFileTypes: [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/x-zip-compressed',
      //'application/rar',
      'application/vnd.ms-excel',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ],

    // // allows mapping of file types to label values ( how type is shown in error label )
    // // "null" means don't show
    fileValidateTypeLabelExpectedTypesMap: {
      'image/jpg': '.JPG',
      'image/jpeg': '.JPEG',
      'image/png': '.PNG',
      'application/pdf': '.PDF',
      'application/x-zip-compressed': '.ZIP',
      //'application/rar':'',
      'application/vnd.ms-excel': '.XLS',
      'application/msword': '.DOC',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.XLSX',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.DOCX'
    },

    // the label to show, {placeholders} are automatically replaced
    fileValidateTypeLabelExpectedTypes: 'Expects {allButLastType} or {lastType}',

    server: {
      //timeout: 30000,
      process: {
        url: "/journey/addfiles",
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.tokenService.getToken()}`
        },
        onload: (response) => {
          let newFiles = JSON.parse(response).files as any[];
          this.files = this.files.concat(newFiles);
          this.filesAdded.emit(newFiles);

          let pondFiles = this.docPond.getFiles();

          pondFiles.forEach(pf => {
            let fileName = pf.file.name.replace(`.${pf.fileExtension}`, '').replace(/[^a-zA-Z0-9-/\[\]]/g, "_");
            if (newFiles.findIndex(f => f.fileName.endsWith(`.${pf.fileExtension}`) && f.fileName.substring(0, f.fileName.length - `.${pf.fileExtension}`.length - 16).endsWith(fileName)) >= 0)
              this.docPond.removeFile(pf);
          });
        },

        onerror: (response) => {
          console.log(response, 'error');
        },

        ondata: (formData) => {
          formData.append('document_id', this.documentType.document_id);
          if (this.borrower.journey && this.borrower.journey == 'dsa') {
            formData.append('mobile', this.borrower.userId);
          }
          formData.append('documentCategory', this.selectedDocument);
          if (this.password) {
            formData.append('password', this.password);
          }
          return formData;
        }
      },
    }
  };

  ngOnInit() {
    if (this.documentType.file_types && Object.keys(this.documentType.file_types).length > 0) {
      this.pondOptions.acceptedFileTypes = Object.keys(this.documentType.file_types);
      this.pondOptions.fileValidateTypeLabelExpectedTypesMap = this.documentType.file_types;
    }

    if (this.documentType.maxFileSize) {
      this.pondOptions.maxFileSize = this.documentType.maxFileSize;
    }

    this.file_options = this.documentType.documents.map((doc) => ({
      text: doc.name,
      value: doc.doc_prepend ? doc.doc_prepend : doc.name,
      password: !!doc.password
    }));

    if (this.documentType.repeat_for) {
      let options = [];
      this.file_options.forEach(opt => {
        let option = JSON.parse(JSON.stringify(opt));
        option.text = `${option.text} - For ${this.borrower.first_name} ${this.borrower.last_name}`;
        option.value = `${option.value} - For ${this.borrower.first_name} ${this.borrower.last_name}`;
        option.group = `${this.borrower.first_name} ${this.borrower.last_name}`;
        options.push(option);
      });

      this.borrower[this.documentType.repeat_for].forEach(group => {
        this.file_options.forEach(opt => {
          let option = JSON.parse(JSON.stringify(opt));
          option.text = `${option.text} - For ${group[this.documentType.repeat_section_label]}`;
          option.value = `${option.value} - For ${group[this.documentType.repeat_section_label]}`;
          option.group = group[this.documentType.repeat_section_label];
          options.push(option);
        });
      });
      this.file_options = options;
    }
  }

  deleteFile(file) {
    this.http.delete(`/journey/deletefile?id=${file.id}`).subscribe((response: any) => {
      this.files.splice(this.files.findIndex(c => c.id == file.id), 1);
      this.fileRemoved.emit(file.id);
    });
  }

  downloadFile(file) {
    this.http.get(`/journey/downloadfile?id=${file.id}`, { responseType: 'blob' }).subscribe((blob: Blob) => {
      var url = window.URL.createObjectURL(blob);
      saveAs(blob, file.fileName);
    });
  }

  documentChanged(event) {
    this.password = null;
    if (event) {
      if (event.password) {
        this.showPassword = true;
      } else {
        this.showPassword = false;
      }
    }

  }
}

