import { FormControl, ValidationErrors } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";




export function minValidator(control: FormControl, field: FormlyFieldConfig, options: any): ValidationErrors {
  if (control.value) {
    return !isNaN(control.value) && parseFloat(control.value) >= options.min ? null : {
      'min': { message: `${field.templateOptions.label} cannot be lower than ${options.min}.` }
    };
  }
}
