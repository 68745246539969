import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flipkart-outer-layout',
  templateUrl: './flipkart-outer-layout.component.html',
  styleUrls: ['./flipkart-outer-layout.component.css']
})
export class FlipkartOuterLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
