import { FormControl, ValidationErrors, Validators } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";




export function nameValidator(control: FormControl, field: FormlyFieldConfig, options: any): ValidationErrors {
  if (control.value) {
    if (!(/^[a-zA-Z ]+$/.test(control.value))) {
      return {
        'name': { message: `${field.templateOptions.label} must contain only letters.` }
      };
    }
  }
}
