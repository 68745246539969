import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-date-input',
  templateUrl: './formly-date-input.component.html',
  styleUrls: ['./formly-date-input.component.css']
})
export class FormlyDateInputComponent extends FieldType implements OnInit {

  pattern: string;
  placeholder: string;

  ngOnInit() {
    this.placeholder = this.to.placeholder;
    this.pattern = this.placeholder.replace(/D/g, '0').replace(/M/g, '0').replace(/Y/g, '0');
  }
}
