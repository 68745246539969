import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatBoxComponent implements OnInit,OnDestroy {
  greeting:string;
  message:string;
  subscription: Subscription;

  @Input() set conversation(getMessage: (arg0: any) => { greeting: any; message: any; }) {
    this.subscription?.unsubscribe();
    this.subscription = this.flowManager.model$.subscribe((data)=>{     
      let {greeting, message} = getMessage(data);
      this.greeting = greeting;
      this.message = message;
    })
  } 
  constructor(private flowManager:FlowManagerService ) { }  

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
