import { Injectable } from "@angular/core";
import { Subject } from 'rxjs/Subject';
import { Router } from "@angular/router";
import { relogin } from "../utils/functions";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Observable, of } from "rxjs";
import { UserIdleService } from "angular-user-idle";
import { HttpClient } from "@angular/common/http";
import { UtilityService } from "./utility.service";

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  
  private token: BehaviorSubject<string>;
  public token$:Observable<string>;
  

  constructor(private http: HttpClient) {
    const token = localStorage.getItem('a_t');
    this.token = new BehaviorSubject<string>(!token || token==='null'? null:token);
    this.token$ = this.token.asObservable();
    this.token$.subscribe((token)=> localStorage.setItem('a_t', token));
  }
  
  public hasToken() {
    return this.token.value != null;
  }

  public getToken() {
    return this.token.value;
  }

  public clearToken() {
    localStorage.setItem('a_t', null)
    this.token.next(null);
  }

  public setToken(token: string) {
    localStorage.setItem('a_t', token)
    this.token.next(token);
  }

  public refreshToken() {
    this.http.get(`/account/refreshtoken`).subscribe((response: any) => {
      this.setToken(response.refreshedToken);
    });
  }
}
