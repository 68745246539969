import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { retry, switchMap, tap } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import gstJourneyWorkflow from 'app/layouts/workflow/gst-borrower-journey';
import Swal from 'sweetalert2';
import { JourneyService } from 'app/layouts/services/journey/journey.service';

declare const window: Window & { dataLayer: Record<string, unknown>[] };

@Component({
  selector: 'app-qualified-step',
  templateUrl: './qualified-step.component.html',
  styleUrls: ['./qualified-step.component.css']
})
export class QualifiedStepComponent implements OnInit, AfterViewInit {

  
  hideWhatsapp = false;
  mobileBrowser = false;
  documentGrid = [
    // for non gst
    {
      grid: [
        {
          img: `img/Financial.svg`,
          text: `Bank statements`,
        },
        {
          img: `img/KYC.svg`,
          text: `Financial statements`
        },
        {
          img: `img/ID.svg`,
          text: `KYC`
        },
      ],

      predicate: (model) => {
       const isGreaterthane20Lacs = (model.loanamount == "Rs 20-50 Lakhs" || model.loanamount == "Rs 50 Lakhs - 1 Crore" || model.loanamount == "Rs 1-3 Crores") && !model.selected_gstin;
        return isGreaterthane20Lacs;
      }
    },
    {
      grid: [
        {
          img: `img/Financial.svg`,
          text: `Bank statements`,
        },
        {
          img: `img/KYC.svg`,
          text: `ITR`
        },
        {
          img: `img/ID.svg`,
          text: `KYC`
        },
      ],

      predicate: (model) => {
        const is10to20Lacs = model.loanamount == "Rs 10-20 Lakhs" && !model.selected_gstin;
        return is10to20Lacs;
      }
    },
    {
      grid: [
        {
          img: `img/Financial.svg`,
          text: `Bank statements`,
        },
        {
          img: `img/KYC.svg`,
          text: `Business KYC`
        },
        {
          img: `img/ID.svg`,
          text: `Individual KYC`
        },
      ],

      predicate: (model) => {
        const isLessthan10Lacs = (model.loanamount == "Rs 1-5 Lakhs" || model.loanamount == "Rs 5-10 Lakhs") && !model.selected_gstin;
        return isLessthan10Lacs;
      }
    },
    // for gst
    {
      grid: [
        {
          img: `img/Financial.svg`,
          text: `Bank statements & financials`,
        },
        {
          img: `img/KYC.svg`,
          text: `GST returns`
        },
        {
          img: `img/ID.svg`,
          text: `KYC`
        },
      ],

      predicate: (model) => {
        const isGreaterthane20LacsWithGst = (model.loanamount == "Rs 20-50 Lakhs" || model.loanamount == "Rs 50 Lakhs - 1 Crore" || model.loanamount == "Rs 1-3 Crores") && model.selected_gstin;
        return isGreaterthane20LacsWithGst;
      }
    },
    {
      grid: [
        {
          img: `img/Financial.svg`,
          text: `Bank statements & ITR`,
        },
        {
          img: `img/KYC.svg`,
          text: `GST returns`
        },
        {
          img: `img/ID.svg`,
          text: `KYC`
        },
      ],

      predicate: (model) => {
        const is10to20LacsWithGst = model.loanamount == "Rs 10-20 Lakhs" && model.selected_gstin;
        return is10to20LacsWithGst;
      }
    },
    {
      grid: [
        {
          img: `img/Financial.svg`,
          text: `Bank statements`,
        },
        {
          img: `img/KYC.svg`,
          text: `Business KYC`
        },
        {
          img: `img/ID.svg`,
          text: `Individual KYC`
        },
      ],

      predicate: (model) => {
        const isLessthan10LacsWithGst = (model.loanamount == "Rs 1-5 Lakhs" || model.loanamount == "Rs 5-10 Lakhs") && model.selected_gstin;
        return isLessthan10LacsWithGst;
      }
    },
    {
      grid: [
        {
          img: `img/Financial.svg`,
          text: `Bank statements`,
        },
        {
          img: `img/KYC.svg`,
          text: `Financial statements`
        },
        {
          img: `img/ID.svg`,
          text: `KYC`
        },
      ],

      predicate: () =>  true
    },
    
  ];

  selectedGrid = [];
  stepGrid = [
    {
      img: `img/Step 1.svg`,
      header:`STEP 1`,
      text:`We assess your application within 1 working day and may call you to confirm certain details.`
    },
    {
      img: `img/Step 2.svg`,
      header: `STEP 2`,
      text: `We find the right loan for your business and call you to let you know.`
    },
    {
      img: `img/Step 3.svg`,
      header: `STEP 3`,
      text: `We deliver you the best loan.`
    }
  ]; 
  closeResult: string;
  starImg = "/img/CE_Stars.gif";
  get model() {
     return this.flowManager.modelValue;
  }

  constructor(private flowManager: FlowManagerService, private modalService: NgbModal, private journey: JourneyService) { }

  ngAfterViewInit(): void {
    const className = this.getLoanAmountEventClassName();
    if (className) {
      this.flowManager.gtmTriggerService.triggerButtonClick({ className })
    }

    setTimeout(() => { this.starImg ="/img/frame_02_delay-0.01s.gif" }, 5000);

    this.ec().then(() => {
      //console.log("ec");
    });
  }

  ngOnInit(): void {
    this.flowManager.journey.getJourneyStats().pipe(
      switchMap(data=> {
        const gotoV2 = !this.flowManager.hasV2TrafficFulfilled(data);
        const workflow = this.flowManager.workflowValue as any;
        const hasModern = workflow.uploadDocumentModern;
        let flowName = this.flowManager.workflowValue.uploadDocument.key;
        if(gotoV2 && hasModern) {
          flowName = gstJourneyWorkflow.uploadDocumentModern.key;
        }

        return this.flowManager.journey.updateLocation(this.flowManager.modelValue._id, {
          location: {
            ...this.flowManager.location,
            flowName: flowName,
            step: 0,
            key: flowName,
          }
        })
      }),
      retry(3)
    )
    .subscribe();

    const updateGrid = (model) => {
      const [selected] = this.documentGrid.filter(g => g.predicate(model))
      this.selectedGrid = selected.grid;
      console.log(this.selectedGrid)
    }

    updateGrid(this.model)
    this.flowManager.model$.subscribe((model) => {
      updateGrid(model)
    });
    if (this.isMobile().any()) {
      this.mobileBrowser = true;
    }
  }

  submit(modal: NgbModalRef) {
    if (modal) {
      modal.close();

    }
    this.flowManager.finish();
    this.flowManager.gtmTriggerService.triggerButtonClick({ className: "document-upload" })
  }

  submitJazz() {
    this.hideWhatsapp = true;
    this.journey.initiateJazz(null).subscribe(res => {
      if (res.success) {
        Swal.fire("Super!","We have sent a Whatsapp message to you on your registered mobile number. It will take you just 5 mins to complete your business loan application.", "success");
        this.flowManager.gtmTriggerService.triggerButtonClick({ className: "jazz-start" });
      }else {
        Swal.fire("Oops!","We couldn't process your request. But don't worry, our representatives will call you and assist you with the process ahead. In the meantime, you may upload relevant documents to complete you application", "info");
        this.flowManager.gtmTriggerService.triggerButtonClick({ className: "jazz-start-failed" });
        this.submit(null);
      }
    });
  }

  getLoanAmountEventClassName(): string {
    if (this.model.loanamount == "Rs 1-5 Lakhs") return `stage-complete deal-1 small-deal`;
    if (this.model.loanamount == "Rs 5-10 Lakhs") return `stage-complete deal-2 small-deal`;
    if (this.model.loanamount == "Rs 10-20 Lakhs") return `stage-complete deal-3`;
    if (this.model.loanamount == "Rs 20-50 Lakhs") return `stage-complete deal-4`;
    if (this.model.loanamount == "Rs 50 Lakhs - 1 Crore") return `stage-complete deal-5`;
    if (this.model.loanamount == "Rs 1-3 Crores") return `stage-complete deal-6`;
    if (this.model.loanamount == "Rs 3 Crores & above") return `stage-complete deal-7`;
    return null;
  }

  test(content) {
    console.log("test");
    this.modalService.open(content).result.then((result) => { this.closeResult = `Closed with: ${result}`});
  }

  isMobile() {
    return {
      Android: () => {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: () => {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: () => {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: () => {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: () => {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: () => {
        return (this.isMobile().Android() || this.isMobile().BlackBerry() || this.isMobile().iOS() || this.isMobile().Opera() || this.isMobile().Windows());
      }
    }
  };

  async ec(){
    window['gtag']('set', 'user_data', {
      "sha256_email_address": this.model.email ? await this.hashData(this.model.email) : null,
      "sha256_phone_number": await this.hashData('+91' + this.model.userId),
        "address": {
        "address.sha256_first_name": await this.hashData(this.model.first_name),
        "address.sha256_last_name": await this.hashData(this.model.last_name),
        "city": this.model.indian_city,
        "region": this.model.indian_state,
        "postal_code": this.model.pincode,
        "country": "INDIA"
        }
      });

      if  (this.model.new_lead_score) {
        window.dataLayer.push({
          'event':'loan_eligibility',
          'loan_amount':this.model.new_lead_score.toString()
        });
      }
  }

  async hashData(input) {
    // Normalize the data
    const normalizedInput = input.trim().toLowerCase();
    // Convert the normalized data to a Uint8Array
    const encoder = new TextEncoder();
    const data = encoder.encode(normalizedInput);
    // Hash the data using the SubtleCrypto API
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    // Convert the hash to a hexadecimal string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }
}
