import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-label',
  template: `
    <label [attr.for]="id" class="form-control-label control-label" *ngIf="to.label">
      <span  [innerHTML]="to.label"></span>
      <ng-container *ngIf="to.required && to.hideRequiredMarker !== true">*</ng-container>

      <span class="badge badge-pill badge-light" *ngIf="to.helpText">
        <i class="fa fa-question-circle" [ngbTooltip]="to.helpText"></i>
       </span>

      <div *ngIf="to.labelDescription" class="text-muted font-weight-light" style="font-size: .9rem;">
      {{ to.labelDescription }}
      </div>
    </label>
    <ng-template #fieldComponent></ng-template>    
  `,
})
export class LabelWrapper extends FieldWrapper implements OnInit {
  ngOnInit(): void {
    console.log(this.to);
  }
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent: ViewContainerRef;

}
