import { FormControl } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout, startWith, map, takeUntil, switchMap } from "rxjs/operators"
import gstJourneyWorkflow from "..";
import workFlow from ".."
import { Step } from "../..";
import stateOptions from "./state-options";



const fields = (flowManager: FlowManagerService): Array<FormlyFieldConfig> =>
    [
        {
            fieldGroupClassName: 'row',
            fieldGroup: [

                {
                    key: 'pan',
                    type: 'mask',
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: 'text',
                        label: 'Your business PAN',
                        placeholder: 'AAAAA1111A',
                        mask: 'SSSSS0000S',
                        required: true,
                        autoCapitalize: true,
                        helpText: `'Business' is a sole proprietorship firm, or a partnership firm, or a company (private or public).
                        `
                    },
                    hooks:{
                        onInit:(field)=>{
                            flowManager.model$.subscribe((model)=>{
                                if(model.loanamount == "Rs 1-5 Lakhs") {
                                    field.templateOptions.label = 'Your PAN';
                                }
                                else {
                                    field.templateOptions.label = 'Your business PAN'
                                }
                            });
                            flowManager.invalidPan$.subscribe(()=>{
                                console.log("updateValueAndValidity");
                                field.formControl.updateValueAndValidity();
                            });
                        }
                    },
                    validators: {
                        pan: {
                            expression: (c) => !c.value || /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/.test(c.value),
                            message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a valid PAN. 
                            Please enter your 10 character PAN.`,
                        },
                        panBusinesType: {
                            expression: (c) => !c.value || c.value.length < 10|| /(^([a-zA-Z]{3})([pPfFcC]{1})([a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})$)/.test(c.value),
                            message: (error, field: FormlyFieldConfig) => `PAN does not match the business type we service right now.`,
                        },
                        invalidPan: {
                            expression: (c) => !c.value || !flowManager.currentInvalidPan || flowManager.currentInvalidPan.pan != c.value,
                            message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a valid PAN.`,
                        }
                    },
                    className: 'col-md-6 col-sm-12'
                },
                // {
                //     key: 'stateCode',
                //     type: 'select',
                //     templateOptions: {
                //         label: 'Your state',
                //         required: true,
                //         options: stateOptions
                //     },
                //     hooks: {
                //         onInit: (field) => {
                //             // flowManager.journey.getLocation()
                //             //     .subscribe(data => {
                //             //         let [state] = stateOptions.filter(s => s.label == data.region.toUpperCase());
                //             //         console.log(state);
                //             //         setTimeout(() => {
                //             //             flowManager.modelValue = { ...flowManager.modelValue, stateCode: state.value, pan: '' };
                //             //         })
                //             //     });
                //         }
                //     },
                //     className: 'col-md-6 col-sm-12'
                // },
                {
                    key: 'personal_pan',
                    type: 'mask',
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: 'text',
                        label: `PAN of the promoter ${flowManager.modelValue.first_name}`,
                        placeholder: 'AAAAA1111A',
                        mask: 'SSSSS0000S',
                        required: true,
                        autoCapitalize: true,
                       
                    },
                    hooks: {
                        onInit: (field) => {
                            flowManager.model$.subscribe(()=>{
                                field.templateOptions.label = `PAN of the promoter ${flowManager.modelValue.first_name}`;
                            });
                            flowManager.invalidPan$.subscribe(()=>{
                                console.log("updateValueAndValidity");
                                field.formControl.updateValueAndValidity();
                            })
                        }
                    },
                    validators: {
                        pan: {
                            expression: (c) => !c.value || /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/.test(c.value),
                            message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a valid PAN. 
                            Please enter your 10 character PAN.`,
                        },
                        personalPan: {
                            expression: (c) => !c.value || c.value.length < 10|| /(^([a-zA-Z]{3})([pP]{1})([a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})$)/.test(c.value),
                            message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a personal PAN.`,
                        },
                        invalidPan: {
                            expression: (c) => !c.value || !flowManager.currentInvalidPan || flowManager.currentInvalidPan.pan != c.value,
                            message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a valid PAN.`,
                        }
                    },
                    className: 'col-md-6 col-sm-12',
                    hideExpression: (model, field) => {
                        const pan = model.pan;
                        return !pan || pan.toLowerCase()[3] == 'p';
                    },
                },
            ]
        },
            
    ]

const pan: Step = {
    key: "pan",

    fields,


    conversation: (model) => ({
        greeting: `Thanks for choosing CreditEnable!`,
        message: `I'm Lakshmi and I'll be helping guide you through your loan application. This will only take a few minutes. 
        `
    }),

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        
        const payload = { 
            ...data,            
            location: flowManager.location, 
            ...flowManager.modelValue,
            pincode: "",
            businessname: "",
            gstins:[], 
        };
        
        flowManager.startLoading();
        flowManager.stepNext();
        return flowManager.journey.searchGstinByPan(payload).pipe(           
            catchError(err => {
                if (typeof err.error == 'string') {
                    if (err.error.includes("Failed to fetch tax payer information")) {
                        if (flowManager.isMobile()) {
                            flowManager.goto(gstJourneyWorkflow.businessDetails.key);
                        }
                        else {
                            flowManager.goto(gstJourneyWorkflow.businessDetailsDesktop.key);
                        }
                    }
                }
                else if (err.error.message && err.error.message.includes("Invalid PAN")) {
                    flowManager.stepPrevious();
                    flowManager.setInvalidPan(err.error);
                }

                return throwError(err);

            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            switchMap(result=> {
                if(flowManager.is5LacAndHasInvalidGstins()) {
                    if (flowManager.isGstinsEmpty) {
                        return flowManager.journey.updateJourney(flowManager.modelValue._id,
                          {
                            disqualified: true,
                            disqualificationKey: 'gst',
                            disqualificationMessage: `As you do not have a valid GST registration, 
                            your business is currently not eligible for a loan from our lender partners. 
                            We'll be able to help you once you have an active GST registration.`})
                          ;
                      }
                      else {
                        return flowManager.journey.updateJourney(flowManager.modelValue._id,
                          {
                            disqualified: true,
                            disqualificationKey: 'gst',
                            disqualificationMessage: `We're unable to help you get a business loan from our lender partners 
                            as your business is currently too young. To fulfil your business funding needs, our lender partners 
                            require a business age of more than 2-years, according to your GST records.`})
                          ;
                      }
                }
                return of(result);
            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap(() => {
                const model = flowManager.modelValue;


                if (model.disqualified) {
                    flowManager.goto(workFlow.disQualifiedMessage.key);
                    return;
                }


                if ((!model.gstins || model.gstins.filter(b=>b.isBusinessVintage).length == 0)) {
                    if (flowManager.isMobile()) {
                        flowManager.goto(gstJourneyWorkflow.businessDetails.key);
                    }
                    else {
                        flowManager.goto(gstJourneyWorkflow.businessDetailsDesktop.key);
                    }
                }
                else {
                    flowManager.stepNext();
                }
            }),
            tap(data=>console.log(data)),
            tap((result) => flowManager.gtmTriggerService.triggerButtonClick({ className: "stage-complete stage-1" })),           
            tap(_ => flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },

}

export default pan;
