import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import negativeSectors from 'app/layouts/workflow/gst-borrower-journey/steps/negative-sectors';

@Component({
  selector: 'app-negative-sector-step',
  templateUrl: './negative-sector-step.component.html',
  styleUrls: ['./negative-sector-step.component.css']
})
export class NegativeSectorStepComponent implements OnInit {
  form: FormGroup;
  sectors = negativeSectors;
  
  constructor(private fb:FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      negativeSector:[""]
    })


    this.form.controls.negativeSector.valueChanges.subscribe(value=>{
      console.log(value);
    })
  }

  notANegativeSector($event) {
    console.log($event);
  }

}
