import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { GstPullStepperComponent } from '../gst-pull-stepper/gst-pull-stepper.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-gst-pull-option',
  templateUrl: './gst-pull-option.component.html',
  styleUrls: ['./gst-pull-option.component.css']
})
export class GstPullOptionComponent implements OnInit {
  @Input() document: any;
  @Input() public gstin: string;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    if (!this.gstin) {
      this.gstin = '';
    }
  }

  open() {
     const dialogRef = this.dialog.open(GstPullStepperComponent, {
      disableClose:false,
      hasBackdrop:true
     });
    dialogRef.componentInstance.documentName = this.document.name;
    dialogRef.componentInstance.gstin = this.gstin;
  }

  next() {

  }

  close() {
    
  }
}

