import { FormControl, ValidationErrors } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";




export function maxLengthValidator(control: FormControl, field: FormlyFieldConfig, options: any): ValidationErrors {
  if (control.value) {
    return control.value.length <= parseInt(options.max) ? null : {
      'maxLength': { message: `${field.templateOptions.label} needs to be less than ${options.max} characters long.` }
    };
  }
}
