import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";

const fields: (flowManager: FlowManagerService) => FormlyFieldConfig[] = (flowManager: FlowManagerService) => (
    [{
        key: "primarybusiness",
        type: "question",
        modelOptions: {
            updateOn: 'change'
        },
        templateOptions: {

            label: `What is your main business activity?`,

            options: [{ "label": "Manufacturer", "value": "Manufacturer" }, { "label": "Wholesaler", "value": "Wholesaler" }, { "label": "Retailer", "value": "Retailer" }, { "label": "Service", "value": "Service" }],
            required: true,
        },
        hooks: {
            onInit: ({ formControl, key }) => {
                let lastValue = flowManager.modelValue[key as string];
                formControl.valueChanges.subscribe(value => {
                    if (lastValue == value || flowManager.loadingValue) return;
                    lastValue = value;
                    console.log(value)
                    const data = {};
                    const name = key as string;
                    data[name] = value;
                    primaryBusiness.next(data, flowManager).subscribe();
                })
            }
        },
        className: "col-12 col-sm-12",


    }]
)

const primaryBusiness: Step = {
    key: "primarybusiness",

    fields,

    conversation: (model) => ({
        greeting: `Great to meet you ${model.first_name}.`,
        message: ` 
        Please help me understand your business so I can check your eligibility and 
        help you get the right loan for your business. 
        This should only take a second. 
        `
    }),

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {


        flowManager.startLoading()
        return flowManager.journey.updateJourney(flowManager.modelValue._id, { ...data, location: flowManager.location }).pipe(

            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap(() => flowManager.stepNext()),
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);
            }),
            tap(_ => flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },

};


export default primaryBusiness;
