import { Component, ChangeDetectionStrategy, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, SelectControlValueAccessor } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { concatMap, delay, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';

@Component({
  selector: 'formly-field-select',
  template: `

 <ng-select #select class="custom"
        bindLabel="label" 
        bindValue="value"
        [multiple]="to.multiple"
        [items]="to.options"
        placeholder ="Search"
        [searchable]="optionLength > 3"
        [class.ng-invalid]="showError"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [required]="to.required"
        appendTo="body"
        [id]="key">
  </ng-select>
  `,
  styleUrls: ['formly-select-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlySelectInput extends FieldType implements OnInit, AfterViewInit {
  @ViewChild('select') select: NgSelectComponent;
  optionLength = 0;
 
  constructor(private flowManagerService: FlowManagerService) {
    super();

  }

  ngOnInit() {
    this.optionLength = (this.to.options as any[]).length;
  }

  ngAfterViewInit(): void {
    this.flowManagerService.model$.subscribe((model) => {
      if (model) {
        this.formControl.patchValue(model[this.key.toString()])
      }
    });

    this.flowManagerService.model$.pipe(
      delay(0),
      concatMap(_ => this.flowManagerService.allTouched$))
      .subscribe((allTouched) => {
        if (!this.formControl.value) {
          this.formControl.patchValue(null);
        }
      })
  }

  is_iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac"))
  }
}
