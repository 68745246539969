import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { getValidCategorySteps, getValOrParseFunc, ICategory, ICategoryStep, IStepSection } from 'app/layouts/constants/document-categories.modern.constant';
import { FileManagerService } from 'app/layouts/services/file-manager/file-manager';
import { environment } from 'src/environments/environment';
import { DocumentCategoryStepModalComponent } from '../document-category-step-modal/document-category-step-modal.component';
import { PerfiosService } from 'app/layouts/services/perfios/perfios.service';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';


@Component({
  selector: 'app-document-category-step-section',
  templateUrl: './document-category-step-section.component.html',
  styleUrls: ['./document-category-step-section.component.css']
})
export class DocumentCategoryStepSectionComponent implements OnInit {
  @Input() section: IStepSection;
  @Input() category: ICategory;
  @Input() categoryHasMultiStep: boolean;
  @Input() borrower;
  @Input() perfiosBankStatementExist: boolean;
  @Input() perfiosAttempted: boolean;

  documentType: { documents: ICategoryStep[]; name: string; };
  fileOptions: { text: string; value: string; required: boolean; upload_folder: string; };
  documents: { name: string }[];
  notes: string;
  @Input() isFirst = false;

  steps: ICategoryStep[];
  constructor(private ref: ChangeDetectorRef,
    private http: HttpClient,
    private fileManager: FileManagerService,
    private perfiosService: PerfiosService,
    private flowManagerService: FlowManagerService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    this.fileOptions = this.getOptions(this.section);
    this.documents = Array.isArray(this.section.documents) ? this.section.documents : this.section.documents(this.borrower);
    this.steps = getValidCategorySteps(this.category.steps, this.borrower);
    this.documentType = this.getDocumentType(this.steps);
  }

  getDocumentType(steps: ICategoryStep[]) {
    return { ...this.category, documents: steps.reduce((p, c) => p.concat(c.sections), []) };
  }

  getOptions(document: IStepSection) {
    return {
      text: document.name,
      value: document.doc_prepend,
      required: !!document.required,
      upload_folder: document.upload_folder,
      alias: document.name
    };
  }

  downloadExample() {
    if (this.section.exampleLinks) {
      const links = getValOrParseFunc(this.section.exampleLinks, this.borrower);
      for (let link of links) {
        window.open('/files/' + link, '_blank');
      }
    }
  }

  startAadharStep() {
    var phone = this.flowManagerService.mobileNumber;
    var origin = window.location.origin;
    window.location.href = origin + "/aadhar-collection-step.html?phone=" + phone;
  }

  generateNetBanking() {
    var phone = this.flowManagerService.mobileNumber;
    const payload = { phone: phone }
    this.perfiosService.generateNetBanking(payload).subscribe(res => {
      if (res.success) {
        window.location.href = res.url;
      }
    })
  }

  generateBankUploadPdf() {
    var phone = this.flowManagerService.mobileNumber;
    const payload = { phone: phone }
    this.perfiosService.generateUpload(payload).subscribe(res => {
      if (res.success) {
        window.location.href = res.url;
      }
    })
  }
}
