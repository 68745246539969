import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileManagerService } from 'app/layouts/services/file-manager/file-manager';
import { JourneyService } from 'app/layouts/services/journey/journey.service';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-gst-pull-stepper',
  templateUrl: './gst-pull-stepper.component.html',
  styleUrls: ['./gst-pull-stepper.component.css']
})
export class GstPullStepperComponent implements OnInit {
  gstinFg: FormGroup;
  otpFg: FormGroup;
  isValidatingOtp = false;
  isFetchSuccess = false;
  isLoading = false;
  @ViewChild('stepper') stepper: MatStepper;

  @Input() public documentName:string;
  @Input() public gstin: string;

  constructor(public dialogRef: MatDialogRef<GstPullStepperComponent>,
    private journeyService: JourneyService,
    private fb: FormBuilder,
    private http:HttpClient,
    private fileManager:FileManagerService) {
  }

  ngOnInit(): void {

    this.gstinFg = this.fb.group({
      gstin: this.fb.control(this.gstin, Validators.required),
      username: this.fb.control('', Validators.required),
    });

    this.otpFg = this.fb.group({
      otp: this.fb.control('', Validators.required)
    });
  }

  close() {
    this.dialogRef.close();
  }

  sendOtp() {    
    this.gstinFg.updateValueAndValidity();
    if (this.gstinFg.invalid) return;

    this.isLoading = true;
      this.journeyService.generateGstrOtp(this.gstinFg.value).subscribe(()=>{
        this.stepper.next();
        this.isLoading = false;
      }, err=>{
        console.log(err);
        this.isLoading = false;
      })
  }

  fetchData() {
    this.otpFg.updateValueAndValidity();
    if (this.otpFg.invalid) return;

    this.isValidatingOtp = true;
    this.journeyService.validateGstrOtp({
      ...this.gstinFg.value,
      ...this.otpFg.value
    })
    .pipe(tap(val=>{
      console.log(val)
      this.isValidatingOtp = false;
      this.stepper.next();
    },err=>{
      console.log(err);
      this.isValidatingOtp = false;
    }),
    switchMap((val)=>this.journeyService.fetchGstrData({...this.gstinFg.value, documentName:this.documentName})),
    tap(val=>{
      this.isFetchSuccess = true;
        this.stepper.next();
    },err=>{
      this.isFetchSuccess = false;
        this.stepper.next();
        console.log(err);
    }),
    )
    .subscribe(()=> {
      this.http.get('/journey/get').subscribe((response: any) => {
        this.fileManager.setFiles(response.files);
      });

    });

  }

}
