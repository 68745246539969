import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";

const fields:(flowManager: FlowManagerService) => FormlyFieldConfig[] = (flowManager:FlowManagerService) => ([{
    key: "business_vintage",
        type: "question",
        modelOptions:{
            updateOn:'change'
        },
        templateOptions: {
            
            label: `Do you have a legal business registration document 
            to prove business operation of 2 years or more?`,                    
            
            options: [{"label":"Yes","value":"Yes"},{"label":"No","value":"No"}],
            required: true,
        },
        hooks: {
            onInit: ({ formControl, key }) => {
                let lastValue = flowManager.modelValue[key as string];
                formControl.valueChanges.subscribe(value => {
                    if(lastValue== value || flowManager.loadingValue) return;
                    lastValue = value;
                    console.log(value)
                    const data = {};
                    const name = key as string;
                    data[name] = value;
                    businessVintage.next(data, flowManager).subscribe();
                })
            }
        },
        className: "col-12 col-sm-12",
        
         
}])

const businessVintage:Step ={
    key:"business_vintage",
    
    fields,
    
    conversation: (model) => {
        return {
            greeting: ``,
            message: `Let us know for how long you've been registered as a business`
        }
    },
    

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        
        
        if(data.business_vintage=="No") {
            data = { 
                ...data, 
                disqualified: true, 
                disqualificationKey: `business_vintage`, 
                disqualificationMessage:`Your profile isn't a good match for our lender partners. 
                Our lender partners currently only provide loans to businesses that are more than two years old. 
                Once you've been in business for more than two years please get back in touch and we will be happy to help.` }
        }
        flowManager.startLoading()
        return flowManager.journey.updateJourney(flowManager.modelValue._id, {...data, location: flowManager.location}).pipe(
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);
            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap((result) => {
                if(result.disqualified) {
                    flowManager.goto(workFlow.disQualifiedMessage.key);
                }
                else {
                    flowManager.stepNext();
                }                
            }),            
            tap(_=>flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },

};


export default businessVintage;
    