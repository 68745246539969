import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import borrowerJourneyWorkflow from 'app/layouts/workflow/borrower-journey';
import { CountdownComponent } from 'ngx-countdown';
import { throwError } from 'rxjs';
import { tap, catchError, finalize, concatMap } from 'rxjs/operators';
import { matchValidator } from '../experian-step/experian-step.component';

@Component({
  selector: 'app-experain-phone-verification-step',
  templateUrl: './experain-phone-verification-step.component.html',
  styleUrls: ['./experain-phone-verification-step.component.css']
})
export class ExperainPhoneVerificationStepComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  isOtpInvalid;
  isResendButtonActive = false;
  isFirstLoad = true;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  constructor(public flowManager: FlowManagerService, private _formBuilder: FormBuilder) { }

  countDownConfig = {
    leftTime: 30,
    formatDate: ({ date }) => {
      const secs = date / 1000;
      if (secs < 1 || this.isFirstLoad || this.isResendButtonActive) return '';
      return ` in ${secs} sec`;
    },
  }

  get controls() { return this.form.controls; }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      otp: ['', [Validators.required, Validators.pattern("^[0-9]{6}$")]],
    });
    this.form.valueChanges.subscribe((value) => {
      this.isOtpInvalid = false
    });
  }

  ngAfterViewInit(): void {
    this.countdown.stop();
  }

  conversation(model: any) {
    return {
      greeting: ``,
      message: `This is the last step to complete your Eligibility Check!`
    }
  }

  back() {
    this.flowManager.stepPrevious();
  }

  resendOtp() {
    this.isResendButtonActive = false;
    this.countdown.restart();
    this.flowManager.journey.maskNumberResendOtp().subscribe();
  }

  handleCountDownEvent($event) {
    console.log($event);
    if ($event.action == "done") {
      this.isResendButtonActive = true;
    }
  }

  submit() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    let flowManager = this.flowManager;
    let data = this.form.value;
    flowManager.startLoading();
    this.isOtpInvalid = false;
    flowManager.journey.validateExperianMaskNumber(data).subscribe(_ => {
      flowManager.goto(borrowerJourneyWorkflow.searchingLenders.key);
      flowManager.journey.fetchReportFromMaskNumber().pipe(
        tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
        tap((result) => {
          if (result.disqualified) {
            if (result.disqualificationKey == 'experian') {
              if (result.experian_score && result.is_experian_score_below_threshold) {
                flowManager.gtmTriggerService.triggerButtonClick({ className: 'experian-disqualified' })
              } else {
                flowManager.gtmTriggerService.triggerButtonClick({ className: 'model-disqualified' })
              }
            }
            flowManager.goto(borrowerJourneyWorkflow.disQualifiedMessage.key);
          }
          else if (result.qualified) {
            flowManager.finish();
          }
          else {
            throw new Error("Failed to move forward");
          }
        }),
        catchError(err => {
          flowManager.throwError(err);
          return throwError(err);
        }),
        tap(_ => flowManager.saveLocation().subscribe()),
        finalize(() => flowManager.stopLoading()),
      ).subscribe();
    },
      err => {
        if (err.error.includes("Invalid OTP")) {
          this.isOtpInvalid = true;
          this.controls['otp'].patchValue("", { emitEvent: false });
          flowManager.stopLoading();
          if (this.isFirstLoad) {
            this.isFirstLoad = false;
            this.isResendButtonActive = true;
          }
        }
      })

  }

}
