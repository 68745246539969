import { FlatTreeControl } from '@angular/cdk/tree';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { JourneyService } from 'app/layouts/services/journey/journey.service';

interface TreeNode {
  name: string;
  children?: TreeNode[];
  files?: any[];
  file: any;
}

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  file: any
}


@Component({
  selector: 'app-zip-file-info',
  templateUrl: './zip-file-info.component.html',
  styleUrls: ['./zip-file-info.component.css']
})
export class ZipFileInfoComponent implements OnInit {
  _file;
  @ViewChild('passwordSwal') private passwordSwal: SwalComponent;

  private _transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      file: node.file
    };
  }

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  seletectedNodeFile: any;


  set file(value) {
    this._file = value;
    this.ngOnChanges(null)
  };

  get file() {
    return this._file;
  }

  paths: [];


  constructor(private http: HttpClient, private journeyService: JourneyService) {


  }

  ngOnInit(): void {

  }

  ngOnChanges(change: SimpleChange) {
    console.log(this.file)
    let filesWithPath = this.file.files.map(f => ({ ...f, absPath: f.path.substring(1) }));
    let result = [];
    let fileResult = [];
    let level = { result, fileResult };

    filesWithPath.forEach(fileWithPath => {
      fileWithPath.absPath.split('\\').reduce((r, name, i, a) => {
        if (!r[name]) {
          r[name] = { result: [], fileResult: [] };
          r.result.push({ name, children: r[name].result, files: r[name].fileResult, file: fileWithPath })
          r.fileResult.push(fileWithPath);
        }
        return r[name];
      }, level)
    })

    this.dataSource.data = result;
    console.log(this.dataSource.data);
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  promptError(file: any) {
    this.seletectedNodeFile = file;
  }

  promptPassword(file: { path: any; id: any; }) {
    this.seletectedNodeFile = file;
    this.passwordSwal.text = file.path;
    this.passwordSwal.html = file.path;
    setTimeout(() => {
      this.passwordSwal.fire().then(result => {
        if (result.isConfirmed && result.value) {
          console.log(result);
          let data = {
            uploadFileId: this._file.id,
            fileDetailsId: file.id,
            password: result.value,
          };
          console.log(data);

          const reload = () => {
            this.file = this._file; //this will reload the state
            this.treeControl.expandAll();
          }

          let changedFile = this.file.files.find(f => f.id == file.id);
          changedFile.isLoading = true;
          reload();
          this.journeyService.updateZippedFilePassword(data)
            .subscribe(data => {
              if (data.isProtected) {
                this.promptPassword(file);
                changedFile.isLoading = false;
              }
              else {
                changedFile.isLoading = false;
                changedFile.isProtected = data.isProtected;
                changedFile.password = data.password;
              }
              reload();
            }, err => {
              console.log(err);
              changedFile.isLoading = false;
              reload();
            });
        }
        else if (result.isConfirmed && !result.value) { this.promptPassword(file); }
      });
    }, 100)

  }

  downloadFile(file: { id: any; fileName: any }) {
    console.log("downloadFile");
    console.log(file);
    this.seletectedNodeFile = file;

    var windowReference = window.open();
    this.journeyService.downloadZippedFile({ id: this.file.id, fileDetailsId: file.id, fileName: file.fileName })
      .subscribe((blob: Blob) => {
        var url = window.URL.createObjectURL(blob);
        windowReference.location.assign(url);
      }, err => console.log(err));
  }


}
