import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { idleTimeout } from '../utils/constants';


@Component({
  selector: 'relogin-modal',
  templateUrl: './relogin-modal.component.html'
})
export class ReloginModalComponent implements OnInit {

  @Input() title = '';
  @Input() content = '';
  @Input() timer:number = 0;
  @Input() dismissText: string = "Stay logged-in";

  @Input() showDismiss = false;

  @Output() dismissed = new EventEmitter<any>();

  idleTime = idleTimeout;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

}
