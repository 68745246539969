export default [
    { "label": "Accommodation Services ", "value": "Accommodation Services" },
    { "label": "Accounting/ Auditing and Tax Consultancy", "value": "Accounting/ Auditing and Tax Consultancy" },
    { "label": "Advertising and Market Research", "value": "Advertising and Market Research" },
    { "label": "Agriculture and Husbandry", "value": "Agriculture and Husbandry" },
    { "label": "Agriculture support Activities", "value": "Agriculture support Activities" },
    { "label": "Architecture/ Engineering and Technical Testing Services", "value": "Architecture/ Engineering and Technical Testing Services" },
    { "label": "Forestry and Logging", "value": "Forestry and Logging" },
    { "label": "Higher Education/ Technical & Vocational Education", "value": "Higher Education/ Technical & Vocational Education" },
    { "label": "Hospital activities", "value": "Hospital activities" },
    { "label": "Housekeeping & Maintenance Service", "value": "Housekeeping & Maintenance Service" },
    { "label": "Land transport via Railways & Pipelines", "value": "Land transport via Railways & Pipelines" },
    { "label": "Land Transport via Road", "value": "Land Transport via Road" },
    { "label": "Laundry/ Hair Dressing and Other Beauty Treatment", "value": "Laundry/ Hair Dressing and Other Beauty Treatment" },
    { "label": "Library/ Archives/ Museums and Other Cultural Activities", "value": "Library/ Archives/ Museums and Other Cultural Activities" },
    { "label": "Management Consultancy ", "value": "Management Consultancy " },
    { "label": "Medical/ Dental/ Nursing and Pathology etc.", "value": "Medical/ Dental/ Nursing and Pathology etc." },
    { "label": "Metals and Metal Products", "value": "Metals and Metal Products" },
    { "label": "Mining of Metal Ores", "value": "Mining of Metal Ores" },
    { "label": "Mining Support Services", "value": "Mining Support Services" },
    { "label": "Motion Picture and Music Production and Publishing Activities", "value": "Motion Picture and Music Production and Publishing Activities" },
    { "label": "Motor Vehicles and Transport Vehicles", "value": "Motor Vehicles and Transport Vehicles" },
    { "label": "Office Administrative and Other Business Services", "value": "Office Administrative and Other Business Services" },
    { "label": "Other Construction Activities", "value": "Other Construction Activities" },
    { "label": "Other Financial Activities", "value": "Other Financial Activities" },
    { "label": "Other Hospital and Medical Care Services", "value": "Other Hospital and Medical Care Services" },
    { "label": "Other ITeS", "value": "Other ITeS" },
    { "label": "Other Manufacturing", "value": "Other Manufacturing" },
    { "label": "Other Mining & Quarrying", "value": "Other Mining & Quarrying" },
    { "label": "Other Personal service Activities", "value": "Other Personal service Activities" },
    { "label": "Other Professional/ Scientific and Technical Services", "value": "Other Professional/ Scientific and Technical Services" },
    { "label": "Other Support Services ", "value": "Other Support Services " },
    { "label": "Paper Publication", "value": "Paper Publication" },
    { "label": "Placement and HR Management Services", "value": "Placement and HR Management Services" },
    { "label": "Plastic/ Rubber/ Non-metallic Minerals and Fabricated Metals", "value": "Plastic/ Rubber/ Non-metallic Minerals and Fabricated Metals" },
    { "label": "Postal & Courier Activities", "value": "Postal & Courier Activities" },
    { "label": "Primary & Secondary Education Services", "value": "Primary & Secondary Education Services" },
    { "label": "Printing and Media", "value": "Printing and Media" },
    { "label": "Real Estate Activities on Fee or Contract Basis", "value": "Real Estate Activities on Fee or Contract Basis" },
    { "label": "Real Estate Activities with Own or Leased Property", "value": "Real Estate Activities with Own or Leased Property" },
    { "label": "Repair of Computers and Personal and Household Goods", "value": "Repair of Computers and Personal and Household Goods" },
    { "label": "Repairs & Installation  of Machinery/ Equipment and Motor Vehicles", "value": "Repairs & Installation  of Machinery/ Equipment and Motor Vehicles" },
    { "label": "Residential Care for Elderly/ Disabled and Other Ailments", "value": "Residential Care for Elderly/ Disabled and Other Ailments" },
    { "label": "Residential Nursing Care Services", "value": "Residential Nursing Care Services" },
    { "label": "Retail Trading", "value": "Retail Trading" },
    { "label": "Scientific Research and Development", "value": "Scientific Research and Development" },
    { "label": "Security and Investigation Activities", "value": "Security and Investigation Activities" },
    { "label": "Services Incidental to Transport", "value": "Services Incidental to Transport" },
    { "label": "Sports/ Amusement and Recreation Activities", "value": "Sports/ Amusement and Recreation Activities" },
    { "label": "Sports/ Recreation/ Cultural and Other Education", "value": "Sports/ Recreation/ Cultural and Other Education" },
    { "label": "Steam and Air-conditioning Supply", "value": "Steam and Air-conditioning Supply" },
    { "label": "Telecommunication Activities", "value": "Telecommunication Activities" },
    { "label": "Textile/ Leather and Other Apparel", "value": "Textile/ Leather and Other Apparel" },
    { "label": "Tour and Travel Operators", "value": "Tour and Travel Operators" },
    { "label": "Veterinary Services", "value": "Veterinary Services" },
    { "label": "Warehousing and Storage", "value": "Warehousing and Storage" },
    { "label": "Waste Management Services", "value": "Waste Management Services" },
    { "label": "Broadcasting and Programming Activities", "value": "Broadcasting and Programming Activities" },
    { "label": "Buildings", "value": "Buildings" },
    { "label": "Chemicals/ Pharmaceuticals and Botanical Products", "value": "Chemicals/ Pharmaceuticals and Botanical Products" },
    { "label": "Computer Programming and Consultancy ", "value": "Computer Programming and Consultancy " },
    { "label": "Computer Software Publication", "value": "Computer Software Publication" },
    { "label": "Computers/ Electronics and Measurement & Control Equipment", "value": "Computers/ Electronics and Measurement & Control Equipment" },
    { "label": "Counselling/ Welfare/ Referral Activities without Accommodation for Elderly and Disabled", "value": "Counselling/ Welfare/ Referral Activities without Accommodation for Elderly and Disabled" },
    { "label": "Creative/ Arts and Entertainment Activities", "value": "Creative/ Arts and Entertainment Activities" },
    { "label": "Data Processing/ Web Hosting and Related Activities", "value": "Data Processing/ Web Hosting and Related Activities" },
    { "label": "Design & Photographic Services", "value": "Design & Photographic Services" },
    { "label": "Educational Support Services", "value": "Educational Support Services" },
    { "label": "Electric Power Generation/ Transmission and Distribution", "value": "Electric Power Generation/ Transmission and Distribution" },
    { "label": "Electrical Equipment/ Machinery and  Transport Equipment", "value": "Electrical Equipment/ Machinery and  Transport Equipment" },
    { "label": "Fishing and Aqua culture", "value": "Fishing and Aqua culture" },
    { "label": "Food and Beverage Services ", "value": "Food and Beverage Services " },
    { "label": "Food/ Beverages and Tobacco", "value": "Food/ Beverages and Tobacco" },
    { "label": "Water Collection/ Treatment and Supply", "value": "Water Collection/ Treatment and Supply" },
    { "label": "Wholesale Trading", "value": "Wholesale Trading" },
    { "label": "Wood/ Furniture and Paper", "value": "Wood/ Furniture and Paper" }]