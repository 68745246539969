import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { TokenService } from 'app/services/token.service';
import { of, pipe } from 'rxjs';
import { tap, switchMap, catchError, delay, first, startWith } from 'rxjs/operators';
import { mapLocation } from '../../../constants/mapLocation';
import gstJourneyWorkflow from 'app/layouts/workflow/gst-borrower-journey';
import navigate from '../../utils/handle-navigation';

@Component({
  selector: 'app-gst-flow',
  templateUrl: './gst-flow.component.html',
  styleUrls: ['./gst-flow.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GstFlowComponent implements OnInit {

  workflow = gstJourneyWorkflow
  params: any;

  constructor(private flowManagerService: FlowManagerService,
    private tokenService: TokenService,
    private route: ActivatedRoute,
    private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams.link) {
      window.location.href = this.route.snapshot.queryParams.link;
    }
    if (this.route.snapshot.queryParams.loggedIn) {
      this.flowManagerService.startWith({
        workflow: this.workflow,
        flowName: this.workflow.loading.key,
        step: 0
      });
    }
    else {
      this.flowManagerService.start(this.workflow);
      // this.flowManagerService.startWith({
      //   workflow: this.workflow,
      //   flowName: this.workflow.disQualifiedMessage.key,
      //   step: 1
      // }); 
    }

    if(this.route.snapshot.queryParams.token) {
      console.log(this.route.snapshot.queryParams.token);
      this.tokenService.setToken(this.route.snapshot.queryParams.token);
      const params = {...this.route.snapshot.queryParams};
      delete params.token;
      this.router.navigate([], {
        queryParams: params,
      });
    }

    this.tokenService.token$.pipe(
      switchMap(token => {
        console.log(token);
        if (token) {
          this.flowManagerService.modelValue = { journey: 'default' };
          return this.flowManagerService.journey.getJourney(this.params).pipe(catchError(err => {
            return of({
              ...this.flowManagerService.modelValue,
              location: null,
              status: err.status
            });
          }))
        }
        else {
          return of({ status: 401 })
        }
      }),
      delay(0),
      tap((data) => navigate(this.flowManagerService, data, this.workflow))
    ).subscribe();
  }

}
