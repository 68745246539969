import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { default_testimonials } from 'app/definitions/testimonial';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit, AfterViewInit {

  testimonials = default_testimonials;

  testimonialsOwlOptions: OwlOptions = {
    loop: false,
    margin: 30,
    autoplay: false,
    autoplayTimeout: 3000,
    rewind: false,
    responsive: {
      0: { items: 1, nav: false, autoHeight: false, autoWidth: false },
      600: { items: 1, nav: false, autoHeight: false, autoWidth: false },
      800: { items: 2, nav: false, autoHeight: false, autoWidth: false },
      1000: { items: 2, nav: false, autoHeight: false, autoWidth: false },
      1200: { items: 3, nav: false, autoHeight: false, autoWidth: false },
      1500: { items: 4, nav: false, autoHeight: false, autoWidth: false }
    }
  };

  constructor() { }  

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    setTimeout(()=> window.dispatchEvent(new Event('resize')))
  }

}
