import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileValid'
})
export class FileValidPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return value && !value.error && !value.isProtected;
  }

}
