import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isZip'
})
export class IsZipPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return value && value.toLowerCase().endsWith('.zip');
  }

}
