import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesDoctypeParser'
})
export class FilesDoctypeParserPipe implements PipeTransform {

  transform(filesInDocType: unknown, ...args: unknown[]): unknown {
    let files = [];
    let option = args[0] as any;

    if(filesInDocType && 
      filesInDocType[option.upload_folder] 
      && filesInDocType[option.upload_folder][option.value])
      {
        files = filesInDocType[option.upload_folder][option.value] 
      }

      return files;
  }
}
