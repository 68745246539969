export function getFormatFromComponents(year: number, month: number, day: number, format: string) {
    format = format.replace(/-/g, '').replace(/\//g, '');
  
    if (format.includes('DD')) {
      format = format.replace(/DD/g, day > 9 ? day.toString() : `0${day.toString()}`);
    } else if (format.includes('D')) {
      format = format.replace(/D/g, day.toString());
    }
  
    if (format.includes('MM')) {
      format = format.replace(/MM/g, month > 9 ? month.toString() : `0${month.toString()}`);
    } else if (format.includes('D')) {
      format = format.replace(/M/g, month.toString());
    }
  
    if (format.includes('YYYY')) {
      format = format.replace(/YYYY/g, year.toString());
    } else if (format.includes('D')) {
      format = format.replace(/YY/g, year.toString().substr(2));
    }
  
    return format;
  }