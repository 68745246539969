import { FormControl } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout, startWith, map, takeUntil, switchMap } from "rxjs/operators"
import gstJourneyWorkflow from "..";
import workFlow from ".."
import { Step } from "../..";
import stateOptions from "./state-options";

const hideLoanType = (model) => {
    return !model.loanamount || model.loanamount == "Rs 1-5 Lakhs" || model.loanamount == "Rs 5-10 Lakhs" || model.loanamount == "Rs 1-3 Crores" || model.loanamount == "Rs 3 Crores & above" || model.loanamount == "Rs 1-3 Crore" || model.loanamount == "Rs 3 Crore and above"
}

const fields = (flowManager: FlowManagerService): Array<FormlyFieldConfig> =>
    [
        {
            fieldGroupClassName: 'row',
            fieldGroup: [

                {
                    key: 'loanamount',
                    type: 'question',
                    modelOptions: {
                        updateOn: 'change',
                    },
                    templateOptions: {
                        label: "Loan amount required?",
                        options: [
                            { label: "1 - 5 Lacs", value: "Rs 1-5 Lakhs", },
                            { label: "6 - 10 Lacs", value: "Rs 5-10 Lakhs", },
                            { label: "11 - 20 Lacs", value: "Rs 10-20 Lakhs", },
                            { label: "21 - 50 Lacs", value: "Rs 20-50 Lakhs", },
                            { label: "51 Lacs - 1 Cr", value: "Rs 50 Lakhs - 1 Crore" },
                            { label: "More than 1 Cr", value: "Rs 1-3 Crores", }
                        ],
                        required: true,
                    },
                    hooks: {
                        onInit: field => {
                            field.formControl.valueChanges.subscribe(value => {
                                flowManager.modelValue = { ...flowManager.modelValue, loanamount: value };
                            })
                        }
                    },
                    className: 'col-12'
                },

                {
                    key: 'typeofloan',
                    type: 'question',
                    modelOptions: {
                        updateOn: 'change',
                    },
                    templateOptions: {
                        label: 'Loan type required?',
                        required: true,
                        options: [
                            { label: 'Unsecured loan', value: 'Unsecured', },
                            { label: 'Secured loan', value: 'Secured', },
                        ],
                    },
                    className: 'col-12',
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return hideLoanType(model);
                    },


                },
            ]
        },
        {
            hideExpression: (model, field) => {
                const isLoanTypeHidden = hideLoanType(model);
                return (!isLoanTypeHidden && !model.typeofloan) || !model.loanamount
            },
            fieldGroupClassName: 'row',
            fieldGroup: [

                {
                    key: 'pan',
                    type: 'mask',
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: 'text',
                        label: 'Your business PAN',
                        placeholder: 'AAAAA1111A',
                        mask: 'SSSSS0000S',
                        required: true,
                        autoCapitalize: true,
                        helpText: `'Business' is a sole proprietorship firm, or a partnership firm, or a company (private or public).
                        `
                    },
                    hooks: {
                        onInit: (field) => {
                            flowManager.model$.subscribe((model) => {
                                if (model.loanamount == "Rs 1-5 Lakhs") {
                                    field.templateOptions.label = 'Your PAN';
                                }
                                else {
                                    field.templateOptions.label = 'Your business PAN'
                                }
                            })
                            flowManager.invalidPan$.subscribe(()=>{
                                console.log("updateValueAndValidity");
                                field.formControl.updateValueAndValidity();
                            })
                        }
                    },
                    validators: {
                        pan: {
                            expression: (c) => !c.value || /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/.test(c.value),
                            message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a valid PAN. 
                            Please enter your 10 character PAN.`,
                        },
                        panBusinesType: {
                            expression: (c) => !c.value || c.value.length < 10 || /(^([a-zA-Z]{3})([pPfFcC]{1})([a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})$)/.test(c.value),
                            message: (error, field: FormlyFieldConfig) => `PAN does not match the business type we service right now.`,
                        },
                        invalidPan: {
                            expression: (c) => !c.value || !flowManager.currentInvalidPan || flowManager.currentInvalidPan.pan != c.value,
                            message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a valid PAN.`,
                        }
                    },
                    className: 'col-md-6 col-sm-12'
                },
                // {
                //     key: 'stateCode',
                //     type: 'select',
                //     templateOptions: {
                //         label: 'Your state',
                //         required: true,
                //         options: stateOptions
                //     },
                //     hooks: {
                //         onInit: (field) => {
                //             // flowManager.journey.getLocation()
                //             //     .subscribe(data => {
                //             //         let [state] = stateOptions.filter(s => s.label == data.region.toUpperCase());
                //             //         console.log(state);
                //             //         setTimeout(() => {
                //             //             flowManager.modelValue = { ...flowManager.modelValue, stateCode: state.value, pan: '' };
                //             //         })
                //             //     });
                //         }
                //     },
                //     className: 'col-md-6 col-sm-12'
                // },

            ]
        },
        {
            hideExpression: (model, field) => {
                return !model.pan || model.pan.length < 10
            },
            fieldGroupClassName: 'row',
            wrappers: ['panel'],
            templateOptions: {
                label: `Main promoter or director's name`,
                description: `Whose mobile number is ${flowManager.mobileNumber}`
            },
            fieldGroup: [

                {
                    key: 'first_name',
                    type: 'input',
                    modelOptions: {
                        updateOn: 'change',
                    },
                    templateOptions: {
                        label: `First Name`,
                        placeholder: 'eg. Lakshmi',
                        required: true,
                        min: 1,
                    },
                    validators: {
                        validation: ['name', 'max-length-name'],
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase()),
                            tap(v => v == formControl.value ? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: 'col-6'
                },
                {
                    key: 'last_name',
                    type: 'input',
                    templateOptions: {
                        label: 'Last Name',
                        placeholder: 'eg. Patel',
                        required: true,
                        min: 1,
                    },
                    validators: {
                        validation: ['name', 'max-length-name'],
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase()),
                            tap(v => v == formControl.value ? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: 'col-6'
                },
                {
                    key: 'email',
                    type: 'input',
                    templateOptions: {
                        type: 'email',
                        label: 'Your email',
                        placeholder: 'eg. lakshmi.patel@xyz.com',
                        required: true,
                        min: 5,
                        max: 100,
                        regex: "^(([^<>()\\[\\]\\\\.`;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.`;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1`3}\\.[0-9]{1`3}\\.[0-9]{1`3}\\.[0-9]{1`3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2`}))$"
                    },
                    validators: {
                        validation: ['email'],
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toLowerCase().trim()),
                            tap(v => v == formControl.value ? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: 'col-6',
                },
                {
                    key: 'personal_pan',
                    type: 'mask',
                    modelOptions: {
                        updateOn: 'change'
                    },
                    hooks: {
                        onInit: (field) => {
                            flowManager.invalidPan$.subscribe(()=>{
                                console.log("updateValueAndValidity");
                                field.formControl.updateValueAndValidity();
                            })
                        }
                    },
                    templateOptions: {
                        type: 'text',
                        label: `Promoter's PAN`,
                        placeholder: 'AAAAA1111A',
                        mask: 'SSSSS0000S',
                        required: true,
                        autoCapitalize: true,

                    },
                    validators: {
                        pan: {
                            expression: (c) => !c.value || /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/.test(c.value),
                            message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a valid PAN. 
                            Please enter your 10 character PAN.`,
                        },
                        personalPan: {
                            expression: (c) => !c.value || c.value.length < 10 || /(^([a-zA-Z]{3})([pP]{1})([a-zA-Z]{1})([0-9]{4})([a-zA-Z]{1})$)/.test(c.value),
                            message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a personal PAN.`,
                        },
                        invalidPan: {
                            expression: (c) => !c.value || !flowManager.currentInvalidPan || flowManager.currentInvalidPan.pan != c.value,
                            message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a valid PAN.`,
                        }
                    },
                    className: 'col-md-6 col-sm-12',
                    hideExpression: (model, field) => {
                        const pan = model.pan;
                        return !pan || pan.toLowerCase()[3] == 'p';
                    },
                },
                // {
                //     key: 'whatsappconsent',
                //     type: 'checkbox',
                //     templateOptions: {
                //         label: `I would like to receive updates on my application via <span class="text-ufo-green"> <i class="fab fa-whatsapp"> </i> WhatsApp </span>`,
                //         required: true,
                //     },
                //     defaultValue: true,
                //     className: 'col-12',                   
                // },
            ]
        },


    ]

const panWithLoan: Step = {
    key: "pan_with_loan",

    fields,

    hideBack: true,

    conversation: (model) => ({
        greeting: `Thanks for choosing CreditEnable!`,
        message: `I'm Lakshmi and I'll be helping guide you through your loan application. This will only take a few minutes. 
        `
    }),

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        const payload = {
            ...data,
            location: flowManager.location,
            ...flowManager.modelValue,
            pincode: "",
            businessname: "",
            gstins: [],
            whatsappconsent: flowManager.whatsappConsent
        };

        flowManager.startLoading();
        flowManager.stepNext();
        return flowManager.journey.searchGstinByPan(payload).pipe(
            catchError(err => {
                if (typeof err.error == 'string') {
                    if (err.error.includes("Failed to fetch tax payer information")) {
                        if (flowManager.isMobile()) {
                            flowManager.goto(gstJourneyWorkflow.businessDetails.key);
                        }
                        else {
                            flowManager.goto(gstJourneyWorkflow.businessDetailsDesktop.key);
                        }
                    }
                }
                else if (err.error.message && err.error.message.includes("Invalid PAN")) {
                    flowManager.stepPrevious();
                    flowManager.setInvalidPan(err.error);
                }

                return throwError(err);
            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            switchMap(result=> {
                if(flowManager.is5LacAndHasInvalidGstins()) {
                    if (flowManager.isGstinsEmpty) {
                        return flowManager.journey.updateJourney(flowManager.modelValue._id,
                            {
                              disqualified: true,
                              disqualificationKey: 'gst',
                              disqualificationMessage: `As you do not have a valid GST registration, 
                              your business is currently not eligible for a loan from our lender partners. 
                              We'll be able to help you once you have an active GST registration.` })
                            ;                        
                      }
                      else {
                        return flowManager.journey.updateJourney(flowManager.modelValue._id,
                            {
                              disqualified: true,
                              disqualificationKey: 'gst',
                              disqualificationMessage: `We're unable to help you get a business loan from our lender partners 
                              as your business is currently too young. To fulfil your business funding needs, our lender partners 
                              require a business age of more than 2-years, according to your GST records.`})
                            ;
                      }
                }
                return of(result);
            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap(() => {
                const model = flowManager.modelValue;

                if (model.disqualified) {
                    flowManager.goto(workFlow.disQualifiedMessage.key);
                    return;
                }

                if ((!model.gstins || model.gstins.filter(b => b.isBusinessVintage).length == 0)) {
                    if (flowManager.isMobile()) {
                        flowManager.goto(gstJourneyWorkflow.businessDetails.key);
                    }
                    else {
                        flowManager.goto(gstJourneyWorkflow.businessDetailsDesktop.key);
                    }
                }
                else {
                    flowManager.stepNext();
                }
            }),
            tap(data => console.log(data)),
            tap((result) => flowManager.gtmTriggerService.triggerButtonClick({ className: "stage-complete stage-1" })),
            tap(_ => flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },

}

export default panWithLoan;
