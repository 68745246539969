import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-loading-step',
  templateUrl: './loading-step.component.html',
  styleUrls: ['./loading-step.component.css'],
})
export class LoadingStepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
