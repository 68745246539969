import { documentCategories } from "./document-categories.constant";

export const documentList = [
    {
      predicate: (borrower: { businesstype: string; typeofloan: string; journey: string; }) => borrower.businesstype=='Sole Proprietorship' && borrower.typeofloan == 'Secured' && (borrower.journey=='flipkart'),
      categories: [
        {
          "document_id": "company_kyc",
          "document_type": "BUSINESS DOCUMENTS",
          "document_label": "business documents",
          "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only.",
          "documents": [{
            "name": "Business GSTIN",
            "required": true,
            "options": []
          }],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "individual_kyc",
          "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
          "document_label": "Proprietor/Partner documents",
          "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only.<br/>Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
          "documents": [{
            "name": "Personal PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Proof of Address",
            "required": true,
            "required_min": 1,
            "options": [
              {
                "option": "Driving License"
              },
              {
                "option": "Phone Bill"
              },
              {
                "option": "Passport"
              },
              {
                "option": "Electricity Bill"
              },
            ]
          },
          {
            "name": "Personal Aadhar Card",
            "required": true,
            "options": []
          }
          ],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "bank_statements",
          "document_type": "BANK STATEMENTS",
          "document_label": "Bank Statements",
          "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
          "documents": [{
            "name": "12 months Bank Statement PDF of all bank accounts",
            "doc_prepend": "Bank Statement",
            "required": true,
            "options": [],
            "password": true
          }
          ],
          "file_types": {
            'application/pdf': '.PDF'
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "property_ownership_proof",
          "document_type": "PROPERTY OWNERSHIP PROOF",
          "document_label": "PROPERTY OWNERSHIP PROOF",
          "documents": [
            {
              "name": "Utility bill or other",
              "doc_prepend": "Property Proof",
              "required": true,
              "options": []
            }
          ],
          "maxFileSize": "20MB"
        },
        {
          "document_id": "itr",
          "document_type": "ITR",
          "document_label": "INCOME TAX RETURN (PDF)",
          "notes": "",
          "documents": [
            {
              "name": "ITR & Computation - Most Recent Year",
              "doc_prepend": "ITR - Recent",
              "required": true,
              "options": []
            },
            {
              "name": "ITR & Computation - Previous Year",
              "doc_prepend": "ITR - Old",
              "required": true,
              "options": []
            },
          ]
        },
        {
          "document_id": "audited_financials",
          "document_type": "AUDITED FINANCIALS",
          "document_label": "AUDITED FINANCIALS",
          "notes": "",
          "documents": [
            {
              "name": "Audited Financial Statements - Most Recent Year",
              "doc_prepend": "Financials - Recent",
              "required": true,
              "options": []
            },
            {
              "name": "Audited Financial Statements - Previous Year",
              "doc_prepend": "Financials - Old",
              "required": true,
              "options": []
            },
            {
              "name": "Provisional Financials (if available)",
              "doc_prepend": "Provisional Financials",
              "required": false,
              "options": []
            },
            {
              "name": "Financial projections (if available)",
              "doc_prepend": "Financial Projections",
              "required": false,
              "options": []
            }
          ]
        },
        {
          "document_id": "loan_takeover",
          "document_type": "LOAN TAKEOVER",
          "document_label": "LOAN TAKEOVER",
          "notes": "These documents are only required if you are looking for a loan takeover.<br/>If you are looking for a new loan, these documents will not be required",
          "is_optional": "1 == 1",
          "documents": [
            {
              "name": "Loan Account Statement",
              "doc_prepend": "Loan Account Statement",
              "options": []
            },
            {
              "name": "Latest Sanction Letters",
              "doc_prepend": "Sanction Letters",
              "options": []
            }
          ]
        },
        {
          "document_id": "gst_returns",
          "document_type": "GST RETURNS",
          "document_label": "GST RETURNS",
          "notes": "",
          "documents": [{
            "name": "Last 12 months GSTR-3B YTD",
            "doc_prepend": "GSTR-3B",
            "required": true,
            "options": []
          }]
        },
        {
          "document_id": "collateral_documents",
          "document_type": "COLLATERAL DOCUMENTS",
          "document_label": "COLLATERAL DOCUMENTS",
          "notes": "The requirement to provide documentation in this category is case-specific. Our team will request this documentation from you only if required to secure your loan",
          "is_optional": "1 == 1",
          "documents": [
            {
              "name": "Property Market Value Document",
              "doc_prepend": "Property Market Value",
              "options": []
            },
            {
              "name": "Other Collateral Document",
              "doc_prepend": "Other",
              "options": []
            }
          ]
        }
      ]
    },
    {
      predicate: borrower=> (borrower.businesstype=='Partnership') && borrower.typeofloan == 'Secured' && (borrower.journey=='flipkart'),
      categories: [
        {
          "document_id": "company_kyc",
          "document_type": "BUSINESS DOCUMENTS",
          "document_label": "business documents",
          "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only",
          "documents": [{
            "name": "Business GSTIN",
            "required": true,
            "options": []
          },
          {
            "name": "Business PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Partnership Deed",
            "required": true,
            "options": []
          }],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "individual_kyc",
          "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
          "document_label": "Proprietor/Partner documents",
          "notes": "Please upload a complete set of Proof of Identity and Proof of Address documentation for each partner in the business.<br/>Guidance for filling in this section: <br/>1.	Please ensure you are uploading the PAN of the main applicant.<br/>2.	Upload a complete set of Proof of Identity, Proof of Address and Aadhar documentation for each Partner in the business.<br/>3.	Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
          "repeat_for": "partners",
          "repeat_section_label": "name",
          "documents": [
            {
              "name": "Personal PAN Card",
              "required": true,
              "options": []
            },
            {
              "name": "Proof of Address",
              "required": true,
              "required_min": 1,
              "options": [
                {
                  "option": "Driving License"
                },
                {
                  "option": "Phone Bill"
                },
                {
                  "option": "Passport"
                },
                {
                  "option": "Electricity Bill"
                },
              ]
            },
            {
              "name": "Aadhar Card",
              "required": true,
              "options": []
            }
          ],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "bank_statements",
          "document_type": "BANK STATEMENTS",
          "document_label": "Bank Statements",
          "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
          "documents": [{
            "name": "12 months Bank Statement PDF of all bank accounts",
            "doc_prepend": "Bank Statement",
            "required": true,
            "options": [],
            "password": true
          }
          ],
          "file_types": {
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "property_ownership_proof",
          "document_type": "PROPERTY OWNERSHIP PROOF",
          "document_label": "PROPERTY OWNERSHIP PROOF",
          "documents": [
            {
              "name": "Utility bill or other",
              "doc_prepend": "Property Proof",
              "required": true,
              "options": []
            }
          ],
          "maxFileSize": "20MB"
        },
        {
          "document_id": "itr",
          "document_type": "ITR",
          "document_label": "INCOME TAX RETURN (PDF)",
          "notes": "",
          "documents": [
            {
              "name": "ITR & Computation - Most Recent Year",
              "doc_prepend": "ITR - Recent",
              "required": true,
              "options": []
            },
            {
              "name": "ITR & Computation - Previous Year",
              "doc_prepend": "ITR - Old",
              "required": true,
              "options": []
            },
          ]
        },
        {
          "document_id": "audited_financials",
          "document_type": "AUDITED FINANCIALS",
          "document_label": "AUDITED FINANCIALS",
          "notes": "",
          "documents": [
            {
              "name": "Audited Financial Statements - Most Recent Year",
              "doc_prepend": "Financials - Recent",
              "required": true,
              "options": []
            },
            {
              "name": "Audited Financial Statements - Previous Year",
              "doc_prepend": "Financials - Old",
              "required": true,
              "options": []
            },
            {
              "name": "Provisional Financials (if available)",
              "doc_prepend": "Provisional Financials",
              "required": false,
              "options": []
            },
            {
              "name": "Financial projections (if available)",
              "doc_prepend": "Financial Projections",
              "required": false,
              "options": []
            }
          ]
        },
        {
          "document_id": "loan_takeover",
          "document_type": "LOAN TAKEOVER",
          "document_label": "LOAN TAKEOVER",
          "notes": "These documents are only required if you are looking for a loan takeover.<br/>If you are looking for a new loan, these documents will not be required",
          "is_optional": "1 == 1",
          "documents": [
            {
              "name": "Loan Account Statement",
              "doc_prepend": "Loan Account Statement",
              "options": []
            },
            {
              "name": "Latest Sanction Letters",
              "doc_prepend": "Sanction Letters",
              "options": []
            }
          ]
        },
        {
          "document_id": "gst_returns",
          "document_type": "GST RETURNS",
          "document_label": "GST RETURNS",
          "notes": "",
          "documents": [{
            "name": "Last 12 months GSTR-3B YTD",
            "doc_prepend": "GSTR-3B",
            "required": true,
            "options": []
          }]
        },
        {
          "document_id": "collateral_documents",
          "document_type": "COLLATERAL DOCUMENTS",
          "document_label": "COLLATERAL DOCUMENTS",
          "notes": "The requirement to provide documentation in this category is case-specific. Our team will request this documentation from you only if required to secure your loan",
          "is_optional": "1 == 1",
          "documents": [
            {
              "name": "Property Market Value Document",
              "doc_prepend": "Property Market Value",
              "options": []
            },
            {
              "name": "Other Collateral Document",
              "doc_prepend": "Other",
              "options": []
            }
          ]
        }
      ]
    },
    {
      predicate: (borrower: { businesstype: string; typeofloan: string; journey: string; })=> (borrower.businesstype=='Private Limited' || borrower.businesstype=='LLP') && borrower.typeofloan == 'Secured' && (borrower.journey=='flipkart'),
      categories: [
        {
          "document_id": "company_kyc",
          "document_type": "BUSINESS DOCUMENTS",
          "document_label": "business documents",
          "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only",
          "documents": [
            {
              "name": "Business PAN Card",
              "required": true,
              "options": []
            },
            {
              "name": "Company Incorporation Certificate",
              "required": true,
              "options": []
            },
            {
              "name": "MoA/AoA",
              "required": true,
              "options": []
            },
            {
              "name": "Shareholding Pattern",
              "required": true,
              "options": []
            },
            {
              "name": "Business GSTIN",
              "required": true,
              "options": []
            }],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "individual_kyc",
          "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
          "document_label": "Proprietor/Partner documents",
          "notes": "Please upload a complete set of Proof of Identity and Proof of Address documentation for each partner in the business.<br/>Guidance for filling in this section: <br/>1.	Please ensure you are uploading the PAN of the main applicant.<br/>2.	Upload a complete set of Proof of Identity, Proof of Address and Aadhar documentation for each Partner in the business.<br/>3.	Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
          "repeat_for": "partners",
          "repeat_section_label": "name",
          "documents": [{
            "name": "Personal PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Proof of Address",
            "required": true,
            "required_min": 1,
            "options": [
              {
                "option": "Driving License"
              },
              {
                "option": "Phone Bill"
              },
              {
                "option": "Passport"
              },
              {
                "option": "Electricity Bill"
              },
            ]
          },
          {
            "name": "Personal Aadhar Card",
            "required": true,
            "options": []
          }
          ],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "bank_statements",
          "document_type": "BANK STATEMENTS",
          "document_label": "Bank Statements",
          "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
          "documents": [{
            "name": "12 months Bank Statement PDF of all bank accounts",
            "doc_prepend": "Bank Statement",
            "required": true,
            "options": [],
            "password": true
          }
          ],
          "file_types": {
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "property_ownership_proof",
          "document_type": "PROPERTY OWNERSHIP PROOF",
          "document_label": "PROPERTY OWNERSHIP PROOF",
          "documents": [
            {
              "name": "Utility bill or other",
              "doc_prepend": "Property Proof",
              "required": true,
              "options": []
            }
          ],
          "maxFileSize": "20MB"
        },
        {
          "document_id": "itr",
          "document_type": "ITR",
          "document_label": "INCOME TAX RETURN (PDF)",
          "notes": "",
          "documents": [
            {
              "name": "ITR & Computation - Most Recent Year",
              "doc_prepend": "ITR - Recent",
              "required": true,
              "options": []
            },
            {
              "name": "ITR & Computation - Previous Year",
              "doc_prepend": "ITR - Old",
              "required": true,
              "options": []
            },
          ]
        },
        {
          "document_id": "audited_financials",
          "document_type": "AUDITED FINANCIALS",
          "document_label": "AUDITED FINANCIALS",
          "notes": "",
          "documents": [
            {
              "name": "Audited Financial Statements - Most Recent Year",
              "doc_prepend": "Financials - Recent",
              "required": true,
              "options": []
            },
            {
              "name": "Audited Financial Statements - Previous Year",
              "doc_prepend": "Financials - Old",
              "required": true,
              "options": []
            },
            {
              "name": "Provisional Financials (if available)",
              "doc_prepend": "Provisional Financials",
              "required": false,
              "options": []
            },
            {
              "name": "Financial projections (if available)",
              "doc_prepend": "Financial Projections",
              "required": false,
              "options": []
            }
          ]
        },
        {
          "document_id": "loan_takeover",
          "document_type": "LOAN TAKEOVER",
          "document_label": "LOAN TAKEOVER",
          "notes": "These documents are only required if you are looking for a loan takeover.<br/>If you are looking for a new loan, these documents will not be required",
          "is_optional": "1 == 1",
          "documents": [
            {
              "name": "Loan Account Statement",
              "doc_prepend": "Loan Account Statement",
              "options": []
            },
            {
              "name": "Latest Sanction Letters",
              "doc_prepend": "Sanction Letters",
              "options": []
            }
          ]
        },
        {
          "document_id": "gst_returns",
          "document_type": "GST RETURNS",
          "document_label": "GST RETURNS",
          "notes": "",
          "documents": [{
            "name": "Last 12 months GSTR-3B YTD",
            "doc_prepend": "GSTR-3B",
            "required": true,
            "options": []
          }]
        },
        {
          "document_id": "collateral_documents",
          "document_type": "COLLATERAL DOCUMENTS",
          "document_label": "COLLATERAL DOCUMENTS",
          "notes": "The requirement to provide documentation in this category is case-specific. Our team will request this documentation from you only if required to secure your loan",
          "is_optional": "1 == 1",
          "documents": [
            {
              "name": "Property Market Value Document",
              "doc_prepend": "Property Market Value",
              "options": []
            },
            {
              "name": "Other Collateral Document",
              "doc_prepend": "Other",
              "options": []
            }
          ]
        }
      ]
    },
    {
      predicate: (borrower: { businesstype: string; journey: string; }) => borrower.businesstype=='Sole Proprietorship' && (borrower.journey=='flipkart'),
      categories: [
        {
          "document_id": "company_kyc",
          "document_type": "BUSINESS DOCUMENTS",
          "document_label": "business documents",
          "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only.",
          "documents": [{
            "name": "Business GSTIN",
            "required": true,
            "options": []
          }],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "individual_kyc",
          "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
          "document_label": "Proprietor/Partner documents",
          "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only.<br/>Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
          "documents": [{
            "name": "Personal PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Proof of Address",
            "required": true,
            "required_min": 1,
            "options": [
              {
                "option": "Driving License"
              },
              {
                "option": "Phone Bill"
              },
              {
                "option": "Passport"
              },
              {
                "option": "Electricity Bill"
              },
            ]
            },
            {
              "name": "Personal Aadhar Card",
              "required": true,
              "options": []
            }
          ],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "bank_statements",
          "document_type": "BANK STATEMENTS",
          "document_label": "Bank Statements",
          "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
          "documents": [{
            "name": "12 months Bank Statement PDF of all bank accounts",
            "doc_prepend": "Bank Statement",
            "required": true,
            "options": [],
            "password": true
          }
          ],
          "file_types": {
            'application/pdf': '.PDF'
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "property_ownership_proof",
          "document_type": "PROPERTY OWNERSHIP PROOF",
          "document_label": "Property Ownership Proof",
          "documents": [
            {
              "name": "Utility bill or other",
              "doc_prepend": "Property Proof",
              "required": true,
              "options": []
            }
          ],
          "maxFileSize": "20MB"
        }
      ]
    },
    {
      predicate: (borrower: { businesstype: string; journey: string; }) => (borrower.businesstype=='Partnership') && (borrower.journey=='flipkart'),
      categories: [
        {
          "document_id": "company_kyc",
          "document_type": "BUSINESS DOCUMENTS",
          "document_label": "business documents",
          "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only",
          "documents": [{
            "name": "Business GSTIN",
            "required": true,
            "options": []
          },
            {
              "name": "Business PAN Card",
              "required": true,
              "options": []
            },
            {
              "name": "Partnership Deed",
              "required": true,
              "options": []
            }],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "individual_kyc",
          "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
          "document_label": "Proprietor/Partner documents",
          "notes": "Please upload a complete set of Proof of Identity and Proof of Address documentation for each partner in the business.<br/>Guidance for filling in this section: <br/>1.	Please ensure you are uploading the PAN of the main applicant.<br/>2.	Upload a complete set of Proof of Identity, Proof of Address and Aadhar documentation for each Partner in the business.<br/>3.	Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
          "repeat_for": "partners",
          "repeat_section_label": "name",
          "documents": [
            {
              "name": "Personal PAN Card",
              "required": true,
              "options": []
            },
            {
              "name": "Proof of Address",
              "required": true,
              "required_min": 1,
              "options": [
                {
                  "option": "Driving License"
                },
                {
                  "option": "Phone Bill"
                },
                {
                  "option": "Passport"
                },
                {
                  "option": "Electricity Bill"
                },
              ]
            },
            {
              "name": "Aadhar Card",
              "required": true,
              "options": []
            }
          ],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "bank_statements",
          "document_type": "BANK STATEMENTS",
          "document_label": "Bank Statements",
          "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
          "documents": [{
            "name": "12 months Bank Statement PDF of all bank accounts",
            "doc_prepend": "Bank Statement",
            "required": true,
            "options": [],
            "password": true
          }
          ],
          "file_types": {
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "property_ownership_proof",
          "document_type": "PROPERTY OWNERSHIP PROOF",
          "document_label": "PROPERTY OWNERSHIP PROOF",
          "documents": [
            {
              "name": "Utility bill or other",
              "doc_prepend": "Property Proof",
              "required": true,
              "options": []
            }
          ],
          "maxFileSize": "20MB"
        }
      ]
    },
    {
      predicate: (borrower: { businesstype: string; journey: string; }) => (borrower.businesstype=='Private Limited' || borrower.businesstype=='LLP') && (borrower.journey=='flipkart'),
      categories: [
        {
          "document_id": "company_kyc",
          "document_type": "BUSINESS DOCUMENTS",
          "document_label": "business documents",
          "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only",
          "documents": [
            {
              "name": "Business PAN Card",
              "required": true,
              "options": []
            },
            {
              "name": "Company Incorporation Certificate",
              "required": true,
              "options": []
            },
            {
              "name": "MoA/AoA",
              "required": true,
              "options": []
            },
            {
              "name": "Shareholding Pattern",
              "required": true,
              "options": []
            },
            {
            "name": "Business GSTIN",
            "required": true,
            "options": []
            }],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "individual_kyc",
          "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
          "document_label": "Proprietor/Partner documents",
          "notes": "Please upload a complete set of Proof of Identity and Proof of Address documentation for each partner in the business.<br/>Guidance for filling in this section: <br/>1.	Please ensure you are uploading the PAN of the main applicant.<br/>2.	Upload a complete set of Proof of Identity, Proof of Address and Aadhar documentation for each Partner in the business.<br/>3.	Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
          "repeat_for": "partners",
          "repeat_section_label": "name",
          "documents": [{
            "name": "Personal PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Proof of Address",
            "required": true,
            "required_min": 1,
            "options": [
              {
                "option": "Driving License"
              },
              {
                "option": "Phone Bill"
              },
              {
                "option": "Passport"
              },
              {
                "option": "Electricity Bill"
              },
            ]
            },
            {
              "name": "Personal Aadhar Card",
              "required": true,
              "options": []
            }
          ],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "bank_statements",
          "document_type": "BANK STATEMENTS",
          "document_label": "Bank Statements",
          "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
          "documents": [{
            "name": "12 months Bank Statement PDF of all bank accounts",
            "doc_prepend": "Bank Statement",
            "required": true,
            "options": [],
            "password": true
          }
          ],
          "file_types": {
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "property_ownership_proof",
          "document_type": "PROPERTY OWNERSHIP PROOF",
          "document_label": "PROPERTY OWNERSHIP PROOF",
          "documents": [
            {
              "name": "Utility bill or other",
              "doc_prepend": "Property Proof",
              "required": true,
              "options": []
            }
          ],
          "maxFileSize": "20MB"
        }
      ]
    },
    {
      predicate: (borrower: { businesstype: string; journey: string; }) => borrower.businesstype=='Sole Proprietorship' && (borrower.journey=='flipkart-bob'),
      categories: [
        {
          "document_id": "company_kyc",
          "document_type": "BUSINESS DOCUMENTS",
          "document_label": "business documents",
          "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only.",
          "documents": [{
            "name": "Business GSTIN",
            "required": true,
            "options": []
          }],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "individual_kyc",
          "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
          "document_label": "Proprietor/Partner documents",
          "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only.<br/>Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
          "documents": [{
            "name": "Personal PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Proof of Address",
            "required": true,
            "required_min": 1,
            "options": [
              {
                "option": "Driving License"
              },
              {
                "option": "Phone Bill"
              },
              {
                "option": "Passport"
              },
              {
                "option": "Electricity Bill"
              },
            ]
            },
            {
              "name": "Aadhar Card",
              "required": true,
              "options": []
            }
          ],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "bank_statements",
          "document_type": "BANK STATEMENTS",
          "document_label": "Bank Statements",
          "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 6 months.",
          "documents": [{
            "name": "6 months Bank Statement PDF of all bank accounts",
            "doc_prepend": "Bank Statement",
            "required": true,
            "options": [],
            "password": true
          }
          ],
          "file_types": {
            'application/pdf': '.PDF'
          },
          "maxFileSize": "20MB"
        }
      ]
    },
    {
      predicate: (borrower: { businesstype: string; journey: string; }) => (borrower.businesstype=='Partnership') && (borrower.journey=='flipkart-bob'),
      categories: [
        {
          "document_id": "company_kyc",
          "document_type": "BUSINESS DOCUMENTS",
          "document_label": "business documents",
          "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only",
          "documents": [{
            "name": "Business GSTIN",
            "required": true,
            "options": []
          },
          {
            "name": "Business PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Partnership Deed",
            "required": true,
            "options": []
          }],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "individual_kyc",
          "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
          "document_label": "Proprietor/Partner documents",
          "notes": "Please upload a complete set of Proof of Identity and Proof of Address documentation for each partner in the business.<br/>Guidance for filling in this section: <br/>1.	Please ensure you are uploading the PAN of the main applicant.<br/>2.	Upload a complete set of Proof of Identity, Proof of Address and Aadhar documentation for each Partner in the business.<br/>3.	Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
          "repeat_for": "partners",
          "repeat_section_label": "name",
          "documents": [{
            "name": "Personal PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Proof of Address",
            "required": true,
            "required_min": 1,
            "options": [
              {
                "option": "Driving License"
              },
              {
                "option": "Phone Bill"
              },
              {
                "option": "Passport"
              },
              {
                "option": "Electricity Bill"
              },
            ]
            },
            {
              "name": "Aadhar Card",
              "required": true,
              "options": []
            }
          ],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "bank_statements",
          "document_type": "BANK STATEMENTS",
          "document_label": "Bank Statements",
          "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 6 months.",
          "documents": [{
            "name": "6 months Bank Statement PDF of all bank accounts",
            "doc_prepend": "Bank Statement",
            "required": true,
            "options": [],
            "password": true
          }
          ],
          "file_types": {
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
      ]
    },
    {
      predicate: (borrower: { businesstype: string; journey: string; }) => (borrower.businesstype=='Private Limited' || borrower.businesstype=='LLP') && (borrower.journey=='flipkart-bob'),
      categories: [
        {
          "document_id": "company_kyc",
          "document_type": "BUSINESS DOCUMENTS",
          "document_label": "business documents",
          "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only",
          "documents": [
            {
              "name": "Business PAN Card",
              "required": true,
              "options": []
            },
            {
              "name": "Company Incorporation Certificate",
              "required": true,
              "options": []
            },
            {
              "name": "MoA/AoA",
              "required": true,
              "options": []
            },
            {
              "name": "Shareholding Pattern",
              "required": true,
              "options": []
            },
            {
              "name": "Business GSTIN",
              "required": true,
              "options": []
            }],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "individual_kyc",
          "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
          "document_label": "Proprietor/Partner documents",
          "notes": "Please upload a complete set of Proof of Identity and Proof of Address documentation for each partner in the business.<br/>Guidance for filling in this section: <br/>1.	Please ensure you are uploading the PAN of the main applicant.<br/>2.	Upload a complete set of Proof of Identity, Proof of Address and Aadhar documentation for each Partner in the business.<br/>3.	Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
          "repeat_for": "partners",
          "repeat_section_label": "name",
          "documents": [{
            "name": "Personal PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Proof of Address",
            "required": true,
            "required_min": 1,
            "options": [
              {
                "option": "Driving License"
              },
              {
                "option": "Phone Bill"
              },
              {
                "option": "Passport"
              },
              {
                "option": "Electricity Bill"
              },
            ]
            },
            {
              "name": "Aadhar Card",
              "required": true,
              "options": []
            }
          ],
          "file_types": {
            'image/jpg': '.JPG',
            'image/jpeg': '.JPEG',
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        },
        {
          "document_id": "bank_statements",
          "document_type": "BANK STATEMENTS",
          "document_label": "Bank Statements",
          "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 6 months.",
          "documents": [{
            "name": "6 months Bank Statement PDF of all bank accounts",
            "doc_prepend": "Bank Statement",
            "required": true,
            "options": [],
            "password": true
          }
          ],
          "file_types": {
            'application/pdf': '.PDF',
          },
          "maxFileSize": "20MB"
        }
      ]
    },
    {
      predicate: (borrower: { admindashboard: any; }) => borrower.admindashboard,
      categories: [...documentCategories]
    },
    {
      predicate: (borrower: { loanamount: string; }) => borrower.loanamount=='Rs 1-5 Lakhs' || borrower.loanamount=='₹1 Lakh to ₹5 Lakhs',
      categories: documentCategories.filter(c => ['individual_kyc', 'company_kyc', 'financials'].includes(c.document_id))
    },
    {
      predicate: (borrower: { loanamount: string; })=>borrower.loanamount=='Rs 5-10 Lakhs' || borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs',
      categories: documentCategories.filter(c => ['individual_kyc', 'company_kyc', 'financials'].includes(c.document_id))
    },
    {
      predicate: (borrower: { loanamount: string; })=> borrower.loanamount!='Rs 1-5 Lakhs' && borrower.loanamount!='Rs 5-10 Lakhs' && borrower.loanamount!='₹1 Lakh to ₹5 Lakhs' && borrower.loanamount!='₹6 Lakhs to ₹10 Lakhs',
      categories: documentCategories.filter(c => ['individual_kyc', 'company_kyc', 'financials'].includes(c.document_id))
    }
  ];
  