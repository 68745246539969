import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <hr/>
    <div style="margin:15px 0;">
      <label class="control-label">{{ to.label }}</label>
    </div>
    <div *ngFor="let field of field.fieldGroup; let i = index;" class="row">
      <formly-field class="col" [field]="field"></formly-field>
      <div class="col-sm-2 d-flex align-items-center">
        <button *ngIf="i > 0 || !to.required" class="btn btn-sm btn-danger" type="button" (click)="remove(i)">Remove</button>
      </div>
    </div>
    <hr/>
    <div style="margin:15px 0;" >
      <button class="btn btn-sm btn-primary" type="button" (click)="add()">{{ to.addText }}</button>
    </div>
    <hr/>
  `,
})
export class RepeatTypeComponent extends FieldArrayType { }
