export default [
    {label: "None of the below", value: "No"},
    {label: "Real estate developers \& agents", value: "Real estate developers \& agents"},
    {label: "Real estate developers \& agents", value: "Real estate developers \& agents"},
    {label: "Jewelry / Diamond / Gems / Precious Metals", value: "Jewelry / Diamond / Gems / Precious Metals"},
    {label: "Massage centers / Spa", value: "Massage centers / Spa"},
    {label: "Tobacco products", value: "Tobacco products"},
    {label: "Press / Media companies / Journalist", value: "Press / Media companies / Journalist"},
    {label: "Bars", value: "Bars"},
    {label: "Arms and ammunition dealers", value: "Arms and ammunition dealers"},
    {label: "Financial service providers (NBFCs / Other money lending companies)", value: "Financial service providers (NBFCs / Other money lending companies)"},
    {label: "Shares/ Bitcoins/ Old coins trading or other speculative activities", value: "Shares / Bitcoins / Old coins trading or other speculative activities"},
    {label: "Brokerage houses", value: "Brokerage houses"},
    {label: "DSA/ Collection agencies ", value: "DSA/ Collection agencies "},
    {label: "Wine shops", value: "Wine shops"},
    {label: "Astrologers", value: "Astrologers"},
    {label: "Marriage bureau", value: "Marriage bureau"},
    {label: "Trusts / NGO", value: "Trusts / NGO"},
    {label: "RTO agents", value: "RTO agents"},
    {label: "Cable TV operator", value: "Cable TV operator"},
    {label: "None of the above", value: "No"},
]