import { data } from "jquery";

export var idleTimeout = 6000;
export var logoutTime = 1200;
export var pingTime = 3000;

export enum JourneyStatus {
  Ongoing,
  Qualified,
  Disqualified,
  Documents
};


var now = new Date();
var d2 = new Date(now.getFullYear(), now.getMonth() -1,1);
var d1 = new Date(now.getFullYear() - 1, now.getMonth(), 1);
var contextYearMonth = `${d1.toLocaleString('default', { month: 'long' })} ${d1.getFullYear()} to ${d2.toLocaleString('default', { month: 'long' })} ${d2.getFullYear()}`; 

export const stateList = {
  "JAMMU AND KASHMIR": "01",
  "HIMACHAL PRADESH": "02",
  "PUNJAB": "03",
  "CHANDIGARH": "04",
  "UTTARAKHAND": "05",
  "HARYANA": "06",
  "DELHI": "07",
  "RAJASTHAN": "08",
  "UTTAR PRADESH": "09",
  "BIHAR": "10",
  "SIKKIM": "11",
  "ARUNACHAL PRADESH": "12",
  "NAGALAND": "13",
  "MANIPUR": "14",
  "MIZORAM": "15",
  "TRIPURA": "16",
  "MEGHLAYA": "17",
  "ASSAM": "18",
  "WEST BENGAL": "19",
  "JHARKHAND": "20",
  "ODISHA": "21",
  "CHATTISGARH": "22",
  "MADHYA PRADESH": "23",
  "GUJARAT": "24",
  "DADRA AND NAGAR HAVELI": "26",
  "MAHARASHTRA": "27",
  "ANDHRA PRADESH": "28",
  "KARNATAKA": "29",
  "GOA": "30",
  "LAKSHWADEEP": "31",
  "KERALA": "32",
  "TAMIL NADU": "33",
  "PUDUCHERRY": "34",
  "ANDAMAN AND NICOBAR ISLANDS": "35",
  "TELANGANA": "36",
  "LADAKH": "38",
  "DAMAN AND DIU": "26",
};
export const old_document_categories = [
  {
    "document_id": "individual_kyc",
    "document_type": "Individual KYC",
    "document_label": "Individual KYC",
    "notes": "In case of Partnership, LLP and Private Limited Companies please upload Individual PAN, Aadhar and Proof of Address for each of the partners or directors separately",
    "documents": [
      {
        "name": "Business Owner Individual PAN",
        "new_document_id": "individual_kyc",
        "new_document_category" : "PAN",
        "doc_prepend": "PAN",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Sole Proprietorship'"
      },
      {
        "name": "Business Owner Aadhar",
        "new_document_id": "individual_kyc",
        "new_document_category": "Aadhar",
        "doc_prepend": "Aadhar",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Sole Proprietorship'"
      },
      {
        "name": "Proof of Address",
        "doc_prepend": "Address Proof",
        "new_document_id": "individual_kyc",
        "new_document_category": "Aadhar",
        "required": true,
        "options": [
          {
            "option": "Passport"
          },
          {
            "option": "Utility Bill"
          }
        ],
        "hide_criteria": "this.borrower.businesstype != 'Sole Proprietorship'"
      },
      {
        "name": "Co-applicant KYC (if applicable)",
        "doc_prepend": "Co-applicant KYC",
        "new_document_id": "individual_kyc",
        "new_document_category": "PAN",
        "required": false,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Sole Proprietorship'"
      },
      {
        "name": "Individual PAN for all Directors/Partners",
        "doc_prepend": "PAN",
        "new_document_id": "individual_kyc",
        "new_document_category": "PAN",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype == 'Sole Proprietorship'"
      },
      {
        "name": "Aadhar for all Directors/Partners",
        "doc_prepend": "Aadhar",
        "new_document_id": "individual_kyc",
        "new_document_category": "Aadhar",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype == 'Sole Proprietorship'"
      },
      {
        "name": "Proof of Address for each partner/director in at least one of the following forms",
        "doc_prepend": "Address Proof",
        "new_document_id": "individual_kyc",
        "new_document_category": "Aadhar",
        "required": true,
        "options": [
          {
            "option": "Aadhar"
          },
          {
            "option": "Passport"
          },
          {
            "option": "Utility Bill"
          }
        ],
        "hide_criteria": "this.borrower.businesstype == 'Sole Proprietorship'"
      }
    ]
  },
  {
    "document_id": "company_kyc",
    "document_type": "Business/Company KYC",
    "document_label": "Business/Company KYC",
    "notes": "",
    "documents": [
      {
        "name": "Business/Company PAN",
        "doc_prepend": "PAN",
        "new_document_id": "company_kyc",
        "new_document_category": "Company KYC",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype == 'Sole Proprietorship'"
      },
      {
        "name": "Partnership Deed",
        "doc_prepend": "Partnership Deed",
        "new_document_id": "company_kyc",
        "new_document_category": "Company KYC",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Partnership' && this.borrower.businesstype != 'LLP'"
      },
      {
        "name": "Company Incorporation Certificate",
        "doc_prepend": "Incorporation Certificate",
        "new_document_id": "company_kyc",
        "new_document_category": "Company KYC",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Private Limited'"
      },
      {
        "name": "MOA",
        "doc_prepend": "MOA",
        "new_document_id": "company_kyc",
        "new_document_category": "Company KYC",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Private Limited'"
      },
      {
        "name": "AOA",
        "doc_prepend": "AOA",
        "new_document_id": "company_kyc",
        "new_document_category": "Company KYC",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Private Limited'"
      },
      {
        "name": "Shareholding Pattern",
        "doc_prepend": "Shareholding Pattern",
        "new_document_id": "company_kyc",
        "new_document_category": "Company KYC",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Private Limited'"
      }
    ]
  },
  {
    "document_id": "business_registration_proof",
    "document_type": "Business Registration Proof",
    "document_label": "Business Registration Proof",
    "documents": [
      {
        "name": "GST Registration Certificate or other",
        "doc_prepend": "GST Certificate",
        "new_document_id": "company_kyc",
        "new_document_category": "Business Registration Proof",
        "required": true,
        "options": []
      }
    ]
  },
  {
    "document_id": "property_ownership_proof",
    "document_type": "Property Ownership Proof",
    "document_label": "Property Ownership Proof",
    "documents": [
      {
        "name": "Utility bill or other",
        "doc_prepend": "Property Proof",
        "new_document_id": "company_kyc",
        "new_document_category": "Property Ownership Proof",
        "required": true,
        "options": []
      }
    ]
  },
  {
    "document_id": "bank_statements",
    "document_type": "Bank Statements",
    "document_label": "Bank Statements",
    "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
    "documents": [
      {
        "name": "Last 12 months Bank Statement PDF (of all Current/Savings bank accounts that includes business transactions)",
        "doc_prepend": "Bank Statement",
        "new_document_id": "financials",
        "new_document_category": "Bank Statements",
        "required": true,
        "options": [],
        "password": true
      }
    ]
  },
  {
    "document_id": "itr",
    "document_type": "ITR",
    "document_label": "Income Tax Return (PDF)",
    "notes": "",
    "is_optional": "this.borrower.loanamount=='Rs 1-5 Lakhs' || this.borrower.loanamount=='Rs 5-10 Lakhs' || this.borrower.loanamount=='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs'",
    "documents": [
      {
        "name": "ITR & Computation - Most Recent Year",
        "doc_prepend": "ITR - Recent",
        "new_document_id": "financials",
        "new_document_category": "ITR",
        "required": true,
        "options": []
      },
      {
        "name": "ITR & Computation - Previous Year",
        "doc_prepend": "ITR - Old",
        "new_document_id": "financials",
        "new_document_category": "ITR",
        "required": true,
        "options": []
      },
    ]
  },
  {
    "document_id": "audited_financials",
    "document_type": "Audited Financials",
    "document_label": "Audited Financials",
    "notes": "",
    "is_optional": "this.borrower.loanamount=='Rs 1-5 Lakhs' || this.borrower.loanamount=='Rs 5-10 Lakhs' || this.borrower.loanamount=='Rs 10-20 Lakhs' || this.borrower.loanamount=='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs' || this.borrower.loanamount=='₹11 Lakhs to ₹20 Lakhs'",
    "documents": [
      {
        "name": "Audited Financial Statements - Most Recent Year",
        "doc_prepend": "Financials - Recent",
        "new_document_id": "financials",
        "new_document_category": "Audited Financials",
        "required": true,
        "options": []
      },
      {
        "name": "Audited Financial Statements - Previous Year",
        "doc_prepend": "Financials - Old",
        "new_document_id": "financials",
        "new_document_category": "Audited Financials",
        "required": true,
        "options": []
      },
      {
        "name": "Provisional Financials (if available)",
        "doc_prepend": "Provisional Financials",
        "new_document_id": "financials",
        "new_document_category": "Audited Financials",
        "required": false,
        "options": []
      },
      {
        "name": "Financial projections (if available)",
        "doc_prepend": "Financial Projections",
        "new_document_id": "financials",
        "new_document_category": "Audited Financials",
        "required": false,
        "options": []
      }
    ]
  },
  {
    "document_id": "loan_takeover",
    "document_type": "Loan Takeover",
    "document_label": "Loan Takeover",
    "notes": "These documents are only required if you are looking for a loan takeover.<br/>If you are looking for a new loan, these documents will not be required",
    "is_optional": "1 == 1",
    "documents": [
      {
        "name": "Loan Account Statement",
        "doc_prepend": "Loan Account Statement",
        "new_document_id": "financials",
        "new_document_category": "Loan Takeover",
        "options": []
      },
      {
        "name": "Latest Sanction Letters",
        "doc_prepend": "Sanction Letters",
        "new_document_id": "financials",
        "new_document_category": "Loan Takeover",
        "options": []
      }
    ]
  },
  {
    "document_id": "gst_returns",
    "document_type": "GST Returns",
    "document_label": "GST Returns",
    "notes": "",
    "is_optional": "this.borrower.loanamount=='Rs 5-10 Lakhs' || this.borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs'",
    "documents": [{
      "name": "Last 12 months GSTR-3B YTD",
      "doc_prepend": "GSTR-3B",
      "new_document_id": "financials",
      "new_document_category": "GSTR-3B",
      "required": true,
      "options": []
    }]
  },
  {
    "document_id": "collateral_documents",
    "document_type": "Collateral Documents",
    "document_label": "Collateral Documents",
    "notes": "The requirement to provide documentation in this category is case-specific. Our team will request this documentation from you only if required to secure your loan",
    "is_optional": "1 == 1",
    "documents": [
      {
        "name": "Property Market Value Document",
        "doc_prepend": "Property Market Value",
        "new_document_id": "financials",
        "new_document_category": "Collateral Documents",
        "options": []
      },
      {
        "name": "Other Collateral Document",
        "doc_prepend": "Other",
        "new_document_id": "financials",
        "new_document_category": "Collateral Documents",
        "options": []
      }
    ]
  },
  {
    "document_id": "internal_assessment_documents",
    "document_type": "Internal Assessment Documents",
    "document_label": "Internal Assessment Documents",
    "notes": "For internal purpose only",
    "is_optional": "1 == 1",
    "documents": [
      {
        "name": "Credit Assessment",
        "doc_prepend": "Credit Assessment",
        "new_document_id": "internal_assessment_documents",
        "new_document_category": "Credit Assessment",
        "options": []
      },
      {
        "name": "Other",
        "doc_prepend": "Other",
        "new_document_id": "internal_assessment_documents",
        "new_document_category": "Other",
        "options": []
      }
    ]
  }
];

export const document_categories = [
  {
    "document_id": "individual_kyc",
    "document_type": "Individual KYC",
    "document_label": "Individual Identity Proof",
    "notes": "In case of Partnership, LLP and Private Limited Companies please upload Individual PAN, Aadhar and Proof of Address for each of the partners or directors separately",
    "documents": [
      {
        "name": "Individual PAN",
        "doc_prepend": "PAN",
        "upload_folder": "individual_kyc",
        "required": true,
        "options": [],
        "conditional_notes": "this.borrower.businesstype == 'Sole Proprietorship' ? 'Upload your PAN card' : (this.borrower.businesstype=='Private Limited' ? 'Upload the PAN cards of all Directors of the company': (this.borrower.businesstype == 'Limited Liability Partnership' || this.borrower.businesstype == 'Partnership' ? 'Upload the PAN cards of all Partners of the firm' : ''))"
      },
      {
        "name": "Aadhaar Card",
        "upload_folder": "individual_kyc",
        "doc_prepend": "Aadhar",
        "required": true,
        "options": [],
        "conditional_notes": "this.borrower.businesstype == 'Sole Proprietorship' ? 'Upload the front and back of your Aadhaar card' : (this.borrower.businesstype=='Private Limited' ? 'Upload the front and back of the Aadhar cards of all Directors of the company': (this.borrower.businesstype == 'Limited Liability Partnership' || this.borrower.businesstype == 'Partnership' ? 'Upload the front and back of the Aadhar cards of all Partners of the firm' : ''))"
      }
    ]
  },
  {
    "document_id": "company_kyc",
    "document_type": "Business KYC",
    "document_label": "Business KYC",
    "notes": "",
    "documents": [
      {
        "name": "Business Registration Proof",
        "upload_folder": "business_registration_proof",
        "options": [],
        "is_optional": "this.borrower.businesstype == 'Private Limited'",
        "conditional_notes":"this.borrower.hasgst == 'Yes' ? 'Upload your GST Registration Certificate' : (this.borrower.businesstype=='Sole Proprietorship' ? 'Upload ANY ONE of the following: Shops & Establishment registration certificate (Gumasta Dhara), VAT Certificate, IEC Certificate, FSSAI License, Udyog Aadhar' : (this.borrower.businesstype == 'Private Limited' ? 'Upload ANY ONE of the following: Shops & Establishment registration certificate (Gumasta Dhara), VAT Certificate, IEC Certificate, FSSAI License, Udyog Aadhar (optional)' : 'Upload ANY ONE of the following: Shops & Establishment registration certificate (Gumasta Dhara), VAT Certificate, IEC Certificate, FSSAI License, Udyog Aadhar'))"
      },
      {
        "name": "Company KYC",
        "upload_folder": "company_kyc",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype =='Sole Proprietorship'",
        "conditional_notes":"this.borrower.businesstype == 'Private Limited' ? 'Upload ALL of the following: Certificate of Incoporation, Company PAN, Articles of Association, Memorandum of Association, Shareholding & Directors list' : (this.borrower.businesstype == 'Partnership' || this.borrower.businesstype == 'Limited Liability Partnership' ? 'Upload your Partnership Deed and Partnership PAN' : '')"
      },
      {
        "name": "Property Ownership Proof",
        "upload_folder": "property_ownership_proof",
        "required": true,
        "options": [],
        "notes": 'Upload proof of ownership of your residential or commercial property. Acceptable proofs are: latest utility bill or Index 2 Property documents'
      }
    ]
  },
  {
    "document_id": "financials",
    "document_type": "Financials",
    "document_label": "Bank Statements & Financials",
    "documents": [
      {
        "name": "Bank Statements",
        "upload_folder": "bank_statements",
        "doc_prepend": "Bank Statements",
        "required": true,
        "notes": "Upload eStatement PDFs for the "+contextYearMonth+" of all the accounts that you use for business transactions",
        "password": true
      },
      {
        "name": "GST Returns",
        "upload_folder": "gst_returns",
        "doc_prepend": "GSTR-3B",
        "required": true,
        "notes": ""+contextYearMonth+" GSTR-3B YTD",
        "password": true,
        "hide_criteria": "this.borrower.loanamount=='Rs 1-5 Lakhs' || this.borrower.loanamount=='Rs 5-10 Lakhs' || this.borrower.loanamount=='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs'",
        "conditional_notes": "this.borrower.loanamount !='Rs 1-5 Lakhs' || this.borrower.loanamount!='Rs 5-10 Lakhs' || this.borrower.loanamount!='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount!='₹6 Lakhs to ₹10 Lakhs' ? 'Upload your GSTR 3B for the "+contextYearMonth+"':''"
      },
      {
        "name": "Income Tax Returns",
        "upload_folder": "itr",
        "doc_prepend": "ITR",
        "required": true,
        "notes": "ITR & Computation for last 2 years",
        "password": true,
        "hide_criteria": "this.borrower.loanamount=='Rs 1-5 Lakhs' || this.borrower.loanamount=='Rs 5-10 Lakhs' || this.borrower.loanamount=='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs'",
        "conditional_notes": "this.borrower.loanamount !='Rs 1-5 Lakhs' || this.borrower.loanamount!='Rs 5-10 Lakhs' || this.borrower.loanamount!='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount!='₹6 Lakhs to ₹10 Lakhs' ? 'Upload your business Income Tax Returns (ITR) with computation for the last 2 financial years':''"
      },
      {
        "name": "Business Financials",
        "upload_folder": "audited_financials",
        "doc_prepend": "Audited Financials",
        "required": true,
        "notes": "CA Certified/Audited Financial Statements for last 2 years",
        "hide_criteria": "this.borrower.loanamount=='Rs 1-5 Lakhs' || this.borrower.loanamount=='Rs 5-10 Lakhs' || this.borrower.loanamount=='Rs 10-20 Lakhs' || this.borrower.loanamount=='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs' || this.borrower.loanamount=='₹11 Lakhs to ₹20 Lakhs'",
        "conditional_notes": "this.borrower.loanamount!='Rs 1-5 Lakhs' || this.borrower.loanamount!='Rs 5-10 Lakhs' || this.borrower.loanamount!='Rs 10-20 Lakhs' || this.borrower.loanamount!='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount!='₹6 Lakhs to ₹10 Lakhs' || this.borrower.loanamount!='₹11 Lakhs to ₹20 Lakhs' ? 'Upload either CA Certified or Audited Company Financials for the last 2 financial years':''"
      },
      {
        "name": "Collateral Documents",
        "upload_folder": "collateral_documents",
        "required": true,
        "doc_prepend": "Collateral Documents",
        "notes":"Upload ALL relevant collateral documents (eg., Sales Deed, municipality approved building plan/plot layout, Title Deeds including the previous chain of property documents if applicable)",
        "hide_criteria": "this.borrower.typeofloan=='Unsecured' || this.borrower.loanamount == 'Rs 1-5 Lakhs' || this.borrower.loanamount == 'Rs 5-10 Lakhs'",
      },
      {
        "name": "Loan Takeover",
        "upload_folder": "loan_takeover",
        "doc_prepend": "Loan Takeover",
        "notes": "Upload the final Loan Sanction Letter and latest Loan Account Statements (optional)",
        "hide_criteria": "this.borrower.typeofloan=='Unsecured' || this.borrower.loanamount == 'Rs 1-5 Lakhs' || this.borrower.loanamount == 'Rs 5-10 Lakhs'"
      }
    ]
  },
  {
    "document_id": "internal_assessment_documents",
    "document_type": "Internal Assessment Documents",
    "document_label": "Internal Assessment Documents",
    "notes": "For internal purpose only",
    "is_optional": "1 == 1",
    "documents": [
      {
        "name": "Credit Assessment",
        "upload_folder": "internal_assessment_documents",
        "doc_prepend": "Credit Assessment",
        "options": []
      },
      {
        "name": "Other",
        "upload_folder": "internal_assessment_documents",
        "doc_prepend": "Other",
        "options": []
      }
    ]
  }
];

export var documentList = [
  {
    "eval_criteria": "this.borrower.businesstype=='Sole Proprietorship' && this.borrower.typeofloan == 'Secured' && (this.borrower.journey=='flipkart')",
    "list": [
      {
        "document_id": "company_kyc",
        "document_type": "BUSINESS DOCUMENTS",
        "document_label": "business documents",
        "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only.",
        "documents": [{
          "name": "Business GSTIN",
          "required": true,
          "options": []
        }],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "individual_kyc",
        "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
        "document_label": "Proprietor/Partner documents",
        "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only.<br/>Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
        "documents": [{
          "name": "Personal PAN Card",
          "required": true,
          "options": []
        },
        {
          "name": "Proof of Address",
          "required": true,
          "required_min": 1,
          "options": [
            {
              "option": "Driving License"
            },
            {
              "option": "Phone Bill"
            },
            {
              "option": "Passport"
            },
            {
              "option": "Electricity Bill"
            },
          ]
        },
        {
          "name": "Personal Aadhar Card",
          "required": true,
          "options": []
        }
        ],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "bank_statements",
        "document_type": "BANK STATEMENTS",
        "document_label": "Bank Statements",
        "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
        "documents": [{
          "name": "12 months Bank Statement PDF of all bank accounts",
          "doc_prepend": "Bank Statement",
          "required": true,
          "options": [],
          "password": true
        }
        ],
        "file_types": {
          'application/pdf': '.PDF'
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "property_ownership_proof",
        "document_type": "PROPERTY OWNERSHIP PROOF",
        "document_label": "PROPERTY OWNERSHIP PROOF",
        "documents": [
          {
            "name": "Utility bill or other",
            "doc_prepend": "Property Proof",
            "required": true,
            "options": []
          }
        ],
        "maxFileSize": "20MB"
      },
      {
        "document_id": "itr",
        "document_type": "ITR",
        "document_label": "INCOME TAX RETURN (PDF)",
        "notes": "",
        "documents": [
          {
            "name": "ITR & Computation - Most Recent Year",
            "doc_prepend": "ITR - Recent",
            "required": true,
            "options": []
          },
          {
            "name": "ITR & Computation - Previous Year",
            "doc_prepend": "ITR - Old",
            "required": true,
            "options": []
          },
        ]
      },
      {
        "document_id": "audited_financials",
        "document_type": "AUDITED FINANCIALS",
        "document_label": "AUDITED FINANCIALS",
        "notes": "",
        "documents": [
          {
            "name": "Audited Financial Statements - Most Recent Year",
            "doc_prepend": "Financials - Recent",
            "required": true,
            "options": []
          },
          {
            "name": "Audited Financial Statements - Previous Year",
            "doc_prepend": "Financials - Old",
            "required": true,
            "options": []
          },
          {
            "name": "Provisional Financials (if available)",
            "doc_prepend": "Provisional Financials",
            "required": false,
            "options": []
          },
          {
            "name": "Financial projections (if available)",
            "doc_prepend": "Financial Projections",
            "required": false,
            "options": []
          }
        ]
      },
      {
        "document_id": "loan_takeover",
        "document_type": "LOAN TAKEOVER",
        "document_label": "LOAN TAKEOVER",
        "notes": "These documents are only required if you are looking for a loan takeover.<br/>If you are looking for a new loan, these documents will not be required",
        "is_optional": "1 == 1",
        "documents": [
          {
            "name": "Loan Account Statement",
            "doc_prepend": "Loan Account Statement",
            "options": []
          },
          {
            "name": "Latest Sanction Letters",
            "doc_prepend": "Sanction Letters",
            "options": []
          }
        ]
      },
      {
        "document_id": "gst_returns",
        "document_type": "GST RETURNS",
        "document_label": "GST RETURNS",
        "notes": "",
        "documents": [{
          "name": "Last 12 months GSTR-3B YTD",
          "doc_prepend": "GSTR-3B",
          "required": true,
          "options": []
        }]
      },
      {
        "document_id": "collateral_documents",
        "document_type": "COLLATERAL DOCUMENTS",
        "document_label": "COLLATERAL DOCUMENTS",
        "notes": "The requirement to provide documentation in this category is case-specific. Our team will request this documentation from you only if required to secure your loan",
        "is_optional": "1 == 1",
        "documents": [
          {
            "name": "Property Market Value Document",
            "doc_prepend": "Property Market Value",
            "options": []
          },
          {
            "name": "Other Collateral Document",
            "doc_prepend": "Other",
            "options": []
          }
        ]
      }
    ]
  },
  {
    "eval_criteria": "(this.borrower.businesstype=='Partnership') && this.borrower.typeofloan == 'Secured' && (this.borrower.journey=='flipkart')",
    "list": [
      {
        "document_id": "company_kyc",
        "document_type": "BUSINESS DOCUMENTS",
        "document_label": "business documents",
        "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only",
        "documents": [{
          "name": "Business GSTIN",
          "required": true,
          "options": []
        },
        {
          "name": "Business PAN Card",
          "required": true,
          "options": []
        },
        {
          "name": "Partnership Deed",
          "required": true,
          "options": []
        }],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "individual_kyc",
        "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
        "document_label": "Proprietor/Partner documents",
        "notes": "Please upload a complete set of Proof of Identity and Proof of Address documentation for each partner in the business.<br/>Guidance for filling in this section: <br/>1.	Please ensure you are uploading the PAN of the main applicant.<br/>2.	Upload a complete set of Proof of Identity, Proof of Address and Aadhar documentation for each Partner in the business.<br/>3.	Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
        "repeat_for": "partners",
        "repeat_section_label": "name",
        "documents": [
          {
            "name": "Personal PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Proof of Address",
            "required": true,
            "required_min": 1,
            "options": [
              {
                "option": "Driving License"
              },
              {
                "option": "Phone Bill"
              },
              {
                "option": "Passport"
              },
              {
                "option": "Electricity Bill"
              },
            ]
          },
          {
            "name": "Aadhar Card",
            "required": true,
            "options": []
          }
        ],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "bank_statements",
        "document_type": "BANK STATEMENTS",
        "document_label": "Bank Statements",
        "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
        "documents": [{
          "name": "12 months Bank Statement PDF of all bank accounts",
          "doc_prepend": "Bank Statement",
          "required": true,
          "options": [],
          "password": true
        }
        ],
        "file_types": {
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "property_ownership_proof",
        "document_type": "PROPERTY OWNERSHIP PROOF",
        "document_label": "PROPERTY OWNERSHIP PROOF",
        "documents": [
          {
            "name": "Utility bill or other",
            "doc_prepend": "Property Proof",
            "required": true,
            "options": []
          }
        ],
        "maxFileSize": "20MB"
      },
      {
        "document_id": "itr",
        "document_type": "ITR",
        "document_label": "INCOME TAX RETURN (PDF)",
        "notes": "",
        "documents": [
          {
            "name": "ITR & Computation - Most Recent Year",
            "doc_prepend": "ITR - Recent",
            "required": true,
            "options": []
          },
          {
            "name": "ITR & Computation - Previous Year",
            "doc_prepend": "ITR - Old",
            "required": true,
            "options": []
          },
        ]
      },
      {
        "document_id": "audited_financials",
        "document_type": "AUDITED FINANCIALS",
        "document_label": "AUDITED FINANCIALS",
        "notes": "",
        "documents": [
          {
            "name": "Audited Financial Statements - Most Recent Year",
            "doc_prepend": "Financials - Recent",
            "required": true,
            "options": []
          },
          {
            "name": "Audited Financial Statements - Previous Year",
            "doc_prepend": "Financials - Old",
            "required": true,
            "options": []
          },
          {
            "name": "Provisional Financials (if available)",
            "doc_prepend": "Provisional Financials",
            "required": false,
            "options": []
          },
          {
            "name": "Financial projections (if available)",
            "doc_prepend": "Financial Projections",
            "required": false,
            "options": []
          }
        ]
      },
      {
        "document_id": "loan_takeover",
        "document_type": "LOAN TAKEOVER",
        "document_label": "LOAN TAKEOVER",
        "notes": "These documents are only required if you are looking for a loan takeover.<br/>If you are looking for a new loan, these documents will not be required",
        "is_optional": "1 == 1",
        "documents": [
          {
            "name": "Loan Account Statement",
            "doc_prepend": "Loan Account Statement",
            "options": []
          },
          {
            "name": "Latest Sanction Letters",
            "doc_prepend": "Sanction Letters",
            "options": []
          }
        ]
      },
      {
        "document_id": "gst_returns",
        "document_type": "GST RETURNS",
        "document_label": "GST RETURNS",
        "notes": "",
        "documents": [{
          "name": "Last 12 months GSTR-3B YTD",
          "doc_prepend": "GSTR-3B",
          "required": true,
          "options": []
        }]
      },
      {
        "document_id": "collateral_documents",
        "document_type": "COLLATERAL DOCUMENTS",
        "document_label": "COLLATERAL DOCUMENTS",
        "notes": "The requirement to provide documentation in this category is case-specific. Our team will request this documentation from you only if required to secure your loan",
        "is_optional": "1 == 1",
        "documents": [
          {
            "name": "Property Market Value Document",
            "doc_prepend": "Property Market Value",
            "options": []
          },
          {
            "name": "Other Collateral Document",
            "doc_prepend": "Other",
            "options": []
          }
        ]
      }
    ]
  },
  {
    "eval_criteria": "(this.borrower.businesstype=='Private Limited' || this.borrower.businesstype=='LLP') && this.borrower.typeofloan == 'Secured' && (this.borrower.journey=='flipkart')",
    "list": [
      {
        "document_id": "company_kyc",
        "document_type": "BUSINESS DOCUMENTS",
        "document_label": "business documents",
        "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only",
        "documents": [
          {
            "name": "Business PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Company Incorporation Certificate",
            "required": true,
            "options": []
          },
          {
            "name": "MoA/AoA",
            "required": true,
            "options": []
          },
          {
            "name": "Shareholding Pattern",
            "required": true,
            "options": []
          },
          {
            "name": "Business GSTIN",
            "required": true,
            "options": []
          }],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "individual_kyc",
        "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
        "document_label": "Proprietor/Partner documents",
        "notes": "Please upload a complete set of Proof of Identity and Proof of Address documentation for each partner in the business.<br/>Guidance for filling in this section: <br/>1.	Please ensure you are uploading the PAN of the main applicant.<br/>2.	Upload a complete set of Proof of Identity, Proof of Address and Aadhar documentation for each Partner in the business.<br/>3.	Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
        "repeat_for": "partners",
        "repeat_section_label": "name",
        "documents": [{
          "name": "Personal PAN Card",
          "required": true,
          "options": []
        },
        {
          "name": "Proof of Address",
          "required": true,
          "required_min": 1,
          "options": [
            {
              "option": "Driving License"
            },
            {
              "option": "Phone Bill"
            },
            {
              "option": "Passport"
            },
            {
              "option": "Electricity Bill"
            },
          ]
        },
        {
          "name": "Personal Aadhar Card",
          "required": true,
          "options": []
        }
        ],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "bank_statements",
        "document_type": "BANK STATEMENTS",
        "document_label": "Bank Statements",
        "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
        "documents": [{
          "name": "12 months Bank Statement PDF of all bank accounts",
          "doc_prepend": "Bank Statement",
          "required": true,
          "options": [],
          "password": true
        }
        ],
        "file_types": {
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "property_ownership_proof",
        "document_type": "PROPERTY OWNERSHIP PROOF",
        "document_label": "PROPERTY OWNERSHIP PROOF",
        "documents": [
          {
            "name": "Utility bill or other",
            "doc_prepend": "Property Proof",
            "required": true,
            "options": []
          }
        ],
        "maxFileSize": "20MB"
      },
      {
        "document_id": "itr",
        "document_type": "ITR",
        "document_label": "INCOME TAX RETURN (PDF)",
        "notes": "",
        "documents": [
          {
            "name": "ITR & Computation - Most Recent Year",
            "doc_prepend": "ITR - Recent",
            "required": true,
            "options": []
          },
          {
            "name": "ITR & Computation - Previous Year",
            "doc_prepend": "ITR - Old",
            "required": true,
            "options": []
          },
        ]
      },
      {
        "document_id": "audited_financials",
        "document_type": "AUDITED FINANCIALS",
        "document_label": "AUDITED FINANCIALS",
        "notes": "",
        "documents": [
          {
            "name": "Audited Financial Statements - Most Recent Year",
            "doc_prepend": "Financials - Recent",
            "required": true,
            "options": []
          },
          {
            "name": "Audited Financial Statements - Previous Year",
            "doc_prepend": "Financials - Old",
            "required": true,
            "options": []
          },
          {
            "name": "Provisional Financials (if available)",
            "doc_prepend": "Provisional Financials",
            "required": false,
            "options": []
          },
          {
            "name": "Financial projections (if available)",
            "doc_prepend": "Financial Projections",
            "required": false,
            "options": []
          }
        ]
      },
      {
        "document_id": "loan_takeover",
        "document_type": "LOAN TAKEOVER",
        "document_label": "LOAN TAKEOVER",
        "notes": "These documents are only required if you are looking for a loan takeover.<br/>If you are looking for a new loan, these documents will not be required",
        "is_optional": "1 == 1",
        "documents": [
          {
            "name": "Loan Account Statement",
            "doc_prepend": "Loan Account Statement",
            "options": []
          },
          {
            "name": "Latest Sanction Letters",
            "doc_prepend": "Sanction Letters",
            "options": []
          }
        ]
      },
      {
        "document_id": "gst_returns",
        "document_type": "GST RETURNS",
        "document_label": "GST RETURNS",
        "notes": "",
        "documents": [{
          "name": "Last 12 months GSTR-3B YTD",
          "doc_prepend": "GSTR-3B",
          "required": true,
          "options": []
        }]
      },
      {
        "document_id": "collateral_documents",
        "document_type": "COLLATERAL DOCUMENTS",
        "document_label": "COLLATERAL DOCUMENTS",
        "notes": "The requirement to provide documentation in this category is case-specific. Our team will request this documentation from you only if required to secure your loan",
        "is_optional": "1 == 1",
        "documents": [
          {
            "name": "Property Market Value Document",
            "doc_prepend": "Property Market Value",
            "options": []
          },
          {
            "name": "Other Collateral Document",
            "doc_prepend": "Other",
            "options": []
          }
        ]
      }
    ]
  },
  {
    "eval_criteria": "this.borrower.businesstype=='Sole Proprietorship' && (this.borrower.journey=='flipkart')",
    "list": [
      {
        "document_id": "company_kyc",
        "document_type": "BUSINESS DOCUMENTS",
        "document_label": "business documents",
        "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only.",
        "documents": [{
          "name": "Business GSTIN",
          "required": true,
          "options": []
        }],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "individual_kyc",
        "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
        "document_label": "Proprietor/Partner documents",
        "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only.<br/>Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
        "documents": [{
          "name": "Personal PAN Card",
          "required": true,
          "options": []
        },
        {
          "name": "Proof of Address",
          "required": true,
          "required_min": 1,
          "options": [
            {
              "option": "Driving License"
            },
            {
              "option": "Phone Bill"
            },
            {
              "option": "Passport"
            },
            {
              "option": "Electricity Bill"
            },
          ]
          },
          {
            "name": "Personal Aadhar Card",
            "required": true,
            "options": []
          }
        ],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "bank_statements",
        "document_type": "BANK STATEMENTS",
        "document_label": "Bank Statements",
        "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
        "documents": [{
          "name": "12 months Bank Statement PDF of all bank accounts",
          "doc_prepend": "Bank Statement",
          "required": true,
          "options": [],
          "password": true
        }
        ],
        "file_types": {
          'application/pdf': '.PDF'
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "property_ownership_proof",
        "document_type": "PROPERTY OWNERSHIP PROOF",
        "document_label": "Property Ownership Proof",
        "documents": [
          {
            "name": "Utility bill or other",
            "doc_prepend": "Property Proof",
            "required": true,
            "options": []
          }
        ],
        "maxFileSize": "20MB"
      }
    ]
  },
  {
    "eval_criteria": "(this.borrower.businesstype=='Partnership') && (this.borrower.journey=='flipkart')",
    "list": [
      {
        "document_id": "company_kyc",
        "document_type": "BUSINESS DOCUMENTS",
        "document_label": "business documents",
        "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only",
        "documents": [{
          "name": "Business GSTIN",
          "required": true,
          "options": []
        },
          {
            "name": "Business PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Partnership Deed",
            "required": true,
            "options": []
          }],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "individual_kyc",
        "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
        "document_label": "Proprietor/Partner documents",
        "notes": "Please upload a complete set of Proof of Identity and Proof of Address documentation for each partner in the business.<br/>Guidance for filling in this section: <br/>1.	Please ensure you are uploading the PAN of the main applicant.<br/>2.	Upload a complete set of Proof of Identity, Proof of Address and Aadhar documentation for each Partner in the business.<br/>3.	Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
        "repeat_for": "partners",
        "repeat_section_label": "name",
        "documents": [
          {
            "name": "Personal PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Proof of Address",
            "required": true,
            "required_min": 1,
            "options": [
              {
                "option": "Driving License"
              },
              {
                "option": "Phone Bill"
              },
              {
                "option": "Passport"
              },
              {
                "option": "Electricity Bill"
              },
            ]
          },
          {
            "name": "Aadhar Card",
            "required": true,
            "options": []
          }
        ],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "bank_statements",
        "document_type": "BANK STATEMENTS",
        "document_label": "Bank Statements",
        "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
        "documents": [{
          "name": "12 months Bank Statement PDF of all bank accounts",
          "doc_prepend": "Bank Statement",
          "required": true,
          "options": [],
          "password": true
        }
        ],
        "file_types": {
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "property_ownership_proof",
        "document_type": "PROPERTY OWNERSHIP PROOF",
        "document_label": "PROPERTY OWNERSHIP PROOF",
        "documents": [
          {
            "name": "Utility bill or other",
            "doc_prepend": "Property Proof",
            "required": true,
            "options": []
          }
        ],
        "maxFileSize": "20MB"
      }
    ]
  },
  {
    "eval_criteria": "(this.borrower.businesstype=='Private Limited' || this.borrower.businesstype=='LLP') && (this.borrower.journey=='flipkart')",
    "list": [
      {
        "document_id": "company_kyc",
        "document_type": "BUSINESS DOCUMENTS",
        "document_label": "business documents",
        "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only",
        "documents": [
          {
            "name": "Business PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Company Incorporation Certificate",
            "required": true,
            "options": []
          },
          {
            "name": "MoA/AoA",
            "required": true,
            "options": []
          },
          {
            "name": "Shareholding Pattern",
            "required": true,
            "options": []
          },
          {
          "name": "Business GSTIN",
          "required": true,
          "options": []
          }],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "individual_kyc",
        "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
        "document_label": "Proprietor/Partner documents",
        "notes": "Please upload a complete set of Proof of Identity and Proof of Address documentation for each partner in the business.<br/>Guidance for filling in this section: <br/>1.	Please ensure you are uploading the PAN of the main applicant.<br/>2.	Upload a complete set of Proof of Identity, Proof of Address and Aadhar documentation for each Partner in the business.<br/>3.	Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
        "repeat_for": "partners",
        "repeat_section_label": "name",
        "documents": [{
          "name": "Personal PAN Card",
          "required": true,
          "options": []
        },
        {
          "name": "Proof of Address",
          "required": true,
          "required_min": 1,
          "options": [
            {
              "option": "Driving License"
            },
            {
              "option": "Phone Bill"
            },
            {
              "option": "Passport"
            },
            {
              "option": "Electricity Bill"
            },
          ]
          },
          {
            "name": "Personal Aadhar Card",
            "required": true,
            "options": []
          }
        ],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "bank_statements",
        "document_type": "BANK STATEMENTS",
        "document_label": "Bank Statements",
        "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
        "documents": [{
          "name": "12 months Bank Statement PDF of all bank accounts",
          "doc_prepend": "Bank Statement",
          "required": true,
          "options": [],
          "password": true
        }
        ],
        "file_types": {
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "property_ownership_proof",
        "document_type": "PROPERTY OWNERSHIP PROOF",
        "document_label": "PROPERTY OWNERSHIP PROOF",
        "documents": [
          {
            "name": "Utility bill or other",
            "doc_prepend": "Property Proof",
            "required": true,
            "options": []
          }
        ],
        "maxFileSize": "20MB"
      }
    ]
  },
  {
    "eval_criteria": "this.borrower.businesstype=='Sole Proprietorship' && (this.borrower.journey=='flipkart-bob')",
    "list": [
      {
        "document_id": "company_kyc",
        "document_type": "BUSINESS DOCUMENTS",
        "document_label": "business documents",
        "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only.",
        "documents": [{
          "name": "Business GSTIN",
          "required": true,
          "options": []
        }],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "individual_kyc",
        "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
        "document_label": "Proprietor/Partner documents",
        "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only.<br/>Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
        "documents": [{
          "name": "Personal PAN Card",
          "required": true,
          "options": []
        },
        {
          "name": "Proof of Address",
          "required": true,
          "required_min": 1,
          "options": [
            {
              "option": "Driving License"
            },
            {
              "option": "Phone Bill"
            },
            {
              "option": "Passport"
            },
            {
              "option": "Electricity Bill"
            },
          ]
          },
          {
            "name": "Aadhar Card",
            "required": true,
            "options": []
          }
        ],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "bank_statements",
        "document_type": "BANK STATEMENTS",
        "document_label": "Bank Statements",
        "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 6 months.",
        "documents": [{
          "name": "6 months Bank Statement PDF of all bank accounts",
          "doc_prepend": "Bank Statement",
          "required": true,
          "options": [],
          "password": true
        }
        ],
        "file_types": {
          'application/pdf': '.PDF'
        },
        "maxFileSize": "20MB"
      }
    ]
  },
  {
    "eval_criteria": "(this.borrower.businesstype=='Partnership') && (this.borrower.journey=='flipkart-bob')",
    "list": [
      {
        "document_id": "company_kyc",
        "document_type": "BUSINESS DOCUMENTS",
        "document_label": "business documents",
        "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only",
        "documents": [{
          "name": "Business GSTIN",
          "required": true,
          "options": []
        },
        {
          "name": "Business PAN Card",
          "required": true,
          "options": []
        },
        {
          "name": "Partnership Deed",
          "required": true,
          "options": []
        }],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "individual_kyc",
        "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
        "document_label": "Proprietor/Partner documents",
        "notes": "Please upload a complete set of Proof of Identity and Proof of Address documentation for each partner in the business.<br/>Guidance for filling in this section: <br/>1.	Please ensure you are uploading the PAN of the main applicant.<br/>2.	Upload a complete set of Proof of Identity, Proof of Address and Aadhar documentation for each Partner in the business.<br/>3.	Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
        "repeat_for": "partners",
        "repeat_section_label": "name",
        "documents": [{
          "name": "Personal PAN Card",
          "required": true,
          "options": []
        },
        {
          "name": "Proof of Address",
          "required": true,
          "required_min": 1,
          "options": [
            {
              "option": "Driving License"
            },
            {
              "option": "Phone Bill"
            },
            {
              "option": "Passport"
            },
            {
              "option": "Electricity Bill"
            },
          ]
          },
          {
            "name": "Aadhar Card",
            "required": true,
            "options": []
          }
        ],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "bank_statements",
        "document_type": "BANK STATEMENTS",
        "document_label": "Bank Statements",
        "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 6 months.",
        "documents": [{
          "name": "6 months Bank Statement PDF of all bank accounts",
          "doc_prepend": "Bank Statement",
          "required": true,
          "options": [],
          "password": true
        }
        ],
        "file_types": {
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
    ]
  },
  {
    "eval_criteria": "(this.borrower.businesstype=='Private Limited' || this.borrower.businesstype=='LLP') && (this.borrower.journey=='flipkart-bob')",
    "list": [
      {
        "document_id": "company_kyc",
        "document_type": "BUSINESS DOCUMENTS",
        "document_label": "business documents",
        "notes": "Uploaded documents should be up to 6MB in size and in .pdf or .jpg/.jpeg format only",
        "documents": [
          {
            "name": "Business PAN Card",
            "required": true,
            "options": []
          },
          {
            "name": "Company Incorporation Certificate",
            "required": true,
            "options": []
          },
          {
            "name": "MoA/AoA",
            "required": true,
            "options": []
          },
          {
            "name": "Shareholding Pattern",
            "required": true,
            "options": []
          },
          {
            "name": "Business GSTIN",
            "required": true,
            "options": []
          }],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "individual_kyc",
        "document_type": "PROPRIETOR/PARTNER DOCUMENTS",
        "document_label": "Proprietor/Partner documents",
        "notes": "Please upload a complete set of Proof of Identity and Proof of Address documentation for each partner in the business.<br/>Guidance for filling in this section: <br/>1.	Please ensure you are uploading the PAN of the main applicant.<br/>2.	Upload a complete set of Proof of Identity, Proof of Address and Aadhar documentation for each Partner in the business.<br/>3.	Valid documents for Proof of Address - Driving License / Phone Bill / Passport / Electricity Bill",
        "repeat_for": "partners",
        "repeat_section_label": "name",
        "documents": [{
          "name": "Personal PAN Card",
          "required": true,
          "options": []
        },
        {
          "name": "Proof of Address",
          "required": true,
          "required_min": 1,
          "options": [
            {
              "option": "Driving License"
            },
            {
              "option": "Phone Bill"
            },
            {
              "option": "Passport"
            },
            {
              "option": "Electricity Bill"
            },
          ]
          },
          {
            "name": "Aadhar Card",
            "required": true,
            "options": []
          }
        ],
        "file_types": {
          'image/jpg': '.JPG',
          'image/jpeg': '.JPEG',
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      },
      {
        "document_id": "bank_statements",
        "document_type": "BANK STATEMENTS",
        "document_label": "Bank Statements",
        "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 6 months.",
        "documents": [{
          "name": "6 months Bank Statement PDF of all bank accounts",
          "doc_prepend": "Bank Statement",
          "required": true,
          "options": [],
          "password": true
        }
        ],
        "file_types": {
          'application/pdf': '.PDF',
        },
        "maxFileSize": "20MB"
      }
    ]
  },
  {
    "eval_criteria": "this.borrower.admindashboard",
    "list": JSON.parse(JSON.stringify(document_categories))
  },
  {
    "eval_criteria": "this.borrower.loanamount=='Rs 1-5 Lakhs' || this.borrower.loanamount=='₹1 Lakh to ₹5 Lakhs'",
    "list": JSON.parse(JSON.stringify(document_categories.filter(c => ['individual_kyc', 'company_kyc', 'financials'].includes(c.document_id))))
  },
  {
    "eval_criteria": "this.borrower.loanamount=='Rs 5-10 Lakhs' || this.borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs'",
    "list": JSON.parse(JSON.stringify(document_categories.filter(c => ['individual_kyc', 'company_kyc', 'financials'].includes(c.document_id))))
  },
  {
    "eval_criteria": "this.borrower.loanamount!='Rs 1-5 Lakhs' && this.borrower.loanamount!='Rs 5-10 Lakhs' && this.borrower.loanamount!='₹1 Lakh to ₹5 Lakhs' && this.borrower.loanamount!='₹6 Lakhs to ₹10 Lakhs'",
    "list": JSON.parse(JSON.stringify(document_categories.filter(c => ['individual_kyc', 'company_kyc', 'financials'].includes(c.document_id))))
  }
];


