import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import borrowerJourneyWorkflow from 'app/layouts/workflow/borrower-journey';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout } from "rxjs/operators"

export function matchValidator(fetchControl: () => AbstractControl, matcher: (x: string, y: string) => boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = control.value;
    let toMatch = fetchControl() ? fetchControl().value : "";

    let isMatched = matcher(value, toMatch);
    return !isMatched ? { match: { value: control.value } } : null;
  };
}

@Component({
  selector: 'app-experian-step',
  templateUrl: './experian-step.component.html',
  styleUrls: ['./experian-step.component.css']
})
export class ExperianStepComponent implements OnInit {

  form:FormGroup;

  maskedMobilePlaceholder = "";
  flowManager: FlowManagerService;
  get controls() { return this.form.controls; }

  get model() {
    return this.flowManager.modelValue;
  }

  constructor(flowManager: FlowManagerService, private _formBuilder: FormBuilder) {
    this.flowManager = flowManager;
  }

  ngOnInit(): void {
    const matcher = (v1, v2) => v1.substring(0, 2) == v2.substring(0, 2) && v1.substring(8, 10) == v2.substring(8, 10);
    const fetchControl = () => {
      try { return this.form.controls["maskedMobileNumber"] } catch { return null }
    };

    this.form = this._formBuilder.group({
      maskedMobileNumber: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required, Validators.pattern("^[0-9]{10}$"), matchValidator(fetchControl, matcher)]],
    });

    this.form.controls['maskedMobileNumber'].valueChanges.subscribe(data => this.maskedMobilePlaceholder = data);

    this.flowManager.saveLocation().subscribe();
  }

  submit() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    let flowManager = this.flowManager;
    let data = this.form.value;
    flowManager.startLoading()
    return flowManager.journey.updateExperianMaskNumber(data).pipe(

      tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
      tap(() => flowManager.stepNext()),
      catchError(err => {
        flowManager.throwError(err);
        return throwError(err);
      }),
      finalize(() => flowManager.stopLoading()),
    )
      .subscribe(() => {

      });
  }

  skip() {

    let flowManager = this.flowManager;
    let data = {
      experian_masked_flow: false,
      experian_skip_masked_flow: true,
      qualified: true,
      disqualified: false,
      disqualificationKey: "",
      disqualificationMessage:""
    };

    flowManager.startLoading()
    flowManager.goto(borrowerJourneyWorkflow.searchingLenders.key);
    return flowManager.journey.updateJourney(flowManager.modelValue._id, data).pipe(

      tap((result) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap((result) => {
               if (result.disqualified) {
                    if (result.disqualificationKey == 'experian') {
                        flowManager.gtmTriggerService.triggerButtonClick({ className: 'experian-disqualified' })
                    } else {
                        flowManager.gtmTriggerService.triggerButtonClick({ className: 'model-disqualified' })
                    }
                    flowManager.goto(borrowerJourneyWorkflow.disQualifiedMessage.key);
                }
                else if (result.qualified) {
                  flowManager.goto(borrowerJourneyWorkflow.qualifiedMessage.key);
                }
                else {
                    throw new Error("Failed to move forward");
                }
            }),
            catchError(err => {

                flowManager.throwError(err);
                return throwError(err);
            }),
            finalize(() => flowManager.stopLoading())
    )
      .subscribe(() => {

      });
  }

  back() {
    this.flowManager.stepPrevious();
  }

  conversation = (model) => ({
    greeting: ``,
    message: ` The number you used to register with us doesn't match with the one the credit bureau has for you. We need that number to process your loan application.`
  })
}
