import { CdkStep } from '@angular/cdk/stepper';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, Subscription } from 'rxjs';
import { concatMap, delay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit, AfterViewInit {
  @Input() showLoanBanner = false;
  @Input() conversation: any;
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
  @Input() isSaving = false;
  @Input() hideStepper = false;
  @Input() hideBack = false;
  @Input() showConfidentialProgressBar = false;
  @Input() form: FormGroup;
  @Input() customProgress;
  @Input() hideProgressBar = false;
  @Input() isDisabled = false;
  private _steps: QueryList<CdkStep> = new QueryList<CdkStep>();
  progressBar: boolean;

  get steps() {
    return this._steps;
  }

  progressValue = 0;
  invalid = true;
  constructor(private flowManager: FlowManagerService) { }

  ngAfterViewInit(): void {
    if (this.form) {
      setTimeout(() => this.invalid = this.form.invalid);
      this.form.statusChanges
        .pipe(
          delay(250),
          tap(_ => this.invalid = !this.form.valid),
        )
        .subscribe()
    }
  }

  ngOnInit(): void {
    this.flowManager.progressBar$
      .subscribe(value => {
        this.progressBar = value;
      });


    this.flowManager.flow$
      .pipe(
        delay(0),
        tap(_ => {
          if (this.flowManager.stepperValue) {
            this._steps = this.flowManager.stepperValue.steps;
          }
        })
      )
      .subscribe()


    this.form?.valueChanges.subscribe((model) => {
      if (this.flowManager.stepperValue) {
        setTimeout(() => this.progressValue = this.flowManager.progressValue)
      }
    })

    this.flowManager.stepper$.subscribe(stepper => {
      if (stepper) {
        setTimeout(() => this.progressValue = this.flowManager.progressValue)
        stepper.selectionChange.asObservable().pipe(
          delay(0),
          tap(() => {
            this.progressValue = this.flowManager.progressValue;
          })
        )
          .subscribe()
      }
    })
  }
}
