var now = new Date();
var d2 = new Date(now.getFullYear(), now.getMonth() -1,1);
var d1 = new Date(now.getFullYear() - 1, now.getMonth(), 1);
var contextYearMonth = `${d1.toLocaleString('default', { month: 'long' })} ${d1.getFullYear()} to ${d2.toLocaleString('default', { month: 'long' })} ${d2.getFullYear()}`; 

export const documentCategories = [
    {
      "document_id": "individual_kyc",
      "document_type": "Individual KYC",
      "document_label": "Individual Identity Proof",
      "notes": "In case of Partnership, LLP and Private Limited Companies please upload Individual PAN, Aadhar and Proof of Address for each of the partners or directors separately",
      "documents": [
        {
          "name": "Individual PAN",
          "doc_prepend": "PAN",
          "upload_folder": "individual_kyc",
          "required": true,
          "options": [],
          "conditional_notes": "this.borrower.businesstype == 'Sole Proprietorship' ? 'Upload your PAN card' : (this.borrower.businesstype=='Private Limited' ? 'Upload the PAN cards of all Directors of the company': (this.borrower.businesstype == 'Limited Liability Partnership' || this.borrower.businesstype == 'Partnership' ? 'Upload the PAN cards of all Partners of the firm' : ''))"
        },
        {
          "name": "Aadhaar Card",
          "upload_folder": "individual_kyc",
          "doc_prepend": "Aadhar",
          "required": true,
          "options": [],
          "conditional_notes": "this.borrower.businesstype == 'Sole Proprietorship' ? 'Upload the front and back of your Aadhaar card' : (this.borrower.businesstype=='Private Limited' ? 'Upload the front and back of the Aadhar cards of all Directors of the company': (this.borrower.businesstype == 'Limited Liability Partnership' || this.borrower.businesstype == 'Partnership' ? 'Upload the front and back of the Aadhar cards of all Partners of the firm' : ''))"
        }
      ]
    },
    {
      "document_id": "company_kyc",
      "document_type": "Business KYC",
      "document_label": "Business KYC",
      "notes": "",
      "documents": [
        {
          "name": "Business Registration Proof",
          "upload_folder": "business_registration_proof",
          "options": [],
          "is_optional": "this.borrower.businesstype == 'Private Limited'",
          "conditional_notes":"this.borrower.hasgst == 'Yes' ? 'Upload your GST Registration Certificate' : (this.borrower.businesstype=='Sole Proprietorship' ? 'Upload ANY ONE of the following: Shops & Establishment registration certificate (Gumasta Dhara), VAT Certificate, IEC Certificate, FSSAI License, Udyog Aadhar' : (this.borrower.businesstype == 'Private Limited' ? 'Upload ANY ONE of the following: Shops & Establishment registration certificate (Gumasta Dhara), VAT Certificate, IEC Certificate, FSSAI License, Udyog Aadhar (optional)' : 'Upload ANY ONE of the following: Shops & Establishment registration certificate (Gumasta Dhara), VAT Certificate, IEC Certificate, FSSAI License, Udyog Aadhar'))"
        },
        {
          "name": "Company KYC",
          "upload_folder": "company_kyc",
          "required": true,
          "options": [],
          "hide_criteria": "this.borrower.businesstype =='Sole Proprietorship'",
          "conditional_notes":"this.borrower.businesstype == 'Private Limited' ? 'Upload ALL of the following: Certificate of Incoporation, Company PAN, Articles of Association, Memorandum of Association, Shareholding & Directors list' : (this.borrower.businesstype == 'Partnership' || this.borrower.businesstype == 'Limited Liability Partnership' ? 'Upload your Partnership Deed and Partnership PAN' : '')"
        },
        {
          "name": "Property Ownership Proof",
          "upload_folder": "property_ownership_proof",
          "required": true,
          "options": [],
          "notes": 'Upload proof of ownership of your residential or commercial property. Acceptable proofs are: latest utility bill or Index 2 Property documents'
        }
      ]
    },
    {
      "document_id": "financials",
      "document_type": "Financials",
      "document_label": "Bank Statements & Financials",
      "documents": [
        {
          "name": "Bank Statements",
          "upload_folder": "bank_statements",
          "doc_prepend": "Bank Statements",
          "required": true,
          "notes": "Upload eStatement PDFs for the "+contextYearMonth+" of all the accounts that you use for business transactions",
          "password": true
        },
        {
          "name": "GST Returns",
          "upload_folder": "gst_returns",
          "doc_prepend": "GSTR-3B",
          "required": true,
          "notes": ""+contextYearMonth+" GSTR-3B YTD",
          "password": true,
          "hide_criteria": "this.borrower.loanamount=='Rs 1-5 Lakhs' || this.borrower.loanamount=='Rs 5-10 Lakhs' || this.borrower.loanamount=='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs'",
          "conditional_notes": "this.borrower.loanamount !='Rs 1-5 Lakhs' || this.borrower.loanamount!='Rs 5-10 Lakhs' || this.borrower.loanamount!='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount!='₹6 Lakhs to ₹10 Lakhs' ? 'Upload your GSTR 3B for the "+contextYearMonth+"':''"
        },
        {
          "name": "Income Tax Returns",
          "upload_folder": "itr",
          "doc_prepend": "ITR",
          "required": true,
          "notes": "ITR & Computation for last 2 years",
          "password": true,
          "hide_criteria": "this.borrower.loanamount=='Rs 1-5 Lakhs' || this.borrower.loanamount=='Rs 5-10 Lakhs' || this.borrower.loanamount=='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs'",
          "conditional_notes": "this.borrower.loanamount !='Rs 1-5 Lakhs' || this.borrower.loanamount!='Rs 5-10 Lakhs' || this.borrower.loanamount!='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount!='₹6 Lakhs to ₹10 Lakhs' ? 'Upload your business Income Tax Returns (ITR) with computation for the last 2 financial years':''"
        },
        {
          "name": "Business Financials",
          "upload_folder": "audited_financials",
          "doc_prepend": "Audited Financials",
          "required": true,
          "notes": "CA Certified/Audited Financial Statements for last 2 years",
          "hide_criteria": "this.borrower.loanamount=='Rs 1-5 Lakhs' || this.borrower.loanamount=='Rs 5-10 Lakhs' || this.borrower.loanamount=='Rs 10-20 Lakhs' || this.borrower.loanamount=='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs' || this.borrower.loanamount=='₹11 Lakhs to ₹20 Lakhs'",
          "conditional_notes": "this.borrower.loanamount!='Rs 1-5 Lakhs' || this.borrower.loanamount!='Rs 5-10 Lakhs' || this.borrower.loanamount!='Rs 10-20 Lakhs' || this.borrower.loanamount!='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount!='₹6 Lakhs to ₹10 Lakhs' || this.borrower.loanamount!='₹11 Lakhs to ₹20 Lakhs' ? 'Upload either CA Certified or Audited Company Financials for the last 2 financial years':''"
        },
        {
          "name": "Collateral Documents",
          "upload_folder": "collateral_documents",
          "required": true,
          "doc_prepend": "Collateral Documents",
          "notes":"Upload ALL relevant collateral documents (eg., Sales Deed, municipality approved building plan/plot layout, Title Deeds including the previous chain of property documents if applicable)",
          "hide_criteria": "this.borrower.typeofloan=='Unsecured' || this.borrower.loanamount == 'Rs 1-5 Lakhs' || this.borrower.loanamount == 'Rs 5-10 Lakhs'",
        },
        {
          "name": "Loan Takeover",
          "upload_folder": "loan_takeover",
          "doc_prepend": "Loan Takeover",
          "notes": "Upload the final Loan Sanction Letter and latest Loan Account Statements (optional)",
          "hide_criteria": "this.borrower.typeofloan=='Unsecured' || this.borrower.loanamount == 'Rs 1-5 Lakhs' || this.borrower.loanamount == 'Rs 5-10 Lakhs'"
        }
      ]
    },
    {
      "document_id": "internal_assessment_documents",
      "document_type": "Internal Assessment Documents",
      "document_label": "Internal Assessment Documents",
      "notes": "For internal purpose only",
      "is_optional": "1 == 1",
      "documents": [
        {
          "name": "Credit Assessment",
          "upload_folder": "internal_assessment_documents",
          "doc_prepend": "Credit Assessment",
          "options": []
        },
        {
          "name": "Other",
          "upload_folder": "internal_assessment_documents",
          "doc_prepend": "Other",
          "options": []
        }
      ]
    }
  ];