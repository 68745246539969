import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";

const fields:(flowManager: FlowManagerService) => FormlyFieldConfig[] = (flowManager:FlowManagerService) => (
    [{
        key: "hasgst",
            type: "question",
            modelOptions:{
                updateOn:'change'
            },
            templateOptions: {
                
                label: "Are you required to file GST",                    
                
                options: [{"label":"Yes","value":"Yes"},{"label":"No","value":"No"}],
                required: true,
            },
            hooks: {
                onInit: ({ formControl, key }) => {
                    let lastValue = flowManager.modelValue[key as string];
                    formControl.valueChanges.subscribe(value => {
                        if(lastValue== value || flowManager.loadingValue) return;
                        lastValue = value;
                        console.log(value)
                        const data = {};
                        const name = key as string;
                        data[name] = value;
                        hasGst.next(data, flowManager).subscribe();
                    })
                }
            },
            className: "col-12 col-sm-12",
            
             
    }]
)

const hasGst:Step ={
    key:"hasgst",
    
    fields,

    
    conversation: (model) => {
        return {
            greeting: ``,
            message: `Let's get to understand your business a bit better`
        }
    },
    

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        
        
        flowManager.startLoading()
        return flowManager.journey.updateJourney(flowManager.modelValue._id, {...data, location: flowManager.location}).pipe(
            
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap(() => flowManager.stepNext()),
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);
            }),
            tap(_=>flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },

};


export default hasGst;
    