import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from 'app/services/token.service';

@Component({
  selector: 'app-flipkart-bob-navbar',
  templateUrl: './flipkart-bob-navbar.component.html',
  styleUrls: ['./flipkart-bob-navbar.component.css']
})
export class FlipkartBobNavbarComponent implements OnInit {
  websiteUrl = 'https://creditenable.com/in/';
  isLoggedIn = false;

  constructor(
    public tokenService: TokenService, 
    public router: Router) {
    
  }

  logout() {
    this.tokenService.clearToken();
  }
  

  ngOnInit() {
    this.tokenService.token$.subscribe((token)=>{
      this.isLoggedIn=!!token;
    })
  } 

}
