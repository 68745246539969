import bankaccount from "./steps/bankaccount.step";
import businessVintage from "./steps/business-vintage.step";
import itr from "./steps/itr.step";
import mab from "./steps/mab.step";
import turnover from "./steps/turnover.step";
import { ComponentWrap, Flow } from "..";
import businessDetails from "./steps/business-details.step";
import loanAmount from "./steps/loan-amount.step";
import businessType from "./steps/business-type.step";
import sector from "./steps/sector.step";
import primaryBusiness from "./steps/primary-business.step";
import businessPincode from "./steps/business-pincode.step";
import panWithLoan from "./steps/pan-with-loan.step";
import businessDetailsDesktop from "./steps/business-details.desktop.step";
import loanDetailsDesktop from "./steps/loan-details.desktop.step";
import businessSectorDesktop from "./steps/business-sector.desktop.step";
import promoterDetails from "./steps/promoter-details.step";
import pan from "./steps/pan.step";
import nameDetails from "./steps/name.step";
import businessName from "./steps/business-name.step";
import { retry } from "rxjs/operators";
import negativeSectors from "./steps/negative-sectors";
import negativeSector from "./steps/negative-sector.step";
import negativeSectorPrompt from "./steps/negative-sector-prompt";

const errorMessage = { title: 'Oops!', text: 'We are unable to move you forward. Please try after a while. Thank you', icon: 'error' };

export interface GstJourneyWorkFlow {
    key: string,
    startFlowName: string,
    linearFlows: string[],
    login: Flow,
    basicDetails: Flow,
    basicDetailsDesktop:Flow;
    promoterDetails: Flow,
    businessSector: Flow,   
    businessSectorDesktop: Flow,
    businessDetails: Flow,
    businessDetailsDesktop: Flow,
    loanDetails: Flow,
    loanDetailsDesktop: Flow,
    experianMismatch: Flow,
    searchingLenders: Flow,
    qualifiedMessage: Flow,
    disQualifiedMessage: Flow,
    uploadDocument: Flow,
    uploadDocumentModern: Flow,
    loading: Flow,
    thankYou: Flow
}

const gstJourneyWorkflow: GstJourneyWorkFlow = {
    key: 'gstJourneyWorkflow',
    startFlowName: 'login',
    linearFlows: ['basicDetails', 'businessDetails'],
    login: {
        key: "login",

        steps: [
            {
                key: "login",
                type: "GstFlowLoginComponent",
            },
        ],
        onFinish: (model, flowManager) => {
            //flowManager.goto(borrowerJourneyWorkflow.basicDetails.key);
            //redirection after token change is handled in home pages
        },
        onError: (error, flowManager) => {

        },
    },



    basicDetails: {
        key: "basicDetails",
        steps: [
            loanAmount,
            nameDetails,
            pan,
            {
                key: "nsdlLoader",
                type: "NsdlLoaderStepComponent",
                excludeHistory: true,
            },
            {
                key: "businessSelectionStepComponent",
                type: "BusinessSelectionStepComponent"
            },
            {
                key: "gstrLoader",
                type: "GstrLoaderStepComponent",
                excludeHistory: true,
            },
        ],
        onFinish: (model, flowManager) => {
            flowManager.gtmTriggerService.triggerButtonClick({ className: "stage-gst" });

            if (flowManager.isMobile()) {
                flowManager.goto(gstJourneyWorkflow.businessSector.key, { excludeHistory: true });
            }
            else {
                flowManager.goto(gstJourneyWorkflow.businessSectorDesktop.key, { excludeHistory: true });
            }
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        }
    },

    basicDetailsDesktop: {
        key: "basicDetailsDesktop",
        steps: [
            panWithLoan,
            {
                key: "nsdlLoader",
                type: "NsdlLoaderStepComponent",
                excludeHistory: true,
            },
            {
                key: "businessSelectionStepComponent",
                type: "BusinessSelectionStepComponent"
            },
            {
                key: "gstrLoader",
                type: "GstrLoaderStepComponent",
                excludeHistory: true,
            },
        ],
        onFinish: (model, flowManager) => {
            flowManager.gtmTriggerService.triggerButtonClick({ className: "stage-gst" });

            if (flowManager.isMobile()) {
                flowManager.goto(gstJourneyWorkflow.businessSector.key, { excludeHistory: true });
            }
            else {
                flowManager.goto(gstJourneyWorkflow.businessSectorDesktop.key, { excludeHistory: true });
            }
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        }
    },

    promoterDetails: {
        key: "promoterDetails",
        steps: [
            promoterDetails,
        ],
        onFinish: (model, flowManager) => {
            if (flowManager.isMobile()) {
                flowManager.goto(gstJourneyWorkflow.businessDetails.key, { excludeHistory: true });
            }
            else {
                flowManager.goto(gstJourneyWorkflow.businessDetailsDesktop.key, { excludeHistory: true });
            }
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        }
    },

    businessSector: {
        key: 'businessSector',
        steps: [
            primaryBusiness,
            sector({isFinisher: true}),
            negativeSector({isFinisher: true}),          
        ],
        onFinish: (model, flowManager) => {
            if (flowManager.isMobile()) {
                flowManager.goto(gstJourneyWorkflow.loanDetails.key, { excludeHistory: true });
            }
            else {
                flowManager.goto(gstJourneyWorkflow.loanDetailsDesktop.key, { excludeHistory: true });
            }
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        }
    },

    businessSectorDesktop: {
        key: 'businessSectorDesktop',
        steps: [
            businessSectorDesktop,
            negativeSector({isFinisher: true}),         
        ],
        onFinish: (model, flowManager) => {
            if (flowManager.isMobile()) {
                flowManager.goto(gstJourneyWorkflow.loanDetails.key, { excludeHistory: true });
            }
            else {
                flowManager.goto(gstJourneyWorkflow.loanDetailsDesktop.key, { excludeHistory: true });
            }
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        }
    },

    businessDetails: {
        key: "businessDetails",
        steps: [            
            businessName,
            businessVintage,
            primaryBusiness,
            sector({isFinisher: false}),
            negativeSector({isFinisher: false}),          
            businessPincode,
        ],
        onStart: (model, flowManager) =>{
            flowManager.gtmTriggerService.triggerButtonClick({ className: "stage-nongst" });            
        },
        onFinish: (model, flowManager) => {
            if (flowManager.isMobile()) {
                flowManager.goto(gstJourneyWorkflow.loanDetails.key, { excludeHistory: true });
            }
            else {
                flowManager.goto(gstJourneyWorkflow.loanDetailsDesktop.key, { excludeHistory: true });
            }
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        }
    },

    businessDetailsDesktop: {
        key: "businessDetailsDesktop",
        steps: [
            businessDetailsDesktop,
            negativeSector({isFinisher: true}),
           
        ],
        onStart: (model, flowManager) =>{
            flowManager.gtmTriggerService.triggerButtonClick({ className: "stage-nongst" })
        },
        onFinish: (model, flowManager) => {
            if (flowManager.isMobile()) {
                flowManager.goto(gstJourneyWorkflow.loanDetails.key, { excludeHistory: true });
            }
            else {
                flowManager.goto(gstJourneyWorkflow.loanDetailsDesktop.key, { excludeHistory: true });
            }
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        }
    },

    loanDetails: {
        key: "loanDetails",
        steps: [
            turnover,
            mab,
            itr,
            bankaccount,
        ],
        onFinish: (model, flowManager) => {
            flowManager.goto(gstJourneyWorkflow.qualifiedMessage.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        }
    },

    loanDetailsDesktop: {
        key: "loanDetailsDesktop",
        steps: [
            loanDetailsDesktop
        ],
        onFinish: (model, flowManager) => {
            flowManager.goto(gstJourneyWorkflow.qualifiedMessage.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        }
    },


    experianMismatch: {
        key: "experianMismatch",
        steps: [
            {
                key: "experianMismatchAskNumber",
                type: "ExperianStepComponent",
            },
            {
                key: "experianMismatchVerification",
                type: "ExperainPhoneVerificationStepComponent"
            },
        ],
        onFinish: (model, flowManager) => {
            flowManager.goto(gstJourneyWorkflow.qualifiedMessage.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    searchingLenders: {
        key: "searchingLenders",
        steps: [
            {
                key: "searchingLenders",
                type: "SearchingLenderStepComponent",
            }
        ],
        onFinish: (model, flowManager) => {
            flowManager.goto(gstJourneyWorkflow.qualifiedMessage.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            flowManager.stepPrevious();
            flowManager.alertService.show(errorMessage);
        },
    },
    qualifiedMessage: {
        key: "qualifiedMessage",
        steps: [
            {
                key: "qualifiedMessage",
                type: "QualifiedStepComponent",
            }
        ],

        onFinish: (model, flowManager) => {
            
            const gotoV1 = () => flowManager.goto(gstJourneyWorkflow.uploadDocument.key, { excludeHistory: true });
            const gotoV2 = () => flowManager.goto(gstJourneyWorkflow.uploadDocumentModern.key, { excludeHistory: true });
            const setDocumentUploadVersion = (version: string) => flowManager.journey.updateLocation(flowManager.modelValue._id, { documentUploadVersion: version }).pipe(retry(3)).subscribe();

            flowManager.journey.getJourneyStats().subscribe(data=>{ 
                if(flowManager.hasV2TrafficFulfilled(data)) {
                    gotoV1();
                    setDocumentUploadVersion("v1");
                    
                }
                else {
                    gotoV2();
                    setDocumentUploadVersion("v2");
                }
            }, error => {
                gotoV1();
                setDocumentUploadVersion("v1");
                console.log(error);
            });
            
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    disQualifiedMessage: {
        key: "disQualifiedMessage",
        steps: [
            {
                key: "disQualifiedMessage",
                type: "DisqualifiedStepComponent",
            }
        ],

        onFinish: (model, flowManager) => {

        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    uploadDocument: {
        key: "uploadDocument",
        steps: [
            {
                key: "uploadDocument",
                type: "DocumentUploadStepComponent",
            },
        ],

        onFinish: (model, flowManager) => {

        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    uploadDocumentModern: {
        key: "uploadDocumentModern",
        steps: [
            {
                key: "uploadDocumentModern",
                type: "DocumentUploadModernStepComponent",
            },
        ],

        onFinish: (model, flowManager) => {

        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    loading: {
        key: "loading",
        steps: [
            {
                key: "loading",
                type: "LoadingStepComponent",
            },
        ],

        onFinish: (model, flowManager) => {

        },
        onError: (error, flowManager) => {
            flowManager.stepPrevious();
            flowManager.alertService.show(errorMessage);
        },
    },
    thankYou: {
        key:"thankYou",
        steps: [
            {
                key:"thankYou",
                type:"ThankYouStepComponent"
            }
        ],

        onFinish: (model, flowManager) => {

        },
        onError: (error, flowManager) => {
           
        },
    }
};

export default gstJourneyWorkflow;