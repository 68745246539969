import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { idleTimeout } from '../utils/constants';


@Component({
  selector: 'experian-report-modal',
  templateUrl: './experian-report-modal.component.html'
})
export class ExperianReportModalComponent implements OnInit {

  @Input()
  report: any = {};

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

}
