import { Router } from "@angular/router";
import { UtilityService } from "../services/utility.service";

export function relogin(router: Router) {
  let journey = UtilityService.journey;
  let partnerId = UtilityService.userId;
  let url = router.url.split('?')[0];
  router.navigateByUrl('/login').then(() => {
    if (journey != 'default') {
      router.navigateByUrl(`/${journey}?leadId=${partnerId}`)
    } else {
      router.navigateByUrl(url);
    }
  });
}

export function price_in_words(price) {
  var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
    dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
    tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
    handle_tens = function (dgt, prevDgt) {
      return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : (tensPlace[dgt] + (prevDgt > 0 ? "-" : "")))
    },
    handle_utlc = function (dgt, nxtDgt, denom) {
      return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "")
    };

  var str = "",
    digitIdx = 0,
    digit = 0,
    nxtDigit = 0,
    words:string[] = [];
  if (price += "", isNaN(parseInt(price))) str = "";
  else if (parseInt(price) > 0 && price.length <= 10) {
    for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
      case 0:
        words.push(handle_utlc(digit, nxtDigit, ""));
        break;
      case 1:
        words.push(handle_tens(digit, price[digitIdx + 1]));
        break;
      case 2:
        words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
        break;
      case 3:
        words.push(handle_utlc(digit, nxtDigit, "Thousand"));
        break;
      case 4:
        words.push(handle_tens(digit, price[digitIdx + 1]));
        break;
      case 5:
        words.push(handle_utlc(digit, nxtDigit, "Lakh"));
        break;
      case 6:
        words.push(handle_tens(digit, price[digitIdx + 1]));
        break;
      case 7:
        words.push(handle_utlc(digit, nxtDigit, "Crore"));
        break;
      case 8:
        words.push(handle_tens(digit, price[digitIdx + 1]));
        break;
      case 9:
        words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? " and" : " Crore") : "")
    }
    str = words.reverse().join("")
  } else str = "";
  return str.replace(/- /g, "-");

}

export function setClarity(key, value) {
  window["clarity"]("set", key, value);
}

export function getDateComponents(date: string, format: string){
  let year = 0, month = 0, day = 0;
  if (format) {
    format = format.replace(/-/g, '').replace(/\//g, '');
    let y1 = format.indexOf('Y');
    let y2 = format.lastIndexOf('Y');
    year = +date.substr(y1, y2 - y1 + 1);

    let m1 = format.indexOf('M');
    let m2 = format.lastIndexOf('M');
    month = +date.substr(m1, m2 - m1 + 1);

    let d1 = format.indexOf('D');
    if (d1 >= 0) {
      let d2 = format.lastIndexOf('D');
      day = +date.substr(d1, d2 - d1 + 1);
    } else {
      day = 1;
    }
  } else {
    year = +date.substr(4);
    month = +date.substr(2, 2);
    day = +date.substr(0, 2);
  }
  return { year, month, day };
}

export function getFormatFromComponents(year: number, month: number, day: number, format: string) {
  format = format.replace(/-/g, '').replace(/\//g, '');

  if (format.includes('DD')) {
    format = format.replace(/DD/g, day > 9 ? day.toString() : `0${day.toString()}`);
  } else if (format.includes('D')) {
    format = format.replace(/D/g, day.toString());
  }

  if (format.includes('MM')) {
    format = format.replace(/MM/g, month > 9 ? month.toString() : `0${month.toString()}`);
  } else if (format.includes('D')) {
    format = format.replace(/M/g, month.toString());
  }

  if (format.includes('YYYY')) {
    format = format.replace(/YYYY/g, year.toString());
  } else if (format.includes('D')) {
    format = format.replace(/YY/g, year.toString().substr(2));
  }

  return format;
}
