import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { catchError, delay, map, timeout, switchMap, takeWhile } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class PerfiosService {
  params: any;
  private intervalDurationInMinutes = 1;
  getLocation() {
    return this.http.get<any>("https://ipapi.co/json/");
  }

  constructor(private http: HttpClient,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  generateNetBanking(data: any): Observable<any> {
    var result = this.http.post<any>("api/Perfios/generateNetBanking", data, { headers: { 'x-api-version': '2.0' } })
    return result;
  }

  generateUpload(data: any): Observable<any> {
    var result = this.http.post<any>("api/Perfios/generateUpload", data, { headers: { 'x-api-version': '2.0' } })
    return result;
  }

  getPerfiosBankStatement(data: any): Observable<any> {
    var result = this.http.post<any>("api/Perfios/getPerfiosBankStatement", data, { headers: { 'x-api-version': '2.0' } })
    return result;
  }
}
