import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Testimonial } from 'app/definitions/testimonial';

@Component({
  selector: 'app-testimonial-card',
  templateUrl: './testimonial-card.component.html',
  styleUrls: ['./testimonial-card.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TestimonialCardComponent implements OnInit {
  @Input()
  testimonial: Testimonial;
  
  constructor() { }

  ngOnInit(): void {
  }

}
