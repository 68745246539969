import { Flow } from "..";

const errorMessage = { title: 'Oops!', text: 'We are unable to move you forward. Please try after a while. Thank you', icon: 'error' };

export interface TestWorkFlow {
    key: string,
    startFlowName: string,
    basicDetails: Flow,
    linearFlows:string[],
    uploadDocument:Flow,
    thankYou:Flow,
}

const testWorkFlow: TestWorkFlow = {
    key: 'testWorkFlow',
    startFlowName: 'uploadDocument',
    linearFlows:[],
    basicDetails: {
        key: "basicDetails",
        steps: [
            
        ],
        onFinish: (model, flowManager) => {
         
        },
        onError: (error, flowManager) => {
        }
    },
    uploadDocument: {
        key: "uploadDocument",
        steps: [
            {
                key: "uploadDocument",
                type: "DocumentUploadModernStepComponent",
            },
        ],
        onFinish: (model, flowManager) => {
         
        },
        onError: (error, flowManager) => {
        }
    },
    thankYou: {
        key: "thankYou",
        steps: [
            {
                key: "thankYou",
                type: "ThankYouStepComponent",
            },
        ],
        onFinish: (model, flowManager) => {
         
        },
        onError: (error, flowManager) => {
        }
    },
};

export default testWorkFlow;