import { Component, ChangeDetectionStrategy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { priceInWords } from '../../functions/priceInWords.function';

@Component({
  selector: 'formly-currency-input',
  template: `
    <div class="input-group">
      <input #money 
            [formControl]="formControl"
            class="form-control"
            [formlyAttributes]="field"
            [class.is-invalid]="showError"
            (keyup)="getAmountInWords(money.value)"
            mask="0999999999"
          />
        <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">INR (₹)</span>
      </div>
    </div>
    <label style="display: block;
    font-size: small;
    margin-top: 3px;
    color: lightgrey;
    background-color: inherit;
    font-weight: 100;" *ngIf="amount_in_words && !showError">{{amount_in_words}}</label>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyCurrencyInput extends FieldType implements AfterViewInit {

  @ViewChild("money")
  money: ElementRef;

  amount_in_words: string = null;

  get type() {
    return this.to.type || 'text';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.money.nativeElement.dispatchEvent(new KeyboardEvent('keyup', { 'key': '' }));
    }, 100);
  }

  public getAmountInWords(amount) {
    if (amount && !isNaN(amount) && parseInt(amount)) {
      this.amount_in_words = priceInWords(parseInt(amount)) + " Rupees";
    }
  } 
}
