import { FormControl } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout, startWith, map, takeUntil } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";

const fields = (flowManager: FlowManagerService): Array<FormlyFieldConfig> =>
    [
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    key: 'loanamount',
                    type: 'question',
                    modelOptions: {
                        updateOn: 'change',
                    },
                    templateOptions: {
                        label: "What loan amount do you need?",
                        options: [
                            { label: "1 - 5 Lacs", value: "Rs 1-5 Lakhs", },
                            { label: "6 - 10 Lacs", value: "Rs 5-10 Lakhs", },
                            { label: "11 - 20 Lacs", value: "Rs 10-20 Lakhs", },
                            { label: "21 - 50 Lacs", value: "Rs 20-50 Lakhs", },
                            { label: "51 Lacs - 1 Cr", value: "Rs 50 Lakhs - 1 Crore" },
                            { label: "More than 1 Cr", value: "Rs 1-3 Crores", }
                        ],
                        required: true,
                    },
                    className: 'col-12'
                },
                {
                    key: 'first_name',
                    type: 'input',
                    modelOptions: {
                        updateOn: 'change',
                    },
                    templateOptions: {
                        type: 'text',
                        label: 'First Name',
                        placeholder: 'eg. Lakshmi',
                        required: true,
                        min: 1,
                        max: 50,
                        regex: "(^[a-zA-Z ]+$)",
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase().trim()),
                            tap(v => v == formControl.value ? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: 'col-6 text-uppercase'
                },
                {
                    key: 'last_name',
                    type: 'input',
                    templateOptions: {
                        type: 'text',
                        label: 'Last Name',
                        placeholder: 'eg. Patel',
                        required: true,
                        min: 1,
                        max: 50,
                        regex: "(^[a-zA-Z ]+$)"
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase().trim()),
                            tap(v => v == formControl.value ? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: 'col-6'
                },
                {
                    key: 'email',
                    type: 'input',
                    templateOptions: {
                        type: 'email',
                        label: 'Email',
                        placeholder: 'eg. lakshmi.patel@xyz.com',
                        required: true,
                        min: 5,
                        max: 100,
                        regex: "^(([^<>()\\[\\]\\\\.`;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.`;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1`3}\\.[0-9]{1`3}\\.[0-9]{1`3}\\.[0-9]{1`3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2`}))$"
                    },
                    validators: {
                        validation: ['email'],
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toLowerCase().trim()),
                            tap(v => v == formControl.value ? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: 'col-6',
                },
                {
                    key: 'dob',
                    type: 'date',
                    templateOptions: {
                        label: 'Date of Birth',
                        placeholder: 'DD/MM/YYYY',
                        required: true,
                    },
                    validators: {
                        validation: [
                            { name: 'date', options: { format: "DD/MM/YYYY" } },
                            { name: 'max-date', options: { max: 662256000, format: "DD/MM/YYYY", label: "Applicant's age" } },
                        ]
                    },
                    className: 'col-6'
                },
                {
                    key: 'pan',
                    type: 'mask',
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {
                        type: 'text',
                        label: 'Your PAN Number',
                        placeholder: 'AAAAA1111A',
                        mask: 'SSSSS0000S',
                        required: true,
                        autoCapitalize: true
                    },
                    validators: {
                        regex: {
                            expression: (c) => !c.value || /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/.test(c.value),
                            message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" does not seem to be a valid PAN. 
                            Please enter your 10 character PAN.`,
                        },
                    },
                    className: 'col-6'
                },
                {
                    key: 'gender',
                    type: 'question',
                    modelOptions: {
                        updateOn: 'change',
                    },
                    templateOptions: {
                        label: 'Gender',
                        placeholder: 'SSSSS0000S',
                        required: true,
                        max: 662256000,
                        options: [
                            { label: 'Male', value: 'Male', },
                            { label: 'Female', value: 'Female', },
                        ],
                        regex: '(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)'
                    },
                    className: 'col-6'
                },

                {
                    key: 'reasonforloan',
                    type: 'question',
                    modelOptions: {
                        updateOn: 'change',
                    },
                    templateOptions: {
                        label: 'What do you want to use your business loan for?',
                        required: true,
                        options: [
                            { label: 'Business Expansion', icon: 'icons-loan-purpose-expansion', value: 'Business Expansion' },
                            { label: 'Working Capital', icon: 'icons-loan-purpose-working-capital', value: 'Working capital' },
                            { label: 'Machinery Purchase', icon: 'icons-loan-purpose-machinery', value: 'Machinery' },
                            { label: 'Others (e.g. Overdraft Facility)', icon: 'icons-loan-purpose-other', value: 'Not Sure / Other' },
                        ],
                    },
                    className: 'col-12',
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => !model.gender,

                },
                {
                    key: 'typeofloan',
                    type: 'question',
                    modelOptions: {
                        updateOn: 'change',
                    },
                    templateOptions: {
                        label: 'Loan Type',
                        required: true,
                        options: [
                            { label: 'Unsecured loan', value: 'Unsecured', },
                            { label: 'Secured loan', value: 'Secured', },
                        ],
                    },
                    className: 'col-12',
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return model.loanamount == "Rs 1-5 Lakhs" || model.loanamount == "Rs 5-10 Lakhs" || model.loanamount == "Rs 1-3 Crores" || model.loanamount == "Rs 3 Crores & above" || model.loanamount == "Rs 1-3 Crore" || model.loanamount == "Rs 3 Crore and above" || !model.gender
                    },


                },
                {
                    key: 'businessname',
                    type: 'input',
                    templateOptions: {
                        label: 'Business Name',
                        input: 'text',
                        required: true
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase()),
                            tap(v => v == formControl.value ? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.gender
                    },
                    className: 'col-6',
                },
                {
                    key: 'pincode',
                    type: 'pincode',
                    templateOptions: {
                        label: 'Business Pincode',
                        placeholder: 'eg. 110011',
                        required: true,
                    },
                    className: 'col-6',
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.gender
                    },
                    asyncValidators: {
                        isJammuKashmir: {
                            expression: (control: FormControl) => flowManager.journey.isValidPincode(control.value)
                                .pipe(
                                    map(data => !data.isJammuKashmir )),
                            message: (_error: any, field: FormlyFieldConfig) => `We are not present in this PIN code`
                        },
                        isValidPincode: {
                            expression: (control: FormControl) => flowManager.journey.isValidPincode(control.value)
                                .pipe(
                                    map(data => data.valid || data.isJammuKashmir)),
                            message: (_error: any, field: FormlyFieldConfig) => `The Pincode entered is not valid.`
                        }
                    }
                },
                {
                    key: 'whatsappconsent',
                    type: 'checkbox',
                    templateOptions: {
                        label: `I would like to receive updates on my application via <span class="text-ufo-green"> <i class="fab fa-whatsapp"> </i> WhatsApp </span>`,
                        required: true,
                    },
                    defaultValue: true,
                    className: 'col-12',
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.gender
                    },
                },
            ]
        }
    ]


const basicDetails: Step = {
    key: "basicDetails",

    fields,

    hideBack: true,

    conversation: (model) => ({
        greeting: `Hi! I'm Lakshmi.`,
        message: `I will help you find the loan that's right for you.
        <i class='d-block sub-header'>(Don't worry, this should only take a few minutes)</i>
        `
    }),

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        flowManager.startLoading()
        const payload = { ...data, location: flowManager.location, ...flowManager.modelValue };
        return flowManager.journey.createJourney(payload).pipe(
            catchError(err => {
                if (err.error.includes("Borrower already exist")) {
                    return flowManager.journey.updateJourney(flowManager.modelValue._id, payload);
                }
                else {
                    flowManager.throwError(err);
                    return throwError(err);
                }
            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap(() => flowManager.finish()),
            tap((result) => flowManager.gtmTriggerService.triggerButtonClick({ className: "stage-complete stage-1" })),

            tap(_ => flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },
}

export default basicDetails;
