// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appInsights: {
    instrumentationKey: '9e05d4dc-b046-4b73-b47c-6cbe4fbc794d'
  },
 //baseUrl: 'https://borrower-journey-dev.creditenable.com/',
  // baseUrl: 'http://localhost:50797/',
  baseUrl:'https://localhost:44380/',
  azureAd: {
    clientId: "8c96578f-8ad7-428c-82aa-832f97c27328",
    tenantId: "b5242107-a98d-44a5-a3df-5063c58f0d57"
  },
  hj: '2075217'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
