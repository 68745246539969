import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { TokenService } from 'app/services/token.service';
import { of } from 'rxjs';
import { switchMap, catchError, delay, tap, startWith } from 'rxjs/operators';
import { BorrowerJourneyWorkFlow } from 'app/layouts/workflow/borrower-journey';
import { FlipkartJourneyWorkFlow } from 'app/layouts/workflow/flipkart-journey';
import { FlipkartBobJourneyWorkFlow } from 'app/layouts/workflow/flipkart-bob-journey';
type Workflow = BorrowerJourneyWorkFlow | FlipkartJourneyWorkFlow | FlipkartBobJourneyWorkFlow;

@Component({
  selector: 'app-flow-manager',
  templateUrl: './flow-manager.component.html',
  styleUrls: ['./flow-manager.component.css']
})
export class FlowManagerComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;

  get steps() {
    return this.flowManager?.flowValue?.steps
  }


  @Input() workflow:Workflow;
  @Input() layout:string ='';

  constructor(public flowManager: FlowManagerService, private tokenService: TokenService) {
    
  }

  ngAfterViewInit(): void {
    this.flowManager.init(this.stepper);

    this.flowManager.flow$.subscribe(()=>{
      if(this.flowManager.flowValue.onStart) {
        this.flowManager.flowValue.onStart(this.flowManager.modelValue, this.flowManager);
      }
    });

    this.stepper.selectionChange.asObservable().subscribe(() => {
      window.scroll(0, 0);
    });
  }

  ngOnInit(): void {
    
  }

}
