import { Injectable } from '@angular/core';
import { FilesDoctypeParserPipe } from 'app/layouts/components/files-doctype-parser/files-doctype-parser.pipe';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FileManagerService {

    private files = new BehaviorSubject<any[]>([]);
    public files$ = this.files.asObservable();

    get filesValue() {
        return this.files.value;
    }

    setFiles(files: any) {
        this.files.next(files);
    }

    addFile($file) {
        this.filesValue.forEach(f=>f.isNew = false);
        $file.isNew = true;
        const files = [...this.filesValue, $file];
        this.files.next(files);
    }

    addFiles(uploadedFiles: any[]) {
        this.filesValue.forEach(f=>f.isNew = false);
        uploadedFiles.forEach(f=>f.isNew = true);
        const files = [...this.filesValue, ...uploadedFiles];
        this.files.next(files);
    }

    removeFile($file) {
        const files = this.filesValue.filter(f => f.id != $file.id);
        this.files.next(files);
    }

    updateFile(predicate: ($event: any) => boolean, action: ($event: any) => void) {
        const files = [...this.filesValue];
        const file = files.find(predicate);
        action(file);
        this.files.next(files);

    }
}