import { FormControl } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout, startWith, map, takeUntil } from "rxjs/operators"
import gstJourneyWorkflow from "..";
import workFlow from ".."
import { Step } from "../..";
import stateOptions from "./state-options";



const fields = (flowManager: FlowManagerService): Array<FormlyFieldConfig> =>
    [
        {
            fieldGroupClassName: 'row',
            wrappers: ['panel'],
            templateOptions: {
                label: `Main promoter or director's name`,
                description: `Whose mobile number is ${flowManager.mobileNumber}`
            },
            fieldGroup: [

                {
                    key: 'first_name',
                    type: 'input',
                    modelOptions: {
                        updateOn: 'change',
                    },
                    templateOptions: {
                        label: `First Name`,
                        placeholder: 'eg. Lakshmi',
                        required: true,
                        min: 1,
                    },
                    validators: {
                        validation: ['name', 'max-length-name'],
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase()),
                            tap(v => v == formControl.value ? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: 'col-6'
                },
                {
                    key: 'last_name',
                    type: 'input',
                    templateOptions: {
                        label: 'Last Name',
                        placeholder: 'eg. Patel',
                        required: true,
                        min: 1,
                    },
                    validators: {
                        validation: ['name', 'max-length-name'],
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase()),
                            tap(v => v == formControl.value ? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: 'col-6'
                },
                {
                    key: 'email',
                    type: 'input',
                    templateOptions: {
                        type: 'email',
                        label: 'Your email',
                        placeholder: 'eg. lakshmi.patel@xyz.com',
                        required: true,
                        min: 5,
                        max: 100,
                        regex: "^(([^<>()\\[\\]\\\\.`;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.`;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1`3}\\.[0-9]{1`3}\\.[0-9]{1`3}\\.[0-9]{1`3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2`}))$"
                    },
                    validators: {
                        validation: ['email'],
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toLowerCase().trim()),
                            tap(v => v == formControl.value ? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: 'col-12',
                },
                // {
                //     key: 'whatsappconsent',
                //     type: 'checkbox',
                //     templateOptions: {
                //         label: `I would like to receive updates on my application via <span class="text-ufo-green"> <i class="fab fa-whatsapp"> </i> WhatsApp </span>`,
                //         required: true,
                //     },
                //     defaultValue: true,
                //     className: 'col-12',                   
                // },
            ]
        },

      
    ]

const nameDetails: Step = {
    key: "nameDetails",

    fields,


    conversation: (model) => ({
        greeting: `Thanks for choosing CreditEnable!`,
        message: `I'm Lakshmi and I'll be helping guide you through your loan application. This will only take a few minutes. 
        `
    }),

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        
        const payload = { 
            ...data,            
            location: flowManager.location, 
            ...flowManager.modelValue,
            pincode: "",
            businessname: "",
            gstins:[], 
        };
        
        return flowManager.journey.updateJourney(flowManager.modelValue._id, payload).pipe(           
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);

            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap(() => flowManager.stepNext()),       
            tap(_ => flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },

}

export default nameDetails;
