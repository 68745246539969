import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { JourneyService } from 'app/layouts/services/journey/journey.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-disqualified-step',
  templateUrl: './disqualified-step.component.html',
  styleUrls: ['./disqualified-step.component.css']
})
export class DisqualifiedStepComponent implements OnInit {

  isNonGst = false;

  
  rejectionMessages = {
"experian"  : `
Your bureau profile isn't a good match for our lender partners at the moment. 
We recommend you reduce delays in your existing repayments & 
reduce utilization of your active lines to improve your credit score and profile.`,

"businesstype"  : `Your profile isn't a good match for our lender partners at the moment as 
your business isn't registered. 
If you register your business you will improve your 
chances of getting a loan in the future. `,

"business_vintage"  : `Your profile isn't a good match for our lender partners right now. 
Our lender partners currently only provide loans to businesses that have been registered for two years or 
more. Once you've been in business for more than two years please get back in touch 
and we will be happy to help get a business loan. `,

"bankaccount"  : `Your profile isn't a good match for our lenders at the moment. To get a business loan, 
you must show lenders that your business income goes into your business Current Account. 
This is so they can check you will have the ability to repay your EMIs.  `,

"itr"  : `Your profile isn't a good match for our lenders. Based on your input, 
it seems that your business is non-compliant on statutory obligations like filing your ITRs.`,

"turnover"  : `Your profile isn't a good match for our lender partners at the moment as 
your turnover isn't sufficient to support repayment of the loan you are looking for.`,

"mab"	: `Your profile isn't a good match for our lender partners at the moment. 
Your monthly balance isn't sufficient to support the EMI payments of the loan you are looking for. `,
"gst"	: ``,
"negativeSector":``,
"gstr3b":``
  }

  get model() {
    return this.flowManager.modelValue;
  }

  constructor(private flowManager:FlowManagerService, private route:ActivatedRoute, private journey: JourneyService) { }

  ngOnInit(): void {
    if(this.model.disqualificationKey!='experian') {
      this.flowManager.gtmTriggerService.triggerButtonClick({ className: `stage-disqualified stage-${this.model.disqualificationKey}`})
    }

    this.flowManager.model$.subscribe(model=>{
      if(model.disqualified && (model.disqualificationKey == "experian" 
      || model.disqualificationKey == "gst" 
      || model.disqualificationKey=='negativeSector'
      || model.disqualificationKey=='gstr3b'
      )) {
        this.rejectionMessages[model.disqualificationKey] = model.disqualificationMessage
      }
    })

    this.flowManager.saveLocation().subscribe();

    this.route.url.subscribe(url=> {
      this.isNonGst = !url || url.length == 0 || url[0].path!='v2';
      console.log(this.isNonGst);
      console.log(url);
    });
  }

  conversation (model) {
    return {
      greeting:`Sorry ${model.first_name}! `,
      message:`You are not eligible for a loan at this time.`
    }
  }

  applyGoldLoan() {
    Swal.fire("Done!","Thank you for expressing your interest. Our partners should get in touch with you shortly", "success");
    this.journey.applyGoldLoan(null).subscribe(res => {});
  }
}
