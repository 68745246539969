import { AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AzureMonitoringService } from './services/logging.service';
import { TokenService } from './services/token.service';
import { relogin } from './utils/functions';
import { Router } from '@angular/router';
import { from, interval, Observable, Subscription } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { UserIdleService } from 'angular-user-idle';
import { UtilityService } from './services/utility.service';
import { HttpClient } from '@angular/common/http';
import { NgxHotjarService } from 'ngx-hotjar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit  {
  constructor(){
   this.pload(
    `${window.location.origin}/img/1-star.svg`,
    `${window.location.origin}/img/2-star.svg`,
    `${window.location.origin}/img/3-star-darkblue.svg`,
    `${window.location.origin}/img/3-star.svg`,
    `${window.location.origin}/img/bajaj.jpg`,
    `${window.location.origin}/img/banner1.jpg`,
    `${window.location.origin}/img/british_high_commission-logo.jpg`,
    `${window.location.origin}/img/business-growth-icon.png`,
    `${window.location.origin}/img/business-growth-icon.svg`,
    `${window.location.origin}/img/business-growth-icon1.png`,
    `${window.location.origin}/img/Business_Expansion.svg`,
    `${window.location.origin}/img/cash-icon.svg`,
    `${window.location.origin}/img/ce-icn.jpg`,
    `${window.location.origin}/img/central_bank_uae.jpg`,
    `${window.location.origin}/img/Company_Building.svg`,
    `${window.location.origin}/img/current-account-icon.png`,
    `${window.location.origin}/img/Dark-Logo.svg`,
    `${window.location.origin}/img/dbs.jpg`,
    `${window.location.origin}/img/deutsche-bank.jpg`,
    `${window.location.origin}/img/double-tick-indicator.svg`,
    `${window.location.origin}/img/efl.jpg`,
    `${window.location.origin}/img/enterpreuners-are-great.jpg`,
    `${window.location.origin}/img/experian-logo.png`,
    `${window.location.origin}/img/Experian_logo.jpg`,
    `${window.location.origin}/img/favicon.png`,
    `${window.location.origin}/img/Financial_Revolutionists_n.jpg`,
    `${window.location.origin}/img/Financial_Technologist.jpg`,
    `${window.location.origin}/img/find.svg`,
    `${window.location.origin}/img/finocate-awards.jpg`,
    `${window.location.origin}/img/fintech-innovation-lab.jpg`,
    `${window.location.origin}/img/fintech-power.jpg`,
    `${window.location.origin}/img/fireworks-sprite-sheet.png`,
    `${window.location.origin}/img/fireworks.mp4`,
    `${window.location.origin}/img/fireworks.webm`,
    `${window.location.origin}/img/Fireworks_animation.gif`,
    `${window.location.origin}/img/flipkart-bg.jpg`,
    `${window.location.origin}/img/flipkart-bob-logo.png`,
    `${window.location.origin}/img/flipkart-logo.png`,
    `${window.location.origin}/img/form-bg.png`,
    `${window.location.origin}/img/global-sme.jpg`,
    `${window.location.origin}/img/icici-bank.jpg`,
    `${window.location.origin}/img/icon-document.svg`,
    `${window.location.origin}/img/icon-documents.PNG`,
    `${window.location.origin}/img/icon-eligibility.PNG`,
    `${window.location.origin}/img/icon-eligibility.svg`,
    `${window.location.origin}/img/icon-otp.PNG`,
    `${window.location.origin}/img/icon-otp.svg`,
    `${window.location.origin}/img/icons-bank-ac-type-current.svg`,
    `${window.location.origin}/img/icons-bank-ac-type-savings.svg`,
    `${window.location.origin}/img/icons-doc-type-business.svg`,
    `${window.location.origin}/img/icons-doc-type-financial.svg`,
    `${window.location.origin}/img/icons-doc-type-personal.svg`,
    `${window.location.origin}/img/icons-loan-purpose-expansion.svg`,
    `${window.location.origin}/img/icons-loan-purpose-machinery.svg`,
    `${window.location.origin}/img/icons-loan-purpose-other.svg`,
    `${window.location.origin}/img/icons-loan-purpose-working-capital.svg`,
    `${window.location.origin}/img/icons-ownership-type-parents-owned.svg`,
    `${window.location.origin}/img/icons-ownership-type-rented.svg`,
    `${window.location.origin}/img/icons-ownership-type-self-owned.svg`,
    `${window.location.origin}/img/icons-ownership-type-sibling-owned.svg`,
    `${window.location.origin}/img/idfc-first-bank.jpg`,
    `${window.location.origin}/img/ID_Proof.svg`,
    `${window.location.origin}/img/inclusive-fintech.jpg`,
    `${window.location.origin}/img/indifi.jpg`,
    `${window.location.origin}/img/inditrade.jpg`,
    `${window.location.origin}/img/intellegrow.jpg`,
    `${window.location.origin}/img/kotak-mahindra-bank.jpg`,
    `${window.location.origin}/img/LLP-icon.PNG`,
    `${window.location.origin}/img/LLP-icon.svg`,
    `${window.location.origin}/img/Logo.svg`,
    `${window.location.origin}/img/Machinery_Purchase.svg`,
    `${window.location.origin}/img/magnifying-glass-search.png`,
    `${window.location.origin}/img/maya_avatar.png`,
    `${window.location.origin}/img/mibp.jpg`,
    `${window.location.origin}/img/NA-icon.PNG`,
    `${window.location.origin}/img/NA-icon.svg`,
    `${window.location.origin}/img/name-underline.jpg`,
    `${window.location.origin}/img/Overdraft.svg`,
    `${window.location.origin}/img/owned-by-parents-icon.png`,
    `${window.location.origin}/img/owned-by-sibling-icon.png`,
    `${window.location.origin}/img/Partnership-icon.PNG`,
    `${window.location.origin}/img/Partnership-icon.svg`,
    `${window.location.origin}/img/personal-requirement-icon.png`,
    `${window.location.origin}/img/personal-requirement-icon.svg`,
    `${window.location.origin}/img/profectus-capital.jpg`,
    `${window.location.origin}/img/pvt-limited-icon.PNG`,
    `${window.location.origin}/img/pvt-limited-icon.svg`,
    `${window.location.origin}/img/Quick_Loan.svg`,
    `${window.location.origin}/img/rejection.svg`,
    `${window.location.origin}/img/Rented-icon.png`,
    `${window.location.origin}/img/saving-account-icon.png`,
    `${window.location.origin}/img/Search.svg`,
    `${window.location.origin}/img/searching-hand-copy.svg`,
    `${window.location.origin}/img/self-owned-icon.png`,
    `${window.location.origin}/img/Sole-Proprietorship-icon.PNG`,
    `${window.location.origin}/img/Sole-Proprietorship-icon.svg`,
    `${window.location.origin}/img/Speedometer.svg`,
    `${window.location.origin}/img/stars-grey.svg`,
    `${window.location.origin}/img/stars-white.svg`,
    `${window.location.origin}/img/Statement.svg`,
    `${window.location.origin}/img/step-separator.svg`,
    `${window.location.origin}/img/tech-nation.jpg`,
    `${window.location.origin}/img/techpreneurs.jpg`,
    `${window.location.origin}/img/testimonial-0.png`,
    `${window.location.origin}/img/testimonial-1.png`,
    `${window.location.origin}/img/ugro.jpg`,
    `${window.location.origin}/img/ujjivan.jpg`,
    `${window.location.origin}/img/user.png`,
    `${window.location.origin}/img/vectorpaint.svg`,
    `${window.location.origin}/img/Working_Capital.svg`,
    `${window.location.origin}/img/Yamini.PNG`,
    `${window.location.origin}/img/ziploan.jpg`,
    `${window.location.origin}/img/fireworks/frame_00_delay-0.3s.png`,
    `${window.location.origin}/img/fireworks/frame_01_delay-0.3s.png`,
    `${window.location.origin}/img/fireworks/frame_02_delay-0.3s.png`,
    `${window.location.origin}/img/fireworks/frame_03_delay-0.3s.png`,
    `${window.location.origin}/img/fireworks/frame_04_delay-s.png`,
    `${window.location.origin}/img/fireworks/frame_05_delay-s.png`,
    `${window.location.origin}/img/fireworks/frame_06_delay-s.png`,
    `${window.location.origin}/img/fireworks/frame_07_delay-s.png`,
    `${window.location.origin}/img/fireworks/frame_08_delay-s.png`,
    `${window.location.origin}/img/fireworks/frame_09_delay-s.png`,
    `${window.location.origin}/img/fireworks/frame_10_delay-s.png`,
    `${window.location.origin}/img/fireworks/frame_11_delay-s.png`,
    `${window.location.origin}/img/fireworks/frame_12_delay-s.png`,
    `${window.location.origin}/img/fireworks/frame_13_delay-s.png`,
   )
  }

  ngOnInit(): void {
    
  }

  pload(...args:any[]) {
    for(let url of args) {
      let image = new Image();
      image.src = url;
    }
  }
}
