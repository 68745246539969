import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-category-step-modal',
  templateUrl: './document-category-step-modal.component.html',
  styleUrls: ['./document-category-step-modal.component.css']
})
export class DocumentCategoryStepModalComponent implements OnInit {
  showDetails = false;
  info: {
    header: string,
    overview: string,
    description: string
  }
  constructor() { }

  ngOnInit(): void {
  }

}
