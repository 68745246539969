import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SweetAlertService } from 'app/layouts/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-sweet-alert',
  templateUrl: './sweet-alert.component.html',
  styleUrls: ['./sweet-alert.component.css']
})
export class SweetAlertComponent implements OnInit {
  @ViewChild('errorSwal') private errorSwal: SwalComponent;

  alert: {
    title: string,
    text: string,
    icon: string,
  } = {
      title: 'Oops!',
      text: 'We are unable to move you forward. Please try after a while. Thank you',
      icon: 'error'
    }

  constructor(private alertService: SweetAlertService) {

  }

  ngOnInit(): void {
    this.alertService.alert$.subscribe(data => {
      if (data) {
        this.alert = data;
        setTimeout(()=> this.errorSwal.fire())
      }
    })
  }

}
