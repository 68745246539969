import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gstr-loader-step',
  templateUrl: './gstr-loader-step.component.html',
  styleUrls: ['./gstr-loader-step.component.css']
})
export class GstrLoaderStepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  conversation = (model) => ({
    greeting: ``,
    message:`Please wait while we verify your business registration.`
  })

}
