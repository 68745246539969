import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { JourneyService } from 'app/layouts/services/journey/journey.service';
import { TokenService } from 'app/services/token.service';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

@Component({
  selector: 'app-gst-flow-new',
  templateUrl: './gst-flow-new.component.html',
  styleUrls: ['./gst-flow-new.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GstFlowNewComponent implements OnInit {

  public IsOtpAvailable: boolean = false;
  public form: FormGroup;
  public otpForm: FormGroup;
  public panForm: FormGroup;
  public IsPanRequired: boolean = false;
  public currentOtp: string = '';
  public stagingJourney: StagingJourney = new StagingJourney();
  public isNewUser: boolean = false;
  public IsOtpSubmitted: boolean = false;
  public isFormSubmitted: boolean = false;
  public isJourneyDisqulified: boolean = false;
  public disqulifiedJourneyMessage: string = "";
  public isPanRequiredForNotFOundExperian: boolean = false;
  public isProcessComplete: boolean = false;
  public isLoading: boolean = false;
  public case: string = "";
  public journey: any = {};

  public turnOverOptions: Array<any> = [
    { "label": "Less than ₹12 Lacs", "value": "Less than INR 12 Lakhs" },
    { "label": "₹12 - ₹15 Lacs", "value": "INR 12 Lakhs - INR 15 Lakhs" },
    { "label": "₹15 - ₹50 Lacs", "value": "INR 15 Lakhs - INR 50 Lakhs" },
    { "label": "More than ₹50 Lacs", "value": "More than INR 50 Lakhs" }];

  public loanAmountOptions: Array<any> = [
    { label: "1 - 5 Lacs", value: "Rs 1-5 Lakhs", },
    { label: "6 - 10 Lacs", value: "Rs 5-10 Lakhs", },
    { label: "11 - 20 Lacs", value: "Rs 10-20 Lakhs", },
    { label: "21 - 50 Lacs", value: "Rs 20-50 Lakhs", },
    { label: "51 Lacs - 1 Cr", value: "Rs 50 Lakhs - 1 Crore" },
    { label: "More than 1 Cr", value: "Rs 1-3 Crores", }
  ]

  public businessTypeOptions: Array<any> = [
    { label: 'Sole Proprietorship', value: 'Sole Proprietorship', checked: true },
    { label: 'Private Limited', value: 'Private Limited', checked: false },
    { label: 'Partnership', value: 'Partnership', checked: false },
    { label: 'Limited Liability Partnership (LLP)', value: 'Limited Liability Partnership', checked: false }]

  public businessVintageOptions: Array<any> = [
    { label: "0 - 2 Years", value: "0 - 2 Years", },
    { label: "2 - 5 Years", value: "2 - 5 Years", },
    { label: "More than 5 Years", value: "More than 5 Years", },
  ]

  public emailRegx =
    /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private journeyService: JourneyService,
    private tokenService: TokenService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      firstName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.pattern(/^[a-zA-Z ]+$/)]],
      lastName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.pattern(/^[a-zA-Z ]+$/)]],
      email: [null, [Validators.pattern(this.emailRegx)]],
      mobile: [null, [Validators.required, Validators.pattern(/^\d{10}$/)]],
      loanAmount: [null, Validators.required],
      turnover: [null, Validators.required],
      businessType: new FormControl(),
      businessPAN: new FormControl(),
      businessVintage: [null, Validators.required],
      businessName: [null, Validators.required],
      businessPincode: [null, [Validators.required, Validators.pattern(/^\d{6}$/)], [PincodeValidator.createValidator(this.journeyService)]]
    });

    this.tokenService.clearToken();
  }

  get formControl() {
    return this.form.controls;
  }

  get otpFormControl() {
    return this.otpForm.controls;
  }

  get panFormControl() {
    return this.panForm.controls;
  }

  getOtp(): void {

    if (this.form.invalid) {
      return;
    }
    this.isFormSubmitted = true;
    this.stagingJourney = new StagingJourney(this.form.value);

    if (!this.stagingJourney.businessType) {
      this.stagingJourney.businessType = 'Sole Proprietorship';
    }
    let headers: HttpHeaders = new HttpHeaders();
    let url = '/account/otpnew';
    this.isLoading = true;
    let service = this.http.post<any>('/account/otpnew', this.stagingJourney, { headers });
    service.subscribe({
      next: (response) => {
        if (response.success) {
          this.IsOtpAvailable = true;
          this.currentOtp = response.token;
          this.stagingJourney.id = response.stagingJourneyId;
          this.isNewUser = response.userCreated;
          this.isLoading = false;
          this.prepareOtpForm();
        } else {
          alert("something went wrong");
          this.isLoading = false;
        }
      },
      error: (error) => {
        alert("ERROR : something went wrong");
        this.isLoading = false;
      }
    })

  }

  login(): void {
    if (this.otpForm.invalid) {
      return;
    }

    let url = `/account/loginnew?otp=${this.otpForm.value.otp}&stagingJourneyId=${this.stagingJourney.id}&whatsappConsent=${this.otpForm.value.whatsappConsent}`;
    let headers: HttpHeaders = new HttpHeaders();
    this.isLoading = true;
    let service = this.http.post<any>(url, {}, { headers });
    service.subscribe({
      next: (response) => {
        if (response.success && response.token) {
          this.IsOtpSubmitted = true;
          this.tokenService.setToken(response.token)
          this.createBorrowerJourneyFromStagingJourney();
        } else {
          alert("something went wrong");
          this.isLoading = false;
        }
      },
      error: (error) => {
        alert("ERROR : something went wrong");
        this.isLoading = false;
      }
    })

  }

  submitPan(): void {
    this.isLoading = true;
    this.getGstDetails(this.panForm.value.businessPAN);
  }

  otpSubmitDisable(): boolean {
    return !this.otpForm.value.termsAndCondition;
  }

  businessTypeChange(evt): void {
    let businessType = evt.value;
    this.IsPanRequired = businessType == "Private Limited" || businessType == "Partnership" || businessType == "Limited Liability Partnership"
    if (this.IsPanRequired) {
      this.form.controls['businessPAN'].setValidators([Validators.required]);
    } else {
      this.form.controls['businessPAN'].setValidators(null);
    }
    this.form.controls['businessPAN'].updateValueAndValidity()
  }

  private prepareOtpForm(): void {
    this.otpForm = this.formBuilder.group({
      otp: [null, Validators.required],
      termsAndCondition: new FormControl(),
      whatsappConsent: new FormControl()
    });
    if (this.currentOtp) {
      this.otpForm.patchValue({
        otp: this.currentOtp
      });
    }
  }

  private createBorrowerJourneyFromStagingJourney(): void {
    this.journeyService.createBorrowerJourneyFromStagingJourney(this.stagingJourney.id).subscribe({
      next: (response) => {
        this.journey = response;
        console.log(this.journey);
        if (this.journey) {
          if (this.journey.disqualified) {
            this.isJourneyDisqulified = true;
            this.disqulifiedJourneyMessage = this.journey.disqualificationMessage;
            this.isLoading = false;
            this.case = this.journey.disqualificationKey;
            return;
          } else {
            this.getExperianDetails();
          }
        }
      },
      error: (error) => {
        alert("Error while creating borrower's journey");
      }
    });
  }


  private getExperianDetails(): void {
    this.journeyService.getExperianDetails(this.journey._id).subscribe({
      next: (response) => {
        console.log({ "Experian_Details": response });
        if (response.isSuccess) {
          let pan: string = "";
          if (response.status == null || response.bureauScore == 0) { //Experian Not Found
            if (this.stagingJourney.businessType === "Sole Proprietorship") {
              this.panForm = this.formBuilder.group({
                businessPAN: [null, Validators.required],
              });
              this.isPanRequiredForNotFOundExperian = true;
              this.isLoading = false;
              this.case = "Experian Not found."
              return;
            }
            pan = this.stagingJourney.businessPAN;
          }
          else if (response.status.toLowerCase() === "rejected") {
            //Experian failed
            let message = "We see that you do not have a bureau profile and that is required by our lender partners at the moment.";
            let data: any = { qualified: false, disqualified: true, disqualificationKey: 'experian', disqualificationMessage: message }
            this.updateThisJourney(data);
            return;
          }
          else {
            if (this.stagingJourney.businessType === "Sole Proprietorship") {
              pan = response.applicantDetails.pan;
            } else {
              pan = this.stagingJourney.businessPAN;
            }
            this.case = "Experian found."
          }
          this.getGstDetails(pan);
        }
      },
      error: (error) => {
        this.isJourneyDisqulified = true;
      }
    });
  }


  private getGstDetails(panNumber: string): void {
    if (panNumber) {
      this.journeyService.getGstDetailsByPan(panNumber).subscribe({
        next: (response) => {
          console.log({ "GST_Details": response });
          if (response && response.length > 0) {
            let gstDetails = response.find(e => e.isGstr3bCompliant === true);
            if (gstDetails && gstDetails.isGstr3bCompliant) {
              this.updateThisJourney({ qualified: true });
              return;
            } else {
              let message = `We're unable to help you get a business loan from our lender partners 
              as your business is currently too young. To fulfil your business funding needs, our lender partners 
              require a business age of more than 2-years, according to your GST records.`
              let data: any = { qualified: false, disqualified: true, disqualificationKey: 'gst', disqualificationMessage: message }
              this.updateThisJourney(data);
            }
          } else {
            if (this.journey.loanAmount == "Rs 1-5 Lakhs") {
              let message = `As you do not have a valid GST registration, 
              your business is currently not eligible for a loan from our lender partners. 
              We'll be able to help you once you have an active GST registration.`;
              let data: any = { qualified: false, disqualified: true, disqualificationKey: 'gst', disqualificationMessage: message }
              this.updateThisJourney(data);


            }
          }

        },
        error: (error) => {
          debugger;
          this.isLoading = false;
        }
      })
    } else {
      this.isLoading = false;
    }
  }

  private updateThisJourney(data: any) {
    this.isJourneyDisqulified = true;
    this.journeyService.updateJourney(this.journey._id, data).subscribe({
      next: (response) => {
        this.journey = response;
        if (this.journey.disqualified) {
          this.isJourneyDisqulified = true;
          this.disqulifiedJourneyMessage = this.journey.disqualificationMessage;
          this.case = this.journey.disqualificationKey;
        } else {
          this.isProcessComplete = true;
        }
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        alert("Error");
      }
    })
  }

}


export class StagingJourney {
  public constructor(init?: Partial<StagingJourney>) {
    Object.assign(this, init);
  }
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  loanAmount: string;
  turnover: string;
  businessType: string;
  businessPAN: string;
  businessVintage: string;
  businessName: string;
  businessPincode: string;
}

export class PincodeValidator {
  static createValidator(journeyService: JourneyService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return journeyService
        .isValidPincode(control.value)
        .pipe(
          map((result: any) =>
            result.isJammuKashmir ? { isJammuKashmir: true } : !result.valid ? { inValid: true } : result.valid
          )
        );
    };
  }
}