import { Component, Output, EventEmitter, OnInit, Input, ViewEncapsulation, AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReloginModalComponent } from '../../../relogin/relogin-modal.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { trigger, transition, animate, style, state } from '@angular/animations'

import { interval } from 'rxjs';
import { take, map, finalize } from 'rxjs/operators';
import 'rxjs/add/operator/map'
import { UtilityService } from '../../../services/utility.service';
import { setClarity } from '../../../utils/functions';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';

@Component({
  selector: 'app-gst-flow-login',
  templateUrl: './gst-flow-login.component.html',
  styleUrls: ['./gst-flow-login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(400, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class GstFlowLoginComponent implements OnInit, AfterViewInit {

  mobile: number;
  userOtp: string;
  validOtp: string = "Enter the OTP";
  otpRequested: boolean = false;
  hideResend = false;
  retryTime = 30;
  otpRetries = 1;
  partnerId: string = '';

  invalidOtp: boolean = false;
  token: string;
  borrower_consent: boolean = true;
  new_user: boolean = true;
  from_website: boolean = false;
  generate_otp: boolean = false;
  whatsapp_consent = true;

  @Output()
  loggedIn: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  @ViewChild('mobileOtpInput') mobileOtpInputRef: any;

  customOptions: OwlOptions = {
    center: true,
    loop: true,
    margin: 30,
    autoplay: true,
    autoplayTimeout: 3000,
    rewind: false,
    items: 1,
    autoHeight: true,
    autoWidth: true,
    nav: false
  };
  loading: boolean = false;
  constructor(private http: HttpClient,
    private tokenService: TokenService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private flowManager: FlowManagerService) {


  }

  ngAfterViewInit() {
    if (this.from_website) {
      document.getElementById('login-container').classList.add('show_all');
    }

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (!params || !params.loggedIn) {
        this.tokenService.clearToken();
      }

      if (params && params.from_website) {
        this.from_website = true;
        document.body.classList.add('hide_all');
      }

      if (params && params.generate_otp) {
        this.generate_otp = true;
      }
    });

    setClarity("page", "login");
  }

  otpRequest() {
    if ('OTPCredential' in window) {
      const abortController = new AbortController();
      let timer = setTimeout(() => {
        abortController.abort();
      }, 1 * 60 * 1000);

      let o: any = {
        otp: { transport: ['sms'] },
        signal: abortController.signal
      };

      window.navigator['credentials'].get(o).then((content) => {
        this.userOtp = content['code'];
        this.setOtp(this.userOtp);

        // if (this.borrower_consent) {
        //   this.login();
        // }
      });

    }
  }

  setOtp(userOtp) {
    if (this.mobileOtpInputRef) {
      this.userOtp = userOtp;
      this.mobileOtpInputRef.nativeElement.value = userOtp;
    }

    if (this.ngOtpInputRef && this.ngOtpInputRef.setValue) {
      this.ngOtpInputRef.setValue(userOtp);
    }
  }

  getOtp(resend?: boolean) {
    if (!this.mobile || this.mobile.toString().length != 10) {
      return;
    }
    this.invalidOtp = false;
    let headers: HttpHeaders = new HttpHeaders();
    let url = '/account/otp';

    url = `${url}?mobile=${this.mobile.toString()}&workflowName=${this.flowManager.workflowValue.key}`;
    if (this.generate_otp) {
      url += '&forceGenerate=true';
    }

    if (resend) {
      url += "&resend=true";
    }

    if (this.from_website) {
      url += "&fromWebsite=true";
    }

    url += `&journey=default`;

    if (this.partnerId) {
      url += `&partnerId=${this.partnerId}`;
    }

    this.hideResend = true;
    this.loading = true;

    this.http.get(url, { headers }).subscribe((response: any) => {
      this.scroll(document.body);
      if (response.success) {
        this.otpRequest();

        if (this.otpRequested) {
          this.otpRetries += 1;
        } else {
          this.otpRequested = true;
        }
        setTimeout(() => this.hideResend = false, 30000);
        this.retryTime = 30;
        interval(1000).pipe(
          take(this.retryTime)
        ).map(() => --this.retryTime).subscribe(() => undefined);

        if (!environment.production && !this.generate_otp) {
          this.validOtp = response.token;
        }
        this.new_user = response.userCreated;
        //this.borrower_consent = !response.userCreated;
        this.whatsapp_consent = response.userCreated;
        if (!this.from_website && !this.generate_otp) {
          setTimeout(() => {
            const ele = document.getElementsByClassName("otp-input")[0];
            (ele as HTMLInputElement).focus();
            if (!environment.production) {
              this.userOtp = this.validOtp;
              this.setOtp(this.userOtp);
            }
            (ele as HTMLInputElement).select();
          }, 20);
        }
      } else {
        const modalRef = this.modalService.open(ReloginModalComponent, { windowClass: 'relogin-dialog' });
        modalRef.componentInstance.title = "OTP failed";
        if (response.error) {
          modalRef.componentInstance.content = "The OTP could not be sent. " + response.error;
        } else {
          modalRef.componentInstance.content = "The OTP could not be sent. Please retry";
        }
        modalRef.componentInstance.showDismiss = true;
        modalRef.componentInstance.dismissText = "Retry";
        this.hideResend = false;

        setTimeout(() => {
          this.modalService.dismissAll();
        }, 5000);
      }

      this.loading = false;
    }, error => {
      this.hideResend = false;
      this.loading = false;
    });
  }


  login() {
    if (!this.borrower_consent) {
      return;
    }
    this.loading = true;
    this.http.get(`/account/login?mobile=${this.mobile.toString()}&otp=${this.userOtp}&isNewUser=${this.new_user}&workflowName=${this.flowManager.workflowValue.key}&whatsappConsent=${this.whatsapp_consent}`)
      .subscribe((response: any) => {
        if (response.success) {
          this.flowManager.mobileNumber = this.mobile.toString();
          this.flowManager.whatsappConsent = this.whatsapp_consent;
          if (this.partnerId) {
            UtilityService.setUserId(this.partnerId);
          } else {
            UtilityService.setUserId(this.mobile.toString());
          }
          this.token = response.token;
          this.tokenService.setToken(response.token);

          if (this.from_website) {
            var loc = new URLSearchParams(window.location.search.toString());
            loc.delete('from_website');
            loc.append('loggedIn', 'true');
            setTimeout(() => {
              window.top.location.href = `${environment.baseUrl}v2?${loc.toString()}`;
            }, 500);
          }
          else {
            this.flowManager.finish();
          }

        } else {
          this.invalidOtp = true;
          this.userOtp = null;
          this.validOtp = null;
          this.otpRequested = false;
          this.loading = false;
        }
      },
        err => this.loading = false);
  }

  setOtpField() {
    this.userOtp = "";
    document.querySelectorAll('input.digit-box').forEach(el => {
      this.userOtp += (el as HTMLInputElement).value;
    });
  }

  setDigits(otp: string) {
    document.querySelectorAll('input.digit-box').forEach(el => {
      let index = parseInt(el.id[el.id.length - 1]);
      (el as HTMLInputElement).value = otp[index - 1];
    });
    this.setOtpField();
  }

  getCodeBoxElement(index): HTMLElement {
    return document.getElementById('digit-' + index);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  scrollIfNotInViewPort(el: HTMLElement) {
    if (!this.isElementInViewport(el)) {
      this.scroll(el);
    }
  }

  isElementInViewport(el: HTMLElement) {

    var rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }

  onOtpChange(args) {
    this.userOtp = args;
  }
}

