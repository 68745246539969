import { FormControl, ValidationErrors } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";




export function currencyValidator(control: FormControl, field: FormlyFieldConfig, options: any): ValidationErrors {
  if (control.value) {
    if (isNaN(control.value) || control.value.toString().startsWith("00")) {
      return {
        'currency': { message: `Please enter a valid amount` }
      };
    }
  }
}
