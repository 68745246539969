import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';

@Component({
  selector: 'app-thank-you-step',
  templateUrl: './thank-you-step.component.html',
  styleUrls: ['./thank-you-step.component.css']
})
export class ThankYouStepComponent implements OnInit, AfterViewInit {
  starImg = "/img/CE_Stars.gif";

  stepGrid = [
    {
      img: `img/Step 1.svg`,
      header:`STEP 1`,
      text:`We assess your application within 1 working day and may call you to confirm certain details.`
    },
    {
      img: `img/Step 2.svg`,
      header: `STEP 2`,
      text: `We find the right loan for your business and call you to let you know.`
    },
    {
      img: `img/Step 3.svg`,
      header: `STEP 3`,
      text: `We deliver you the best loan.`
    }
  ]; 
  
  constructor(private flowManager:FlowManagerService, private modalService: NgbModal) { }
  

  ngOnInit(): void {
    this.flowManager.saveLocation().subscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.starImg ="/img/frame_02_delay-0.01s.gif" }, 5000);
  }

  test(content) {
    console.log("test");
    this.modalService.open(content).result.then((result) => { console.log(result) });
  }

}
