import { Component, Input, AfterViewInit, ViewEncapsulation, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { ResponseContentType } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../../services/token.service';
import { saveAs } from 'file-saver';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent implements OnInit, AfterViewInit {

  @Input()
  documentType: any;

  @Input()
  borrower: any;

  @Input()
  files: any[];

  @Input()
  fileOption: any;

  @ViewChild('docPond') docPond: any;

  @ViewChild('passwordSwal') private passwordSwal: SwalComponent;

  @Output()
  filesAdded = new EventEmitter<any[]>();

  @Output()
  fileRemoved = new EventEmitter<any>();

  showPasswordPopupTimeout: any;
  
  file_options: any[];
  selectedDocument: any;

  showPassword = false;
  password: string;
  sortComplete: boolean;
  documentUploadComplete: boolean;
 
  filesRequiringPassword:any[] = [];
  protectedFileNames: string;
  emitEventTimeout: any;

  pondOptions;
  
  @ViewChild('deleteSwal') private deleteSwal: SwalComponent;
  @ViewChild('deleteSuccessSwal') private deleteSuccessSwal: SwalComponent;

  constructor(private http: HttpClient, private router: Router, private tokenService: TokenService) {
    //this.tokenService.tokenInvalidated.subscribe(res => this.router.navigateByUrl(''));
  }

  ngOnInit(): void {
    

    this.pondOptions = {
      maxFileSize: '20MB',
      maxFiles: 10,
      instantUpload: true,
      allowMultiple: true,
      allowRevert: false,
  
      //allowFileTypeValidation: true,
      
      labelIdle: '<div class="text-center w-100"><i class="fa fa-upload"></i></div><span class="show-web">Click to select your file(s)</span><span class="show-mobile">Tap to select your file(s)</span>',
  
      labelMaxFileSizeExceeded: 'File is too large',
      labelMaxFileSize: 'Maximum file size is {filesize}',
  
      // set allowed file types with mime types
      acceptedFileTypes: [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/x-zip-compressed',
        //'application/rar',
        'application/vnd.ms-excel',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ],
  
      // // allows mapping of file types to label values ( how type is shown in error label )
      // // "null" means don't show
      fileValidateTypeLabelExpectedTypesMap: {
        'image/jpg': '.JPG',
        'image/jpeg': '.JPEG',
        'image/png': '.PNG',
        'application/pdf': '.PDF',
        'application/x-zip-compressed': '.ZIP',
        //'application/rar':'',
        'application/vnd.ms-excel': '.XLS',
        'application/msword': '.DOC',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.XLSX',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.DOCX'
      },
  
      // the label to show, {placeholders} are automatically replaced
      fileValidateTypeLabelExpectedTypes: 'Expects {allButLastType} or {lastType}',
  
      server: {
        //timeout: 30000,
        process: {
          url: "/journey/addfiles",
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.tokenService.getToken()}`
          },
          onload: (response) => {
            let nf = JSON.parse(response).files as any[];
            this.files = this.files.concat(nf);
  
            if (nf.length > 0 && nf[0].isProtected) {
              this.manageTimeout(nf);
            }
  
            let pondFiles = this.docPond.getFiles();
            pondFiles.forEach(pf => {
              let fileName = pf.file.name.replace(`.${pf.fileExtension}`, '').replace(/[^a-zA-Z0-9-/\[\]]/g, "_");
              if (nf.findIndex(f => f.fileName.endsWith(`.${pf.fileExtension}`) && f.fileName.substring(0, f.fileName.length - `.${pf.fileExtension}`.length - 16).endsWith(fileName)) >= 0)
                this.docPond.removeFile(pf);
            });
  
            this.filesAdded.emit(nf);
          },
          
          onerror: (response) => {
          },
  
          ondata: (formData) => {
            formData.append('document_id', this.fileOption.upload_folder);
            formData.append('DocumentUploadVersion', 'v1');
            if (this.borrower.journey && this.borrower.journey == 'dsa') {
              formData.append('mobile', this.borrower.userId);
            }
            formData.append('documentCategory', this.fileOption.value);
            if (this.password) {
              formData.append('password', this.password);
            }
            return formData;
          }
        },
      }
    };
  }

  

  manageTimeout(nf: any[]) {
    if (this.showPasswordPopupTimeout) {
      window.clearTimeout(this.showPasswordPopupTimeout);
    }
    this.filesRequiringPassword = this.filesRequiringPassword.concat(nf);
    this.showPasswordPopupTimeout = setTimeout(() => this.showPasswordPopup(), 5000);
  }

  showPasswordPopup() {
    this.protectedFileNames = '<ul style="text-align:left !important">' + this.filesRequiringPassword.map(f => `<li>${f.fileName}</li>`).join('') + '</ul>';
    this.passwordSwal.html = this.protectedFileNames;
    this.passwordSwal.showLoaderOnConfirm = true;
    setTimeout(() => this.passwordSwal.fire().then(result => {
      if (result.isConfirmed && result.value) {
        let fileIds = this.filesRequiringPassword.map(f => f.id).join('&fileIds=');
        this.http.get(`/journey/updatepassword?password=${result.value}&fileIds=${fileIds}`).subscribe(response => {
        });
      }
      this.filesRequiringPassword = [];
    }), 100);
    this.showPasswordPopupTimeout = null;
  }

  ngAfterViewInit() {
    if (this.documentType.file_types && Object.keys(this.documentType.file_types).length > 0) {
      this.pondOptions.acceptedFileTypes = Object.keys(this.documentType.file_types);
      this.pondOptions.fileValidateTypeLabelExpectedTypesMap = this.documentType.file_types;
    }

    if (this.documentType.maxFileSize) {
      this.pondOptions.maxFileSize = this.documentType.maxFileSize;
    }

    this.file_options = this.documentType.documents.map((doc) => ({
      text: doc.name,
      value: doc.doc_prepend ? doc.doc_prepend : doc.name,
      password: !!doc.password
    }));

    if (this.documentType.repeat_for) {
      let options = [];
      this.file_options.forEach(opt => {
        let option = JSON.parse(JSON.stringify(opt));
        option.text = `${option.text} - For ${this.borrower.first_name} ${this.borrower.last_name}`;
        option.value = `${option.value} - For ${this.borrower.first_name} ${this.borrower.last_name}`;
        option.group = `${this.borrower.first_name} ${this.borrower.last_name}`;
        options.push(option);
      });

      this.borrower[this.documentType.repeat_for].forEach(group => {
        this.file_options.forEach(opt => {
          let option = JSON.parse(JSON.stringify(opt));
          option.text = `${option.text} - For ${group[this.documentType.repeat_section_label]}`;
          option.value = `${option.value} - For ${group[this.documentType.repeat_section_label]}`;
          option.group = group[this.documentType.repeat_section_label];
          options.push(option);
        });
      });
      this.file_options = options;
    }
  }

  checkFileCategoryStatus() {
    if (this.documentType) {
      this.documentType.forEach(docType => {
        let file_options = docType.documents.map((doc) => ({
          text: doc.name,
          value: doc.doc_prepend ? doc.doc_prepend : doc.name,
          required: !!doc.required
        }));

        if (docType.repeat_for) {
          let options = [];

          file_options.forEach(opt => {
            let option = JSON.parse(JSON.stringify(opt));
            option.text = `${option.text} - For ${this.borrower.first_name} ${this.borrower.last_name}`;
            option.value = `${option.value} - For ${this.borrower.first_name} ${this.borrower.last_name}`;
            option.group = `${this.borrower.first_name} ${this.borrower.last_name}`;
            options.push(option);
          });

          this.borrower[docType.repeat_for].forEach(group => {
            file_options.forEach(opt => {
              let option = JSON.parse(JSON.stringify(opt));
              option.text = `${option.text} - For ${group[docType.repeat_section_label]}`;
              option.value = `${option.value} - For ${group[docType.repeat_section_label]}`;
              option.group = group[docType.repeat_section_label];
              options.push(option);
            });
          });
          file_options = options;
        }

        file_options.sort((x, y) => (x.required === y.required) ? 0 : x.required ? -1 : 1);

        docType.pendingFilesCount = file_options.filter(option => this.files.findIndex(f => f.documentType == docType.document_id && (f.documentCategory == option.value || f.documentCategory == option.text)) < 0 && option.required).length;
        docType.options = file_options;
        docType.requiredFilesCount = file_options.filter(option => option.required).length;
      });
      this.documentUploadComplete = this.documentType.filter(docType => !docType.eval_is_optional && docType.pendingFilesCount > 0).length == 0;
      this.sortComplete = true;
    }
  }
  deleteFileConfirmation(file) {
    //this.files = file;
    this.selectedDocument = file;
    this.deleteSwal.fire();
  }

  deleteFile(file) {
    this.http.delete(`/journey/deletefile?id=${file.id}`).subscribe((response: any) => {
        this.files.splice(this.files.findIndex(c => c.id == file.id), 1);
        this.fileRemoved.emit(file.id);
        this.deleteSuccessSwal.fire();
    });
    
  }

  downloadFile(file) {
    this.http.get(`/journey/downloadfile?id=${file.id}`, { responseType : 'blob' }).subscribe((blob : Blob) => {
      var url = window.URL.createObjectURL(blob);
      saveAs(blob, file.fileName);
      //window.open(url);
    });
  }

  documentChanged(event) {
    this.password = null;
    if (event) {
      if (event.password) {
        this.showPassword = true;
      } else {
        this.showPassword = false;
      }
    }
  }

  showFileName(str: string) {
    let n = 25;
    if (str.indexOf(']_') > 0) {
      str = str.substr(str.indexOf(']_') + 2);
    }
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
  }

  isMobile() {
    return {
      Android: () => {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: () => {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: () => {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: () => {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: () => {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: () => {
        return (this.isMobile().Android() || this.isMobile().BlackBerry() || this.isMobile().iOS() || this.isMobile().Opera() || this.isMobile().Windows());
      }
    }
  };
}

