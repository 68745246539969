import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";

const fields:(flowManager: FlowManagerService) => FormlyFieldConfig[] = (flowManager:FlowManagerService) => (
    [{
        key: "itr",
            type: "question",
            modelOptions:{
                updateOn:'change'
            },
            templateOptions: {
                
                label: "Have you filed ITR for more than 2 years?",                    
                
                options: [{"label":"Yes","value":"Yes"},{"label":"No","value":"No"}],
                required: true,
            },
            hooks: {
                onInit: ({ formControl, key }) => {
                    let lastValue = flowManager.modelValue[key as string];
                    formControl.valueChanges.subscribe(value => {
                        if(lastValue== value || flowManager.loadingValue) return;
                        lastValue = value;
                        console.log(value)
                        const data = {};
                        const name = key as string;
                        data[name] = value;
                        itr.next(data, flowManager).subscribe();
                    })
                }
            },
            className: "col-12 col-sm-12",
    }]
)

const itr:Step ={
    key: "itr",
    
    fields,

    
    conversation: (model) => {
        return {
            greeting: ``,
            message: `Just a couple more questions to go...`
        }
    },

    skipOn: (model) => {
        return model.loanamount=="Rs 1-5 Lakhs" || model.loanamount=="Rs 5-10 Lakhs"
    },    

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        
        
        const model = flowManager.modelValue;
        const hasNoItr = model.itr=="No";
        const isSecured = model.typeofloan=="Secured";
        const isAbove10 = model.loanamount!="Rs 1-5 Lakhs" && model.loanamount != "Rs 5-10 Lakhs";
        if( hasNoItr &&
            (
                isSecured ||
                isAbove10
            )
        ) 
        {
            data = { 
                ...data, 
                disqualified: true, 
                disqualificationKey: `itr`, 
                disqualificationMessage:`Your profile isn't a good match for our lenders. 
                Based on your input; it seems that your business is non-compliant on statutory obligations.
                ` }       
        }
        flowManager.startLoading()
        return flowManager.journey.updateJourney(flowManager.modelValue._id, {...data, location: flowManager.location}).pipe(
            
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap((result) => {
                if(result.disqualified) {
                    flowManager.goto(workFlow.disQualifiedMessage.key);
                }
                else {
                    flowManager.stepNext();
                }                
            }),
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);
            }),
            tap(_=>flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },

};


export default itr;
    