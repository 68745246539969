import { FormlyCheckboxInput } from "./components/bootstrap-checkbox/formly-checkbox-input.component";
import { FormlyCurrencyInput } from "./components/bootstrap-currency/formly-currency-input.component";
import { FormlyInputMaskComponent } from "./components/bootstrap-mask/formly-input-mask.component";
import { FormlyPincodeInput } from "./components/bootstrap-pincode/formly-pincode-input.component";
import { FormlyQuestionComponent } from "./components/bootstrap-question/formly-question.component";
import { FormlySelectInput } from "./components/bootstrap-select/formly-select-input.component";
import { FormlySwitchInput } from "./components/bootstrap-switch/formly-switch-input.component";
import { RepeatTypeComponent } from "./components/repeating-section/repeating-section.type";
import { FieldsetWrapper } from "./wrappers/fieldset-wrapper.component";
import { LabelWrapper } from "./wrappers/label-wrapper.component";
import { maxDateValidator } from "./validators/max-date.validator";
import { minDateValidator } from "./validators/min-date.validator";
import { maxLengthValidator } from "./validators/max-length.validator";
import { minLengthValidator } from "./validators/min-length.validator";
import { maxValidator } from "./validators/max.validator";
import { minValidator } from "./validators/min.validator";
import { regexValidator } from "./validators/regex.validator";
import { currencyValidator } from "./validators/currency.validator";
import { dateValidator } from "./validators/date.validator";
import { FormlyDateInputComponent } from "./components/bootstrap-dateinput/formly-date-input.component";
import { FormlyDatePickerComponent } from "./components/bootstrap-datepicker/formly-date-picker.component";
import { emailValidator } from "./validators/email.validator";
import { PanelWrapperComponent } from "./wrappers/panel-wrapper.component";
import { nameValidator } from "./validators/name.validator";

const formlyConfig = {   
    types: [
      {
        name: 'date',
        component: FormlyDateInputComponent,
        wrappers: ['form-field'],
      },
      {
        name: 'datepicker',
        component: FormlyDatePickerComponent,
        wrappers: ['label', 'fieldset'],
      },
      {
        name: 'question',
        component: FormlyQuestionComponent,
        wrappers: ['label', 'fieldset'],
      },
      {
        name: 'currency',
        component: FormlyCurrencyInput,
        wrappers: ['label', 'fieldset'],
      },
      {
        name: 'pincode',
        component: FormlyPincodeInput,
        wrappers: ['label', 'fieldset'],
      },
      {
        name: 'checkbox',
        component: FormlyCheckboxInput,
        wrappers: [],
      },
      {
        name: 'select',
        component: FormlySelectInput,
        wrappers: ['label', 'fieldset'],
      },
      {
        name: 'switch',
        component: FormlySwitchInput,
        wrappers: ['label'],
      },
      {
        name: 'mask',
        component: FormlyInputMaskComponent,
        wrappers: ['label', 'fieldset'],
      },
      { 
        name: 'repeat', 
        component: RepeatTypeComponent,
      },
    ],
    wrappers: [
      { name: 'label', component: LabelWrapper },
      { name: 'fieldset', component: FieldsetWrapper },
      { name: 'panel', component: PanelWrapperComponent }
    ],
    validators: [
      {
        name: 'min-number',
        validation: minValidator,
      },
      {
        name: 'max-number',
        validation: maxValidator,
      },
      {
        name: 'min-length',
        validation: minLengthValidator,
      },
      {
        name: 'max-length',
        validation: maxLengthValidator,
      },
      {
        name: 'max-length-name',
        validation: maxLengthValidator,
        options: { max: 50 },
      },
      {
        name: 'min-date',
        validation: minDateValidator,
      },
      {
        name: 'max-date',
        validation: maxDateValidator,
      },
      {
        name: 'regex',
        validation: regexValidator,
      },
      {
        name: 'date',
        validation: dateValidator,
      },
      {
        name: 'currency',
        validation: currencyValidator,
      },
      {
        name:'email',
        validation: emailValidator
      },
      {
        name:'name',
        validation: nameValidator
      }
    ]
  };
  
  export default formlyConfig;
