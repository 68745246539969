import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileHasError'
})
export class FileHasErrorPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return value && value.error && value.error.length > 0;
  }

}
