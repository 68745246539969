import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-search-loader',
  templateUrl: './search-loader.component.html',
  styleUrls: ['./search-loader.component.css']
})
export class SearchLoaderComponent implements OnInit {


  owlOptions: OwlOptions;

  itemWidth: number = 200;

  banks = [
    'bajaj.jpg',
    'dbs.jpg',
    'deutsche-bank.jpg',
    'efl.jpg',
    'icici-bank.jpg',
    'idfc-first-bank.jpg',
    'indifi.jpg',
    'inditrade.jpg',
    'intellegrow.jpg',
    'kotak-mahindra-bank.jpg',
    'profectus-capital.jpg',
    'ugro.jpg',
    'ujjivan.jpg',
    'ziploan.jpg'
  ];

  ngOnInit() {

    this.owlOptions = {
      center: true,
      loop: true,
      margin: 30,
      autoplay: true,
      autoplayTimeout: 1200,
      rewind: false,
      dots: false,
      autoWidth: true,
      responsive: { 
        0: { items: 3, nav: false, dots: false, }, 
        600: { items: 3, nav: false, dots: false, }, 
        1000: { items: 5, nav: false, dots: false, } 
      }
    };
  }
}

