import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ComponentWrap } from 'app/layouts/workflow';

import { LoginComponent               } from "app/layouts/components/login/login.component"                                                   ;
import { ExperianStepComponent        } from "app/layouts/components/experian-step/experian-step.component"                ;
import { SearchingLenderStepComponent } from "app/layouts/components/searching-lender-step/searching-lender-step.component";
import { QualifiedStepComponent       } from "app/layouts/components/qualified-step/qualified-step.component"              ;
import { DisqualifiedStepComponent    } from "app/layouts/components/disqualified-step/disqualified-step.component"        ;
import { DocumentUploadStepComponent  } from "app/layouts/components/document-upload-step/document-upload-step.component"  ;
import { FlipkartDocumentUploadStepComponent } from "app/layouts/components/flipkart-document-upload-step/flipkart-document-upload-step.component";
import { FlipkartLoginComponent } from "app/layouts/components/flipkart-login/flipkart-login.component";
import { FlipkartBobLoginComponent } from "app/layouts/components/flipkart-bob-login/flipkart-bob-login.component";
import { LoadingStepComponent } from '../loading-step/loading-step.component';
import { ExperainPhoneVerificationStepComponent } from '../experain-phone-verification-step/experain-phone-verification-step.component';
import { BusinessSelectionStepComponent } from '../business-selection-step/business-selection-step.component';
import { GstFlowLoginComponent } from '../gst-flow-login/gst-flow-login.component';
import { NsdlLoaderStepComponent } from '../nsdl-loader-step/nsdl-loader-step.component';
import { GstrLoaderStepComponent } from '../gstr-loader-step/gstr-loader-step.component';
import { DocumentUploadModernStepComponent } from '../document-upload-modern-step/document-upload-modern-step.component';
import { ThankYouStepComponent } from '../thank-you-step/thank-you-step.component';

const componentMaps = {
  'LoginComponent': LoginComponent,
  'ExperianStepComponent': ExperianStepComponent,
  'SearchingLenderStepComponent': SearchingLenderStepComponent,
  'QualifiedStepComponent': QualifiedStepComponent,
  'DisqualifiedStepComponent': DisqualifiedStepComponent,
  'DocumentUploadStepComponent': DocumentUploadStepComponent,
  'FlipkartDocumentUploadStepComponent':FlipkartDocumentUploadStepComponent,
  'FlipkartLoginComponent':FlipkartLoginComponent,
  'FlipkartBobLoginComponent': FlipkartBobLoginComponent,
  'LoadingStepComponent': LoadingStepComponent,
  'ExperainPhoneVerificationStepComponent': ExperainPhoneVerificationStepComponent,
  'BusinessSelectionStepComponent': BusinessSelectionStepComponent,
  'GstFlowLoginComponent': GstFlowLoginComponent,
  'NsdlLoaderStepComponent': NsdlLoaderStepComponent,
  'GstrLoaderStepComponent': GstrLoaderStepComponent,
  'DocumentUploadModernStepComponent': DocumentUploadModernStepComponent,
  'ThankYouStepComponent': ThankYouStepComponent,
}

@Directive({
  selector: '[appDynamicContent]'
})
export class DynamicContentDirective {
  @Input() set appDynamicContent (component) {
    this.loadComponent(component);
  };

  @Input() stepper:MatStepper;

  async loadComponent(component) {
    if(component == 'LoginComponent') {
      componentMaps[component] = (await import("app/layouts/components/login/login.component")).LoginComponent;      
    }
    if(component == 'GstFlowLoginComponent') {
      componentMaps[component] = (await import("app/layouts/components/gst-flow-login/gst-flow-login.component")).GstFlowLoginComponent;
    }
    this.viewContainerRef.clear();
    const ctxInjector = this.viewContainerRef.injector;
    const componentRef = this.viewContainerRef.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(componentMaps[component]), 0,  ctxInjector);
 
  }

  constructor(public viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) { 
    

  }

}
