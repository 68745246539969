import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormlyCheckboxInput } from "./components/bootstrap-checkbox/formly-checkbox-input.component";
import { FormlyCurrencyInput } from "./components/bootstrap-currency/formly-currency-input.component";
import { FormlyInputMaskComponent } from "./components/bootstrap-mask/formly-input-mask.component";
import { FormlyPincodeInput } from "./components/bootstrap-pincode/formly-pincode-input.component";
import { FormlyQuestionComponent } from "./components/bootstrap-question/formly-question.component";
import { FormlySelectInput } from "./components/bootstrap-select/formly-select-input.component";
import { FormlySwitchInput } from "./components/bootstrap-switch/formly-switch-input.component";
import { RepeatTypeComponent } from "./components/repeating-section/repeating-section.type";
import { FormlyDateInputComponent } from "./components/bootstrap-dateinput/formly-date-input.component";
import { FormlyDatePickerComponent } from "./components/bootstrap-datepicker/formly-date-picker.component";
import { FieldsetWrapper } from './wrappers/fieldset-wrapper.component';
import { LabelWrapper } from './wrappers/label-wrapper.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { NgxMaskModule } from 'ngx-mask';
import { FormlyModule } from '@ngx-formly/core';
import formlyConfig from './formly.config';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PanelWrapperComponent } from './wrappers/panel-wrapper.component';

@NgModule({
  declarations: [
    FormlyCheckboxInput,
    FormlyCurrencyInput,
    FormlyInputMaskComponent,
    FormlyPincodeInput,
    FormlyQuestionComponent,
    FormlySelectInput,
    FormlySwitchInput,
    RepeatTypeComponent,
    FormlyDateInputComponent,
    FormlyDatePickerComponent,
    //wrappers
    FieldsetWrapper,
    LabelWrapper,    
    PanelWrapperComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    FormlySelectModule,
    NgSelectModule,
    FormlyModule.forChild(formlyConfig),    
    NgxMaskModule.forChild(),
    UiSwitchModule,
  ],
  exports:[
    FormlyCheckboxInput,
    FormlyCurrencyInput,
    FormlyInputMaskComponent,
    FormlyPincodeInput,
    FormlyQuestionComponent,
    FormlySelectInput,
    FormlySwitchInput,
    RepeatTypeComponent,
    FormlyDateInputComponent,
    FormlyDatePickerComponent,
    //wrappers
    FieldsetWrapper,
    LabelWrapper,
    PanelWrapperComponent,
  ]
})
export class BorrowerJourneyFormlyModule { }
