import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";

const fields: (flowManager: FlowManagerService) => FormlyFieldConfig[] = (flowManager: FlowManagerService) => (
    [{
        key: "turnover",
        type: "question",
        modelOptions: {
            updateOn: 'change'
        },
        templateOptions: {

            label: "What is the annual gross sales revenue of your business?",

            options: [
                { "label": "Less than ₹12 Lacs", "value": "Less than INR 12 Lakhs" },
                { "label": "₹12 - ₹15 Lacs", "value": "INR 12 Lakhs - INR 15 Lakhs" },
                { "label": "₹15 - ₹50 Lacs", "value": "INR 15 Lakhs - INR 50 Lakhs" },
                { "label": "More than ₹50 Lacs", "value": "More than INR 50 Lakhs" }],
            required: true,
        },
        hooks: {
            onInit: ({ formControl, key }) => {
                let lastValue = flowManager.modelValue[key as string];
                formControl.valueChanges.subscribe(value => {
                    if(lastValue== value || flowManager.loadingValue) return;
                    lastValue = value;
                    console.log(value)
                    const data = {};
                    const name = key as string;
                    data[name] = value;
                    turnover.next(data, flowManager).subscribe();
                })
            }
        },
        className: "col-12 col-sm-12",


    }]
)

const turnover: Step = {
    key:"turnover",
    
    fields,


    conversation: (model) => {
        return {
            greeting: ``,
            message: `Now let's check your revenue<i class='d-block sub-header'>(We ask this to see if your business can support the loan you want)</i>`
        }
    },


    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        
        
        const model = flowManager.modelValue;
        if (model.turnover == "Less than INR 12 Lakhs") {
            data = {
                ...data,
                disqualified: true,
                disqualificationKey: `turnover`,
                disqualificationMessage: `Your profile isn't a good match for our lender partners. 
                Your turnover isn't sufficient to support repayment of the loan you are looking for.  
                ` }
        }
        flowManager.startLoading()
        return flowManager.journey.updateJourney(flowManager.modelValue._id, { ...data, location: flowManager.location }).pipe(

            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap((result) => {
                if (result.disqualified) {
                    flowManager.goto(workFlow.disQualifiedMessage.key);
                }
                else {
                    flowManager.stepNext();
                }
            }),
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);
            }),
            tap(_ => flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },

};


export default turnover;
