import { FormControl, ValidationErrors } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";




export function minLengthValidator(control: FormControl, field: FormlyFieldConfig, options: any): ValidationErrors {
  if (control.value) {
    return control.value.length >= parseInt(options.min) ? null : {
      'minLength': { message: `${field.templateOptions.label} needs to be at least ${options.min} characters long.` }
    };
  }
}
