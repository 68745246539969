import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-switch-input',
  template: `
    <div class="d-inline-block ml-2">
      <ui-switch (change)="onChange($event)"
        [formlyAttributes]="field"
        [checkedLabel]="to.options[0].label"
        [uncheckedLabel]="to.options[1].label"
        [checked]="to.defaultValue"
      ></ui-switch>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlySwitchInput extends FieldType implements OnInit {
  get type() {
    return this.to.type || 'switch';
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.to['defaultValue']) {
        this.onChange(true);
      }
    });
  }

  onChange(event) {
    if (event) {
      this.formControl.setValue(this.to.options[0].value);
    } else {
      this.formControl.setValue(this.to.options[1].value);
    }
  }
}
