import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs";
import { catchError, delay, finalize, concatMap, retry, tap } from "rxjs/operators";
import workFlow from ".."
import { Step } from "../..";

const fields: (flowManager: FlowManagerService) => FormlyFieldConfig[] = (flowManager: FlowManagerService) =>
    [
        {
            key: 'businesstype',
            type: 'question',
            modelOptions: {
                updateOn: 'change'
            },
            templateOptions: {
                label: "What is your Business Type?",
                options: [
                    { label: 'Private Limited', icon: 'pvt-limited-icon', value: 'Private Limited' },
                    { label: 'Sole Proprietorship', icon: 'Sole-Proprietorship-icon', value: 'Sole Proprietorship' },
                    { label: 'Partnership', icon: 'Partnership-icon', value: 'Partnership' },
                    { label: 'Limited Liability Partnership (LLP)', icon: 'LLP-icon', value: 'Limited Liability Partnership' },
                    { label: 'Trading but not Registered', icon: 'NA-icon', value: 'Unregistered' },
                ],
                required: true,

            },
            hooks: {
                onInit: ({ formControl, key }) => {
                    let lastValue = flowManager.modelValue[key as string];
                    formControl.valueChanges.subscribe(value => {
                        if(lastValue== value || flowManager.loadingValue) return;
                        lastValue = value;
                        console.log(value)
                        const data = {};
                        const name = key as string;
                        data[name] = value;
                        businessType.next(data, flowManager).subscribe();
                    })
                }
            },
            className: 'col-12'
        }
    ]


const businessType: Step = {
    key:"businessType",
    
    fields,

    conversation: (model) => ({
        greeting: `Nice to meet you, ${model.first_name}`,
        message: `Now let's check your eligibility.
        <i class='d-block sub-header'>(This should only take 2 minutes)</i>
        `
    }),

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {        
        if (data.businesstype == "Unregistered") {
            data = {
                ...data,
                disqualified: true,
                disqualificationKey: `businesstype`,
                disqualificationMessage: `Your profile isn't a good match for our lender partners. 
                Your business isn't registered. If you register your business you will improve your chances 
                of getting a loan in the future.` }
        }
        flowManager.startLoading()
        const payload = { ...data, location: flowManager.location };
        
        return flowManager.journey.updateJourney(flowManager.modelValue._id, payload).pipe(
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap((result) => {
                if (result.disqualified) {
                    flowManager.goto(workFlow.disQualifiedMessage.key);
                }
                else {
                    flowManager.stepNext();
                }
            }),
            tap(_ => flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);
            }),
        )
    },
};

export default businessType;

