import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { delay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-flipkart-step',
  templateUrl: './flipkart-step.component.html',
  styleUrls: ['./flipkart-step.component.css']
})
export class FlipkartStepComponent implements OnInit, AfterViewInit {
  @Output() next = new EventEmitter();
  @Input() isSaving = false;
  @Input() form: FormGroup;
  invalid = true;

  constructor() { }

  ngAfterViewInit(): void {
    if (this.form) {
      setTimeout(()=> this.invalid = this.form.invalid);
      this.form.statusChanges
        .pipe(
          delay(250),
          tap(_ => this.invalid = !this.form.valid),
        )
        .subscribe()
    }
  }

  ngOnInit(): void {
  }

}
