import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import categories, { getValOrParseFunc } from 'app/layouts/constants/document-categories.modern.constant';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import rm1Workflow from 'app/layouts/workflow/rm1';
import testWorkFlow from 'app/layouts/workflow/test';
import { TokenService } from 'app/services/token.service';
import { of, pipe } from 'rxjs';
import { tap, switchMap, catchError, delay, first, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { mapLocation } from '../../../constants/mapLocation';
import navigate from '../../utils/handle-navigation';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TestComponent implements OnInit {

  
  workflow = testWorkFlow
  params: any;

  constructor(private flowManagerService: FlowManagerService,
    private tokenService: TokenService,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit(): void {
    const data = categories.map(category=>({
      name:category.name,
      steps: category.steps.map(step=>({
        name:getValOrParseFunc(step.name, this.flowManagerService.modelValue),
        sections: step.sections.map(section=> ({
          name:section.name,
          documents: (Array.isArray(section.documents)?section.documents:section.documents({})).map(document=>({
            name:document.name,
            acceptedFileTypes:document.acceptedFileTypes,
          }))
        }))
      }))
    }));
    console.log(JSON.stringify(data));


    this.flowManagerService.start(this.workflow);

    this.tokenService.token$.pipe(
      switchMap(token => {
        if (token) {
          this.flowManagerService.modelValue = { journey: 'default' };
          return this.flowManagerService.journey.getJourney(this.params).pipe(catchError(err => {
            return of({
              ...this.flowManagerService.modelValue,
              location: null,
              status: err.status
            });
          }))
        }
        else {
          //window.location.href = `${environment.baseUrl}`;
        }
      })
      ,
      delay(0),
      tap((data) => this.flowManagerService.modelValue = { ...data })
    ).subscribe();
  }

}
