import { FormControl } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout, startWith, map, takeUntil } from "rxjs/operators"
import gstJourneyWorkflow from "..";
import workFlow from ".."
import { Step } from "../..";

const stateOptions = [
    //{ label:'JAMMU AND KASHMIR', value:1 },
    { label:'HIMACHAL PRADESH', value:2 },
    { label:'PUNJAB', value:3 },
    { label:'CHANDIGARH', value:4 },
    { label:'UTTARAKHAND', value:5 },
    { label:'HARYANA', value:6 },
    { label:'DELHI', value:7 },
    { label:'RAJASTHAN', value:8 },
    { label:'UTTAR PRADESH', value:9 },
    { label:'BIHAR', value:10 },
    { label:'SIKKIM', value:11 },
    { label:'ARUNACHAL PRADESH', value:12 },
    { label:'NAGALAND', value:13 },
    { label:'MANIPUR', value:14 },
    { label:'MIZORAM', value:15 },
    { label:'TRIPURA', value:16 },
    { label:'MEGHALAYA', value:17 },
    { label:'ASSAM', value:18 },
    { label:'WEST BENGAL', value:19 },
    { label:'JHARKHAND', value:20 },
    { label:'ODISHA', value:21 },
    { label:'CHATTISGARH', value:22 },
    { label:'MADHYA PRADESH', value:23 },
    { label:'GUJARAT', value:24 },
    { label:'DADRA AND NAGAR HAVELI AND DAMAN AND DIU', value:26 },
    { label:'MAHARASHTRA', value:27 },
    { label:'ANDHRA PRADESH', value:28 },
    { label:'KARNATAKA', value:29 },
    { label:'GOA', value:30 },
    { label:'LAKSHADWEEP', value:31 },
    { label:'KERALA', value:32 },
    { label:'TAMIL NADU', value:33 },
    { label:'PUDUCHERRY', value:34 },
    { label:'ANDAMAN AND NICOBAR ISLANDS', value:35 },
    { label:'TELANGANA', value:36 },
    { label:'ANDHRA PRADESH', value:37 },
    { label:'LADAKH', value:38 },
  ].sort((x,y)=> y.label > x.label ? -1:1);

const fields = (flowManager: FlowManagerService): Array<FormlyFieldConfig> =>
    [
        {
           
            fieldGroupClassName: 'row',
            wrappers: ['panel'],
            templateOptions: {
                label:`Main promoter or director's name`,
                description:`Whose mobile number is ${flowManager.mobileNumber}`
            },
            fieldGroup: [
                
                {
                    key: 'first_name',
                    type: 'input',
                    modelOptions: {
                        updateOn: 'change',
                    },
                    templateOptions: {
                        type: 'text',
                        label: `First Name`,
                        placeholder: 'eg. Lakshmi',
                        required: true,
                        min: 1,
                        max: 50,
                        regex: "(^[a-zA-Z ]+$)",
                    },                 
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase().trim()),
                            tap(v => v == formControl.value? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: 'col-6'
                },
                {
                    key: 'last_name',
                    type: 'input',
                    templateOptions: {
                        type: 'text',
                        label: 'Last Name',
                        placeholder: 'eg. Patel',
                        required: true,
                        min: 1,
                        max: 50,
                        regex: "(^[a-zA-Z ]+$)"
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase().trim()),
                            tap(v => v == formControl.value? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: 'col-6'
                },
                {
                    key: 'email',
                    type: 'input',
                    templateOptions: {
                        type: 'email',
                        label: 'Email',
                        placeholder: 'eg. lakshmi.patel@xyz.com',
                        required: true,
                        min: 5,
                        max: 100,
                        regex: "^(([^<>()\\[\\]\\\\.`;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.`;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1`3}\\.[0-9]{1`3}\\.[0-9]{1`3}\\.[0-9]{1`3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2`}))$"
                    },
                    validators: {
                        validation: ['email'],
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toLowerCase().trim()),
                            tap(v => v == formControl.value? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },
                    className: 'col-12',
                },
                          
            ]
        },

    ]

const promoterDetails: Step = {
    key:"pan_with_loan",
    
    fields,

    hideBack: false,

    conversation: (model) => ({
        greeting: `Welcome to CreditEnable. I'm Lakshmi.`,
        message: `I am here to help you find the right business loan.
        Don't worry, this should only take a few minutes
        `
    }),

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        flowManager.startLoading()
        const payload = { ...data, whatsappconsent: flowManager.whatsappConsent, location: flowManager.location, ...flowManager.modelValue };


     
        return flowManager.journey.createJourney(payload).pipe(  
            catchError(err => {
                if (err.error.includes("Borrower already exist")) {
                    return flowManager.journey.updateJourney(flowManager.modelValue._id, payload);
                }
                else {
                    flowManager.throwError(err);
                    return throwError(err);
                }                
            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap(() => {
                flowManager.finish();
            }),
            tap((result)=>  flowManager.gtmTriggerService.triggerButtonClick({ className: "stage-complete stage-1"})),           
            tap(_=>flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },
}

export default promoterDetails;
