import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { FileManagerService } from 'app/layouts/services/file-manager/file-manager';
import { saveAs } from 'file-saver';
import { delay } from 'rxjs/operators';
import { ZipFileInfoComponent } from '../zip-file-info/zip-file-info.component';

@Component({
  selector: 'app-file-actions',
  templateUrl: './file-actions.component.html',
  styleUrls: ['./file-actions.component.css']
})
export class FileActionsComponent implements OnInit, AfterViewInit {
  selectedDocument: any;
  swalPasswordMessage = ``;
  @Input() file;

  @ViewChild('deleteSwal') private deleteSwal: SwalComponent;
  @ViewChild('deleteSuccessSwal') private deleteSuccessSwal: SwalComponent;
  @ViewChild('passwordSwal') private passwordSwal: SwalComponent;

  constructor(private http: HttpClient, public dialog: MatDialog, private fileManager: FileManagerService) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    if (this.file.isProtected && this.file.isNew) {
      this.showPasswordPopup(this.file);
    }
  }

  deleteFileConfirmation(file) {
    //this.files = file;
    this.selectedDocument = file;
    this.deleteSwal.fire();
  }

  deleteFile(file) {
    const fileIndex = this.fileManager.filesValue.findIndex($file => file.id == $file.id);
    this.fileManager.removeFile(file);

    this.http.delete(`/journey/deletefile?id=${file.id}`).subscribe((response: any) => {
      //this.fileManager.removeFile(file);
    }, err => {
      const files = this.fileManager.filesValue;
      files.splice(fileIndex, 0, file);
      this.fileManager.setFiles(files);
    });

  }

  downloadFile(file) {
    var windowReference = window.open();
    this.http.get(`/journey/downloadfile?id=${file.id}`, { responseType: 'blob' }).subscribe((blob: Blob) => {
      var url = window.URL.createObjectURL(blob);
      windowReference.location.assign(url); 
    });
   
  }



  openInfo(file) {
    const ref = this.dialog.open(ZipFileInfoComponent, {
      width: '80%',
      disableClose: false,
      hasBackdrop: true
    });
    ref.componentInstance.file = file;
  }

  showPasswordPopup(file) {
    this.swalPasswordMessage = file.fileName;
    this.passwordSwal.showLoaderOnConfirm = true;
    setTimeout(() => this.passwordSwal.fire().then(result => {
      if (result.isConfirmed && result.value) {
        let data = {
          fileId: file.id,
          password: result.value
        }
        this.http.post<any>(`/journey/UpdateFilePassword`, data).subscribe(data => {
          if (data.isProtected) {
            this.showPasswordPopup(file);
          }
          else {
            this.fileManager.updateFile($file => $file.id == data.id, $file => {
              $file.isProtected = data.isProtected,
              $file.isPasswordValid = data.isPasswordValid
            });
          }
        }, err => {
          console.log(err);
        });
      }
      else if (result.isConfirmed && !result.value) { this.showPasswordPopup(file); }
    }), 100);

  }

}
