import { FormControl } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout, startWith, map, takeUntil } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";

const fields = (flowManager: FlowManagerService): Array<FormlyFieldConfig> =>
    [
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                
                {
                    key: 'loanamount',
                    type: 'question',
                    modelOptions: {
                        updateOn: 'change',
                    },
                    templateOptions: {
                        label: "What loan amount do you need?",
                        options: [
                            { label: "1 - 5 Lacs", value: "Rs 1-5 Lakhs", },
                            { label: "6 - 10 Lacs", value: "Rs 5-10 Lakhs", },
                            { label: "11 - 20 Lacs", value: "Rs 10-20 Lakhs", },
                            { label: "21 - 50 Lacs", value: "Rs 20-50 Lakhs", },
                            { label: "51 Lacs - 1 Cr", value: "Rs 50 Lakhs - 1 Crore" },
                            { label: "More than 1 Cr", value: "Rs 1-3 Crores", }
                        ],
                        required: true,
                    },
                    className: 'col-12'
                },    
                
                {
                    key: 'typeofloan',
                    type: 'question',
                    modelOptions: {
                        updateOn: 'change',
                    },
                    templateOptions: {
                        label: 'Loan Type',
                        required: true,
                        options: [
                            { label: 'Unsecured loan', value: 'Unsecured', },
                            { label: 'Secured loan', value: 'Secured', },
                        ],
                    },
                    className: 'col-12',
                    hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                        return !model.loanamount || model.loanamount == "Rs 1-5 Lakhs" || model.loanamount == "Rs 5-10 Lakhs" || model.loanamount == "Rs 1-3 Crores" || model.loanamount == "Rs 3 Crores & above" || model.loanamount == "Rs 1-3 Crore" || model.loanamount == "Rs 3 Crore and above"
                    },


                },
            ]
        }
    ]


const loanAmount: Step = {
    key:"loanamount",
    
    fields,

    hideBack: true,

     conversation: (model) => ({
        greeting: `Thanks for choosing CreditEnable!`,
        message: `I'm Lakshmi and I'll be helping guide you through your loan application. This will only take a few minutes. 
        `
    }),

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        flowManager.startLoading()
        flowManager.goto(workFlow.searchingLenders.key);
        const payload = { ...data, location: flowManager.location, ...flowManager.modelValue,
            whatsappconsent:flowManager.whatsappConsent };
        return flowManager.journey.createJourney(payload).pipe(
            catchError(err => {
                if (err.error.includes("Borrower already exist")) {
                    return flowManager.journey.updateJourney(flowManager.modelValue._id, payload);
                }
                else {
                    flowManager.throwError(err);
                    return throwError(err);
                }
            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            concatMap((result: any) => flowManager.journey.updateJourney(flowManager.modelValue._id, { qualified : true })),
            tap((result) => {
                flowManager.modelValue = { ...flowManager.modelValue, ...result };
                if (result.experian_masked_flow) {
                    flowManager.goto(workFlow.experianMismatch.key);
                }
                else if (result.disqualified) {
                    if (result.disqualificationKey == 'experian') {
                        if (result.experian_score && result.is_experian_score_below_threshold) {
                            flowManager.gtmTriggerService.triggerButtonClick({ className: 'experian-disqualified' })
                        } else {
                            flowManager.gtmTriggerService.triggerButtonClick({ className: 'model-disqualified' })
                        }                        
                    } 
                    flowManager.goto(workFlow.disQualifiedMessage.key);
                }
                else if (result.qualified) {
                    flowManager.finish();
                }
                else {
                    flowManager.stepPrevious();
                    throw new Error("Failed to move forward");
                } 
            }),
            tap(_=>flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },
}

export default loanAmount;
