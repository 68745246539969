import { FormControl, ValidationErrors } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { getDateComponents } from "../functions/getDateComponents.function";




export function dateValidator(control: FormControl, field: FormlyFieldConfig, options: any): ValidationErrors {
  if (control.value) {
    let value;
    if (control.value instanceof Date) {
      value = control.value;
    } else if (typeof control.value === 'object') {
      value = new Date(control.value.year, control.value.month, control.value.day);
    }
    else {
      var b = control.value as string;
      options.format = options.format.replace(/-/g, '').replace(/\//g, '');
      let dateComponents = getDateComponents(b, options.format);
      let year = dateComponents.year, month = dateComponents.month, day = dateComponents.day;
      if (month == 0 || day == 0 || month > 12 || day > 31 || year < 1900 || year > 2100)
        return { 'date': { message: `Please enter a valid date.` } };
      else if (day > new Date(year, month, 0).getDate())
        return { 'date': { message: `Please enter a valid date.` } };
      value = new Date(year, --month, day);
    }
    return value != null ? null : { 'date': { message: `Please enter a valid date.` } };
  }
}
