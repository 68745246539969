import { Injectable } from "@angular/core";
import { Subject } from 'rxjs/Subject';
import { HttpParams } from "@angular/common/http";
import { JourneyStatus } from "../utils/constants";

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor() { }
  static journeyChanged: Subject<string> = new Subject<string>();
  static websiteUrlChanged: Subject<string> = new Subject<string>();
  static journeyStatusChanged: Subject<JourneyStatus> = new Subject<JourneyStatus>();
  static journey: string = 'default';
  static userId: string = '';
  static dsaId: string = '';
  static websiteUrl: string = 'https://creditenable.com';

  public static changeJourney(j: string) {
    this.journey = j;
    setTimeout(() => this.journeyChanged.next(j), 300);
  }

  public static changeJourneyStatus(status: JourneyStatus) {
    this.journeyStatusChanged.next(status);
  }

  public static setUserId(u: string) {
    this.userId = u;
  }

  public static setDsaId(d: string) {
    this.dsaId = d;
  }

  public static updateWebsiteUrlParams(params: HttpParams) {
    this.websiteUrl = 'https://creditenable.com/in/?' + (params && params.keys().length > 0 ? params.keys().filter((v, i, a) => a.indexOf(v) === i).map((p) => `${p}=${params.get(p)}`).join("&") : '');
    this.websiteUrlChanged.next(this.websiteUrl);
  }
}
