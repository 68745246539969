import { Component, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-checkbox-input',
  template: `
    <div class="d-flex">
      <input
        type="checkbox"
        [class.is-invalid]="showError"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [indeterminate]="to.indeterminate && formControl.value == null"
      />
      <label
        [for]="id"
        [innerHTML]="to.label"
        class="checkbox-control-label"
      >
        <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
      </label>
    </div>
  `,
  styles: [`
    .checkbox-control-label {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      margin-left:5px!important;
      display:inline!important;
      font-weight: normal!important;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyCheckboxInput extends FieldType {
  get type() {
    return this.to.type || 'checkbox';
  }
}
