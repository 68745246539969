import bankaccount from "./steps/bankaccount.step";
import basicDetails from "./steps/basic-details.step";
import businessType from "./steps/business-type.step";
import businessVintage from "./steps/business-vintage.step";
import hasGst from "./steps/has-gst.step";
import itr from "./steps/itr.step";
import mab from "./steps/mab.step";
import primaryBusiness from "./steps/primary-business.step";
import residentialAddress from "./steps/residential-address.step";
import sector from "./steps/sector.step";
import turnover from "./steps/turnover.step";
import { ComponentWrap, Flow } from "..";

const errorMessage = {title:'Oops!', text:'We are unable to move you forward. Please try after a while. Thank you', icon:'error'};

export interface BorrowerJourneyWorkFlow {
    key:string,
    startFlowName:string,
    linearFlows:string[],
    login: Flow,
    basicDetails: Flow,
    businessDetails: Flow,
    experianMismatch: Flow,
    searchingLenders: Flow,
    qualifiedMessage: Flow,
    disQualifiedMessage: Flow,
    uploadDocument: Flow,
    loading:Flow,
}

const borrowerJourneyWorkflow: BorrowerJourneyWorkFlow = {
    key: 'borrowerJourneyWorkflow',
    startFlowName: 'login',
    linearFlows: ['basicDetails', 'businessDetails'],
    login: {
        key: "login",

        steps: [
            {
                key:"login",
                type: "LoginComponent",
            },
        ],
        onFinish: (model, flowManager) => {
            //flowManager.goto(borrowerJourneyWorkflow.basicDetails.key);
            //redirection after token change is handled in home pages
        },
        onError: (error, flowManager) => {
            
        },
    },
    basicDetails: {
        key: "basicDetails",

        steps: [
            basicDetails,
        ],
        onFinish: (model, flowManager) => {
            flowManager.goto(borrowerJourneyWorkflow.businessDetails.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    businessDetails: {
        key: "businessDetails",
        steps: [
            businessType,
            //residentialAddress, //remove it as now we are getting this details for experian "enhanced match" api
            businessVintage,
            bankaccount,
            sector,
            primaryBusiness,
            hasGst,
            itr,
            turnover,
            mab,
        ],
        onFinish: (model, flowManager) => {
            flowManager.goto(borrowerJourneyWorkflow.qualifiedMessage.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        }
    },
    experianMismatch: {
        key: "experianMismatch",
        steps: [
            {
                key:"experianMismatchAskNumber",
                type: "ExperianStepComponent",
            },
            {
                key:"experianMismatchVerification",
                type: "ExperainPhoneVerificationStepComponent"
            },
        ],
        onFinish: (model, flowManager) => {
            flowManager.goto(borrowerJourneyWorkflow.qualifiedMessage.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    searchingLenders: {
        key: "searchingLenders",
        steps: [
            {
                key:"searchingLenders",
                type: "SearchingLenderStepComponent",
            }
        ],
        onFinish: (model, flowManager) => {
            flowManager.goto(borrowerJourneyWorkflow.qualifiedMessage.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            flowManager.stepPrevious();
            flowManager.alertService.show(errorMessage);
        },
    },
    qualifiedMessage: {
        key: "qualifiedMessage",
        steps: [
            {
                key:"qualifiedMessage",
                type: "QualifiedStepComponent",
            }
        ],

        onFinish: (model, flowManager) => {
            flowManager.goto(borrowerJourneyWorkflow.uploadDocument.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    disQualifiedMessage: {
        key: "disQualifiedMessage",
        steps: [
            {
                key:"disQualifiedMessage",
                type: "DisqualifiedStepComponent",
            }
        ],

        onFinish: (model, flowManager) => {

        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    uploadDocument: {
        key: "uploadDocument",
        steps: [
            {
                key:"uploadDocument",
                type: "DocumentUploadStepComponent",
            },
        ],

        onFinish: (model, flowManager) => {

        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    loading: {
        key: "loading",
        steps: [
            {
                key:"loading",
                type: "LoadingStepComponent",
            },
        ],

        onFinish: (model, flowManager) => {

        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    }

};

export default borrowerJourneyWorkflow;