export const documentCategories = [
  {
    "document_id": "individual_kyc",
    "document_type": "Individual KYC",
    "document_label": "Individual KYC",
    "notes": "In case of Partnership, LLP and Private Limited Companies please upload Individual PAN, Aadhar and Proof of Address for each of the partners or directors separately",
    "documents": [
      {
        "name": "Business Owner Individual PAN",
        "new_document_id": "individual_kyc",
        "new_document_category" : "PAN",
        "doc_prepend": "PAN",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Sole Proprietorship'"
      },
      {
        "name": "Business Owner Aadhar",
        "new_document_id": "individual_kyc",
        "new_document_category": "Aadhar",
        "doc_prepend": "Aadhar",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Sole Proprietorship'"
      },
      {
        "name": "Proof of Address",
        "doc_prepend": "Address Proof",
        "new_document_id": "individual_kyc",
        "new_document_category": "Aadhar",
        "required": true,
        "options": [
          {
            "option": "Passport"
          },
          {
            "option": "Utility Bill"
          }
        ],
        "hide_criteria": "this.borrower.businesstype != 'Sole Proprietorship'"
      },
      {
        "name": "Co-applicant KYC (if applicable)",
        "doc_prepend": "Co-applicant KYC",
        "new_document_id": "individual_kyc",
        "new_document_category": "PAN",
        "required": false,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Sole Proprietorship'"
      },
      {
        "name": "Individual PAN for all Directors/Partners",
        "doc_prepend": "PAN",
        "new_document_id": "individual_kyc",
        "new_document_category": "PAN",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype == 'Sole Proprietorship'"
      },
      {
        "name": "Aadhar for all Directors/Partners",
        "doc_prepend": "Aadhar",
        "new_document_id": "individual_kyc",
        "new_document_category": "Aadhar",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype == 'Sole Proprietorship'"
      },
      {
        "name": "Proof of Address for each partner/director in at least one of the following forms",
        "doc_prepend": "Address Proof",
        "new_document_id": "individual_kyc",
        "new_document_category": "Aadhar",
        "required": true,
        "options": [
          {
            "option": "Aadhar"
          },
          {
            "option": "Passport"
          },
          {
            "option": "Utility Bill"
          }
        ],
        "hide_criteria": "this.borrower.businesstype == 'Sole Proprietorship'"
      }
    ]
  },
  {
    "document_id": "company_kyc",
    "document_type": "Business/Company KYC",
    "document_label": "Business/Company KYC",
    "notes": "",
    "documents": [
      {
        "name": "Business/Company PAN",
        "doc_prepend": "PAN",
        "new_document_id": "company_kyc",
        "new_document_category": "Company KYC",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype == 'Sole Proprietorship'"
      },
      {
        "name": "Partnership Deed",
        "doc_prepend": "Partnership Deed",
        "new_document_id": "company_kyc",
        "new_document_category": "Company KYC",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Partnership' && this.borrower.businesstype != 'LLP'"
      },
      {
        "name": "Company Incorporation Certificate",
        "doc_prepend": "Incorporation Certificate",
        "new_document_id": "company_kyc",
        "new_document_category": "Company KYC",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Private Limited'"
      },
      {
        "name": "MOA",
        "doc_prepend": "MOA",
        "new_document_id": "company_kyc",
        "new_document_category": "Company KYC",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Private Limited'"
      },
      {
        "name": "AOA",
        "doc_prepend": "AOA",
        "new_document_id": "company_kyc",
        "new_document_category": "Company KYC",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Private Limited'"
      },
      {
        "name": "Shareholding Pattern",
        "doc_prepend": "Shareholding Pattern",
        "new_document_id": "company_kyc",
        "new_document_category": "Company KYC",
        "required": true,
        "options": [],
        "hide_criteria": "this.borrower.businesstype != 'Private Limited'"
      }
    ]
  },
  {
    "document_id": "business_registration_proof",
    "document_type": "Business Registration Proof",
    "document_label": "Business Registration Proof",
    "documents": [
      {
        "name": "GST Registration Certificate or other",
        "doc_prepend": "GST Certificate",
        "new_document_id": "company_kyc",
        "new_document_category": "Business Registration Proof",
        "required": true,
        "options": []
      }
    ]
  },
  {
    "document_id": "property_ownership_proof",
    "document_type": "Property Ownership Proof",
    "document_label": "Property Ownership Proof",
    "documents": [
      {
        "name": "Utility bill or other",
        "doc_prepend": "Property Proof",
        "new_document_id": "company_kyc",
        "new_document_category": "Property Ownership Proof",
        "required": true,
        "options": []
      }
    ]
  },
  {
    "document_id": "bank_statements",
    "document_type": "Bank Statements",
    "document_label": "Bank Statements",
    "notes": "- Bank Statements can be uploaded either month-wise, quaterly, bi-yearly  or for the full year.<br/>- Only e-statements are accepted, not the scanned copies.<br/>- Password should be uploaded in a separate word/excel file in case any document is password protected.<br/>- m-Passbooks are not accepted.<br/>- All monthly bank statement should be in single PDF file for past 12 months.",
    "documents": [
      {
        "name": "Last 12 months Bank Statement PDF (of all Current/Savings bank accounts that includes business transactions)",
        "doc_prepend": "Bank Statement",
        "new_document_id": "financials",
        "new_document_category": "Bank Statements",
        "required": true,
        "options": [],
        "password": true
      }
    ]
  },
  {
    "document_id": "itr",
    "document_type": "ITR",
    "document_label": "Income Tax Return (PDF)",
    "notes": "",
    "is_optional": "this.borrower.loanamount=='Rs 1-5 Lakhs' || this.borrower.loanamount=='Rs 5-10 Lakhs' || this.borrower.loanamount=='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs'",
    "documents": [
      {
        "name": "ITR & Computation - Most Recent Year",
        "doc_prepend": "ITR - Recent",
        "new_document_id": "financials",
        "new_document_category": "ITR",
        "required": true,
        "options": []
      },
      {
        "name": "ITR & Computation - Previous Year",
        "doc_prepend": "ITR - Old",
        "new_document_id": "financials",
        "new_document_category": "ITR",
        "required": true,
        "options": []
      },
    ]
  },
  {
    "document_id": "audited_financials",
    "document_type": "Audited Financials",
    "document_label": "Audited Financials",
    "notes": "",
    "is_optional": "this.borrower.loanamount=='Rs 1-5 Lakhs' || this.borrower.loanamount=='Rs 5-10 Lakhs' || this.borrower.loanamount=='Rs 10-20 Lakhs' || this.borrower.loanamount=='₹1 Lakh to ₹5 Lakhs' || this.borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs' || this.borrower.loanamount=='₹11 Lakhs to ₹20 Lakhs'",
    "documents": [
      {
        "name": "Audited Financial Statements - Most Recent Year",
        "doc_prepend": "Financials - Recent",
        "new_document_id": "financials",
        "new_document_category": "Audited Financials",
        "required": true,
        "options": []
      },
      {
        "name": "Audited Financial Statements - Previous Year",
        "doc_prepend": "Financials - Old",
        "new_document_id": "financials",
        "new_document_category": "Audited Financials",
        "required": true,
        "options": []
      },
      {
        "name": "Provisional Financials (if available)",
        "doc_prepend": "Provisional Financials",
        "new_document_id": "financials",
        "new_document_category": "Audited Financials",
        "required": false,
        "options": []
      },
      {
        "name": "Financial projections (if available)",
        "doc_prepend": "Financial Projections",
        "new_document_id": "financials",
        "new_document_category": "Audited Financials",
        "required": false,
        "options": []
      }
    ]
  },
  {
    "document_id": "loan_takeover",
    "document_type": "Loan Takeover",
    "document_label": "Loan Takeover",
    "notes": "These documents are only required if you are looking for a loan takeover.<br/>If you are looking for a new loan, these documents will not be required",
    "is_optional": "1 == 1",
    "documents": [
      {
        "name": "Loan Account Statement",
        "doc_prepend": "Loan Account Statement",
        "new_document_id": "financials",
        "new_document_category": "Loan Takeover",
        "options": []
      },
      {
        "name": "Latest Sanction Letters",
        "doc_prepend": "Sanction Letters",
        "new_document_id": "financials",
        "new_document_category": "Loan Takeover",
        "options": []
      }
    ]
  },
  {
    "document_id": "gst_returns",
    "document_type": "GST Returns",
    "document_label": "GST Returns",
    "notes": "",
    "is_optional": "this.borrower.loanamount=='Rs 5-10 Lakhs' || this.borrower.loanamount=='₹6 Lakhs to ₹10 Lakhs'",
    "documents": [{
      "name": "Last 12 months GSTR-3B YTD",
      "doc_prepend": "GSTR-3B",
      "new_document_id": "financials",
      "new_document_category": "GSTR-3B",
      "required": true,
      "options": []
    }]
  },
  {
    "document_id": "collateral_documents",
    "document_type": "Collateral Documents",
    "document_label": "Collateral Documents",
    "notes": "The requirement to provide documentation in this category is case-specific. Our team will request this documentation from you only if required to secure your loan",
    "is_optional": "1 == 1",
    "documents": [
      {
        "name": "Property Market Value Document",
        "doc_prepend": "Property Market Value",
        "new_document_id": "financials",
        "new_document_category": "Collateral Documents",
        "options": []
      },
      {
        "name": "Other Collateral Document",
        "doc_prepend": "Other",
        "new_document_id": "financials",
        "new_document_category": "Collateral Documents",
        "options": []
      }
    ]
  },
  {
    "document_id": "internal_assessment_documents",
    "document_type": "Internal Assessment Documents",
    "document_label": "Internal Assessment Documents",
    "notes": "For internal purpose only",
    "is_optional": "1 == 1",
    "documents": [
      {
        "name": "Credit Assessment",
        "doc_prepend": "Credit Assessment",
        "new_document_id": "internal_assessment_documents",
        "new_document_category": "Credit Assessment",
        "options": []
      },
      {
        "name": "Other",
        "doc_prepend": "Other",
        "new_document_id": "internal_assessment_documents",
        "new_document_category": "Other",
        "options": []
      }
    ]
  }
];