import { FormControl, ValidationErrors } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { getDateComponents } from "../functions/getDateComponents.function";




export function maxDateValidator(control: FormControl, field: FormlyFieldConfig, options: any): ValidationErrors {
  if (control.value) {
    let value;
    if (control.value instanceof Date) {
      value = control.value;
    } else if (typeof control.value === 'object') {
      value = new Date(control.value.year, control.value.month, control.value.day);
    }
    else {
      var b = control.value as string;
      options.format = options.format.replace(/-/g, '').replace(/\//g, '');

      let dateComponents = getDateComponents(b, options.format);
      let year = dateComponents.year, month = dateComponents.month, day = dateComponents.day;

      if (month == 0 || day == 0 || month > 12 || day > 31 || year < 1900 || year > 2100)
        return { 'maxDate': { message: `Please enter a valid date.` } };
      value = new Date(year, --month, day);
    }
    let period = '';
    if ((options.max * 1000) % 31536000000 == 0) {
      period = `${(options.max * 1000) / 31536000000} years`;
    } else if ((options.max * 1000) % 2592000000 == 0) {
      period = `${(options.max * 1000) / 2592000000} months`;
    } else if ((options.max * 1000) % 86400000 == 0) {
      period = `${(options.max * 1000) / 86400000} days`;
    }
    let maximumDate = new Date(new Date().getTime() - (options.max * 1000));
    return maximumDate.getTime() > value.getTime() ? null : { 'maxDate': { message: options.label ? `${options.label} must be minimum ${period}.` : `${field.templateOptions.label} needs to be on/before ${maximumDate.toDateString()}.` } };
  }
}
