import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-panel',
  template: `
  <label [attr.for]="id" class="form-control-label control-label" *ngIf="to.label">
  {{ to.label }}  
  <span class="badge badge-pill badge-light" *ngIf="to.description">
    <i class="fa fa-question-circle" [ngbTooltip]="to.description"></i>
   </span>  
</label>
<ng-container #fieldComponent></ng-container>

  `,
})
export class PanelWrapperComponent extends FieldWrapper {
}