import { param } from 'jquery';
import * as moment from 'moment';
const now = new Date();
const currentMonth = moment().toDate();
const previousMonth = moment().subtract(1, 'months').toDate();
const thirteenMonthsAgo = moment().subtract(13, 'months').toDate();

const printMonthAndYear = (d1, d2) => `${d1.toLocaleString('default', { month: 'long' })} ${d1.getFullYear()} to ${d2.toLocaleString('default', { month: 'long' })} ${d2.getFullYear()}`;
const getAssesmentYear = (prev) => {
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    if (currentMonth >= 9) return `${currentYear - (prev - 1)} - ${currentYear + prev}`;
    return `${currentYear - prev} - ${currentYear - (prev - 1)}`
}

const getFinancialYear = (prev) => {
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    if (currentMonth >= 9) return `${currentYear - prev} - ${currentYear - (prev - 1)}`;
    return `${currentYear - (prev + 1)} - ${currentYear - prev}`
}

export interface ICategoryStepDocument {
    allowMultiple?: boolean;
    name: string;
    acceptedFileTypes: string[];
}

export interface Info {
    title: string,
    overview: string;
    description: string
}

export function getValOrParseFunc($param: any, data: any) {
    if (typeof $param === 'function') return $param(data)
    return $param;
}

export interface IStepSection {
    name: string,
    upload_folder: string;
    doc_prepend: string;
    required: boolean;
    fileType: string,
    documents: ICategoryStepDocument[] | ((borrower: any) => ICategoryStepDocument[]);
    options?: any[];
    exampleLinks?: string[] | ((model:any)=> string[]),
    info?: Info | ((model: any) => Info);
}

export interface ICategoryStep {
    name: string | ((model: any) => string),
    sections: IStepSection[]
}

export function getValidCategorySteps(value: ICategoryStep[], borrower: any) {
    const steps = value.map(step => ({ ...step, sections: [...step.sections] }));
    steps.forEach(step => {
        step.sections = step.sections
            .filter(section => hasDocs(section, borrower))
    });
    const result = steps.filter(step => step.sections.length > 0);
    return result;
}

export function hasDocs(section: IStepSection, borrower: any) {
    return (Array.isArray(section.documents) || section.documents(borrower).length > 0);
}

export interface ICategory {
    name: string,
    steps: ICategoryStep[];
}

const kycFileTypes = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/x-zip-compressed',
    'application/zip',
];

const documentFileTypes = [
    'application/pdf',
    'application/x-zip-compressed',
    'application/zip',
    // 'application/vnd.ms-excel',
    // 'application/msword',
    // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

const isPan = (model, fourthLetter) => model.pan && model.pan[3] == fourthLetter;
const isFPan = model => isPan(model, 'F');
const isCPan = model => isPan(model, 'C');
const isPPan = model => isPan(model, 'P');
const isSecured = model => model.typeofloan && model.typeofloan == 'Secured';

const categories: ICategory[] = [
    {
        name: 'Banking',
        steps: [
            {
                name: 'Bank Statements - last 12 months',
                sections: [
                    {
                        name: "Bank Statements",
                        upload_folder: "bank_statements",
                        doc_prepend: "Bank Statements",
                        required: true,
                        documents: [
                            {
                                name: `Period from - ${printMonthAndYear(thirteenMonthsAgo, currentMonth)}`,
                                acceptedFileTypes: documentFileTypes,
                                allowMultiple: true
                            }
                        ],
                        fileType: "Digital pdf only or zip folder",
                        exampleLinks: ['example-docs/bank-statement.pdf'],
                        info: model => {
                            const isBelow10 = (model.loanamount == "Rs 1-5 Lakhs" || model.loanamount == "Rs 5-10 Lakhs");
                            const endMessage = isBelow10 ? "OD/ CC accounts and savings account" : "and OD/ CC accounts";
                            return ({
                                title: `Bank Statements`,
                                overview: `
                            <ol> 
                                <li>All bank accounts where turnover is deposited</li>
                                <li>Mutiple bank statements allowed </li>
                                <li>Period = last 12 completed months</li>
                            </ol>`,

                                description: `
                            <p>Please upload the bank statements of all those accounts where your business revenue is credited and loan instalments are paid from. You can upload statements of current accounts, ${endMessage}.</p>
                            <p>For example, if you have monthly bank account statements  your bank statements are monthly, you can upload 12 files. If you have 2 such business accounts, you can upload 24 attachments zipped into one zip folder.</p>
                            <p>If you have a single bank statement of last 12 months, you upload a single file for each account.</p>
                            <p>You get maximum loan eligibility when you upload all business bank accounts.</p>
                            `
                            })
                        }
                    }
                ]
            },
        ],
    },
    {
        name: "ITR / Financials",
        steps: [
            {
                name: "ITR acknowledgement & computation",
                sections: [
                    {
                        name: "ITR acknowledgement & computation",
                        upload_folder: "itr",
                        doc_prepend: "ITR",
                        required: true,
                        documents: [
                            {
                                name: `Assessment year ${getAssesmentYear(1)}`,
                                acceptedFileTypes: documentFileTypes
                            },
                            {
                                name: `Assessment year ${getAssesmentYear(2)}`,
                                acceptedFileTypes: documentFileTypes
                            }
                        ],
                        fileType: "Digital pdf or scanned pdf or zip folder",
                        exampleLinks: ['example-docs/itr-comp-2020-21.pdf'],
                        info: {
                            title: 'ITR acknowledgement & computation',
                            overview: `You may upload all the ITR and/ or computation documents indicated in this section in a single zipped folder also. Upload in any one placeholder and move forward.`,
                            description: null

                        }
                    },
                ]
            },
            {
                name: "Audited/CA certified Financial Statements",
                sections: [
                    {
                        name: "Audited/CA certified Financial Statements",
                        upload_folder: "audited_financials",
                        doc_prepend: "Audited Financials",
                        required: true,
                        documents: (model) => {
                            const isGreaterthane20Lacs = (model.loanamount == "Rs 20-50 Lakhs" || model.loanamount == "Rs 50 Lakhs - 1 Crore" || model.loanamount == "Rs 1-3 Crores");

                            if (isGreaterthane20Lacs || isSecured(model)) {
                                return [
                                    {
                                        name: `Financial year ${getFinancialYear(1)}`,
                                        acceptedFileTypes: documentFileTypes
                                    },
                                    {
                                        name: `Financial year ${getFinancialYear(2)}`,
                                        acceptedFileTypes: documentFileTypes
                                    }
                                ]
                            }

                            return [];
                        },
                        fileType: "Digital pdf or scanned pdf or zip folder",
                        info: {
                            title: `Audited/CA certified Financial Statements`,
                            overview: `You may upload all the financial statements indicated in this section in a single zipped folder also. Upload in any one placeholder and move forward.`,
                            description: null
                        },
                        exampleLinks: ['example-docs/form-3ca.pdf', 'example-docs/form-3cb.pdf']
                    }
                ]
            },
            {
                name: "Tax audit report",
                sections: [
                    {
                        name: "Tax audit report",
                        upload_folder: "audited_financials",
                        doc_prepend: "Tax audit report",
                        required: true,
                        documents: (model) => {
                            const isGreaterthane20Lacs = (model.loanamount == "Rs 20-50 Lakhs" || model.loanamount == "Rs 50 Lakhs - 1 Crore" || model.loanamount == "Rs 1-3 Crores");

                            if (isGreaterthane20Lacs || isSecured(model)) {
                                return [
                                    {
                                        name: `Assessment year ${getAssesmentYear(1)}`,
                                        acceptedFileTypes: documentFileTypes
                                    },
                                    {
                                        name: `Assessment year ${getAssesmentYear(2)}`,
                                        acceptedFileTypes: documentFileTypes
                                    }
                                ]
                            }

                            return [];
                        },
                        fileType: "Digital pdf or scanned pdf or zip folder",
                        info: {
                            title: `Tax audit report`,
                            overview: `You may upload all the tax audit reports indicated in this section in a single zipped folder also. Upload in any one placeholder and move forward.`,
                            description: null
                        },
                        exampleLinks: ['example-docs/audit-report-1st-7-pages.pdf']
                    }
                ]
            },
            {
                name: "Provision Financials",
                sections: [
                    {
                        name: "Provision Financials",
                        upload_folder: "audited_financials",
                        doc_prepend: "Provision Financials",
                        required: false,
                        documents: (model) => {
                            const isGreaterthane20Lacs = (model.loanamount == "Rs 20-50 Lakhs" || model.loanamount == "Rs 50 Lakhs - 1 Crore" || model.loanamount == "Rs 1-3 Crores");

                            if (isGreaterthane20Lacs || isSecured(model)) {
                                return [
                                    {
                                        name: `For financial year ${getFinancialYear(0)}`,
                                        acceptedFileTypes: documentFileTypes
                                    },
                                ]
                            }

                            return [];
                        },
                        fileType: "Digital pdf or scanned pdf or zip folder",
                        info: {
                            title: `Provision Financials`,
                            overview: `You may upload all the KYC documents indicated in this section in a single zipped folder also. Upload in any one section and move forward.`,
                            description: null
                        }
                    }
                ]

            },
        ],
    },
    {
        name: 'GST Returns',
        steps: [
            {
                name: "GST Returns",
                sections: [
                    {
                        name: "GST Returns",
                        upload_folder: "gst_returns",
                        doc_prepend: "GSTR-3B",
                        required: true,
                        documents: (model) => {
                            const loanAmountIsGreaterThan10Lacs = model.loanamount != "Rs 1-5 Lakhs" && model.loanamount != "Rs 5-10 Lakhs";
                            const hasGst = model.selected_gstin;
                            if (loanAmountIsGreaterThan10Lacs && hasGst) {
                                return [
                                    {
                                        name: `Period - from ${printMonthAndYear(thirteenMonthsAgo, previousMonth)}`,
                                        acceptedFileTypes: documentFileTypes,
                                        allowMultiple: true
                                    }
                                ]
                            }

                            return [];

                        },
                        fileType: "Digital pdf or scanned pdf or zip folder",
                        exampleLinks: ['example-docs/gst-return-cmp08.pdf', 'example-docs/gst-return-gstr-3b.pdf'],
                        info: {
                            title: `GST Returns`,
                            overview: `You may upload all the GST returns together or in a single zipped folder also. Upload in any one placeholder and move forward.`,
                            description: null
                        }
                    }
                ]
            },
        ],
    },
    {
        name: 'VAT / GST Returns',
        steps: [
            {
                name: "VAT / GST Returns",
                sections: [
                    {
                        name: "VAT / GST Returns",
                        upload_folder: "gst_returns",
                        doc_prepend: "GSTR-3B",
                        required: true,
                        documents: (model) => {
                            const loanAmountIsGreaterThan10Lacs = model.loanamount != "Rs 1-5 Lakhs" && model.loanamount != "Rs 5-10 Lakhs";
                            const hasNoGst = !model.selected_gstin;
                            if (loanAmountIsGreaterThan10Lacs && hasNoGst) {
                                return [
                                    {
                                        name: `Period - from ${printMonthAndYear(thirteenMonthsAgo, previousMonth)}`,
                                        acceptedFileTypes: documentFileTypes,
                                        allowMultiple: true
                                    }
                                ]
                            }

                            return [];

                        },
                        fileType: "Digital pdf or scanned pdf or zip folder",
                        info: {
                            title: `VAT Returns`,
                            overview: `You may upload all the KYC documents indicated in this section in a single zipped folder also. Upload in any one section and move forward.`,
                            description: null
                        }
                    }
                ]
            },
        ],
    },
    {
        name: 'KYC',
        steps: [
            {
                name: "Business KYC",
                sections: [
                    {
                        name: "Business KYC",
                        upload_folder: "business_registration_proof",
                        doc_prepend: "business_registration_proof",
                        options: [],
                        required: true,
                        documents: model => {
                            const registrationDocuments = (model) => {
                                const hasGst = model.selected_gstin;
                                const isRemarketingFlow = model.location && model.location.workflowName == 'rm1Workflow';


                                if (isRemarketingFlow) {
                                    return [
                                        {
                                            name: 'Business registration certificate or GST certificate',
                                            acceptedFileTypes: kycFileTypes
                                        }
                                    ]
                                }
                                else if (hasGst) {
                                    return [
                                        {
                                            name: 'GST Registration',
                                            acceptedFileTypes: kycFileTypes
                                        }
                                    ]
                                }
                                else {
                                    return [
                                        {
                                            name: 'Business registration certificate',
                                            acceptedFileTypes: kycFileTypes
                                        }
                                    ]
                                }
                            }

                            const businessDocuments = (model) => {

                                if (isFPan(model)) {
                                    /*
                                        Latest partnership deed
                                        Firm's PAN card
                                    */
                                    return [
                                        {
                                            name: "Latest partnership deed",
                                            acceptedFileTypes: kycFileTypes
                                        },
                                        {
                                            name: "Firm's PAN card",
                                            acceptedFileTypes: kycFileTypes
                                        }
                                    ]
                                }
                                else if (isCPan(model)) {
                                    /*
                                        Company's PAN card
                                        Memorandum & Articles of Association
                                        Certificate of Incorporation
                                        Shareholding & Directors list
                                    */

                                    return [
                                        {
                                            name: "Company's PAN card",
                                            acceptedFileTypes: kycFileTypes
                                        },
                                        {
                                            name: "Memorandum & Articles of Association",
                                            acceptedFileTypes: kycFileTypes
                                        },
                                        {
                                            name: "Certificate of Incorporation",
                                            acceptedFileTypes: kycFileTypes
                                        },
                                        {
                                            name: "Shareholding & Directors list",
                                            acceptedFileTypes: kycFileTypes
                                        }
                                    ]
                                }

                                return [];

                            }

                            return [...registrationDocuments(model), ...businessDocuments(model)];

                        },
                        fileType: "Click a picture or Digital document or Scanned copy",
                        info: {
                            title: `Business KYC`,
                            overview: `You may upload all the KYC documents indicated in this section in a single zipped folder also. Upload in any one section and move forward.`,
                            description: null
                        },
                        exampleLinks: model=> { 
                            const docs = [
                                'example-docs/trade-licence.pdf',
                                'example-docs/udyam-doc.pdf',
                            ];
                            const isGstUser = !!model.selected_gstin;
                            if(isGstUser) docs.unshift('example-docs/gst-reg-06.pdf');
                            return docs;
                        }
                    },

                ]
            },
            {
                name: model => isPPan(model) ? `Proprietor KYC` : `Promoter KYC`,
                sections: [
                    {
                        name: "Proprietor KYC - ID proof",
                        doc_prepend: "PAN",
                        upload_folder: "individual_kyc",
                        required: true,
                        documents: model => {
                            if (isPPan(model)) {
                                return [{
                                    name: "PAN card",
                                    acceptedFileTypes: kycFileTypes
                                }]
                            }
                            return [];
                        },
                        fileType: "Click a picture or Digital document or Scanned copy",
                        info: {
                            title: `Proprietor KYC - ID proof`,
                            overview: `You may upload all the KYC documents indicated in this section in a single zipped folder also. Upload in any one section and move forward.`,
                            description: null
                        },
                        exampleLinks: ['example-docs/new-correction-pan-card-500x500.jpg']
                    },
                    {
                        name: "Proprietor KYC - Address proof",
                        upload_folder: "individual_kyc",
                        doc_prepend: "Aadhar",
                        required: true,
                        documents: model => {
                            if (isPPan(model)) {
                                return [{
                                    name: "Aadhaar card - both sides",
                                    acceptedFileTypes: kycFileTypes
                                }]
                            }
                            return [];
                        },
                        fileType: "Click a picture or Digital document or Scanned copy",
                        info: {
                            title: `Proprietor KYC - Address proof`,
                            overview: `You may upload all the KYC documents indicated in this section in a single zipped folder also. Upload in any one section and move forward.`,
                            description: null
                        },
                        exampleLinks: ['example-docs/aadhaar-card.jpg']
                    },
                    {
                        name: "All Partners KYC - ID proof",
                        doc_prepend: "PAN",
                        upload_folder: "individual_kyc",
                        required: true,
                        documents: model => {
                            if (isFPan(model)) {
                                return [{
                                    name: "PAN card",
                                    acceptedFileTypes: kycFileTypes
                                }]
                            }
                            return [];
                        },
                        fileType: "Click a picture or Digital document or Scanned copy",
                        info: {
                            title: `All Partners KYC - ID proof`,
                            overview: `You may upload all the KYC documents indicated in this section in a single zipped folder also. Upload in any one section and move forward.`,
                            description: null
                        },
                        exampleLinks: ['example-docs/new-correction-pan-card-500x500.jpg']
                    },
                    {
                        name: "All Partners KYC - Address proof",
                        upload_folder: "individual_kyc",
                        doc_prepend: "Aadhar",
                        required: true,
                        documents: model => {
                            if (isFPan(model)) {
                                return [{
                                    name: "Aadhaar card - both sides",
                                    acceptedFileTypes: kycFileTypes
                                }]
                            }
                            return [];
                        },
                        fileType: "Click a picture or Digital document or Scanned copy",
                        info: {
                            title: `All Partners KYC - Address proof`,
                            overview: `You may upload all the KYC documents indicated in this section in a single zipped folder also. Upload in any one section and move forward.`,
                            description: null
                        },
                        exampleLinks: ['aadhaar-card.jpg']
                    },
                    {
                        name: "All Promoters/ Directors - ID proof",
                        doc_prepend: "PAN",
                        upload_folder: "individual_kyc",
                        required: true,
                        documents: model => {
                            if (isCPan(model)) {
                                return [{
                                    name: "PAN card",
                                    acceptedFileTypes: kycFileTypes
                                }]
                            }
                            return [];
                        },
                        fileType: "Click a picture or Digital document or Scanned copy",
                        info: {
                            title: `All Promoters/ Directors - ID proof`,
                            overview: `You may upload all the KYC documents indicated in this section in a single zipped folder also. Upload in any one section and move forward.`,
                            description: null
                        },
                        exampleLinks: ['example-docs/new-correction-pan-card-500x500.jpg']
                    },
                    {
                        name: "All Promoters/ Directors - Address proof",
                        upload_folder: "individual_kyc",
                        doc_prepend: "Aadhar",
                        required: true,
                        documents: model => {
                            if (isCPan(model)) {
                                return [{
                                    name: "Aadhaar card - both sides",
                                    acceptedFileTypes: kycFileTypes
                                }]
                            }
                            return [];
                        },
                        fileType: "Click a picture or Digital document or Scanned copy",
                        info: {
                            title: `All Promoters/ Directors - Address proof`,
                            overview: `You may upload all the KYC documents indicated in this section in a single zipped folder also. Upload in any one section and move forward.`,
                            description: null
                        },
                        exampleLinks: ['aadhaar-card.jpg']
                    }
                ]
            },
            {
                name:`Property ownership proof`,
                sections: [
                    {
                        name: "Property ownership proof",
                        upload_folder: "property_ownership_proof",
                        doc_prepend: "property_ownership_proof",
                        documents: model => {
                            if (!isSecured(model)) {
                                return [
                                    {
                                        name: "Latest Utility Bill",
                                        acceptedFileTypes: kycFileTypes
                                    },
                                    {
                                        name: "Index II Property Documents",
                                        acceptedFileTypes: kycFileTypes
                                    },
                                ]
                            }
                            return [];
                        },
                        required: true,
                        fileType: "Click a picture or Digital document or Scanned copy",
                        info: {
                            title: `Property ownership proof`,
                            overview: `You may upload these documents together or in a single zipped folder also. Upload in any one placeholder and move forward.`,
                            description: null
                        }
                    },
                ]
            },
            {
                name:`Rental agreement, if rented property`,
                sections: [
                    {
                        name: "Rental agreement, if rented property",
                        upload_folder: "property_ownership_proof",
                        doc_prepend: "property_ownership_proof",
                        documents: model => {
                            if (!isSecured(model)) {
                                return [
                                    {
                                        name: "Latest rental agreement",
                                        acceptedFileTypes: kycFileTypes
                                    },
                                ]
                            }
                            return [];
                        },
                        required: true,
                        fileType: "Click a picture or Digital document or Scanned copy",
                        info: {
                            title: `Property ownership proof`,
                            overview: `You may upload these documents together or in a single zipped folder also. Upload in any one placeholder and move forward.
                            `,
                            description: null
                        }
                    },
                ]
            },
            {
                name: `Collateral documents` ,
                sections: [
                    
                    {
                        name: "Collateral & loan takeover documents",
                        upload_folder: "collateral_documents",
                        doc_prepend: "Collateral Documents",
                        documents: model => {
                            if (isSecured(model)) {
                                return [
                                    {
                                        name: "Sale deed, building plan, etc.",
                                        acceptedFileTypes: kycFileTypes
                                    },
                                    {
                                        name: "Loan sanction letter & loan account statement (optional)",
                                        acceptedFileTypes: kycFileTypes
                                    }
                                ]
                            }
                            return [];
                        },
                        required: true,
                        fileType: "Click a picture or Digital document or Scanned copy",
                        info: {
                            title: `Collateral & loan takeover documents`,
                            overview: `Sale Deed, municipality approved building plan/plot layout, Title Deeds including the previous chain of property documents, if applicable.`,
                            description: null
                        }
                    },
                    
                ]
            },

        ],
    }
]



export default categories;