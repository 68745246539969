export 
  interface Testimonial {
  quote: string;
  stars: number;
  img: string;
  name: string;
  org?: string;
  position?: string;
}

export const default_testimonials: Testimonial[] = [
  {
    quote: "CreditEnable understood my needs and matched me with the right business loan at a great ROI! Their process is easy, and the 100% online service was helpful, especially now when I can’t leave home. This funding will help me grow even in the lockdown!",
    name: "Ruben Ghosh",
    org: "Myrsa Technology Solutions Pvt. Ltd.",
    position: "Proprietor",
    stars: 5,
    img: "testimonial-0.png"
  },
  {
    quote: "Since COVID has disrupted the education sector, the loan enabled by CreditEnable is crucial to our survival and growth. Their easy application, good service, and timely follow-up meant I got my loan quick, without having to visit a lender even once!",
    name: "Prashant Chadha",
    org: "Learning Inc.",
    position: "Proprietor",
    stars: 5,
    img: "testimonial-1.png"
  },
  {
    quote: "CreditEnable is amazing! My business is growing fast, so I needed a flexible loan that wouldn’t impact my cash flow. Their matching technology is so good that one of their lenders said yes to me the next day and cash was in my account that week!",
    name: "Sachin Patil",
    org: "American Ferto",
    position: "Proprietor",
    stars: 5,
    img: "user.png"
  },
  {
    quote: "The business loan will help me to buy the latest softwares, to grow my business to the next level, which will increase my business production and profit in upcoming days, Many thanks. CreditEnable has a good, supportive and expert team.",
    name: "Dhayanithi",
    org: "Structural Ethics Engineering",
    position: "Proprietor",
    stars: 5,
    img: "user.png"
  },
  {
    quote: "I'm really impressed with the custom solutions provided by understanding the needs of my business. Also the team was very prompt in communication and follow-up.",
    name: "Jayprakash Kodalil",
    org: "Vijaya World",
    position: "Proprietor",
    stars: 4,
    img: "user.png"
  },
  {
    quote: "Really in this tough time, it helped me to purchase materials to process my pending orders.",
    name: "Sandhya Kumari",
    org: "Soara Corporation",
    position: "Director",
    stars: 5,
    img: "user.png"
  },
  {
    quote: "Their team turned around my loan application in no time at all! They were transparent, explained the loan application process, and answered all my queries. Their experts were familiar with my financials and helped me get the best loan for my needs.",
    name: "Ashish Agarwal",
    org: "AVS Global",
    position: "Proprietor",
    stars: 4,
    img: "user.png"
  }
];
