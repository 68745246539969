import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { GaugeChartModule } from 'angular-gauge-chart';

/*Material UI*/
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';


import { AccordionModule } from 'primeng/accordion';
import { PanelModule } from 'primeng/panel';

import { FilePondModule, registerPlugin } from 'ngx-filepond';

/*Components*/
import { FormlyStepComponent } from './formly-step/formly-step.component';
import { ExperianStepComponent } from './experian-step/experian-step.component';
import { FooterComponent } from './footer/footer.component';
import { StepperComponent } from './stepper/stepper.component';
import { LoanBannerComponent } from './loan-banner/loan-banner.component';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { StepComponent } from './step/step.component';
import { IsNotSkippedPipe } from './is-not-skipped/is-not-skipped';
import { ScoreGaugeMeterComponent } from './score-gauge-meter/score-gauge-meter.component';
import { QualifiedStepComponent } from './qualified-step/qualified-step.component';
import { DisqualifiedStepComponent } from './disqualified-step/disqualified-step.component';
import { DynamicContentDirective } from './dynamic-content/dynamic-content.directive';
import { DocumentUploadStepComponent } from './document-upload-step/document-upload-step.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { SharedModule } from 'primeng-lts/api';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { LoginComponent } from './login/login.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PartnersComponent } from './partners/partners.component';
import { TestimonialCardComponent } from './testimonial-card/testimonial-card.component';
import { CardModule } from 'primeng/card'
import { RatingModule } from 'primeng/rating';
import { NavbarComponent } from './navbar/navbar.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchLoaderComponent } from './search-loader/search-loader.component';
import { SearchingLenderStepComponent } from './searching-lender-step/searching-lender-step.component';
import { FlipkartStepComponent } from './flipkart-step/flipkart-step.component';
import { FlipkartDocumentUploadStepComponent } from './flipkart-document-upload-step/flipkart-document-upload-step.component';
import { FlipkartFileUploadComponent } from './flipkart-file-upload/flipkart-file-upload.component';
import { FlipkartOuterLayoutComponent } from './flipkart-outer-layout/flipkart-outer-layout.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlipkartNavbarComponent } from './flipkart-navbar/flipkart-navbar.component';
import { FlipkartLoginComponent } from './flipkart-login/flipkart-login.component';
import { FlipkartBobNavbarComponent } from './flipkart-bob-navbar/flipkart-bob-navbar.component';
import { FlipkartBobLoginComponent } from './flipkart-bob-login/flipkart-bob-login.component';
import { FlipkartBobOuterLayoutComponent } from './flipkart-bob-outer-layout/flipkart-bob-outer-layout.component';
import { FlipkartBobStepComponent } from './flipkart-bob-step/flipkart-bob-step.component';
import { GtmButtonTriggerComponent } from './gtm-button-trigger/gtm-button-trigger.component';
import { FilesDoctypeParserPipe } from './files-doctype-parser/files-doctype-parser.pipe';
import { LoadingStepComponent } from './loading-step/loading-step.component';
import { SweetAlertComponent } from './sweet-alert/sweet-alert.component';
import { ExperainPhoneVerificationStepComponent } from './experain-phone-verification-step/experain-phone-verification-step.component';
import { CountdownModule } from 'ngx-countdown';
import { GstPullOptionComponent } from './gst-pull-option/gst-pull-option.component';
import { GstPullStepperComponent } from './gst-pull-stepper/gst-pull-stepper.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { BusinessSelectionStepComponent } from './business-selection-step/business-selection-step.component';
import { GstFlowLoginComponent } from './gst-flow-login/gst-flow-login.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgOtpInputModule } from  'ng-otp-input';
import { NgxMaskModule } from 'ngx-mask';
import { NsdlLoaderStepComponent } from './nsdl-loader-step/nsdl-loader-step.component';
import { GstrLoaderStepComponent } from './gstr-loader-step/gstr-loader-step.component';
import { DocumentUploadModernStepComponent } from './document-upload-modern-step/document-upload-modern-step.component';
import { FileUploadModernComponent } from './file-upload-modern/file-upload-modern.component';
import { DocumentCategoryComponent } from './document-category/document-category.component';
import { DocumentCategoryStepModalComponent } from './document-category-step-modal/document-category-step-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { DocumentListComponent } from './document-list/document-list.component';
import { FilenamePipe } from './filename/filename.pipe';
import { DocumentCategoryStepSectionComponent } from './document-category-step-section/document-category-step-section.component';
import { FileSvgComponent } from './file-svg/file-svg.component';
import { FileSvgEnabledComponent } from './file-svg-enabled/file-svg-enabled.component';
import { FileInfoComponent } from './file-info/file-info.component';
import { IsZipPipe } from './is-zip/is-zip.pipe';
import { ZipFileInfoComponent } from './zip-file-info/zip-file-info.component';
import { FileProtectedPipe } from './file-protected/file-protected.pipe';
import { FileHasErrorPipe } from './file-has-error/file-has-error.pipe';
import { FileIsValidPipe } from './file-is-valid/file-is-valid.pipe';
import {MatExpansionModule} from '@angular/material/expansion'
import {MatTreeModule} from '@angular/material/tree';
import { FileValidPipe } from './file-valid/file-valid.pipe';
import { ThankYouStepComponent } from './thank-you-step/thank-you-step.component';
import { FileActionsComponent } from './file-actions/file-actions.component';
import { NegativeSectorStepComponent } from './negative-sector-step/negative-sector-step.component';
import { StatusLoginComponent } from './status-login/status-login.component';

@NgModule({
  declarations: [
    FormlyStepComponent, 
    ExperianStepComponent, 
    FooterComponent, 
    StepperComponent, 
    LoanBannerComponent, 
    ChatBoxComponent, 
    StepComponent, 
    IsNotSkippedPipe,
    ScoreGaugeMeterComponent,
    QualifiedStepComponent,
    DisqualifiedStepComponent,
    DynamicContentDirective,
    DocumentUploadStepComponent,
    FileUploadComponent,
    LoginComponent,
    StatusLoginComponent,
    PartnersComponent,
    TestimonialCardComponent,
    NavbarComponent,
    SearchLoaderComponent,
    SearchingLenderStepComponent,
    FlipkartStepComponent,
    FlipkartDocumentUploadStepComponent,
    FlipkartFileUploadComponent,
    FlipkartOuterLayoutComponent,
    FlipkartNavbarComponent,
    FlipkartLoginComponent,
    FlipkartBobNavbarComponent,
    FlipkartBobLoginComponent,
    FlipkartBobOuterLayoutComponent,
    FlipkartBobStepComponent,
    GtmButtonTriggerComponent,
    FilesDoctypeParserPipe,
    LoadingStepComponent,
    SweetAlertComponent,
    ExperainPhoneVerificationStepComponent,
    GstPullOptionComponent,
    GstPullStepperComponent,
    BusinessSelectionStepComponent,
    GstFlowLoginComponent,
    NsdlLoaderStepComponent,
    GstrLoaderStepComponent,
    DocumentUploadModernStepComponent,
    FileUploadModernComponent,
    DocumentCategoryComponent,
    DocumentCategoryStepModalComponent,
    DocumentListComponent,
    FilenamePipe,
    DocumentCategoryStepSectionComponent,
    FileSvgComponent,
    FileSvgEnabledComponent,
    FileInfoComponent,
    IsZipPipe,
    ZipFileInfoComponent,
    FileProtectedPipe,
    FileHasErrorPipe,
    FileIsValidPipe,
    FileValidPipe,
    ThankYouStepComponent,
    FileActionsComponent,
    NegativeSectorStepComponent,
  ],
  
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FormlyModule.forRoot(),
    FormlyBootstrapModule,
    GaugeChartModule,
    SweetAlert2Module.forChild(),
    CarouselModule,
    NgbModule,
    NgSelectModule,
    
    /*Material UI imports */
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatIconModule,
    MatExpansionModule,
    MatTreeModule,
    /* PrimeNg imports */
    AccordionModule,
    PanelModule,
    SharedModule,
    FilePondModule,
    RatingModule,
    CardModule,

    CountdownModule,
    NgOtpInputModule,
    NgxMaskModule.forChild(),
  ],

  exports:  [
    FormlyStepComponent, 
    ExperianStepComponent, 
    FooterComponent, 
    StepperComponent, 
    LoanBannerComponent, 
    ChatBoxComponent, 
    StepComponent,
    IsNotSkippedPipe,
    ScoreGaugeMeterComponent,
    QualifiedStepComponent,
    DisqualifiedStepComponent,
    DynamicContentDirective,
    DocumentUploadStepComponent,
    LoginComponent,
    StatusLoginComponent,
    PartnersComponent,
    TestimonialCardComponent,
    NavbarComponent,
    SearchLoaderComponent,
    SearchingLenderStepComponent,
    FlipkartStepComponent,
    FlipkartDocumentUploadStepComponent,
    FlipkartFileUploadComponent,
    FlipkartOuterLayoutComponent,
    FlipkartNavbarComponent,
    FlipkartBobNavbarComponent,
    FlipkartBobLoginComponent,
    FlipkartBobOuterLayoutComponent,
    FlipkartBobStepComponent,
    GtmButtonTriggerComponent,
    FilesDoctypeParserPipe,
    LoadingStepComponent,
    SweetAlertComponent,
    ExperainPhoneVerificationStepComponent,
    GstPullOptionComponent,
    GstPullStepperComponent,
    GstFlowLoginComponent,
    NsdlLoaderStepComponent,
    GstrLoaderStepComponent,
    DocumentUploadModernStepComponent,
    DocumentCategoryStepModalComponent,
    DocumentCategoryStepSectionComponent,
    ZipFileInfoComponent,
    FileActionsComponent,
  ],
  entryComponents:[
    GstPullOptionComponent,
    GstPullStepperComponent,
    DocumentCategoryStepModalComponent,
    ZipFileInfoComponent
  ]
})
export class ComponentsModule { }
