import { Component, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-pincode-input',
  template: `
    <input type="text"
        min="100000"
        max="999999"
        maxLength="6"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        [formControl]="formControl"
        class="form-control"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
        [placeholder]="field.templateOptions.placeholder"
      />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyPincodeInput extends FieldType {
  
  get type() {
    return this.to.type || 'text';
  }
}
