import { Flow } from "..";
import loanAmount from "./steps/loan-amount.step";

const errorMessage = { title: 'Oops!', text: 'We are unable to move you forward. Please try after a while. Thank you', icon: 'error' };

export interface Rm1WorkFlow {
    key: string,
    startFlowName: string,
    linearFlows: string[],
    login: Flow,
    basicDetails: Flow,
    experianMismatch: Flow,
    searchingLenders: Flow,
    qualifiedMessage: Flow,
    disQualifiedMessage: Flow,
    uploadDocument: Flow,
    loading: Flow,
}

const rm1Workflow: Rm1WorkFlow = {
    key: 'rm1Workflow',
    startFlowName: 'login',
    linearFlows: ['basicDetails'],
    login: {
        key: "login",

        steps: [
            {
                key: "login",
                type: "GstFlowLoginComponent",
            },
        ],
        onFinish: (model, flowManager) => {
            //flowManager.goto(borrowerJourneyWorkflow.basicDetails.key);
            //redirection after token change is handled in home pages
        },
        onError: (error, flowManager) => {

        },
    },



    basicDetails: {
        key: "basicDetails",
        steps: [
            loanAmount,
           
        ],
        onFinish: (model, flowManager) => {
            //flowManager.gtmTriggerService.triggerButtonClick({ className: "stage-gst" });
            flowManager.goto(rm1Workflow.qualifiedMessage.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        }
    },

   


    experianMismatch: {
        key: "experianMismatch",
        steps: [
            {
                key: "experianMismatchAskNumber",
                type: "ExperianStepComponent",
            },
            {
                key: "experianMismatchVerification",
                type: "ExperainPhoneVerificationStepComponent"
            },
        ],
        onFinish: (model, flowManager) => {
            flowManager.goto(rm1Workflow.qualifiedMessage.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    searchingLenders: {
        key: "searchingLenders",
        steps: [
            {
                key: "searchingLenders",
                type: "SearchingLenderStepComponent",
            }
        ],
        onFinish: (model, flowManager) => {
            flowManager.goto(rm1Workflow.qualifiedMessage.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            flowManager.stepPrevious();
            flowManager.alertService.show(errorMessage);
        },
    },
    qualifiedMessage: {
        key: "qualifiedMessage",
        steps: [
            {
                key: "qualifiedMessage",
                type: "QualifiedStepComponent",
            }
        ],

        onFinish: (model, flowManager) => {
            flowManager.goto(rm1Workflow.uploadDocument.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    disQualifiedMessage: {
        key: "disQualifiedMessage",
        steps: [
            {
                key: "disQualifiedMessage",
                type: "DisqualifiedStepComponent",
            }
        ],

        onFinish: (model, flowManager) => {

        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    uploadDocument: {
        key: "uploadDocument",
        steps: [
            {
                key: "uploadDocument",
                type: "DocumentUploadStepComponent",
            },
        ],

        onFinish: (model, flowManager) => {

        },
        onError: (error, flowManager) => {
            flowManager.alertService.show(errorMessage);
        },
    },
    loading: {
        key: "loading",
        steps: [
            {
                key: "loading",
                type: "LoadingStepComponent",
            },
        ],

        onFinish: (model, flowManager) => {

        },
        onError: (error, flowManager) => {
            flowManager.stepPrevious();
            flowManager.alertService.show(errorMessage);
        },
    },

};

export default rm1Workflow;