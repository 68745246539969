import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";
import negativeSectors from "./negative-sectors";
import sectors from "./sectors";
import selectedSectors from "./selected-sectors";

const negativeSector: (param: { isFinisher: boolean }) => Step = ({ isFinisher }) => ({
    key: "negativeSector",

    fields: [{
        key: "negativeSector",
        type: "question",
        modelOptions:{
            updateOn:'change'
        },
        templateOptions: {

            label: `Is your main business activity any of the following categories?
                <br/>
<div class="row n-sector-list" >
    <div class="col-sm-12 col-md-6">
        <ul>
            <li>Real estate developers & agents</li>
            <li>Manufacturer or Retailer of Real Jewelry (Diamond, Precious Gems, Gold, Silver)</li>
            <li>Bars/ Wine shops</li>
            <li>Massage parlors / Spa</li>    
        </ul>
    </div>
    <div class="col-sm-12 col-md-6">
        <ul>           
            <li>Tobacco manufacturer or distributor</li>
            <li>Brokerage houses</li>
            <li>Marriage bureau</li>
            <li>Cable TV operator</li>          
        </ul>
    </div>
</div>
`,
            options: [
                { label: "No", value: "No" },
                { label: "Yes", value: "Yes" },
            ],
            required: true,
            hideRequiredMarker: true
        },
        hooks: {
            onInit: (field) => {

            }
        },
        className: "col-12 col-sm-12",


    }],

    skipOn: (model) => {
        const isAbove10Lacs = (model.loanamount != "Rs 1-5 Lakhs" && model.loanamount != "Rs 5-10 Lakhs");
        return isAbove10Lacs;
    },

    conversation: (model) => ({
        greeting: `Great to meet you ${model.first_name}.`,
        message: ` 
        Please help me understand your business so I can check your eligibility and 
        help you get the right loan for your business. 
        This should only take a second. 
        `
    }),

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        //if (flowManager.modelValue.negativeSector != "No") {
        //    data = {
        //        ...data,
        //        disqualified: true,
        //        disqualificationKey: `negativeSector`,
        //        disqualificationMessage: `Your business falls into a category that our lender partners do not currently serve. 
        //        Please apply for another business, and we may be able to help you get a hassle-free business loan.` }
        //}

        flowManager.startLoading()
        return flowManager.journey.updateJourney(flowManager.modelValue._id, { ...data, location: flowManager.location }).pipe(
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);
            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap((result) => {
                if (result.disqualified) {
                    flowManager.goto(workFlow.disQualifiedMessage.key);
                }
                else {
                    if(isFinisher) {
                        flowManager.finish();
                    }
                    else {
                        flowManager.stepNext();
                    }
                }
            }),
            tap(_ => flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },

});


export default negativeSector;
