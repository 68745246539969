export function getDateComponents(date: string, format: string){
    let year = 0, month = 0, day = 0;
    if (format) {
      format = format.replace(/-/g, '').replace(/\//g, '');
      let y1 = format.indexOf('Y');
      let y2 = format.lastIndexOf('Y');
      year = +date.substr(y1, y2 - y1 + 1);
  
      let m1 = format.indexOf('M');
      let m2 = format.lastIndexOf('M');
      month = +date.substr(m1, m2 - m1 + 1);
  
      let d1 = format.indexOf('D');
      if (d1 >= 0) {
        let d2 = format.lastIndexOf('D');
        day = +date.substr(d1, d2 - d1 + 1);
      } else {
        day = 1;
      }
    } else {
      year = +date.substr(4);
      month = +date.substr(2, 2);
      day = +date.substr(0, 2);
    }
    return { year, month, day };
  }