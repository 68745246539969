import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";

const fields: (flowManager: FlowManagerService) => FormlyFieldConfig[] = (flowManager: FlowManagerService) => (
    [{
        key: "mab",
        type: "question",
        modelOptions: {
            updateOn: 'change'
        },
        templateOptions: {

            label: `What is your average monthly balance?`,
            labelDescription:`(Balance maintained in your bank accounts on a consistent basis.)`,
            options: [{ "label": "Less than ₹10,000", "value": "Less than INR 10,000" },
            { "label": "₹10,000 - ₹20,000", "value": "INR 10,000 to INR 20,000" },
            { "label": "₹20,001 - ₹30,000", "value": "INR 20,000 to INR 30,000" },
            { "label": "₹30,001 - ₹50,000", "value": "INR 30,000 to INR 50,000" },
            { "label": "More than ₹50,000", "value": "More than INR 50,000" }],
            required: true,
        },
        hooks: {
            onInit: ({ formControl, key }) => {
                let lastValue = flowManager.modelValue[key as string];
                formControl.valueChanges.subscribe(value => {
                    if (lastValue == value || flowManager.loadingValue) return;
                    lastValue = value;
                    console.log(value)
                    const data = {};
                    const name = key as string;
                    data[name] = value;
                    mab.next(data, flowManager).subscribe();
                })
            }
        },
        className: "col-12 col-sm-12",


    }]
)


const mab: Step = {
    key:"mab",
    
    fields,


    conversation: (model) => {
        return {
            greeting: ``,
            message: `
            Thanks, ${model.first_name}!
            Now let's check whether your revenues and cash flow can support the loan you want. 
            This will only take a minute.  
            `
        }
    },


    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        let onSuccess = (result)=>{
            if (result.disqualified) {
                flowManager.goto(workFlow.disQualifiedMessage.key);
            }
            else {
                flowManager.stepNext();
            }
        }

        try {
            const model = flowManager.modelValue;
            const mabIsLessThan10000 = model.mab === "Less than INR 10,000";
            const loanIsGreaterThan5LacsAndMabIsLessThan20000 = (model.loanamount != "Rs 1-5 Lakhs" && model.mab == "INR 10,000 to INR 20,000");
            
            if (mabIsLessThan10000) {
                data = {
                    ...data,
                    disqualified: true,
                    disqualificationKey: `mab`,
                    disqualificationMessage: `Your profile isn't a good match for our lender partners. 
                    Your monthly balane isn't sufficient to support the EMI payments linked to the loan 
                    you are looking for. 
                    ` }
            }
            else if(flowManager.stepperValue.steps.length == (flowManager.stepperValue.selectedIndex + 1)) {
                data = { ...data, qualified: true }
                onSuccess = (result) => {
                    if (result.experian_masked_flow) {
                        flowManager.goto(workFlow.experianMismatch.key);
                    }
                    else if (result.disqualified) {
                        if (result.disqualificationKey == 'experian') {
                            if (result.experian_score && result.is_experian_score_below_threshold) {
                                flowManager.gtmTriggerService.triggerButtonClick({ className: 'experian-disqualified' })
                            } else {
                                flowManager.gtmTriggerService.triggerButtonClick({ className: 'model-disqualified' })
                            }                        
                        } 
                        flowManager.goto(workFlow.disQualifiedMessage.key);
                    }
                    else if (result.qualified) {
                        flowManager.finish();
                    }
                    else {
                        flowManager.stepPrevious();
                        throw new Error("Failed to move forward");
                    }       
                }
    
                flowManager.goto(workFlow.searchingLenders.key);
            }

            flowManager.startLoading()
           
        }
        catch (err) {
            console.log(err);
        }
        return flowManager.journey.updateJourney(flowManager.modelValue._id, { ...data }).pipe(

            tap((result) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap((result) => {
                onSuccess(result);
            }),
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);
            }),
            tap(_ => flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading())
        )
    },

};


export default mab;
