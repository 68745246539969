import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from "app/layouts/services/flow-manager/flow-manager.service";
import { Observable, of, throwError } from "rxjs"
import { concatMap, finalize, tap } from "rxjs/operators"
import { Step } from "../..";

const stepThree:Step ={
    key:"stepThree",
    fields: [{
        key: "stepthree",
            type: "input",
            modelOptions:{
                updateOn:'blur'
            },
            templateOptions: {
                type:"text",
                label: "Step3",                    
                
                
                required: true,
            },

            className: "col-12 col-sm-12",
            
            
            hideExpression: (model: any, formState: any, field: FormlyFieldConfig) =>{
                return !model.gender
            }, 
    }],

    
    label:'Get your loan',

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        flowManager.startLoading()
        const payload = { ...data, location: flowManager.location };
        return flowManager.journey.updateJourney(flowManager.modelValue._id, payload).pipe(            
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap(() => flowManager.finish()),
            tap(_=>flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },

};


export default stepThree;
    