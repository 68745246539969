import { FormControl, ValidationErrors } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";




export function maxValidator(control: FormControl, field: FormlyFieldConfig, options: any): ValidationErrors {
  if (control.value) {
    return !isNaN(control.value) && parseFloat(control.value) <= options.max ? null : {
      'max': { message: `${field.templateOptions.label} cannot be greater than ${options.max}.` }
    };
  }
}
