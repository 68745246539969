import { Injectable, Injector } from '@angular/core';
import { TokenService } from 'app/services/token.service';
import { BehaviorSubject } from 'rxjs';
import { FlowManagerService } from '../flow-manager/flow-manager.service';



@Injectable({
  providedIn: 'root'
})
export class GtmTriggerService {

  private buttonTrigger: BehaviorSubject<ButtonTriggerEvent> = new BehaviorSubject(null);
  public buttonTrigger$ = this.buttonTrigger.asObservable();
  private buttonTriggerHistory: ButtonTriggerEvent[] = [];
  private flowManagerService: FlowManagerService;

  constructor(private tokenService: TokenService, private injectorSerice: Injector) {
    this.tokenService.token$.subscribe(() => {
      this.buttonTriggerHistory = [];
    })

  }

  triggerButtonClick($event: ButtonTriggerEvent) {

    if ($event.className.indexOf('upload') < 0) {
      this.flowManagerService = this.injectorSerice.get(FlowManagerService);
      let journey = this.flowManagerService.modelValue;
      if (journey) {
        if (!journey.gtmtags) {
          journey.gtmtags = $event.className.split(' ');
        } else {
          let tags = $event.className.split(' ');
          tags.forEach(function (value) {
            if (journey.gtmtags.indexOf(value) < 0) {
              journey.gtmtags.push(value);
            }
          });
        }
        this.flowManagerService.journey.updateJourney(journey._id, { gtmtags: journey.gtmtags }, true).subscribe({
          next: (response) => {
            this.flowManagerService.modelValue = { ...this.flowManagerService.modelValue, ...response }
          },
          error: (error) => { }
        });
      }
    }

    if (!this.buttonTriggerHistory.some(t => t.className === $event.className) || $event.ignoreHistory) {
      this.buttonTriggerHistory.push($event);
      this.buttonTrigger.next($event);
    }
  }
}


interface ButtonTriggerEvent {
  className: string;
  ignoreHistory?: boolean;
}