import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JourneyService {
  params: any;
  getLocation() {
    return this.http.get<any>("https://ipapi.co/json/");
  }

  constructor(private http: HttpClient,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });

  }

  isValidPincode(value: any) {
    return this.http.get<any>(`api/PincodeValidator`, { params: { value }, headers: { 'x-api-version': '2.0' } }).pipe(
      timeout(6000),
      catchError(err => of({ valid: true })));
  }

  createJourney(data: any): Observable<any> {
    console.log(this.params);
    return this.http.post<any>("api/Journey", data, { headers: { 'x-api-version': '2.0' }, params: this.params });
  }

  updateJourney(id: string, data: any, ignoreQualification?: boolean): Observable<any> {
    if (ignoreQualification) {
      return this.http.patch<any>(`api/Journey/${id}?&ignoreQualification=true`, data, { headers: { 'x-api-version': '2.0' } })
    } else {
      return this.http.patch<any>(`api/Journey/${id}`, data, { headers: { 'x-api-version': '2.0' } })
    }
  }

  updateLocation(id: string, data: any): Observable<any> {
    return this.http.patch<any>(`api/Journey/${id}/location`, data, { headers: { 'x-api-version': '2.0' } })
  }

  getJourney(params): Observable<any> {
    return this.http.get<any>(`api/Journey`, { headers: { 'x-api-version': '2.0' }, params })
  }

  applyGoldLoan(params): Observable<any> {
    return this.http.get<any>(`api/Journey/applyGoldLoan`, { headers: { 'x-api-version': '2.0' }, params })
  }

  initiateJazz(params): Observable<any> {
    return this.http.get<any>(`api/Journey/initiateJazz`, { headers: { 'x-api-version': '2.0' }, params })
  }

  updateExperianMaskNumber(data: any) {
    return this.http.patch(`/api/Experian/MaskNumber`, data, { headers: { 'x-api-version': '2.0' } })
  }

  validateExperianMaskNumber(data: any) {
    return this.http.post(`/api/Experian/MaskNumber/Validate`, data, { headers: { 'x-api-version': '2.0' } })
  }

  fetchReportFromMaskNumber() {
    return this.http.post(`/api/Experian/FetchReportFromMaskedMobile`, null, { headers: { 'x-api-version': '2.0' } })
  }

  maskNumberResendOtp() {
    return this.http.post(`/api/Experian/MaskNumber/ResendOtp`, null, { headers: { 'x-api-version': '2.0' } })
  }

  fetchGstrData(data) {
    return this.http.post(`/api/Gstr/FetchData`, data, { headers: { 'x-api-version': '2.0' } })
  }

  generateGstrOtp(data) {
    return this.http.post(`/api/Gstr/GenerateOtp`, data, { headers: { 'x-api-version': '2.0' } })
  }

  validateGstrOtp(data) {
    return this.http.post(`/api/Gstr/VerifyOtp`, data, { headers: { 'x-api-version': '2.0' } })
  }

  searchGstinByPan(data) {
    console.log(this.params)
    return this.http.post(`/api/Gst/SearchGstinByPan`, data, { headers: { 'x-api-version': '2.0' }, params: this.params })
  }

  selectGstin(data) {
    return this.http.post(`/api/Gst/SelectGstin`, data, { headers: { 'x-api-version': '2.0' } })
  }

  fetchTaxPayers(data) {
    return this.http.post(`/api/Gst/FetchTaxPayers`, data, { headers: { 'x-api-version': '2.0' } })
  }

  updateZippedFilePassword(data) {
    return this.http.post<any>(`/journey/UpdateZippedFilePassword`, data)
  }

  getJourneyStats(): Observable<any> {
    return this.http.get<any>(`Journey/GetStats`);
  }

  downloadZippedFile(data: { id: string, fileDetailsId: string, fileName: string }) {
    return this.http.get(`/Journey/DownloadZippedFile/`, { responseType: 'blob', params: data });
  }

  getExperianDetails(stagingJourneyId): Observable<any> {
    return this.http.get(`/api/Experian/GetExperianDetails?journeyId=${stagingJourneyId}`, { headers: { 'x-api-version': '2.0' } })
  }

  getGstDetailsByPan(panNumber): Observable<any> {
    return this.http.get(`/api/Gst/GetGstDetailsByPan?panNumber=${panNumber}`, { headers: { 'x-api-version': '2.0' } })
  }

  createBorrowerJourneyFromStagingJourney(stagingJourneyId): Observable<any> {
    return this.http.patch(`api/Journey/${stagingJourneyId}/CreateBorrowerJourneyFromStagingJourney`, {}, { headers: { 'x-api-version': '2.0' } })
  }
}
