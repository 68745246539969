import { FormControl } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { stateList } from "app/utils/constants";
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout, startWith, map, takeUntil } from "rxjs/operators"
import gstJourneyWorkflow from "..";
import workFlow from ".."
import { Step } from "../..";
import { askNegativeSectorQuestion } from "./business-sector.desktop.step";
import pincodeStateCodeAlert from "./pincode-state-code-alert";
import sectors from "./sectors";
import selectedSectors from "./selected-sectors";
import stateOptions from "./state-options";



const fields = (flowManager: FlowManagerService): Array<FormlyFieldConfig> =>
    [
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    key: 'businessname',
                    type: 'input',
                    templateOptions: {
                        label: 'Name of your business',
                        input: 'text',
                        required: true
                    },
                    hooks: {
                        onInit: ({ formControl }) => formControl.valueChanges.pipe(
                            startWith(formControl.value),
                            map(v => v || ""),
                            map(v => v.toUpperCase()),
                            tap(v => v == formControl.value ? {} : formControl.patchValue(v)),
                        ).subscribe()
                    },                    
                    className: 'col-12',
                    hideExpression: (model) => {    
                        const pan = model.pan;
                        return pan && pan.toLowerCase()[3] != 'p';
                    }
                },
                {
                    key: "business_vintage",
                    type: "question",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: `Do you have a legal business registration document 
            to prove business operation of 2 years or more?`,

                        options: [{ "label": "Yes", "value": "Yes" }, { "label": "No", "value": "No" }],
                        required: true,
                    },
                    className: "col-12 col-sm-12",


                },
            ],

        },
        {
            hideExpression: (model) => {               
                const businessVintageDisqualified = !model.business_vintage || model.business_vintage == "No";
                return businessVintageDisqualified || !model.businessname;
            },
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    key: "primarybusiness",
                    type: "question",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: "What is your main business activity?",

                        options: [
                            { "label": "Manufacturer", "value": "Manufacturer" },
                            { "label": "Wholesaler", "value": "Wholesaler" },
                            { "label": "Retailer", "value": "Retailer" },
                            { "label": "Service", "value": "Service" }
                        ],
                        required: true,
                    },
                    className: "col-12 col-sm-12",


                },
                {
                    key: "sector",
                    type: "select",
                    modelOptions: {
                        updateOn: 'change'
                    },
                    templateOptions: {

                        label: "Which sector do you operate in?",
                        options: sectors,
                        required: true,
                    },
                    hooks: {
                        onInit: (field) => {
                            let lastValue
                            field.formControl.valueChanges.subscribe((val) => {

                                if (lastValue != val && selectedSectors.some(v => v.value == val)) {
                                    field.templateOptions.alert = "You may be eligible for a loan more than ₹10 lacs if your financial health is satisfactory."
                                    lastValue = val;
                                }
                                else {
                                    field.templateOptions.alert = null;
                                    lastValue = null;
                                }
                            })
                        }
                    },
                    hideExpression: (model)=> !model.primarybusiness || askNegativeSectorQuestion(model),
                    className: "col-12 col-sm-12",
                },
                {
                    key: 'pincode',
                    type: 'pincode',
                    templateOptions: {
                        label: 'What is the PIN code of your office address? ',
                        placeholder: 'eg. 110011',
                        required: true,
                    },
                    className: 'col-12',
                    asyncValidators: {
                        isJammuKashmir: {
                            expression: (control: FormControl) => flowManager.journey.isValidPincode(control.value)
                                .pipe(
                                    map(data => !data.isJammuKashmir)),
                            message: (_error: any, field: FormlyFieldConfig) => `We are not present in this PIN code`
                        },
                        isValidPincode: {
                            expression: (control: FormControl) => flowManager.journey.isValidPincode(control.value)
                                .pipe(
                                    map(data => data.valid || data.isJammuKashmir)),
                            message: (_error: any, field: FormlyFieldConfig) => `The Pincode entered is not valid.`
                        }
                    },
                    hooks: {
                        onInit: (field) => {
                           pincodeStateCodeAlert(field,flowManager);
                        }
                    },
                    hideExpression: model=> (askNegativeSectorQuestion(model) && !model.primarybusiness) || (!askNegativeSectorQuestion(model) && !model.sector),
                },
            ]
        }
    ]


const businessDetailsDesktop: Step = {
    key: "businessDetailsDesktop",

    fields,

    hideBack: false,

    conversation: (model) => ({
        greeting: ``,
        message: `Let's get to know your business before we move ahead.
        `
    }),

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        flowManager.startLoading()

        if (data.business_vintage == "No") {
            data = {
                ...data,
                disqualified: true,
                disqualificationKey: `business_vintage`,
                disqualificationMessage: `Your profile isn't a good match for our lender partners. 
                Our lender partners currently only provide loans to businesses that are more than two years old. 
                Once you've been in business for more than two years please get back in touch and we will be happy to help.` }
        }



        const payload = { ...data };

        return flowManager.journey.updateJourney(flowManager.modelValue._id, payload).pipe(
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);
            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap((result) => {
                if (result.disqualified) {
                    flowManager.goto(gstJourneyWorkflow.disQualifiedMessage.key)
                } else {
                    if(askNegativeSectorQuestion(flowManager.modelValue)) {
                        flowManager.stepNext(); 
                    }
                    else {
                        flowManager.finish();
                    }
                }
            }),
            tap(_ => flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },
}

export default businessDetailsDesktop;
