import borrowerJourneyWorkflow from 'app/layouts/workflow/borrower-journey';
import { Flow } from '../workflow';
import flipkartBobJourneyWorkflow from '../workflow/flipkart-bob-journey';
import flipkartJourneyWorkflow from '../workflow/flipkart-journey';

function mapLocationOnData(data: { journey: any; step: any; qualified:boolean; }): any {
  let location: {
    workflowName:string;
    flowName:string;
    step: number
  } = {
    workflowName: borrowerJourneyWorkflow.key,
    flowName: borrowerJourneyWorkflow.basicDetails.key,
    step: 0
  };

  switch (data.journey) {
    case 'default':
      location.workflowName = borrowerJourneyWorkflow.key;
      break;
    case 'flipkart':
      location.workflowName = flipkartJourneyWorkflow.key;
      location.flowName = flipkartBobJourneyWorkflow.uploadDocument.key;
      break;
    case 'flipkart-bob':
      location.workflowName = flipkartBobJourneyWorkflow.key;
      location.flowName = flipkartBobJourneyWorkflow.uploadDocument.key;
      break;
    default:
      location.workflowName = borrowerJourneyWorkflow.key;
      break;
  }

  if(data.journey == 'default') {

    if(data.qualified) {
      return {
        workflowName: borrowerJourneyWorkflow.key,
        flowName: borrowerJourneyWorkflow.uploadDocument.key,
        step: 0
      }
    }


    switch (parseFloat(data.step)) {
      case 1.01:
        location.flowName = borrowerJourneyWorkflow.businessDetails.key;
        break;
      case 1.02:
        location.flowName = borrowerJourneyWorkflow.businessDetails.key;
        break;
      case 1.03:
        location.flowName = borrowerJourneyWorkflow.businessDetails.key;
        break;
      case 1.04:
        location.flowName = borrowerJourneyWorkflow.businessDetails.key;
        location.step = 1;
        break;
      case 1.05:
        location.flowName = borrowerJourneyWorkflow.businessDetails.key;
        location.step = 2
        break;
      case 1.06:
        location.flowName = borrowerJourneyWorkflow.businessDetails.key;
        location.step = 3
        break;
      case 1.07:
        location.flowName = borrowerJourneyWorkflow.businessDetails.key;
        location.step = 4
        break;
      case 1.08:
        location.flowName = borrowerJourneyWorkflow.businessDetails.key;
        location.step = 5;
        break;
      case 1.09:
        location.flowName = borrowerJourneyWorkflow.businessDetails.key;
        location.step = 6;
        break;
      case 1.10:
        location.flowName = borrowerJourneyWorkflow.businessDetails.key;
        location.step = 7;
        break;
      case 1.11:
        location.flowName = borrowerJourneyWorkflow.businessDetails.key;
        location.step = 8;
        break;
      case 1:
        location.flowName = borrowerJourneyWorkflow.businessDetails.key;
        break;
      default:
        location.flowName = borrowerJourneyWorkflow.basicDetails.key;
        break;
    }
  }

  return location;
}

export function mapLocation(options: {workflow:any, model:any }, data: { journey: any; step: any; qualified:boolean; }) {
  let map = mapLocationOnData(data);
  if (map.workflowName == options.workflow.key) {
    map.step = map.step - getStepDiff(options, map.flowName);
  }
  return map;
}

function getStepDiff(options: {workflow:any, model:any }, flowName: string): number {
  const flow: Flow = options.workflow[flowName];
  const total = flow.steps.length;
  const unskipped = flow.steps.filter(s => !s.skipOn ||  !s.skipOn(options.model)).length;
  return total - unskipped;
}
