import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filename'
})
export class FilenamePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let limit= window.innerWidth > 700? 48 : 24;
    if(args.length>0) {
      limit = args[0] as number;
    }
    if(value.length > limit) {
      return value.substring(0, limit/2) + '...' + value.substring(value.length-(limit/2),value.length);
    }
    return value;
  }

}
