import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GtmTriggerService } from 'app/layouts/services/gtm-trigger/gtm-trigger.service';

@Component({
  selector: 'app-gtm-button-trigger',
  templateUrl: './gtm-button-trigger.component.html',
  styleUrls: ['./gtm-button-trigger.component.css']
})
export class GtmButtonTriggerComponent implements AfterViewInit {

  @ViewChild("triggerable") triggerableBtn:ElementRef;

  constructor(private gtmTriggerService:GtmTriggerService) { }

  ngAfterViewInit(): void {
    this.gtmTriggerService.buttonTrigger$.subscribe(($event)=>{
      if($event) {
        this.triggerableBtn.nativeElement.className = $event.className;
        this.triggerableBtn.nativeElement.click();
        console.group("GTM Event");
        console.log(`Button clicked ${JSON.stringify($event)}`);
        console.groupEnd();
      }
    })
  }
}
