import { FormControl, ValidationErrors } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";




export function regexValidator(control: FormControl, field: FormlyFieldConfig, options: any): ValidationErrors {
  if (control.value) {
    return new RegExp(options.regex, "g").test(control.value) ? null : {
      'regex': { message: `${control.value} does not appear to be a valid ${field.templateOptions.label}.` }
    };
  }
}
