import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nsdl-loader-step',
  templateUrl: './nsdl-loader-step.component.html',
  styleUrls: ['./nsdl-loader-step.component.css']
})
export class NsdlLoaderStepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  conversation = (model) => ({
    greeting: ``,
    message:`Now let's check your PAN details.`
  })

}
