import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout, startWith, map } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";

const fields:(flowManager: FlowManagerService) => FormlyFieldConfig[] = (flowManager:FlowManagerService) => ([
    {
        key: 'businessname',
        type: 'input',
        templateOptions: {
            label: 'Name of your business',
            input: 'text',
            required: true
        },
        hooks: {
            onInit: ({ formControl }) => formControl.valueChanges.pipe(
                startWith(formControl.value),
                map(v => v || ""),
                map(v => v.toUpperCase()),
                tap(v => v == formControl.value ? {} : formControl.patchValue(v)),
            ).subscribe()
        },                    
        className: 'col-12',
    },
])

const businessName:Step ={
    key:"businessname",
    
    fields,

    skipOn: (model) => {
        const pan = model.pan;
        return pan && pan.toLowerCase()[3] != 'p';
    },    
  
    conversation: (model) => {
        return {
            greeting: ``,
            message: `Please introduce your business.`
        }
    },
    

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        flowManager.startLoading()
        return flowManager.journey.updateJourney(flowManager.modelValue._id, {...data, location: flowManager.location}).pipe(
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);
            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),           
            tap((result) =>  flowManager.stepNext()),           
            tap(_=>flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },

};


export default businessName;
    