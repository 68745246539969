import { mapLocation } from "app/layouts/constants/mapLocation";
import { FlowManagerService } from "app/layouts/services/flow-manager/flow-manager.service";
import { BorrowerJourneyWorkFlow } from "app/layouts/workflow/borrower-journey";
import { FlipkartBobJourneyWorkFlow } from "app/layouts/workflow/flipkart-bob-journey";
import { FlipkartJourneyWorkFlow } from "app/layouts/workflow/flipkart-journey";
import gstJourneyWorkflow, { GstJourneyWorkFlow } from "app/layouts/workflow/gst-borrower-journey";
import rm1Workflow, { Rm1WorkFlow } from "app/layouts/workflow/rm1";
import { TestWorkFlow } from "app/layouts/workflow/test";

type WorkFlow = GstJourneyWorkFlow
    | Rm1WorkFlow
    | BorrowerJourneyWorkFlow
    | FlipkartJourneyWorkFlow
    | FlipkartBobJourneyWorkFlow
    | TestWorkFlow
    | GstJourneyWorkFlow;

const startFlow = (flowManager: FlowManagerService, workflow: WorkFlow) => {
    if (flowManager.isMobile()) {
        flowManager.startWith({
            workflow: workflow,
            flowName: workflow.basicDetails.key,
            step: 0
        });
    }
    else {
        const theWorkflow = workflow as any;
        if (theWorkflow.basicDetailsDesktop) {
            flowManager.startWith({
                workflow: workflow,
                flowName: theWorkflow.basicDetailsDesktop.key,
                step: 0
            });
        }
        else {
            flowManager.startWith({
                workflow: workflow,
                flowName: workflow.basicDetails.key,
                step: 0
            });
        }
    }
}

const getRmJourney = (data: { archive: any[] }) => {

    if (data.archive && Array.isArray(data.archive) && data.archive.some(journey => journey.rm)) {
        return data.archive.find(journey => journey.rm)
    }
    return null;
}

export default function navigate(flowManager: FlowManagerService, data: any, workflow: WorkFlow) {
    const model = { ...data };
    delete model.status;
    flowManager.modelValue = model;
    if (!data.status) {
        if (data.archive) {
            const rmJoureny = getRmJourney(data);
            if (rmJoureny) {
                const rmModel = { ...rmJoureny,  journey:'default' };
                delete rmModel.archive;
                delete rmModel._id;
                delete rmModel.loanamount;
                delete rmModel.typeofloan;
                delete rmModel.zoho_entity_id;
                delete rmModel.CE_Lead_ID;
                delete rmModel.Deal_Id;
                delete rmModel.partnerId;
                delete rmModel.flipkart_pull_at;
                delete rmModel.flipkart_pull_statuscode;
                delete rmModel.flipkart_pull_success;
                delete rmModel.qualified;
                delete rmModel.disqualified;
                delete rmModel.disqualificationKey;
                delete rmModel.disqualificationMessage;
                delete rmModel.isZohoSynced;
                delete rmModel.isZohoUpdated;
                delete rmModel.isStorageNameSynced;
                delete rmModel.experian_masked_flow;
                delete rmModel.experian_model_result;
                delete rmModel.experian_score;
                delete rmModel.experian_success;
                delete rmModel.experian_unmasked_mobile_number;
                delete rmModel.experian_masked_mobile_numbers;
                delete rmModel.experian_masked_mobile_number;
                delete rmModel.masknumber_validated;
                delete rmModel.documents_submitted;
                delete rmModel.documents_submitted_at;
                delete rmModel.isExperianReportSynced;
                flowManager.modelValue = rmModel;
                if (workflow.key != rmModel.rm) flowManager.navigate(rmModel.rm);
                else
                    startFlow(flowManager, workflow);
            }
            else {
                if (workflow.key == rm1Workflow.key)
                    flowManager.navigate(gstJourneyWorkflow.key);
                else
                    startFlow(flowManager, workflow);
            }
        }
        else {
            const options = { workflow: workflow, model: flowManager.modelValue };
            const locationV1 = { journey: data.journey, step: data.step, qualified: model.qualified };
            const location = data.location || mapLocation(options, locationV1);
            const { workflowName } = location;
            if (workflow.key != "flipkartJourneyWorkflow" && workflow.key != workflowName) {
                flowManager.navigate(workflowName);
            }
            flowManager.startWith({ ...location, workflow: workflow });
        }
    }
    else if (data.status == 404) {
        if (workflow.key == rm1Workflow.key)
            flowManager.navigate(gstJourneyWorkflow.key);
        else
            startFlow(flowManager, workflow);
    }
    else {
        flowManager.gotoLogin();
    }
}
