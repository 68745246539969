import { Component, Input, OnInit, ViewEncapsulation, OnDestroy, ViewChild, EventEmitter, Output, HostListener, ViewChildren, QueryList } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../../services/token.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { setClarity } from '../../../utils/functions';
import { UserIdleService } from 'angular-user-idle';
import { Subscription, timer, Subject } from 'rxjs';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ExperianResponse } from '../../../definitions/experian-report';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { documentList } from 'app/layouts/constants/document-list.constant';
import { documentCategories } from 'app/layouts/constants/depreciated/document-categories.constant';
import { GtmTriggerService } from 'app/layouts/services/gtm-trigger/gtm-trigger.service';
import categories, { getValidCategorySteps, hasDocs, ICategory } from 'app/layouts/constants/document-categories.modern.constant';
import { DocumentCategoryComponent } from '../document-category/document-category.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { switchMap, takeUntil, repeatWhen, delay } from 'rxjs/operators';
import { FileManagerService } from 'app/layouts/services/file-manager/file-manager';
import gstJourneyWorkflow from 'app/layouts/workflow/gst-borrower-journey';
import { PerfiosService } from 'app/layouts/services/perfios/perfios.service';

@Component({
  selector: 'app-document-upload-modern-step',
  templateUrl: './document-upload-modern-step.component.html',
  styleUrls: ['./document-upload-modern-step.component.scss'],
})
export class DocumentUploadModernStepComponent implements OnInit, OnDestroy {
  private subscription: Subscription
  @ViewChild("stepper") stepper;
  categories: ICategory[];
  borrower: any;
  @ViewChildren(DocumentCategoryComponent) categoryComponents!: QueryList<DocumentCategoryComponent>;
  devForm: FormGroup;
  isProduction: boolean;
  categoriesCode: string[] = [];
  @ViewChild('completeApplicationSwal') completeApplicationSwal!: SwalComponent;
  swalMessage: string = '';
  syncCRMTimeout: any;
  perfiosBankStatementExist: boolean = false;
  perfiosAttempted: boolean = false;
  private stopPolling$ = new Subject<void>();

  constructor(private http: HttpClient,
    private flowManager: FlowManagerService,
    private fb: FormBuilder,
    private tokenService: TokenService,
    private fileManager: FileManagerService,
    private perfiosService: PerfiosService,
    private gtmService: GtmTriggerService) {
    this.isProduction = true;//environment.production;
    this.devForm = this.fb.group({
      borrower: [JSON.stringify({})],
    });
  }

  devSubmit() {
    const value = this.devForm.controls['borrower'].value;
    console.log(value);
    const model = JSON.parse(value);
    console.log(model);
    this.changeBorrower(model);
  }

  changeBorrower(model) {
    this.borrower = model;
    this.categories = categories.filter(category => getValidCategorySteps(category.steps, this.borrower).length > 0);
  }

  ngOnInit(): void {
    if (!this.isProduction) {
      this.categoriesCode = categories.map(category => JSON.stringify(category, function (key, value) {
        if (typeof value === 'function') {

          return value.toString().replace(/\\/g, '').replace(/\n/g, `<br />`);
        } else {
          return value;
        }
      }, 4));

      this.changeBorrower({});
      this.isProduction = environment.production;

      if (this.tokenService.hasToken()) {
        this.flowManager.model$.subscribe(model => {
          this.changeBorrower(model);
          this.devForm.patchValue({ borrower: JSON.stringify(model, null, 4) })
        });

        this.http.get('/journey/get').subscribe((response: any) => {
          this.fileManager.setFiles(response.files);
        });
      }
    }
    else {
      this.flowManager.model$.subscribe(model => {
        this.changeBorrower(model);
      });

      this.http.get('/journey/get').subscribe((response: any) => {
        this.fileManager.setFiles(response.files);
      });

      this.fileManager.files$.subscribe((files) => {
        if (files.length == 1) {
          this.gtmService.triggerButtonClick({ className: "uploaded-first-document" });
        }

        const newfiles = files.filter(file => file.isNew);
        newfiles.forEach(file => {
          this.gtmService.triggerButtonClick({ className: `upload-${file.documentType}`, ignoreHistory: true });
        });

        if (this.syncCRMTimeout) {
          window.clearTimeout(this.syncCRMTimeout);
        }
        this.syncCRMTimeout = setTimeout(() => this.syncCRM(), 30000);
      });
    }

    this.startPolling();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.stopPolling$.next();
    this.stopPolling$.complete();
  }

  private startPolling() {
    const payload = { phone: this.flowManager.mobileNumber }
    timer(0, 1 * 60 * 1000) // Initial delay: 0 ms, Interval: 1 minute
      .pipe(
        switchMap(() => this.perfiosService.getPerfiosBankStatement(payload)),
        repeatWhen((completed) => completed.pipe(delay(1000))), // Delay for 1 second before repeating
        takeUntil(this.stopPolling$)
      )
      .subscribe(
        (data) => {
          if (data.success) {
            console.log('Fetched data:', data);
            this.perfiosBankStatementExist = true;
            // Stop polling once the response indicates success
            this.stopPolling$.next();
            this.stopPolling$.complete();
            this.stepper.next();
          }
          console.log("atempt : " + data.perfios_attempted)
          this.perfiosAttempted = data.perfios_attempted;
        },
        (error) => {
          console.error('Error fetching data:', error);
        }
      );
  }


  conversation = (model) => ({
    greeting: ``,
    message: `Please upload the following documents within 24 hours to get a loan within 2-3 days`
  })

  completeApplication() {
    const emptyCategoryIndex = this.categories
      .findIndex(category =>
        !category.steps.some(step =>
          step.sections.some(section => hasDocs(section, this.borrower) &&
            this.fileManager.filesValue.some(f => f.documentCategory == section.doc_prepend))));

    let emptyStepIndex = null;
    if (emptyCategoryIndex && emptyCategoryIndex > -1) {
      emptyStepIndex = this.categories[emptyCategoryIndex].steps.findIndex(step =>
        step.sections.some(section => hasDocs(section, this.borrower) &&
          !this.fileManager.filesValue.some(f => f.documentCategory == section.doc_prepend)))

      let xyz = this.categories[emptyCategoryIndex].steps.filter(step =>
        step.sections.some(section => hasDocs(section, this.borrower) &&
          !this.fileManager.filesValue.some(f => f.documentCategory == section.doc_prepend)))
      console.log(xyz);
    }
    setTimeout(() => {
      if (emptyCategoryIndex > -1) {
        this.stepper.selectedIndex = emptyCategoryIndex;
        const categoryComponent = this.categoryComponents.toArray()[emptyCategoryIndex];
        this.swalMessage = `Please submit your ${categoryComponent.category.name} documents!`
        setTimeout(() => this.completeApplicationSwal.fire().then(success => console.log(success), err => console.log(err)), 100);
        categoryComponent.setStep(emptyStepIndex);
      }
      else {
        this.gtmService.triggerButtonClick({ className: "uploaded-all-categories" });
        this.documentUploadCompleted();
        this.flowManager.goto(gstJourneyWorkflow.thankYou.key);
      }
    });
  }

  documentUploadCompleted() {
    let url = '/journey/documentuploadcomplete';
    if (this.borrower.journey && this.borrower.journey == 'dsa') {
      url += `?mobile=${this.borrower.userId}`;
    }
    console.log("documentUploadCompleted called!");
    this.http.post(url, null).subscribe((res: any) => console.log(res), err => console.log(err));
  }

  syncCRM() {
    this.syncCRMTimeout = null;
    console.log("CRMUpdateDocumentUploaded called!");
    this.http.post('/journey/CRMUpdateDocumentUploaded', null).subscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    if (this.syncCRMTimeout) {
      this.syncCRM();
    }
  }
}

