import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { BorrowerJourneyLayoutRoutingModule } from './borrower-journey-layout-routing.module';
import formlyConfig from '../borrower-journey-formly/formly.config';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { BorrowerJourneyFormlyModule } from '../borrower-journey-formly/borrower-journey-formly.module';
import { GaugeChartModule } from 'angular-gauge-chart';

/*Material UI*/
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/*Pages*/
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from '../components/login/login.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxMaskModule } from 'ngx-mask';
import { FlipkartComponent } from './pages/flipkart/flipkart.component';
import { FlowManagerComponent } from './components/flow-manager/flow-manager.component';
import { FlipkartBobComponent } from './pages/flipkart-bob/flipkart-bob.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { RouterModule } from '@angular/router';
import { BorrowerJourneyLayoutComponent } from './borrower-journey-layout.component';
import { CountdownModule } from 'ngx-countdown';
import { GstFlowComponent } from './pages/gst-flow/gst-flow.component';
import { GstFlowNewComponent } from './pages/gst-flow-new/gst-flow-new.component';
import { Rm1FlowComponent } from './pages/rm1-flow/rm1-flow.component';
import { TestComponent } from './pages/test/test.component';
import { StatusComponent } from './pages/status/status.component';

@NgModule({
  declarations: [
    BorrowerJourneyLayoutComponent,
    HomeComponent,
    FlipkartComponent,
    FlowManagerComponent,
    FlipkartBobComponent,
    GstFlowComponent,
    GstFlowNewComponent,
    Rm1FlowComponent,
    TestComponent,
    StatusComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BorrowerJourneyFormlyModule,
    FormlyBootstrapModule,
    FormlySelectModule,
    NgxMaskModule.forChild(),
    GaugeChartModule,
    CarouselModule,
    /*Material UI*/
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    UiSwitchModule,
    CountdownModule
  ]
})
export class BorrowerJourneyLayoutModule { }
