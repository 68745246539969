import { Component, Input, OnInit, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-file-svg-enabled',
  templateUrl: './file-svg-enabled.component.html',
  styleUrls: ['./file-svg-enabled.component.css']
})
export class FileSvgEnabledComponent implements OnInit {
  @Input() filename;
  format: any;

  constructor() { }

  ngOnInit(): void {
    this.format = this.filename.substring(this.filename.length-4, this.filename.length).toLowerCase();
  }

  ngOnChanges(value:SimpleChange) {
    this.format = this.filename.substring(this.filename.length-4, this.filename.length).toLowerCase();
  }

}
