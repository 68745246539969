import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import gstJourneyWorkflow from 'app/layouts/workflow/gst-borrower-journey';
import { throwError } from 'rxjs';
import { catchError, concatMap, delay, finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-business-selection-step',
  templateUrl: './business-selection-step.component.html',
  styleUrls: ['./business-selection-step.component.scss'],
})
export class BusinessSelectionStepComponent implements OnInit, AfterViewInit {
  form: FormGroup
  @ViewChild("tooltip") tooltip: NgbTooltip;
  tooltipMessage = ``
  isNextDisable = false;
  is5LacTranche: boolean;

  get controls() {
    return this.form.controls;
  }

  get model() {
    return this.flowManager.modelValue;
  }

 

  checkNextButtonStatus() {
    const checker = () => {
      this.isNextDisable = this.flowManager.isGstinsEmpty
    }
    checker();
    this.flowManager.model$.pipe(delay(0)).subscribe(() => checker())
  }

  checkTooptip() {
    if (this.tooltip) {
      if (this.flowManager.isGstinsEmpty) {
        setTimeout(() => {
          if(this.tooltip) {
            this.tooltip.disableTooltip = false;
            this.tooltipMessage = "None of your GSTINs seems to be active you can apply for an unregistered business here!";
            this.tooltip.open();
          }          
        })
      }
      else {
        this.tooltip.disableTooltip = true;
        this.tooltipMessage = "";
      }
    }

  }

  

  selectDefault() {
    if (this.model.selected_gstin) {
      const selected = this.flowManager.gstins.find(g => g.gstin == this.model.selected_gstin);
      this.form.controls.selected_gstin.patchValue(selected);
    }
    else if (this.flowManager.gstins.length == 1 && this.flowManager.gstins[0].isActive && !this.model.selected_gstin) {
      this.form.controls.selected_gstin.patchValue(this.flowManager.gstins[0]);
    }
  }

  constructor(public flowManager: FlowManagerService, private fb: FormBuilder) {
    this.form = this.fb.group({
      selected_gstin: [null, [Validators.required]],
    });

    this.flowManager.model$.subscribe((model) => {
      this.selectDefault();
      this.checkTooptip();
     
      this.is5LacTranche = model.loanamount == "Rs 1-5 Lakhs";
    });
  }

  ngAfterViewInit(): void {
    this.selectDefault();
    this.checkTooptip();
   
  }

  ngOnInit(): void {
    this.selectDefault();
    this.checkTooptip();
    this.checkNextButtonStatus();
    
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const flowManager = this.flowManager;
    flowManager.startLoading();
    flowManager.stepNext();
    flowManager.journey.selectGstin({ selectedGstin: this.form.controls.selected_gstin.value.gstin })
      .pipe(
        catchError(err => {
          flowManager.stepPrevious();
          flowManager.throwError(err);
          return throwError(err);
        }),
        tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
        tap(this.handleSubmitResponse(flowManager)),
        tap(_ => flowManager.saveLocation().subscribe()),
        finalize(() => flowManager.stopLoading()),
      )
      .subscribe()
  }

  private handleSubmitResponse(flowManager: FlowManagerService): (x: any) => void {
    return (result) => {
      if (result.disqualified) {
        if (result.disqualificationKey == 'experian') {
          if (result.experian_score && result.is_experian_score_below_threshold) {
            flowManager.gtmTriggerService.triggerButtonClick({ className: 'experian-disqualified' });
          } else {
            flowManager.gtmTriggerService.triggerButtonClick({ className: 'model-disqualified' });
          }
        }
        flowManager.goto("disQualifiedMessage");
      }
      else {
        flowManager.finish();
      }
    };
  }

  back() {
    try{
      this.flowManager.modelValue = {
        ...this.flowManager.modelValue,
        selected_gstin: this.form.controls.selected_gstin.value.gstin
      };
    }
    catch{ }

    this.flowManager.stepPrevious();
  }

  conversation = (model) => ({
    greeting: `Great to meet you ${model.first_name}.`,
    message: ` 
    Please help me understand your business so I can check your eligibility and 
    help you get the right loan for your business. 
    This should only take a second. 
    `
  })

  skip() {
    const [activeGstin] = this.flowManager.gstins.filter((gstin: { isActive: any; }) => gstin.isActive);
    if (activeGstin) {
      this.flowManager.modelValue = { ...this.flowManager.modelValue, pincode: activeGstin.pincode }
    }
    if (this.flowManager.isMobile()) {
      this.flowManager.goto(gstJourneyWorkflow.businessDetails.key);
    }
    else {
      this.flowManager.goto(gstJourneyWorkflow.businessDetailsDesktop.key);
    }
  }
}
