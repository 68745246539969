import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { FileManagerService } from 'app/layouts/services/file-manager/file-manager';
import { saveAs } from 'file-saver';
import { delay } from 'rxjs/operators';
import { ZipFileInfoComponent } from '../zip-file-info/zip-file-info.component';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {
  files: any[];
 

  @Input() section;
  @Input() document;

  
  constructor(private http: HttpClient, public dialog: MatDialog, private fileManager: FileManagerService) { }

  ngOnInit(): void {
    this.files = [];
    this.fileManager.files$.pipe(
      delay(100)
    ).subscribe(files => {      
      const copyFiles = files
      .filter(f => f.documentCategory == this.section.doc_prepend)
      .filter(d => d.documentName == this.document.name);
      this.files = JSON.parse(JSON.stringify(copyFiles));
      
    });
  }

  ngOnChanges(change: SimpleChange) {
    //console.log(change);
  }

  
}
