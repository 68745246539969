import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Step } from "app/layouts/workflow";



@Component({
  selector: 'app-formly-step',
  templateUrl: './formly-step.component.html',
  styleUrls: ['./formly-step.component.css']
})
export class FormlyStepComponent implements OnInit, AfterViewInit {

  private _step:Step;
  private _fields:FormlyFieldConfig[];

  @Input() set step(value:Step){
    this._step = value;
    const fields = this._step.fields as any;
    if(Array.isArray(fields)) this._fields = fields;
    if(fields instanceof Function) this._fields = fields(this.flowManager);
  }; 

  get step() {
    return this._step;
  }

  get fields() {
    return this._fields;
  }

  @Input() model:any;
  private _form = new FormGroup({});

  public get form() {
    return this._form;
  }

  constructor(public flowManager:FlowManagerService) { 
    
  }

  ngAfterViewInit(): void {
    
  }

  ngOnInit(): void {

  }  

  public submit() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      this.flowManager.setAllTouched(true);
    }
    else {
      this.step.next(this._form.value, this.flowManager)
      .subscribe(()=>{      
        
      });
    }    
  }
}
