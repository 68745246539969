import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";
import negativeSectors from "./negative-sectors";
import sectors from "./sectors";
import selectedSectors from "./selected-sectors";

export const askNegativeSectorQuestion = (model: { loanamount: string; }) => (model.loanamount == "Rs 1-5 Lakhs" || model.loanamount == "Rs 5-10 Lakhs"); 



const fields: (flowManager: FlowManagerService) => FormlyFieldConfig[] = (flowManager: FlowManagerService) => (
    [{
        fieldGroupClassName: 'row',
        fieldGroup: [
            {
                key: "primarybusiness",
                type: "question",
                modelOptions: {
                    updateOn: 'change'
                },
                templateOptions: {

                    label: "What is your main business activity?",       

                    options: [{ "label": "Manufacturer", "value": "Manufacturer" }, { "label": "Wholesaler", "value": "Wholesaler" }, { "label": "Retailer", "value": "Retailer" }, { "label": "Service", "value": "Service" }],
                    required: true,
                },
                className: "col-12 col-sm-12",


            }            
        ]
    },
    {
        key: "sector",
        type: "select",
        modelOptions: {
            updateOn: 'change'
        },
        templateOptions: {
    
            label: "Which sector do you operate in?", 
            options: sectors,
            required: true,
        },
        hooks:{
            onInit:(field) =>{
                let lastValue
                field.formControl.valueChanges.subscribe((val)=>{
                   
                    if(lastValue!= val && selectedSectors.some(v=> v.value == val)) {
                        field.templateOptions.alert = "You may be eligible for a loan more than ₹10 lacs if your financial health is satisfactory.";
                        lastValue = val;
                    }
                    else {
                      field.templateOptions.alert = null;
                      lastValue = null;
                    }
                })
            }
        },
        hideExpression: model=>{
            const primaryBusinessNotSelected = !model.primarybusiness;      
            return primaryBusinessNotSelected || askNegativeSectorQuestion(model);
        },
        className: "col-12 col-sm-12",
    
    
    }]
);

const businessSectorDesktop: Step = {
    key: "businessSectorDesktop",

    fields,


    conversation: (model) => ({
        greeting: `Great to meet you ${model.first_name}.`,
        message: ` 
        Please help me understand your business so I can check your eligibility and 
        help you get the right loan for your business. 
        This should only take a second. 
        `
    }),


    next: (data: any, flowManager: FlowManagerService): Observable<any> => {


        flowManager.startLoading()
        return flowManager.journey.updateJourney(flowManager.modelValue._id, { ...data, location: flowManager.location }).pipe(

            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap(() => {
                if(askNegativeSectorQuestion(flowManager.modelValue)) {
                    flowManager.stepNext(); 
                }
                else {
                    flowManager.finish();
                }
            }),
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);
            }),
            tap(_ => flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },

};


export default businessSectorDesktop;
