import { Flow } from "..";
import stepOne from "./steps/one.step";
import stepThree from "./steps/three.step";
import stepTwo from "./steps/two.step";

export interface FlipkartBobJourneyWorkFlow {
    key:string,
    startFlowName:string,
    linearFlows:string[],
    login: Flow,
    basicDetails: Flow,
    uploadDocument: Flow,
}

const flipkartBobJourneyWorkflow: FlipkartBobJourneyWorkFlow = {
    key: 'flipkartBobJourneyWorkflow',
    startFlowName: 'login',
    linearFlows: ['basicDetails'],
    login: {
        key: "login",

        steps: [
            {
                key:"login",
                type: "FlipkartBobLoginComponent",
            },
        ],
        onFinish: (model, flowManager) => {
            //flowManager.goto(flipkartBobJourneyWorkflow.basicDetails.key);
            //redirection after token change is handled in home pages
        },
        onError: (error, flowManager) => {
            
        },
    },

    basicDetails: {
        key: "basicDetails",
        steps: [
            stepOne,
            stepTwo, //Placeholder step not to execute
            stepThree //Placeholder step not to execute
        ],

        onFinish: (model, flowManager) => {
            flowManager.goto(flipkartBobJourneyWorkflow.uploadDocument.key, { excludeHistory: true });
        },
        onError: (error, flowManager) => {
            
        },
    },

    uploadDocument: {
        key: "uploadDocument",

        steps: [
            {
                key:"uploadDocument",
                type: "FlipkartDocumentUploadStepComponent",
            },
        ],
        onFinish: (model, flowManager) => {
           
        },
        onError: (error, flowManager) => {
            
        },
    },
};

export default flipkartBobJourneyWorkflow;