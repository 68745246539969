import { FormControl } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core"
import { FlowManagerService } from 'app/layouts/services/flow-manager/flow-manager.service';
import { Observable, of, throwError } from "rxjs"
import { catchError, delay, finalize, concatMap, retry, tap, timeout, startWith, map, takeUntil } from "rxjs/operators"
import workFlow from ".."
import { Step } from "../..";
import pincodeStateCodeAlert from "./pincode-state-code-alert";

const fields = (flowManager: FlowManagerService): Array<FormlyFieldConfig> =>
    [
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                
                {
                    key: 'pincode',
                    type: 'pincode',
                    templateOptions: {
                        label: 'What is the PIN code of your office address? ',
                        placeholder: 'eg. 110011',
                        required: true,
                    },
                    className: 'col-12',                  
                    asyncValidators: {
                        isJammuKashmir: {
                            expression: (control: FormControl) => flowManager.journey.isValidPincode(control.value)
                                .pipe(
                                    map(data => !data.isJammuKashmir )),
                            message: (_error: any, field: FormlyFieldConfig) => `We are not present in this PIN code`
                        },
                        isValidPincode: {
                            expression: (control: FormControl) => flowManager.journey.isValidPincode(control.value)
                                .pipe(
                                    map(data => data.valid || data.isJammuKashmir)),
                            message: (_error: any, field: FormlyFieldConfig) => `The Pincode entered is not valid.`
                        }
                    },
                    hooks: {
                        onInit: (field) => {
                           pincodeStateCodeAlert(field,flowManager);
                        }
                    }
                },         
            ]
        }
    ]


const businessPincode: Step = {
    key:"businessPincode",
    
    fields,

    hideBack: false,

    conversation: (model) => ({
        greeting: ``,
        message: `Let's get to know your business before we move ahead.
        `
    }),

    next: (data: any, flowManager: FlowManagerService): Observable<any> => {
        flowManager.startLoading()
        const payload = { ...data };
     
        return flowManager.journey.updateJourney(flowManager.modelValue._id, payload).pipe(
            catchError(err => {
                flowManager.throwError(err);
                return throwError(err);
            }),
            tap((result: any) => flowManager.modelValue = { ...flowManager.modelValue, ...result }),
            tap(() => flowManager.finish()),
            tap(_=>flowManager.saveLocation().subscribe()),
            finalize(() => flowManager.stopLoading()),
        )
    },
}

export default businessPincode;
