import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-score-gauge-meter',
  templateUrl: './score-gauge-meter.component.html',
  styleUrls: ['./score-gauge-meter.component.css']
})
export class ScoreGaugeMeterComponent implements OnInit { 

  public canvasWidth = 150
  @Input() public needleValue = 700
  public options:any = {
    hasNeedle: true,
    needleColor: "black",
    needleStartValue: 0,
    arcColors: ["rgb(255,84,84)", "rgb(239,214,19)", "rgb(61,204,91)"],
    arcDelimiters: [30, 70],
    rangeLabel: ["0", "900"],
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let innerWidth = window.innerWidth;
    
    if (innerWidth > 576) {
      this.canvasWidth = 200
    } else {
      this.canvasWidth = 150
    }
  }


  constructor() { }

  ngOnInit(): void {

  }

}
