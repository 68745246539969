import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileProtected'
})
export class FileProtectedPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    return  value && value.isProtected;
  }

}
