import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { getValidCategorySteps, getValOrParseFunc, ICategory, ICategoryStep, Info, } from 'app/layouts/constants/document-categories.modern.constant';
import { FileManagerService } from 'app/layouts/services/file-manager/file-manager';
import { environment } from 'src/environments/environment';
import { DocumentCategoryStepModalComponent } from '../document-category-step-modal/document-category-step-modal.component';

@Component({
  selector: 'app-document-category',
  templateUrl: './document-category.component.html',
  styleUrls: ['./document-category.component.scss'],
})
export class DocumentCategoryComponent implements OnInit {

  @Input() category: ICategory;
  @Input() borrower;


  @ViewChild("stepper") stepper: MatStepper;
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Output() completeApplication = new EventEmitter();
  @Output("fileChanged") fileChangedEvent = new EventEmitter<any>();
  steps: ICategoryStep[];
  nextStep: ICategoryStep;
  prevStep: ICategoryStep;

  @Input() totalCategory: number;
  @Input() currentCategoryIndex: number;
  @Input() perfiosBankStatementExist: boolean;
  @Input() perfiosAttempted: boolean;
  title: any;
  disableCta: boolean;
  nextStepName: any;
  prevStepName: any;

  constructor(public dialog: MatDialog, private fileManager: FileManagerService) { }


  ngOnInit(): void {
    this.steps = getValidCategorySteps(this.category.steps, this.borrower);
    this.updateStepperInfo();
    this.fileManager.files$.subscribe(() => this.updateStepperInfo());
  }

  ngOnChanges(change: SimpleChanges) {
    this.steps = getValidCategorySteps(this.category.steps, this.borrower);
    this.updateStepperInfo();
  }

  public setStep(emptyStepIndex: any) {
    this.stepper.selectedIndex = emptyStepIndex;
    this.updateStepperInfo();
  }

  get currentStepIndex() {
    return !this.stepper || this.stepper.selectedIndex == -1 ? 0 : this.stepper.selectedIndex;
  }

  private updateStepperInfo() {
    const currentStepIndex = this.currentStepIndex;

    this.nextStep = this.steps[currentStepIndex + 1];
    this.prevStep = this.steps[currentStepIndex - 1];
    this.nextStepName = this.nextStep ? getValOrParseFunc(this.nextStep.name, this.borrower) : "";
    this.prevStepName = this.prevStep ? getValOrParseFunc(this.prevStep.name, this.borrower) : "";

    const isSectionRequired = (
      this.steps[currentStepIndex].sections.length == 1 ?
        this.steps[currentStepIndex].sections[0].required : true
    );

    const isSectionEmpty = this.steps[currentStepIndex].sections.some(section =>
      !this.fileManager.filesValue.some(f => f.documentCategory == section.doc_prepend));

    this.disableCta = isSectionRequired && isSectionEmpty;

    this.title = this.category.name;
    if (this.category.steps.length == 1) { this.title = getValOrParseFunc(this.steps[0].name, this.borrower) };
  }

  stepNext() {
    if (this.stepper.selectedIndex == this.steps.length - 1) {
      this.next.emit();
    }
    else {
      this.stepper.next();
    }
    this.updateStepperInfo();
  }

  stepPrevious() {
    if (this.stepper.selectedIndex == 0) {
      this.previous.emit();
    }
    else {
      this.stepper.previous();
    }
    this.updateStepperInfo();
  }

  stepPreviousSubSection($event) {
    this.stepper.previous();
    this.updateStepperInfo();
    $event.preventDefault()
  }

  stepNextSubSection($event) {
    this.stepper.next();
    this.updateStepperInfo();
    $event.preventDefault()
  }

  showInfoModal() {
    const dialogRef = this.dialog.open(DocumentCategoryStepModalComponent, {
      width: '80%',
      disableClose: false,
      hasBackdrop: true
    });
    const info = getValOrParseFunc(this.steps[this.currentStepIndex].sections[0].info, this.borrower) as Info;
    dialogRef.componentInstance.info = {
      header: info.title,
      overview: info.overview,
      description: info.description
    };
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
